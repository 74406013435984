import { FormControl, MenuItem, Select } from '@mui/material';
import { Box } from '@/components/muis/box';
import Typography from '@/components/muis/typography';
import { styled } from 'styled-components';
import React, { useEffect, useState } from 'react';
import { useController } from 'react-hook-form';
import { ISelect } from '../../../types/opportunity/modify-opportunity';
import { CheckedItems, WrapperHead } from '../';
import { AddSelect } from './add-selects';
import { ListSelects } from './list-selects';

interface ConstructorSelectorProps {
  title: string;
  selects: ISelect[];
  isWarning?: boolean;
  tooltip?: string;
  className?: string;
  chooseOnlyOneSelect?: boolean;
  defaultValue?: string;
  changeProperties: (value: ISelect[]) => void;
  nameField: string;
  addNewText: string;
  control: any;
  rules: any;
  resetField: any;
  setError: any;
}

export const ConstructorSelector: React.FC<ConstructorSelectorProps> = ({
  title,
  selects,
  isWarning,
  nameField,
  tooltip,
  className,
  chooseOnlyOneSelect = false,
  defaultValue = '',
  changeProperties,
  addNewText,
  control,
  rules,
  resetField,
  setError
}) => {
  const {
    field,
    fieldState: { invalid },
    formState: { errors, isSubmitted }
  } = useController({ name: nameField, control, rules });
  const [isAdding, setIsAdding] = useState(false);
  const [searchValue, setSearchValue] = useState<string>('');
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const getSelectsWithoutDeletedSelect = (item: string) =>
    selects.map((select) => ({
      ...select,
      checked: select.text === item ? false : select.checked
    }));
  const updateSelects = (updatedSelects: ISelect[]) =>
    changeProperties(updatedSelects);
  useEffect(() => {
    if (!isSubmitted) return;
    const isOneItemChecked = selects.some(({ checked }) => checked);
    isOneItemChecked
      ? resetField(nameField, { keepError: false })
      : setError
        ? setError(nameField, {
            type: 'chosenItem',
            message: 'Select items'
          })
        : null;
  }, [isSubmitted, selects]);
  return (
    <Box sx={{ width: '100%' }} className={className}>
      <WrapperHead title={title} isWarning={isWarning} tooltip={tooltip} />
      <FormControl sx={{ width: '100%' }}>
        <CustomSelect
          defaultValue={defaultValue}
          valid={invalid.toString()}
          open={open}
          onOpen={handleOpen}
          onClose={handleClose}
          SelectDisplayProps={{ style: { padding: '4px 5px' } }}
          {...field}
        >
          <MenuItem sx={{ display: 'none' }} value={defaultValue}>
            {defaultValue}
          </MenuItem>
          {isAdding ? (
            <AddSelect
              selects={selects}
              searchValue={searchValue}
              setSearchValue={setSearchValue}
              setIsAdding={setIsAdding}
              updateSelects={updateSelects}
            />
          ) : (
            <ListSelects
              searchValue={searchValue}
              setSearchValue={setSearchValue}
              setIsAdding={setIsAdding}
              selects={selects}
              addNewText={addNewText}
              updateSelects={updateSelects}
              handleClose={handleClose}
              chooseOnlyOneSelect={chooseOnlyOneSelect}
            />
          )}
        </CustomSelect>
      </FormControl>
      {invalid && (
        <Typography sx={{ color: '#C60F0F' }}>
          {String(errors?.[nameField]?.message)}
        </Typography>
      )}
      <CheckedItems
        list={selects.filter(({ checked }) => checked).map(({ text }) => text)}
        deleteHandle={(item) =>
          updateSelects(getSelectsWithoutDeletedSelect(item))
        }
      />
    </Box>
  );
};

const CustomSelect = styled(Select)<{ valid: string }>`
  width: 100%;
  background: ${(props) =>
    props.valid === 'true' ? '#FFDFE5' : props.theme.primary.dark};
  border: ${(props) =>
    props.valid === 'true'
      ? '3px solid #ED9DAB'
      : `2px solid ${props.theme.secondary.light}`};
  border-radius: 8px;
  padding: 5px 20px;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  color: #32323280;
  box-shadow: none;
  .MuiOutlinedInput-notchedOutline {
    border: 0;
  }
  .MuiSelect-select {
    padding: 0;
  }
  .MuiSelect-icon {
    color: ${(props) => props.theme.font.action};
    transform: scale(1.3);
  }
`;
