import { NotesIcon } from '@/assets/icons/menu-icons/notes';
import { BurgerMenuIcon } from '@/assets/static/icons/burger-menu';
import { CloseMenuIcon } from '@/assets/static/icons/close-menu';
import Tabs, { ITab, MainTabsMobile, TabMobileWrap, TabsDetailsTitleMobile, TabsWrapMobile, TabTitle } from '@/components/basicComponents/fat-tabs';
import BasicTooltip from '@/components/basicComponents/tooltip';
import FormattingTooltip from '@/components/basicComponents/tooltip/dollarsTooltip';
import { INotesData } from '@/components/client-details/types';
import ProgressBar from '@/components/client-entity-details/progressBar';
import {
  ClientEntityAllocations,
  ClientEntityCommitments,
  ClientEntitySummary,
  ProgressBarValuesType
} from '@/components/client-entity-details/types';
import { useAuth } from '@/hooks/use-auth';
import { useResponsive } from '@/hooks/use-responsive';
import { ContainerPage } from '@/styles/common';
import { formatCash } from '@/utils/formatCash';
import guid from '@/utils/guid';
import { Dispatch, SetStateAction, useEffect, useMemo, useState } from 'react';
import styled, { useTheme } from 'styled-components';
import SimpleTable from './fat-assetClassSimpleTable';
import { AssetClassTargetAllocationDto, TabsEnum } from './types';

type DetailsProps = {
  currentTab: TabsEnum;
  setCurrentTab: (value: TabsEnum) => void;
  setIsNotesOpen: () => void;
  data: ClientEntitySummary | ClientEntityCommitments | ClientEntityAllocations | undefined;
  notesData: INotesData[] | null;
  setIsTabMenuOpen: Dispatch<SetStateAction<boolean>>;
  isTabMenuOpen: boolean;
  isGrouped?: boolean;
  ignoreAllocationTargets: boolean;
  ignoreBiteSize: boolean;
  handleClickMenuItem: (tab: TabsEnum) => void;
  disableDetails?: boolean;
  assetClassTableData: AssetClassTargetAllocationDto[];
  tabs: ITab[];
};

const Details = ({
  data,
  notesData,
  setIsNotesOpen,
  currentTab,
  setCurrentTab,
  isTabMenuOpen,
  setIsTabMenuOpen,
  isGrouped,
  ignoreAllocationTargets,
  ignoreBiteSize,
  handleClickMenuItem,
  disableDetails = false,
  assetClassTableData,
  tabs
}: DetailsProps) => {
  const { user } = useAuth();
  const theme = useTheme();
  const { isMobile, isDesktop, isTablet, isLargeDesktop } = useResponsive();

  const [detailsData, setDetailsData] = useState<ClientEntitySummary | ClientEntityCommitments | ClientEntityAllocations>();
  const [isShowDetails, setIsShowDetails] = useState(true);

  const detailsDataMemo = useMemo(() => {
    return data
      ? {
          ...data,
          privatePercentage: currentTab === TabsEnum.ALLOCATIONS && isGrouped ? data.privatePercentage * 100 : data.privatePercentage
        }
      : detailsData;
  }, [data]);

  useEffect(() => {
    if (data) {
      setDetailsData(data);
    }
  }, [data]);

  // const notesLength = useMemo(() => {
  //   return detailsDataMemo?.notes.filter(el => el.title && el.notes).length;
  // }, [data]);

  return (
    <DetailsWrap isMobile={isMobile} disableDetails={disableDetails}>
      {isDesktop || isTablet || isLargeDesktop ? (
        <>
          <TabsWrapper>
            <CustomTabs tabs={tabs} activeTab={currentTab} setActiveTab={(value) => setCurrentTab(value as TabsEnum)} />
            <NotesWrapper onClick={isShowDetails ? setIsNotesOpen : () => null}>
              <NotesCounterWrap id="openNotes">
                <NotesIcon id="openNotes" width={24} height={24} fill={theme.action.primary} />
                <NotesCounter id="openNotes">{notesData?.length}</NotesCounter>
              </NotesCounterWrap>
              <NotesTitle id="openNotes">Notes</NotesTitle>
            </NotesWrapper>
          </TabsWrapper>
          <TabsLine />
        </>
      ) : (
        <TabsWrapper>
          <TabsControl isMobile={isMobile}>
            {isTabMenuOpen ? (
              <CloseMenuIcon fill={theme.action.primary} onClick={() => setIsTabMenuOpen(!isTabMenuOpen)} style={{ cursor: 'pointer' }} />
            ) : (
              <BurgerMenuIcon fill={theme.action.primary} onClick={() => setIsTabMenuOpen(!isTabMenuOpen)} style={{ cursor: 'pointer' }} />
            )}
            <TabMobileWrap onClick={() => setCurrentTab(currentTab)} isActive>
              <TabTitle isActive isMobile={isMobile}>
                {currentTab}
              </TabTitle>
            </TabMobileWrap>
          </TabsControl>
          <NotesWrapper onClick={isShowDetails ? setIsNotesOpen : () => null}>
            <NotesCounterWrap id="openNotes">
              <NotesIcon id="openNotes" width={24} height={24} fill={theme.action.primary} />
              <NotesCounter id="openNotes">{notesData?.length}</NotesCounter>
            </NotesCounterWrap>
            <NotesTitle id="openNotes">Notes</NotesTitle>
          </NotesWrapper>
        </TabsWrapper>
      )}
      {isTabMenuOpen && isMobile && (
        <MainTabsMobile>
          {tabs.map((tab, tabId) => (
            <TabsWrapMobile key={tab.value} onClick={() => handleClickMenuItem(tab.value as TabsEnum)} isActive={currentTab === tab.value}>
              <TabsDetailsTitleMobile isActive={currentTab === tab.value}>{tab.value}</TabsDetailsTitleMobile>
            </TabsWrapMobile>
          ))}
        </MainTabsMobile>
      )}
      {(!isTabMenuOpen || !isMobile) && !disableDetails && (
        <DetailsBoby isMobile={isMobile} isOpen={isShowDetails}>
          <ExpandableWrapp isMobile={isMobile} isOpen={isShowDetails}>
            {!ignoreAllocationTargets && (
              <CardsContainer isMobile={isMobile} isOpen={isShowDetails}>
                <Card isMobile={isMobile}>
                  <CardTitle>
                    <FormattingTooltip zIndex={120}>{detailsDataMemo?.totalEntitiesValue as number}</FormattingTooltip>
                  </CardTitle>
                  <CardDesc>TOTAL ENTITY VALUE</CardDesc>
                </Card>
                <Card isMobile={isMobile}>
                  <CardTitle>{detailsDataMemo?.privatePercentage?.toFixed(2)}%</CardTitle>
                  <CardDesc>PRIVATE CAPITAL</CardDesc>
                </Card>
                <Card isMobile={isMobile}>
                  <CardTitle>{detailsDataMemo?.targetOCRatio?.toFixed(2)}x</CardTitle>
                  <CardDesc>TARGET OC RATIO</CardDesc>
                </Card>
                <Card isMobile={isMobile}>
                  <CardTitle>{detailsDataMemo?.currentOCRatio === -1 ? '-' : `${detailsDataMemo?.currentOCRatio.toFixed(2)}x`}</CardTitle>
                  <CardDesc>CURRENT OC RATIO</CardDesc>
                </Card>
              </CardsContainer>
            )}

            <MoreDetails isMobile={isMobile} isOpen={isShowDetails}>
              <FlexWrap twoColumns={currentTab === TabsEnum.SUMMARY || ignoreAllocationTargets} isMobile={isMobile} isTablet={isTablet}>
                <MoreDetailItem isMobile={isMobile}>
                  <ItemRow>
                    <ItemTitle>On Hold</ItemTitle>
                    <ItemValue>{detailsDataMemo?.onHold ? 'Yes' : 'No'}</ItemValue>
                  </ItemRow>

                  {user && user.tenant.type === 'advisoryFirm' && detailsDataMemo?.alwaysShow && (
                    <ItemRow>
                      <ItemTitle>Always Show</ItemTitle>
                      <ItemValue>Yes</ItemValue>
                    </ItemRow>
                  )}

                  {user && user.tenant.type === 'advisoryFirm' && !detailsDataMemo?.impact.startsWith('No') && (
                    <ItemRow>
                      <ItemTitle>Impact</ItemTitle>
                      <ItemValue>{detailsDataMemo?.impact}</ItemValue>
                    </ItemRow>
                  )}

                  {detailsDataMemo?.biteSize && (
                    <ItemRow>
                      <ItemTitle>
                        <BiteSizeWrap>
                          Bite Size
                          <BasicTooltip tooltipContent={'Illiquid Alternative/Restricted Alternative'} translateX={-21} withTooltipIcon />
                        </BiteSizeWrap>
                      </ItemTitle>
                      {ignoreBiteSize ? (
                        <ItemValue>Use minimum</ItemValue>
                      ) : (
                        <ItemValue>
                          {detailsDataMemo.biteSize.fundBiteSize &&
                          !isNaN(detailsDataMemo.biteSize.fundBiteSize) &&
                          detailsDataMemo.biteSize.fundBiteSize > 0
                            ? formatCash(detailsDataMemo.biteSize.fundBiteSize)
                            : '$0'}
                          {' / '}
                          {detailsDataMemo.biteSize.directBiteSize &&
                          !isNaN(detailsDataMemo.biteSize.directBiteSize) &&
                          detailsDataMemo.biteSize.directBiteSize > 0
                            ? formatCash(detailsDataMemo.biteSize.directBiteSize)
                            : '$0'}
                        </ItemValue>
                      )}
                    </ItemRow>
                  )}
                </MoreDetailItem>

                <MoreDetailItem isMobile={isMobile}>
                  {/* <ItemRow>
                  <ItemTitle>Custody Requirement</ItemTitle>
                  <ItemValue>{detailsDataMemo?.custodied ? 'Yes' : 'No'}</ItemValue>
                </ItemRow> */}

                  <ItemRow>
                    <ItemTitle>Tax Exempt</ItemTitle>
                    <ItemValue>{detailsDataMemo?.ubtiBlocker ? 'Yes' : 'No'}</ItemValue>
                  </ItemRow>

                  <ItemRow>
                    <ItemTitle>Sectors to Avoid</ItemTitle>
                    <SectorsWrap>
                      {detailsDataMemo?.sectorsToAvoid.filter((sector) => sector !== '').length ? (
                        detailsDataMemo?.sectorsToAvoid
                          .filter((x, i, a) => a.indexOf(x) == i)
                          .map((el) => {
                            if (el.length && el.length > 15) {
                              return (
                                <BasicTooltip tooltipContent={el.trim()}>
                                  <SectorsItem key={guid()}>{el.trim().slice(0, 15) + ' ...'}</SectorsItem>
                                </BasicTooltip>
                              );
                            }
                            if (el.length) {
                              return <SectorsItem key={guid()}>{el.trim()}</SectorsItem>;
                            }
                          })
                      ) : (
                        <ItemValue>N/A</ItemValue>
                      )}
                    </SectorsWrap>
                  </ItemRow>
                </MoreDetailItem>
              </FlexWrap>
              {!ignoreAllocationTargets && (
                <>
                  {currentTab === TabsEnum.SUMMARY ? (
                    <MoreDetailsProgressBar isMobile={isMobile}>
                      <ProgressBar data={detailsDataMemo?.progressBarValues as ProgressBarValuesType | undefined} title={'Private Capital Target'} />
                    </MoreDetailsProgressBar>
                  ) : (
                    <MoreDetailsSimpleTable isMobile={isMobile}>
                      <SimpleTable assetClassTableData={assetClassTableData} />
                    </MoreDetailsSimpleTable>
                  )}
                </>
              )}
            </MoreDetails>
          </ExpandableWrapp>
        </DetailsBoby>
      )}

      {/* <HideDetailsWrap isMobile={isMobile} onClick={setIsShowDetails}>
        <ArrowBtn isRotate={isOpen}>
          <SortIcon fill={theme.action.primary} />
        </ArrowBtn>
        <ControlDetailsTitle isMobile={isMobile}>{isOpen ? 'Hide Details' : 'Show Details'}</ControlDetailsTitle>
      </HideDetailsWrap> */}
    </DetailsWrap>
  );
};

const TabsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

const CustomTabs = styled(Tabs)`
  padding-top: 0;
`;

const TabsLine = styled.div`
  margin: 0 -60px;
  border-bottom: 1px ${({ theme }) => theme.border.base} solid;
`;

const NotesWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
  cursor: pointer;
`;

const FlexWrap = styled.div<{ twoColumns: boolean; isMobile: boolean; isTablet: boolean }>`
  /* display: grid;
  grid-template-columns: ${({ twoColumns }) => (twoColumns ? ' 1fr 1fr' : '1fr')}; */
  display: flex;
  flex-direction: ${({ twoColumns, isMobile, isTablet }) => (twoColumns && !isTablet && !isMobile ? 'row' : 'column')};
  gap: 14px;
  width: 50%;
`;

const HideDetailsWrap = styled.div<{ isMobile: boolean }>`
  position: relative;
  top: ${({ isMobile }) => (isMobile ? '0' : '15px')};
  cursor: pointer;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 10px 0;
  gap: 12.5px;
`;

const TabsControl = styled.div<{ isMobile?: boolean }>`
  display: flex;
  align-items: ${({ isMobile }) => (isMobile ? 'center' : 'stretch')};
  gap: 13px;
`;

const NotesTitle = styled.div`
  font-family: Blinker, serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 16px;
  color: ${({ theme }) => theme.action.primary};
  margin-top: 3.5px;
`;

const NotesCounterWrap = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  top: 3px;
`;

const NotesCounter = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: -5px;
  bottom: 0;
  right: 0;
  left: 0;
  font-family: Blinker, serif;
  font-size: 13px;
  font-weight: 700;
  line-height: 18px;
  text-align: center;
  color: ${({ theme }) => theme.action.primary};
`;

const ArrowBtn = styled.div<{ isRotate: boolean }>`
  transform: ${({ isRotate }) => (isRotate ? 'rotate(180deg)' : 'rotate(0deg)')};
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.3s;
`;

const ControlDetailsTitle = styled.div<{ isMobile: boolean }>`
  font-family: Blinker, serif;
  font-size: ${({ isMobile }) => (isMobile ? '13px' : '16px')};
  font-weight: 600;
  line-height: 22px;
  color: ${({ theme }) => theme.action.primary};
`;

const ControlDetails = styled.div<{ isMobile: boolean; isOpen: boolean }>`
  display: flex;
  justify-content: space-between;
  /* height: ${({ isMobile }) => (isMobile ? '30px' : 'auto')}; */
  padding-top: ${({ isMobile, isOpen }) => (isMobile && isOpen ? '25px' : '0')};
  //z-index: 100;
`;

const SectorsWrap = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
`;

const SectorsItem = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2px 20px;
  background-color: ${({ theme }) => theme.layer[2]};
  color: ${({ theme }) => theme.font.base};
  border-radius: 12px;
  font-family: Blinker, serif;
  font-size: 13px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0;
`;

const ItemValue = styled.div`
  font-family: Blinker, serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 22px;
  color: ${({ theme }) => theme.font.base};
  white-space: nowrap;
`;

const ItemTitle = styled.div`
  font-family: Blinker, serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  color: ${({ theme }) => theme.font.base};
  white-space: nowrap;
  width: 160px;
`;

const ItemRow = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 30px;
`;

const MoreDetailItem = styled.div<{ isMobile: boolean }>`
  display: flex;
  flex-direction: column;
  width: ${({ isMobile }) => (isMobile ? '100%' : '50%')};
  gap: 14px;
  @media (max-width: 1100px) {
    //width: 50%;
    /* justify-content: center; */
  }
`;

const MoreDetailsProgressBar = styled(MoreDetailItem)<{ isMobile: boolean }>`
  width: ${({ isMobile }) => (isMobile ? '100%' : '33%')};
`;

const MoreDetailsSimpleTable = styled(MoreDetailItem)<{ isMobile: boolean }>`
  width: ${({ isMobile }) => (isMobile ? '100%' : '50%')};
`;

const CardsContainer = styled.div<{ isOpen: boolean; isMobile: boolean }>`
  display: flex;
  flex-direction: ${({ isMobile }) => (isMobile ? 'column' : 'row')};
  gap: 10px;
`;

const MoreDetails = styled.div<{ isOpen: boolean; isMobile: boolean }>`
  display: flex;
  flex-direction: ${({ isMobile }) => (isMobile ? 'column' : 'row')};
  gap: ${({ isMobile }) => (isMobile ? '14px' : 0)};
  justify-content: space-between;
  padding-top: 25px;
`;

const CardTitle = styled.div`
  color: ${({ theme }) => theme.font.strong};
  font-family: Blinker, serif;
  font-size: 23px;
  font-weight: 700;
  line-height: 32px;
  text-align: center;
`;

const CardDesc = styled.div`
  color: ${({ theme }) => theme.font.weak};
  font-family: Blinker, serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0;
  text-align: center;
`;

const Card = styled.div<{ short?: boolean; isOpen?: boolean; isMobile: boolean }>`
  display: flex;
  cursor: ${({ short, isOpen }) => (short && isOpen ? 'pointer' : 'default')};
  width: ${({ short, isMobile }) => (isMobile ? '100%' : short ? '5.3%' : '30.9%')};
  height: 80px;
  min-height: 80px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: ${({ theme }) => theme.layer.base};
  border-radius: 10px;
`;

const DetailsWrap = styled(ContainerPage)<{ isMobile: boolean; disableDetails: boolean }>`
  padding: ${({ isMobile, disableDetails }) =>
    isMobile ? (disableDetails ? '0 20px' : '0 20px 10px 20px') : disableDetails ? '0 60px' : '0 60px 40px 60px'};
  background-color: ${({ theme }) => theme.layer[1]};
  /* border-bottom: 1px solid ${({ theme }) => theme.border.base}; */
`;

const DetailsBoby = styled.div<{ isOpen: boolean; isMobile: boolean }>`
  padding-top: 10px;
  opacity: ${({ isOpen }) => (isOpen ? '1' : '0')};
  display: grid;
  grid-template-rows: ${({ isOpen }) => (isOpen ? '1fr' : '0fr')};
  overflow: ${({ isOpen }) => (isOpen ? 'visible' : 'hidden')};
  transition: grid-template-rows 300ms;
`;

const ExpandableWrapp = styled.div<{ isMobile: boolean; isOpen: boolean }>`
  min-height: 0;
`;

const BiteSizeWrap = styled.div`
  display: flex;
  gap: 5px;
`;

export default Details;
