import NoResults from '@/components/table/noResults';
import { AssetClassTargetAllocationDto } from '../types';
import TableRow from './tableRow';

interface TableBodyProps {
  investorSummaryRows: AssetClassTargetAllocationDto[];
  ignoreAllocationTargets: boolean;
  targetSubAssetClasses: boolean;
}

const TableBody = ({ investorSummaryRows, ignoreAllocationTargets, targetSubAssetClasses }: TableBodyProps) => {
  if (!investorSummaryRows?.length) {
    return <NoResults />;
  }

  return (
    <>
      {investorSummaryRows.map((row) => (
        <TableRow key={row.id} row={row} ignoreAllocationTargets={ignoreAllocationTargets} targetSubAssetClasses={targetSubAssetClasses} />
      ))}
    </>
  );
};

export default TableBody;
