import { RefreshIcon } from '@/assets/icons/info-icons/refresh';
import { RefreshButton, Results, TopWrapper, ViewBySelectSelect, ViewByText } from '@/components/table/fat-filterPage/desktop-filter';
import { TableColumns } from '@/components/table/types';
import { useEffect, useState } from 'react';
import styled, { useTheme } from 'styled-components';
import { Section } from '../../../edit-entity/section';
import { AssetClassForecastValueDto, CommitmentForecastValueDto, TransformedAssetClassForecastValueDto } from '../../types';
import { getUniqueDates, groupAssetClasses } from '../../utils';
import AssetClassTable from './assetClassTable/table';
import CommitmentTable from './commitmentTable/table';

interface CommitmentPlanSectionProps {
  assetClassNAVs: AssetClassForecastValueDto[];
  existingCommitmentNAVs: CommitmentForecastValueDto[];
  targetSubAssetClasses: boolean;
}

const VIEW_BY_SELECTS = ['Asset Class', 'Existing Commitments'];

export const NavForecastSection = ({ assetClassNAVs, existingCommitmentNAVs, targetSubAssetClasses }: CommitmentPlanSectionProps) => {
  const theme = useTheme();

  const [viewBySelect, setViewBySelect] = useState('Asset Class');
  const [tableHeaderColumns, setTableHeaderColumns] = useState<TableColumns[]>([]);
  const [tableData, setTableData] = useState<TransformedAssetClassForecastValueDto[]>([]);
  const [columnWidth, setColumnWidth] = useState(0);

  useEffect(() => {
    if (!assetClassNAVs) return;

    const tableColumns = getUniqueDates(assetClassNAVs);
    const tableColumnsWithWidth = tableColumns.map((column) => ({ ...column, width: 85 / tableColumns.length }));
    setColumnWidth(85 / tableColumns.length);
    setTableHeaderColumns([{ title: 'Asset Class', key: null, width: 15, keySort: 'deal' }, ...tableColumnsWithWidth]);

    const transformedData = groupAssetClasses(assetClassNAVs);
    setTableData(transformedData);
  }, [assetClassNAVs]);

  const onChangeViewBySelect = (value: string) => {
    setViewBySelect(value);
    const updatedColumns = [...tableHeaderColumns];
    if (updatedColumns.length > 0) {
      updatedColumns[0] = {
        ...updatedColumns[0],
        title: value === 'Asset Class' ? 'Asset Class' : 'Commitment'
      };
    }
    setTableHeaderColumns(updatedColumns);
  };

  return (
    <Section id="navForecast" title="NAV Forecast">
      <TableWrapper>
        <TopWrapper>
          {viewBySelect === 'Existing Commitments' && (
            <>
              <Results>{existingCommitmentNAVs.length} Results</Results>
              <RefreshButton onClick={() => {}}>
                <RefreshIcon width={15} height={15} fill={theme.action.primary} />
              </RefreshButton>
            </>
          )}
          <WrapperSelector padding={viewBySelect === 'Existing Commitments' ? '5px 10px 5px 30px' : '5px 0'}>
            <ViewByText>View By</ViewByText>
            <ViewBySelectSelect width={'auto'} selected={viewBySelect} setSelected={onChangeViewBySelect} data={VIEW_BY_SELECTS} />
          </WrapperSelector>
        </TopWrapper>
        {viewBySelect === 'Asset Class' && (
          <AssetClassTable
            tableData={tableData}
            tableHeaderColumns={tableHeaderColumns}
            columnWidth={columnWidth}
            loading={false}
            targetSubAssetClasses={targetSubAssetClasses}
          />
        )}
        {viewBySelect === 'Existing Commitments' && (
          <CommitmentTable tableData={existingCommitmentNAVs} tableHeaderColumns={tableHeaderColumns} loading={false} columnWidth={columnWidth} />
        )}
      </TableWrapper>
    </Section>
  );
};

const TableWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const WrapperSelector = styled.div<{ padding: string }>`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: ${({ padding }) => padding};
  gap: 6px;
`;
