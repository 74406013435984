import client from '@/apollo-client';
import { ApprovedIcon } from '@/assets/icons/info-icons/approved';
import { ArrowDown } from '@/assets/icons/info-icons/arrowDown';
import { RejectedIcon } from '@/assets/icons/info-icons/rejected';
import { AiAssistantIcon } from '@/assets/static/icons/ai-assistant';
import { RevertIcon } from '@/assets/static/icons/revert-icon';
import { ArrowUp } from '@/assets/static/icons/todo-page/arrow-up';
import { ErrorBanner } from '@/components/dueDiligence-page/dueDiligenceRecord/errorBanner';
import ShowText from '@/components/dueDiligence-page/dueDiligenceRecord/showText';
import { TierDropdown } from '@/components/dueDiligence-page/dueDiligenceRecord/tierDropdown';
import { FieldValueWrapper, InfoRowCell, RowWrapper } from '@/components/dueDiligence-page/dueDiligenceRecord/tierSection';
import { CitationsType } from '@/components/dueDiligence-page/dueDiligenceRecord/types';
import { ButtonWrapper } from '@/components/dueDiligence-page/modifyDiligence';
import Button from '@/components/fat-basicComponents/button';
import { getAssetSignedUrl } from '@/components/settings-page/queries';
import { useResponsive } from '@/hooks/use-responsive';
import { PaddingWrap } from '@/styles/common';
import React, { useEffect, useState } from 'react';
import styled, { useTheme } from 'styled-components';
import { IDocumentRecordFieldEntity } from '../../types';

interface EditWrapperProps {
  children: React.ReactNode;
  handleClose: () => void;
  showAiSuggestion: boolean;
  isEditMode: boolean;
  startWithAISuggestion: () => void;
  editSuggested: (suggestion: string) => void;
  clearSuggestion?: () => void;
  revertPriorDraft?: () => void;
  showRevertPriorDraft?: boolean;
  errorMessage?: string | null;
  errorDescription?: string | null;
  row: IDocumentRecordFieldEntity;
  investmentId: string | null;
  confirmButton: {
    name: string;
    onClick: () => void;
    disabled?: boolean;
  };
}

export const EditWrapper = ({
  children,
  handleClose,
  confirmButton,
  showAiSuggestion,
  errorMessage,
  errorDescription,
  isEditMode,
  startWithAISuggestion,
  editSuggested,
  clearSuggestion,
  revertPriorDraft,
  showRevertPriorDraft,
  row,
  investmentId
}: EditWrapperProps) => {
  const { isMobile, isTablet } = useResponsive();
  const theme = useTheme();

  const [currentCitation, setCurrentCitation] = useState<CitationsType | null>();
  const [colapseAll, setColapseAll] = useState(true);

  useEffect(() => {
    addEventListener('popstate', handleClose, false);
    return () => {
      removeEventListener('popstate', handleClose);
    };
  }, []);

  useEffect(() => {
    if (!row) return;

    if (row.AI) {
      setCurrentCitation(row.AI.citations[0]);
    }
  }, [row]);

  const prevSuggestion = () => {
    if (!currentCitation) return;
    const currentIndex = row.AI.citations.indexOf(currentCitation);

    if (currentIndex > 0) {
      setCurrentCitation(row.AI.citations[currentIndex - 1]);
    } else {
      setCurrentCitation(row.AI.citations[row.AI.citations.length - 1]);
    }
  };

  const nextSuggestion = () => {
    if (!currentCitation) return;
    const currentIndex = row.AI.citations.indexOf(currentCitation);

    if (currentIndex !== -1 && currentIndex < row.AI.citations.length - 1) {
      setCurrentCitation(row.AI.citations[currentIndex + 1]);
    } else {
      setCurrentCitation(row.AI.citations[0]);
    }
  };

  const openPdf = async (pageNumber: string) => {
    if (!investmentId && !currentCitation) return;

    const assetKey = `investments/${investmentId}/ddr/${currentCitation?.filename}`;
    const { data } = await client.query({
      fetchPolicy: 'network-only',
      query: getAssetSignedUrl,
      variables: {
        assetKey: assetKey
      }
    });
    window.open(`${data.getAssetSignedUrl}#page=${pageNumber}`, '_blank');
  };

  if (!row) return <></>;

  return (
    <>
      {errorMessage && errorDescription && <ErrorBanner errorMessage={errorMessage} errorDescription={errorDescription} />}
      <PaddingWrap>
        <ContentWrapper isMobile={isMobile}>
          {showAiSuggestion && (
            <>
              {isEditMode ? (
                <ControlButtonsWrap isMobile={isMobile}>
                  <Button size="sm" onClick={startWithAISuggestion}>
                    <AiAssistantIcon width={16} height={16} fill={theme.header.action} />
                    Start Over With AI Suggestion
                  </Button>
                  {showRevertPriorDraft && (
                    <Button size="sm" onClick={revertPriorDraft}>
                      <RevertIcon fill={theme.context.light} />
                      Revert to Prior Draft
                    </Button>
                  )}
                  <SuggestionActionWrap isMobile={isMobile}>
                    <Button size="sm" onClick={clearSuggestion}>
                      <RejectedIcon width={16} height={16} fill={theme.context.light} />
                      Clear
                    </Button>
                  </SuggestionActionWrap>
                </ControlButtonsWrap>
              ) : (
                <ControlButtonsWrap isMobile={isMobile}>
                  {showRevertPriorDraft && (
                    <Button size="sm" onClick={revertPriorDraft}>
                      <RevertIcon fill={theme.context.light} />
                      Revert to Prior Draft
                    </Button>
                  )}
                  <SuggestionActionWrap isMobile={isMobile}>
                    <Button size="sm" onClick={() => editSuggested(row.AI?.answers[0])}>
                      <ApprovedIcon width={16} height={16} fill={theme.context.light} />
                      Adopt and Edit Suggested
                    </Button>
                    <Button size="sm" onClick={clearSuggestion}>
                      <RejectedIcon width={16} height={16} fill={theme.context.light} />
                      Clear
                    </Button>
                  </SuggestionActionWrap>
                </ControlButtonsWrap>
              )}
            </>
          )}
          {children}
          <ControlWrapper isMobile={isMobile}>
            {/* <WarningAboutFields>
              <img src="/static/icons/warning-star.svg" alt="Star" />
              <RequiredText>Required fields</RequiredText>
            </WarningAboutFields> */}
            <ButtonWrapper>
              <Button size="lg" styleType="outline" onClick={handleClose}>
                Cancel
              </Button>
              <Button onClick={confirmButton.onClick} styleType={confirmButton.disabled ? 'disabled' : 'default'} size="lg">
                {confirmButton.name}
              </Button>
            </ButtonWrapper>
          </ControlWrapper>
          {currentCitation && row.AI?.citations && (
            <AllSuggestionsWrapper>
              <SuggestionsHeader>
                <AllSuggestions>
                  <AiAssistantIcon fill={theme.font.base} width={28} height={28} />
                  All Suggestions
                </AllSuggestions>
                <ColapseAll onClick={() => setColapseAll(!colapseAll)}>
                  {colapseAll ? (
                    <ArrowUp width={23} height={23} fill={theme.font.action} />
                  ) : (
                    <ArrowDown style={{ position: 'relative', top: '1px', right: '4px' }} width={12} height={12} fill={theme.font.action} />
                  )}
                  Colapse All
                </ColapseAll>
              </SuggestionsHeader>
              <SuggestionsBody colapseAll={colapseAll}>
                <ExpandableWrapp>
                  <TierDropdown title={currentCitation.filename ?? ''}>
                    <>
                      {/* <RowWrapper isMobile={isMobile} disabled={false}>
                        <InfoRowCell>Prompt</InfoRowCell>
                        <FieldValueWrapper>
                          <ShowText>{currentCitation.prompt}</ShowText>
                        </FieldValueWrapper>
                      </RowWrapper> */}
                      <RowWrapper isMobile={isMobile} disabled={false}>
                        <InfoRowCell>Page Reference</InfoRowCell>
                        <FieldValueWrapper>
                          {currentCitation.pageNumbers?.map((pageNumber, index) => (
                            <PageNumber key={pageNumber + index} onClick={() => openPdf(currentCitation.pageNumbers[0])}>{`${
                              index ? ' - ' : 'Pages '
                            }${pageNumber}`}</PageNumber>
                          ))}
                        </FieldValueWrapper>
                      </RowWrapper>
                      <RowWrapper isMobile={isMobile} disabled={false}>
                        <InfoRowCell>Suggested Answer</InfoRowCell>
                        <AiRowCellWrap>
                          <AiIconWrap>
                            <AiAssistantIcon width={28} height={28} fill={theme.font.base} />
                          </AiIconWrap>
                          <AiAnswer>
                            <ShowText>{currentCitation.answer}</ShowText>
                          </AiAnswer>
                        </AiRowCellWrap>
                      </RowWrapper>
                      <AdoptAndEditButtonWrapper>
                        <Button onClick={() => editSuggested(currentCitation?.answer ?? '')} styleType="outline" size="lg">
                          Adopt and Edit
                        </Button>
                      </AdoptAndEditButtonWrapper>
                      <SuggestionNavigate>
                        <PrevSuggestion onClick={prevSuggestion}>{'<'}</PrevSuggestion>
                        <PageNumbers>
                          Showing {row.AI.citations.indexOf(currentCitation) + 1} of {row.AI.citations.length}
                        </PageNumbers>
                        <NextSuggestion onClick={nextSuggestion}>{'>'}</NextSuggestion>
                      </SuggestionNavigate>
                    </>
                  </TierDropdown>
                </ExpandableWrapp>
              </SuggestionsBody>
            </AllSuggestionsWrapper>
          )}
        </ContentWrapper>
      </PaddingWrap>
    </>
  );
};

const ContentWrapper = styled.div<{ isMobile: boolean }>`
  margin-top: 50px;
  padding: ${({ isMobile }) => (isMobile ? '20px 20px 0' : '60px 60px 0')};
  border: 1px solid ${({ theme }) => theme.border.base};
  border-radius: 10px;
`;

const AdoptAndEditButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin: 30px 0;
`;

const ControlButtonsWrap = styled.div<{ isMobile: boolean }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: ${({ isMobile }) => (isMobile ? 'column' : 'row')};
  gap: 10px;
  margin-top: ${({ isMobile }) => (isMobile ? '-10px' : '-50px')};
  padding: 20px 0;
`;

const ExpandableWrapp = styled.div`
  min-height: 0;

  #dropdownTitle {
    line-height: 35px;
  }
`;

const SuggestionsBody = styled.div<{ colapseAll: boolean }>`
  display: grid;
  grid-template-rows: ${({ colapseAll }) => (colapseAll ? '1fr' : '0fr')};
  overflow: ${({ colapseAll }) => (colapseAll ? 'visible' : 'hidden')};
  transition: grid-template-rows 300ms;
`;

const AllSuggestionsWrapper = styled.div`
  margin: 50px 0 20px 0;
`;

const SuggestionsHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const ColapseAll = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
  color: ${({ theme }) => theme.font.action};
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
`;

const AllSuggestions = styled.div`
  color: ${({ theme }) => theme.font.strong};
  font-size: 28px;
  font-weight: 600;
  display: flex;
  align-items: center;
  gap: 4px;
`;

const AiRowCellWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
`;

const AiIconWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  height: 100%;
`;

const AiAnswer = styled.div`
  padding-left: 10px;
  border-left: 1px solid ${({ theme }) => theme.border.base};
`;

const SuggestionNavigate = styled.div`
  margin-top: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
`;

const PrevSuggestion = styled.button`
  cursor: pointer;
  padding: 8px 19px;
  border-radius: 3px;
  border: 1px solid ${({ theme }) => theme.font.action};
  background: ${({ theme }) => theme.layer.base};
  color: ${({ theme }) => theme.font.action};
  font-size: 13px;
  font-weight: 400;
`;

const NextSuggestion = styled(PrevSuggestion)``;

const PageNumbers = styled.div`
  color: ${({ theme }) => theme.font.weak};
  font-size: 13px;
  font-weight: 400;
`;

const PageNumber = styled.span`
  cursor: pointer;
  color: ${({ theme }) => theme.font.action};
`;

const SuggestionActionWrap = styled.div<{ isMobile: boolean }>`
  display: flex;
  align-items: center;
  flex-direction: ${({ isMobile }) => (isMobile ? 'column' : 'row')};
  gap: 10px;
  margin-left: auto;
`;

const ControlWrapper = styled.div<{ isMobile: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: ${({ isMobile }) => (isMobile ? 'column' : 'row')};
  gap: ${({ isMobile }) => (isMobile ? '10px' : '0')};
  margin: 20px 0;
`;
