import TableHeader from '@/components/table/fat-tableHeader';
import { useResponsive } from '@/hooks/use-responsive';
import { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';

import { Loader } from '@/components/loaders/loader';
import { TableBodyWrapper, TableWrapper } from '@/components/table/styles';
import { ISort } from '@/components/table/types';
import useStickyFooter from '@/hooks/useStickyFooter';
import { simplifiedSummaryTableColumns, summaryTablecolumns } from '../constatnts';
import { AssetClassTargetAllocationDto } from '../types';
import MobileTable from './mobileTable';
import TableBody from './tableBody';
import TableSumFooter from './tableSumFooter';

interface TableSummaryProps {
  investorSummaryRows: AssetClassTargetAllocationDto[];
  refetch: () => void;
  loading: boolean;
  ignoreAllocationTargets: boolean;
  targetSubAssetClasses: boolean;
}

const TableSummary = ({ investorSummaryRows, refetch, loading, ignoreAllocationTargets, targetSubAssetClasses }: TableSummaryProps) => {
  const { isMobile, isTablet } = useResponsive();

  const [sort, setSort] = useState<ISort>({ key: null, asc: true });
  const [sortedTableData, setSortedTableData] = useState<AssetClassTargetAllocationDto[]>([]);

  const containerRef = useRef<HTMLDivElement | null>(null);
  const footerRef = useRef<HTMLDivElement | null>(null);

  useStickyFooter(containerRef, footerRef);

  useEffect(() => {
    setSortedTableData([...investorSummaryRows]);
  }, [investorSummaryRows]);

  useEffect(() => {
    if (!sort.key) return;

    const sortedData = [...investorSummaryRows]
      .sort((a, b) => {
        return sort.asc
          ? (a.pacingTarget?.remainingPacingTarget || 0) - (b.pacingTarget?.remainingPacingTarget || 0)
          : (b.pacingTarget?.remainingPacingTarget || 0) - (a.pacingTarget?.remainingPacingTarget || 0);
      })
      .map((assetClass) => {
        if (assetClass.subAssetClasses) {
          const sortedSubAssetClasses = assetClass.subAssetClasses.sort((a, b) => {
            return sort.asc
              ? (a.pacingTarget?.remainingPacingTarget || 0) - (b.pacingTarget?.remainingPacingTarget || 0)
              : (b.pacingTarget?.remainingPacingTarget || 0) - (a.pacingTarget?.remainingPacingTarget || 0);
          });

          return { ...assetClass, subAssetClasses: sortedSubAssetClasses };
        }

        return assetClass;
      });

    setSortedTableData(sortedData);
  }, [sort]);

  return (
    <>
      {isMobile ? (
        <>
          {loading ? (
            <Loader />
          ) : (
            <MobileTable investorSummaryRows={sortedTableData} loading={loading} ignoreAllocationTargets={ignoreAllocationTargets} />
          )}
        </>
      ) : (
        <TableWrapper padding="0">
          <CustomTableHeader
            isTablet={isTablet}
            refetch={() => {}}
            savedSort={sort}
            savedSetSort={setSort}
            columns={ignoreAllocationTargets ? simplifiedSummaryTableColumns : summaryTablecolumns}
          />
          <TableBodyWrapper>
            {loading ? (
              <Loader />
            ) : (
              <>
                <TableBody
                  investorSummaryRows={sortedTableData}
                  ignoreAllocationTargets={ignoreAllocationTargets}
                  targetSubAssetClasses={targetSubAssetClasses}
                />
                <TableSumFooterWrapper ref={footerRef}>
                  <TableSumFooter investorSummaryRows={investorSummaryRows} ignoreAllocationTargets={ignoreAllocationTargets} />
                </TableSumFooterWrapper>
                <div ref={containerRef}></div>
              </>
            )}
          </TableBodyWrapper>
        </TableWrapper>
      )}
    </>
  );
};

export default TableSummary;

const CustomTableHeader = styled(TableHeader)<{ isTablet: boolean }>`
  position: sticky;
  top: ${({ isTablet }) => (isTablet ? '50px' : '0')};
  z-index: 8;
  padding-top: 0;
`;

const TableSumFooterWrapper = styled.div`
  &.sticky {
    position: sticky;
    bottom: 0;
    z-index: 1;
  }
`;
