import { ManagerUpdateDetails } from '@/components/managerUpdates-page/managerUpdateDetails';
import { Helmet as Head } from 'react-helmet';

const ManagerUpdateDetailsPage = () => {
  return (
    <>
      <Head>
        <title>Manager Updates Details</title>
      </Head>
      <ManagerUpdateDetails />
    </>
  );
};

export default ManagerUpdateDetailsPage;
