import { Checkbox } from '@mui/material';
import { Box } from '@/components/muis/box';
import Typography from '@/components/muis/typography';
import { styled } from '@mui/material/styles';
import React from 'react';

import { IToggleCircle } from '../../../types/opportunity/modify-opportunity';
import { WrapperHead } from '../index';

interface ToggleCircleProps {
  title?: string;
  isWarning?: boolean;
  tooltip?: string;
  tabs: IToggleCircle[];
  onChange: (label: string, checked: boolean) => void;
  classNames?: {
    container?: string;
    checkbox?: string;
    circle?: string;
  };
}

export const ToggleCircle: React.FC<ToggleCircleProps> = ({
  classNames,
  title,
  isWarning,
  tooltip,
  tabs,
  onChange
}) => {
  return (
    <Box className={classNames?.container}>
      {title && (
        <WrapperHead title={title} isWarning={isWarning} tooltip={tooltip} />
      )}
      <Box sx={{ display: 'flex', columnGap: 1.25 }}>
        {tabs.map(({ label, checked }) => (
          <CheckboxWrapper key={label}>
            <CustomCheckbox
              checked={checked}
              onChange={(event) => onChange(label, event.target.checked)}
              icon={<ContainerCircle className={classNames?.checkbox} />}
              checkedIcon={
                <ContainerCircle className={classNames?.checkbox}>
                  {checked && <Circle className={classNames?.circle} />}
                </ContainerCircle>
              }
            />
            <Typography>{label}</Typography>
          </CheckboxWrapper>
        ))}
      </Box>
    </Box>
  );
};
const CheckboxWrapper = styled(Box)(() => ({
  display: 'flex'
}));
const CustomCheckbox = styled(Checkbox)(() => ({
  '&:hover': {
    background: 'transparent'
  },
  padding: '0 10px 0 0'
}));
const ContainerCircle = styled(Box)(() => ({
  height: 24,
  width: 24,
  background: '#F0F1F3',
  border: '1px solid #C7CBD2',
  borderRadius: 12,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center'
}));
const Circle = styled(Box)(() => ({
  height: 16,
  width: 16,
  background: '#373F4E',
  borderRadius: 8
}));
