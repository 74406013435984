import { useStatusData } from '@/hooks/useStatusData';
import { dateFormatter } from '@/utils/dateFormatter';
import { format } from 'date-fns';
import { useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import { AssetClassForecastSummaryDto } from '../types';
import { AssetClassRow } from './assetClassRow';

interface AssetClassTableProps {
  assetClassForecasts?: AssetClassForecastSummaryDto[];
}

const AssetClassTable = ({ assetClassForecasts }: AssetClassTableProps) => {
  const { data: statusData } = useStatusData();

  const [currentDate, setCurrentDate] = useState<string>('');

  useEffect(() => {
    setCurrentDate(dateFormatter(format(new Date(), 'yyyy-MM-dd'), 'yyyy-MM-dd'));
  }, []);

  const rowSum = useMemo(() => {
    const sum = {
      total: 'Total',
      target: 0,
      asOfForecast: 0,
      fiveYearForecast: 0,
      tenYearForecast: 0
    };
    for (let i = 0; i < assetClassForecasts?.length; i++) {
      sum.target += assetClassForecasts[i].target;
      sum.asOfForecast += assetClassForecasts[i].asOfForecast;
      sum.fiveYearForecast += assetClassForecasts[i].fiveYearForecast;
      sum.tenYearForecast += assetClassForecasts[i].tenYearForecast;
    }
    return sum;
  }, [assetClassForecasts]);

  return (
    <SimpleTableWrap>
      <SimpleTableHeader>
        <HeaderItem width={'32%'}>ASSET CLASS</HeaderItem>
        <HeaderItem width={'17%'}>TARGET</HeaderItem>
        <HeaderItem width={'17%'}>AS OF {currentDate}</HeaderItem>
        <HeaderItem width={'17%'}>Plan Start + 5</HeaderItem>
        <HeaderItem width={'17%'}>Plan Start + 10</HeaderItem>
      </SimpleTableHeader>
      <SimpleTableBody>
        {assetClassForecasts.map((assetClass) => (
          <AssetClassRow
            key={assetClass.name + assetClass.target}
            assetClass={assetClass}
            targetSubAssetClasses={statusData?.target_sub_asset_classes}
          />
        ))}
      </SimpleTableBody>
      <SimpleTableFooter>
        <FooterItem width={'32%'}>TOTAL</FooterItem>
        <FooterItem width={'17%'}>{rowSum.target.toFixed(2)}%</FooterItem>
        <FooterItem width={'17%'}>{rowSum.asOfForecast.toFixed(2)}%</FooterItem>
        <FooterItem width={'17%'}>{rowSum.fiveYearForecast.toFixed(2)}%</FooterItem>
        <FooterItem width={'17%'}>{rowSum.tenYearForecast.toFixed(2)}%</FooterItem>
      </SimpleTableFooter>
    </SimpleTableWrap>
  );
};

const SimpleTableBody = styled.div`
  display: flex;
  flex-direction: column;
  /* max-height: 200px;
  overflow-y: auto; */
`;

const FooterItem = styled.div<{ width: string }>`
  width: ${({ width }) => width};
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-family: Blinker, serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: 0;
  color: ${({ theme }) => theme.font.strong};
`;

const SimpleTableFooter = styled.div`
  display: flex;
  border-radius: 4px;
  align-items: center;
  justify-content: space-between;
  background-color: ${({ theme }) => theme.layer[2]};
  padding: 5px 24px;
`;

const HeaderItem = styled.div<{ width: string }>`
  width: ${({ width }) => width};
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-family: Blinker, serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: 0;
  color: ${({ theme }) => theme.header.action};
`;

const SimpleTableHeader = styled.div`
  display: flex;
  border-radius: 4px;
  align-items: center;
  justify-content: space-between;
  background-color: ${({ theme }) => theme.header.base};
  padding: 5px 24px;
`;

const SimpleTableWrap = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 3px;
`;

export default AssetClassTable;
