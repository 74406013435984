import styled from 'styled-components';

import { Loader } from '@/components/loaders/loader';
import TableHeader from '@/components/table/fat-tableHeader';
import { TableWrapper } from '@/components/table/styles';
import { ISort, TableColumns } from '@/components/table/types';
import { useAuth } from '@/hooks/use-auth';
import { useResponsive } from '@/hooks/use-responsive';
import useStickyFooter from '@/hooks/useStickyFooter';
import { useRef } from 'react';
import { advisoryFirmColumns, entityColumns, investorColumns } from '../constatnts';
import { IInvestorCommitment, InvestmentEntity, InvestmentEntityByAdvisoryFirm } from '../types';
import MobileSumFooterAdvisoryFirm from './mobileTable/mobileSumFooterAdvisoryFirm';
import MobileSumFooterEntity from './mobileTable/mobileSumFooterEntity';
import MobileSumFooterInvestor from './mobileTable/mobileSumFooterInvestor';
import MobileTableAdvisoryFirm from './mobileTable/mobileTableAdvisoryFirm';
import MobileTableEntity from './mobileTable/mobileTableEntity';
import MobileTableInvestor from './mobileTable/mobileTableInvestor';
import TabelBodyAdvisoryFirm from './tabelBodyAdvisoryFirm';
import TabelBodyEntity from './tabelBodyEntity';
import TabelBodyInvestor from './tabelBodyInvestor';
import { default as TableSumFooterAdvisoryFirm } from './tableSumFooterAdvisoryFirm';
import TableSumFooterEntity from './tableSumFooterEntity';
import TableSumFooterInvestor from './tableSumFooterInvestor';

type InvestmentDetailsTable = {
  advisoryFirmTableRows: InvestmentEntityByAdvisoryFirm[];
  entityTableRows: InvestmentEntity[];
  investorTableRows: IInvestorCommitment[];
  loading: boolean;
  refetch: any;
  viewByValue: string;
  sort: ISort;
  setSort: (value: ISort) => void;
};

const Table = ({
  advisoryFirmTableRows,
  entityTableRows,
  investorTableRows,
  loading,
  refetch,
  viewByValue,
  sort,
  setSort
}: InvestmentDetailsTable) => {
  const { isMobile, isTablet } = useResponsive();
  const { user } = useAuth();

  const containerRef = useRef<HTMLDivElement | null>(null);
  const footerRef = useRef<HTMLDivElement | null>(null);

  useStickyFooter(containerRef, footerRef);

  const filteredColumns = (columns: TableColumns[]) => {
    let filtered = columns;
    if (viewByValue !== 'Entity') {
      filtered = filtered.filter((column) => column.key !== 'VEHICLE');
    }

    if (user && user.tenant.type === 'advisoryFirm') {
      return filtered.map((column) => {
        if (column.key !== 'ADVISORY_FIRMS') {
          return column;
        }
        return { ...column, width: 0, title: '' };
      });
    }

    return filtered;
  };

  const TableBody = () => {
    switch (viewByValue) {
      case 'Advisory Firm':
        return (
          <TableWrapper padding="0">
            {isMobile ? (
              <MobileTableAdvisoryFirm
                columns={filteredColumns(advisoryFirmColumns)}
                advisoryFirmTableRows={advisoryFirmTableRows}
                loading={loading}
              />
            ) : (
              <>
                <CustomTableHeader
                  isTablet={isTablet}
                  columns={filteredColumns(advisoryFirmColumns)}
                  refetch={refetch}
                  savedSort={sort}
                  savedSetSort={setSort}
                />
                {loading ? (
                  <Loader />
                ) : (
                  <TabelBodyAdvisoryFirm columns={filteredColumns(advisoryFirmColumns)} advisoryFirmTableRows={advisoryFirmTableRows} />
                )}
              </>
            )}
            {isMobile && !loading ? (
              <MobileSumFooterAdvisoryFirm columns={filteredColumns(advisoryFirmColumns)} tableData={advisoryFirmTableRows} />
            ) : !loading ? (
              <TableSumFooterWrapper ref={footerRef}>
                {<TableSumFooterAdvisoryFirm columns={filteredColumns(advisoryFirmColumns)} tableData={advisoryFirmTableRows} />}
              </TableSumFooterWrapper>
            ) : (
              <></>
            )}
            <div ref={containerRef}></div>
          </TableWrapper>
        );
      case 'Investor':
        return (
          <TableWrapper padding="0">
            {isMobile ? (
              <MobileTableInvestor columns={filteredColumns(investorColumns)} investorTableRows={investorTableRows} loading={loading} />
            ) : (
              <>
                <CustomTableHeader
                  isTablet={isTablet}
                  columns={filteredColumns(investorColumns)}
                  refetch={refetch}
                  savedSort={sort}
                  savedSetSort={setSort}
                />
                {loading ? <Loader /> : <TabelBodyInvestor columns={filteredColumns(investorColumns)} investorTableRows={investorTableRows} />}
              </>
            )}
            {isMobile && !loading ? (
              <MobileSumFooterInvestor columns={filteredColumns(investorColumns)} tableData={investorTableRows} />
            ) : !loading ? (
              <TableSumFooterWrapper ref={footerRef}>
                {<TableSumFooterInvestor columns={filteredColumns(investorColumns)} tableData={investorTableRows} />}
              </TableSumFooterWrapper>
            ) : (
              <></>
            )}
            <div ref={containerRef}></div>
          </TableWrapper>
        );
      case 'Entity':
        return (
          <TableWrapper padding="0">
            {isMobile ? (
              <MobileTableEntity columns={filteredColumns(entityColumns)} entityTableRows={entityTableRows} loading={loading} />
            ) : (
              <>
                <CustomTableHeader
                  isTablet={isTablet}
                  columns={filteredColumns(entityColumns)}
                  refetch={refetch}
                  savedSort={sort}
                  savedSetSort={setSort}
                />
                {loading ? <Loader /> : <TabelBodyEntity columns={filteredColumns(entityColumns)} entityTableRows={entityTableRows} />}
              </>
            )}
            {isMobile && !loading ? (
              <MobileSumFooterEntity columns={filteredColumns(entityColumns)} tableData={entityTableRows} />
            ) : !loading ? (
              <TableSumFooterWrapper ref={footerRef}>
                {<TableSumFooterEntity columns={filteredColumns(entityColumns)} tableData={entityTableRows} />}
              </TableSumFooterWrapper>
            ) : (
              <></>
            )}
            <div ref={containerRef}></div>
          </TableWrapper>
        );
      default:
        return <></>;
    }
  };

  return <TableBody />;
};

export default Table;

const CustomTableHeader = styled(TableHeader)<{ isTablet: boolean }>`
  position: sticky;
  top: ${({ isTablet }) => (isTablet ? '130px' : '80px')};
  z-index: 8;
  padding-top: 0;
`;

const TableSumFooterWrapper = styled.div`
  &.sticky {
    position: sticky;
    bottom: 0;
    z-index: 1;
  }
`;
