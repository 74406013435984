import { RejectedBannerIcon } from '@/assets/icons/info-icons/rejectedBanner';
import { Banner } from '@/components/basicComponents/banner';
import RadioButton from '@/components/basicComponents/radioButton';
import { useResponsive } from '@/hooks/use-responsive';
import { AnimatePresence, motion } from 'framer-motion';
import { ChangeEvent, Dispatch, SetStateAction, useEffect, useState } from 'react';
import { UseFormGetValues, UseFormSetValue } from 'react-hook-form';
import styled, { useTheme } from 'styled-components';
import { EntityInput } from '../../fat-investorDetails-page/fat-entityDetails-page/edit-entity/input';
import { Section } from '../../fat-investorDetails-page/fat-entityDetails-page/edit-entity/section';
import { CreateInvestorDto } from '../types';

const defaultBiteSizeOptions = ['Default to minimum required commitment', 'Set a default bite size for this entity'];

interface SetAllocationBiteSizeProps {
  setFormValue: UseFormSetValue<CreateInvestorDto>;
  getValues: UseFormGetValues<CreateInvestorDto>;
  setIsBiteSizeValid: Dispatch<SetStateAction<boolean>>;
}

export const SetAllocationBiteSize = ({ setFormValue, getValues, setIsBiteSizeValid }: SetAllocationBiteSizeProps) => {
  const { isMobile } = useResponsive();
  const theme = useTheme();

  const [selectedDefaultBiteSizeOptions, setSelectedDefaultBiteSizeOptions] = useState(defaultBiteSizeOptions[0]);
  const [fundBiteSize, setFundBiteSize] = useState<string>('');
  const [directBiteSize, setDirectBiteSize] = useState<string>('');

  useEffect(() => {
    setSelectedDefaultBiteSizeOptions(getValues('ignoreBiteSize') ? defaultBiteSizeOptions[0] : defaultBiteSizeOptions[1]);
    setFundBiteSize(getValues('defaultBiteSize1') ? getValues('defaultBiteSize1').toLocaleString('en-US') : '');
    setDirectBiteSize(getValues('defaultBiteSize2') ? getValues('defaultBiteSize2').toLocaleString('en-US') : '');
  }, [getValues('ignoreBiteSize'), getValues('defaultBiteSize1'), getValues('defaultBiteSize2')]);

  useEffect(() => {
    if (selectedDefaultBiteSizeOptions === defaultBiteSizeOptions[0]) {
      setIsBiteSizeValid(true);
      return;
    }
    if (fundBiteSize && directBiteSize) {
      setIsBiteSizeValid(true);
      return;
    }
    setIsBiteSizeValid(false);
  }, [fundBiteSize, directBiteSize, selectedDefaultBiteSizeOptions]);

  const handleRadioChange = (event: ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value;
    setSelectedDefaultBiteSizeOptions(newValue);
    setFormValue('ignoreBiteSize', newValue === defaultBiteSizeOptions[0]);
  };

  const handleInputChange = (value: string, type: 'defaultBiteSize1' | 'defaultBiteSize2') => {
    const cleanValue = value.replace(/\D/g, '');
    if (!cleanValue) {
      setValue(type, '');
      return;
    }
    const formattedValue = parseFloat(cleanValue).toLocaleString('en-US');

    setValue(type, formattedValue);
  };

  const setValue = (key: string, value: any) => {
    if (key === 'ignoreBiteSize') {
      setFormValue(key as any, value);
    } else {
      setFormValue(key as any, parseFloat(value.replace(/\D/g, '')));
    }
    switch (key) {
      case 'defaultBiteSize1':
        setFundBiteSize(value);
        break;
      case 'defaultBiteSize2':
        setDirectBiteSize(value);
        break;
    }
  };

  return (
    <Section title="Default Bite Size">
      <DefaultBiteSize isMobile={isMobile}>
        <OptionsWrapper>
          Set the default amount for initial commitments:
          {defaultBiteSizeOptions.map((option, index) => (
            <RadioButton
              key={option + index}
              name="defaultBiteSize"
              radioButtonText={option}
              selectedOption={selectedDefaultBiteSizeOptions}
              handleRadioChange={(event) => handleRadioChange(event)}
            />
          ))}
        </OptionsWrapper>
        <Banner
          title=""
          description="The commitment amount will be set to the minimum required for any deal unless a specific default bite size is set here. This amount can be overridden when making an allocation."
          icon={<RejectedBannerIcon fill={theme.header.base} />}
          bgColor={theme.layer[1]}
          color={theme.font.base}
        />
      </DefaultBiteSize>
      <AnimatePresence>
        {selectedDefaultBiteSizeOptions === defaultBiteSizeOptions[1] && (
          <motion.div
            initial={{
              opacity: 0
            }}
            animate={{
              opacity: 1
            }}
            exit={{
              opacity: 0
            }}
          >
            <BiteSizeWrap isMobile={isMobile}>
              <EntityInput
                value={fundBiteSize ?? ''}
                setValue={(value) => handleInputChange(value, 'defaultBiteSize1')}
                type="text"
                label="Illiquid Alternative"
                required
                tooltip="The default commitment amount for corresponding strategies"
                withDollarSign
              />
              <EntityInput
                value={directBiteSize ?? ''}
                setValue={(value) => handleInputChange(value, 'defaultBiteSize2')}
                type="text"
                label="Restricted Alternative"
                required
                tooltip="The default commitment amount for corresponding strategies"
                withDollarSign
              />
            </BiteSizeWrap>
          </motion.div>
        )}
      </AnimatePresence>
    </Section>
  );
};

const OptionsWrapper = styled.div`
  max-width: 343px;
  width: 100%;
  color: ${({ theme }) => theme.font.base};
  font-size: 16px;
  font-weight: 400;
  line-height: 22.4px;
`;

const DefaultBiteSize = styled.div<{ isMobile: boolean }>`
  display: flex;
  align-items: ${({ isMobile }) => (isMobile ? 'flex-start' : 'flex-end')};
  flex-direction: ${({ isMobile }) => (isMobile ? 'column-reverse' : 'row')};
  gap: ${({ isMobile }) => (isMobile ? '15px' : '0')};
`;

const BiteSizeWrap = styled.div<{ isMobile: boolean }>`
  display: flex;
  align-items: center;
  gap: ${({ isMobile }) => (isMobile ? '30px' : '15px')};
  flex-direction: ${({ isMobile }) => (isMobile ? 'column' : 'row')};
`;
