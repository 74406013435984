import { useResponsive } from '@/hooks/use-responsive';

import { Loader } from '@/components/loaders/loader';
import TableHeader from '@/components/table/fat-tableHeader';
import { TableBodyWrapper, TableWrapper } from '@/components/table/styles';
import { ISort } from '@/components/table/types';
import styled from 'styled-components';
import { ICommitmentPlan } from '../../fat-pacingTab/types';
import { columns } from '../constatnts';
import MobileTable from './mobileTable';
import TableBody from './tableBody';

interface TableProps {
  tableData: ICommitmentPlan[];
  loading: boolean;
  sort: ISort;
  updateLoading: boolean;
  currentRow: ICommitmentPlan | null;
  setSort: (value: ISort) => void;
  deleteCommitmentPlan: (row: ICommitmentPlan) => void;
  makeActivePlan: (row: ICommitmentPlan) => void;
}

const Table = ({ tableData, loading, sort, updateLoading, currentRow, setSort, deleteCommitmentPlan, makeActivePlan }: TableProps) => {
  const { isMobile, isTablet } = useResponsive();

  return (
    <TableWrapper padding="0">
      {isMobile ? (
        <>
          {loading ? (
            <Loader />
          ) : (
            <MobileTable
              tableData={tableData}
              updateLoading={updateLoading}
              currentRow={currentRow}
              deleteCommitmentPlan={deleteCommitmentPlan}
              makeActivePlan={makeActivePlan}
            />
          )}
        </>
      ) : (
        <>
          <CustomTableHeader isTablet={isTablet} refetch={() => {}} columns={columns} savedSort={sort} savedSetSort={setSort} />
          <TableBodyWrapper>
            {loading ? (
              <Loader />
            ) : (
              <TableBody
                tableData={tableData}
                updateLoading={updateLoading}
                currentRow={currentRow}
                deleteCommitmentPlan={deleteCommitmentPlan}
                makeActivePlan={makeActivePlan}
              />
            )}
          </TableBodyWrapper>
        </>
      )}
    </TableWrapper>
  );
};

export default Table;

const CustomTableHeader = styled(TableHeader)<{ isTablet: boolean }>`
  position: sticky;
  top: ${({ isTablet }) => (isTablet ? '130px' : '80px')};
  z-index: 8;
  padding-top: 0;
`;
