import { INotesData } from '../client-details/types';

export enum TabsEnum {
  summary = 'Summary',
  commitments = 'Commitments',
  allocations = 'Allocations'
}

export type ClientEntityDetailsSummary = {
  ClientEntityDetailsSummary: ClientEntitySummary;
};

export type BiteSizeType = {
  fundBiteSize: number | null;
  directBiteSize: number | null;
};

export type ProgressBarValuesType = {
  economicExposure: number;
  totalCapitalTarget: number;
  capitalTarget: number;
  isGoalAchieved: boolean;
};

export type ClientEntitySummaryTableType = {
  privateRealAssetPercentage: number;
  privateEquityPercentage: number;
  privateCreditPercentage: number;
  diversifyingStrategiesPercentage: number;
  privateEquityTargetAllocations: number;
  privateCreditTargetAllocations: number;
  privateRealAssetTargetAllocations: number;
  diversifyingStrategiesTargetAllocations: number;
  economicExposureForPrivateCredit: number;
  economicExposureForPrivateRealAssets: number;
  economicExposureForPrivateEquity: number;
  economicExposureForDiversifyingStrategies: number;
  privateEquityCapacity: number;
  privateCreditCapacity: number;
  privateRealAssetsCapacity: number;
  diversifyingStrategiesCapacity: number;
};

export type ClientEntitySummary = {
  id: string;
  name: string;
  exportId: string;
  entityCRMId: string;
  totalEntitiesValue: number;
  privatePercentage: number;
  targetOCRatio: number;
  currentOCRatio: number;
  notes: INotesData[];
  alwaysShow: boolean;
  biteSize: BiteSizeType;
  onHold: boolean;
  custodied: boolean;
  ubtiBlocker: boolean;
  impact: string;
  sectorsToAvoid: string[];
  progressBarValues: ProgressBarValuesType;
  summary: ClientEntitySummaryTableType;
  ignoreBiteSize: boolean;
  ignoreAllocationTargets: boolean;
};

export type SummaryTableData = {
  asset: string;
  target: number;
  targetAllocations: number;
  exposure: number;
  capacity: number;
};

export type ClientEntityDetailsCommitments = {
  ClientEntityDetailsCommitments: ClientEntityCommitments;
};

export type ClientEntityCommitments = {
  name: string;
  totalEntitiesValue: number;
  privatePercentage: number;
  targetOCRatio: number;
  currentOCRatio: number;
  notes: INotesData[];
  alwaysShow: boolean;
  onHold: boolean;
  impact: string;
  sectorsToAvoid: string[];
  privateRealAssetPercentage: number;
  privateEquityPercentage: number;
  privateCreditPercentage: number;
  diversifyingStrategiesPercentage: number;
  privateEquityCapacity: number;
  privateCreditCapacity: number;
  privateRealAssetsCapacity: number;
  diversifyingStrategiesCapacity: number;
  custodied: boolean;
  ubtiBlocker: boolean;
  biteSize: BiteSizeType;
  progressBarValues: undefined;
  commitments: CommitmentsType[];
};

export type CommitmentsType = {
  id: string;
  name: string;
  committedCapital: number;
  capitalCalled: number;
  capitalRemaining: number;
  distributionsToDate: string;
  marketValue: number;
  status: string;
  assetClass: string | null;
  moreInfo: CommitmentsMoreInfoType;
  investmentId: string;
  valuationAmount: number | null;
  valuationDate: Date | null;
  aggregateCallsAndDistributions: number | null;
};

export type CommitmentsMoreInfoType = {
  id: string;
  managerId: string;
  assetClass: string;
  nextClose: string;
  finalClose: string;
  investorDocsDue: string;
  minimum: number;
  custodied: boolean;
  ubtiBlocker: boolean;
  sri: boolean;
  targetIRR: number;
  targetYield: number;
};

export type ClientEntityDetailsAllocations = {
  ClientEntityDetailsAllocations: ClientEntityAllocations;
};

export type SuitabilityCriteria = {
  label: string;
  value: boolean;
};

export type ClientEntityAllocationsTableType = {
  investmentId: string;
  name: string;
  suitability: SuitabilityEnum;
  status: string;
  investorDocsDue: string;
  assetClass: string;
  minimum: number;
  suitabilityCriteria: SuitabilityCriteria[];
  proposalType: string;
  committedCapital: number;
};

export type ClientEntityAllocations = {
  id: string;
  name: string;
  totalEntitiesValue: number;
  privatePercentage: number;
  targetOCRatio: number;
  currentOCRatio: number;
  notes: INotesData[];
  biteSize: BiteSizeType;
  ubtiBlocker: boolean;
  custodied: boolean;
  alwaysShow: boolean;
  onHold: boolean;
  impact: string;
  sectorsToAvoid: string[];
  privateRealAssetPercentage: number;
  privateEquityPercentage: number;
  privateCreditPercentage: number;
  diversifyingStrategiesPercentage: number;
  privateEquityCapacity: number;
  privateCreditCapacity: number;
  privateRealAssetsCapacity: number;
  diversifyingStrategiesCapacity: number;
  progressBarValues: undefined;
  allocations: ClientEntityAllocationsTableType[];
};

export enum SuitabilityEnum {
  SUITABLE = 'Suitable',
  ON_HOLD = 'On hold',
  NO_CAPACITY = 'No capacity',
  NO_CAPACITY_PENDING_PROPOSAL = 'No capacity pending proposal',
  EXCLUDED_STRATEGY = 'Excluded strategy',
  CUSTODIED_REQUIREMENT = 'Custodied requirement',
  UBTI_REQUIREMENT = 'UBTI requirement',
  ASSET_CLASS_FULL = 'Asset class full',
  ASSET_CLASS_FULL_PENDING_PROPOSAL = 'Asset class full pending proposal'
}
