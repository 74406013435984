import { EditIcon } from '@/assets/icons/edit';
import { ApprovedIcon } from '@/assets/icons/info-icons/approved';
import { ArrowDown } from '@/assets/icons/info-icons/arrowDown';
import { CalendarIcon } from '@/assets/icons/info-icons/calendar';
import { EditTodoIcon } from '@/assets/icons/info-icons/editTodo';
import { FlagIcon } from '@/assets/icons/info-icons/flag';
import { RedStarIcon } from '@/assets/icons/info-icons/redStar';
import { AiAssistantIcon } from '@/assets/static/icons/ai-assistant';
import { ArrowUp } from '@/assets/static/icons/todo-page/arrow-up';
import { IFile } from '@/components/dueDiligence-page/dueDiligenceRecord';
import { AssignedTo, AssignedToWrapper, EditAssignedTo, RedirectText } from '@/components/dueDiligence-page/dueDiligenceRecord/sectionStatus';
import ShowText from '@/components/dueDiligence-page/dueDiligenceRecord/showText';
import {
  ArrowWrapper,
  DropdownBody,
  DropdownHeader,
  DropdownWrapper,
  ExpandableWrapp,
  HeaderInfo,
  Title
} from '@/components/dueDiligence-page/dueDiligenceRecord/tierDropdown';
import { InfoRowCell, NoRequired, RowWrapper } from '@/components/dueDiligence-page/dueDiligenceRecord/tierSection';
import { NoteDataType } from '@/components/dueDiligence-page/dueDiligenceRecord/types';
import Button from '@/components/fat-basicComponents/button';
import Header from '@/components/fat-header';
import { GoBackButton } from '@/components/fat-header/goBackButton';
import { PageTitle } from '@/components/fat-header/pageTitle';
import { LoaderOnWholeScreen } from '@/components/loaders/loader-on-whole-screen';
import DropdownActions from '@/components/table/fat-dropdownActions';
import { useResponsive } from '@/hooks/use-responsive';
import { useGoBack } from '@/hooks/useGoBack';
import { MainWrap, PaddingWrap } from '@/styles/common';
import { NetworkStatus, useQuery } from '@apollo/client';
import { headingsPlugin, linkDialogPlugin, linkPlugin, listsPlugin, MDXEditor, MDXEditorMethods } from '@mdxeditor/editor';
import { MutableRefObject, useEffect, useRef, useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import styled, { useTheme } from 'styled-components';
import { SectionsWrapper, SeparateStick, StatusText, StatusWrapper } from '..';
import ChangeAssignee from '../modals/changeAssignee';
import PublishUpdate from '../modals/publishUpdate';
import UnpublishUpdate from '../modals/unpublishUpdate';
import { GET_DOCUMENT_RECORD_QUERY } from '../queries';
import { DOCUMENT_RECORD_STATUS_ENUM, IDocumentRecordFieldEntity, IGetDocumentRecordQuery } from '../types';
import { AdvancedOptions } from './advanced-options';
import { AiPane } from './ai-pane';
import { EditFieldPage } from './edit-pages';
import Notes from './notes';

export const EditManagerUpdate = () => {
  const { isMobile, isTablet } = useResponsive();
  const goBack = useGoBack();
  const theme = useTheme();
  const params = useParams();

  const [searchParams, setSearchParams] = useSearchParams();

  const backToTitle = searchParams.get('backToTitle') || '';

  const [editFieldPageType, setEditFieldPageType] = useState('default');
  const [scrollTo, setScrollTo] = useState(0);
  const [currentField, setCurrentField] = useState<IDocumentRecordFieldEntity | null>(null);
  const [allFields, setAllFields] = useState<IDocumentRecordFieldEntity[] | null>(null);
  const [isAiPaneOpen, setIsAiPaneOpen] = useState(false);
  const [modalWindow, setModalWindow] = useState({
    isOpen: false,
    type: 'publish-update'
  });
  const [isDropdown, setIsDropdown] = useState(true);
  const [arrowHover, setArrowHover] = useState(false);
  const [isNotesOpen, setIsNotesOpen] = useState(false);
  const [noteData, setNoteData] = useState<NoteDataType | null>(null);
  const [files, setFiles] = useState<IFile[]>([]);
  const [generateAnswersLoading, setGenerateAnswersLoading] = useState(false);
  const [isGenerateAnswersLoadingCompleted, setIsGenerateAnswersLoadingCompleted] = useState(false);

  const editorRefs = useRef<(MDXEditorMethods | null)[]>([]);
  const containerEditorRefs: MutableRefObject<(HTMLDivElement | null)[]> = useRef([]);

  const {
    data: documentRecordData,
    refetch: documentRecordRefetch,
    networkStatus: documentRecordNetworkStatus
  } = useQuery<{ getDocumentRecord: IGetDocumentRecordQuery }>(GET_DOCUMENT_RECORD_QUERY, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'cache-and-network',
    skip: !params?.updateId,
    variables: {
      id: params?.updateId
    },
    onCompleted: (data) => {
      setAllFields(data.getDocumentRecord.fields);
    },
    onError: (error) => {
      console.error(error);
    }
  });

  useEffect(() => {
    editorRefs?.current?.forEach((editorRef, index) => {
      if (editorRef) {
        editorRef.setMarkdown(documentRecordData?.getDocumentRecord?.fields[index].value || '');
      }
    });
  }, [documentRecordData]);

  useEffect(() => {
    containerEditorRefs?.current?.forEach((containerEditorRef) => {
      if (containerEditorRef) {
        const links: NodeListOf<HTMLAnchorElement> = containerEditorRef.querySelectorAll('a');
        links.forEach((link: HTMLAnchorElement) => {
          link.setAttribute('target', '_blank');
        });
      }
    });
  }, [documentRecordData]);

  useEffect(() => {
    return () => {
      document.body.style.overflow = '';
      document.body.style.scrollbarGutter = '';
      document.body.style.position = '';
      document.body.style.width = '';
    };
  }, []);

  const handleMouseEnterAiPane = () => {
    document.body.style.overflow = 'hidden';
    document.body.style.scrollbarGutter = 'stable';
    document.body.style.position = 'fixed';
    document.body.style.width = '100%';
  };

  const handleMouseLeaveAiPane = () => {
    document.body.style.overflow = '';
    document.body.style.scrollbarGutter = '';
    document.body.style.position = '';
    document.body.style.width = '';
  };

  const openAdvancedOptions = () => {
    handleMouseLeaveAiPane();
    setIsAiPaneOpen(false);
    setEditFieldPageType('advanced-options');
  };

  const handleGenerateAnswersLoadingChange = (loadingStatus: boolean, showCompleteIcon: boolean) => {
    setGenerateAnswersLoading(loadingStatus);
    setIsGenerateAnswersLoadingCompleted(showCompleteIcon);
  };

  const closeAdvancedOptions = () => {
    setIsAiPaneOpen(true);
    setEditFieldPageType('default');
  };

  const openModalWindow = (type: string) => {
    setModalWindow({ ...modalWindow, isOpen: true, type });
  };

  const closeModalWindow = () => setModalWindow({ ...modalWindow, isOpen: false });

  const openEditPage = (row: IDocumentRecordFieldEntity) => {
    setScrollTo(window.scrollY);
    setEditFieldPageType(row.documentField.type);
    setCurrentField(row);
  };

  const closeEditPage = () => {
    setEditFieldPageType('default');
    setCurrentField(null);
  };

  const openNotes = (documentRecordData: IGetDocumentRecordQuery) => {
    const { note, updatedAt } = documentRecordData;
    setIsNotesOpen(!isNotesOpen);
    setNoteData({ note, updateAt: updatedAt, task: '', assignedBy: undefined, assignedTo: undefined });
  };

  const dropdownActions = () => {
    return [
      ...(documentRecordData?.getDocumentRecord?.status === 'DRAFT'
        ? [
            {
              name: 'Publish Update',
              onClick: () => openModalWindow('publish-update')
            }
          ]
        : []),
      ...(documentRecordData?.getDocumentRecord?.status === 'PUBLISHED'
        ? [
            {
              name: 'Unpublish Update',
              onClick: () => openModalWindow('unpublish-update')
            }
          ]
        : [])
    ];
  };

  if (documentRecordNetworkStatus === NetworkStatus.loading || documentRecordNetworkStatus === NetworkStatus.setVariables) {
    return <LoaderOnWholeScreen />;
  }

  if (editFieldPageType === 'advanced-options') {
    return (
      <AdvancedOptions
        handleClose={closeAdvancedOptions}
        documentRecordRefetch={documentRecordRefetch}
        files={files}
        setFiles={setFiles}
        handleGenerateAnswersLoadingChange={handleGenerateAnswersLoadingChange}
        dueDiligenceRecord={{}}
      />
    );
  }

  if (editFieldPageType !== 'default' && editFieldPageType !== 'advanced-options') {
    return (
      <EditManagerUpdatesWrapper>
        <EditFieldPage
          handleClose={closeEditPage}
          allFields={allFields}
          currentField={currentField}
          setIsAiPaneOpen={setIsAiPaneOpen}
          setCurrentField={setCurrentField}
          investmentId={''}
          editFieldPageType={editFieldPageType}
          deleteAttachment={() => {}}
          recordId={documentRecordData?.getDocumentRecord.id ?? null}
          refetch={documentRecordRefetch}
        />
        <AiPane
          handleMouseEnterAiPane={handleMouseEnterAiPane}
          handleMouseLeaveAiPane={handleMouseLeaveAiPane}
          dueDiligenceRecord={{}}
          documentRecordRefetch={documentRecordRefetch}
          files={files}
          setFiles={setFiles}
          isAiPaneOpen={isAiPaneOpen}
          onClose={() => setIsAiPaneOpen(false)}
          handleGenerateAnswersLoadingChange={handleGenerateAnswersLoadingChange}
          freeChatOnly={editFieldPageType !== 'default' && editFieldPageType !== 'advanced-options'}
          openAdvancedOptions={openAdvancedOptions}
        />
      </EditManagerUpdatesWrapper>
    );
  }

  return (
    <EditManagerUpdatesWrapper>
      <div style={{ width: '100%' }}>
        {modalWindow.isOpen && modalWindow.type === 'publish-update' && (
          <PublishUpdate
            isOpen={modalWindow.isOpen}
            onClose={closeModalWindow}
            updateId={documentRecordData?.getDocumentRecord?.id ?? null}
            ownerEmail={documentRecordData?.getDocumentRecord?.owner?.email ?? null}
            refetch={documentRecordRefetch}
          />
        )}
        {modalWindow.isOpen && modalWindow.type === 'unpublish-update' && (
          <UnpublishUpdate
            isOpen={modalWindow.isOpen}
            onClose={closeModalWindow}
            updateId={documentRecordData?.getDocumentRecord?.id ?? null}
            refetch={documentRecordRefetch}
          />
        )}
        {modalWindow.isOpen && modalWindow.type === 'change-assignee' && (
          <ChangeAssignee
            isOpen={modalWindow.isOpen}
            onClose={closeModalWindow}
            assignee={documentRecordData?.getDocumentRecord?.assignee ?? null}
            owner={documentRecordData?.getDocumentRecord?.owner ?? null}
            updateId={documentRecordData?.getDocumentRecord?.id ?? null}
            status={documentRecordData?.getDocumentRecord?.status ?? null}
            documentRecordRefetch={documentRecordRefetch}
          />
        )}
        <MainWrap>
          <Header modalControl={<GoBackButton handleClose={() => goBack({ fallBack: '/investors' })} backToTitle={backToTitle} />} />
          <PageTitle title={documentRecordData?.getDocumentRecord?.name ?? ''} />
        </MainWrap>
        <MainWrap>
          <PaddingWrap>
            <Notes onClick={() => setIsNotesOpen(false)} isOpen={isNotesOpen} data={noteData} />
            <StatusWrapper isMobile={isMobile}>
              <Status isMobile={isMobile}>
                <div>
                  <FlagIcon fill={theme.font.base} />
                </div>
                <SeparateStick />
                <StatusText>{DOCUMENT_RECORD_STATUS_ENUM[documentRecordData?.getDocumentRecord?.status]}</StatusText>
                {!dropdownActions().length || (
                  <DropDownWrapper isMobile={isMobile}>
                    <DropdownActions isMobile isListCenter items={dropdownActions()} />
                  </DropDownWrapper>
                )}
              </Status>
              <AiAssistantButton
                styleType="outline"
                onClick={() => setIsAiPaneOpen(!isAiPaneOpen)}
                size="md"
                icon={<AiAssistantIcon fill={theme.font.action} />}
                secondaryIcon={isGenerateAnswersLoadingCompleted && <ApprovedIcon width={20} height={20} fill={theme.context.success} />}
                isLoading={generateAnswersLoading}
              >
                AI Assistant
              </AiAssistantButton>
            </StatusWrapper>
            <SectionsWrapper>
              <DropdownWrapper>
                <DropdownHeader isMobile={isMobile} isChecklist={false} isDropdown={isDropdown}>
                  <HeaderInfo>
                    <Title isMobile={isMobile} id="dropdownTitle">
                      Update
                    </Title>
                    <AssignedToWrapper isMobile={isMobile}>
                      <EditAssignedTo isMobile={isMobile} onClick={() => openModalWindow('change-assignee')}>
                        <CalendarIcon fill={theme.context.warning} />
                        <AssignedTo isMobile={isMobile}>
                          Assigned To:{' '}
                          <RedirectText disabled={false}>{`${documentRecordData?.getDocumentRecord?.assignee?.firstName ?? ''} ${
                            documentRecordData?.getDocumentRecord?.assignee?.lastName ?? ''
                          }`}</RedirectText>
                        </AssignedTo>
                        <EditIcon width={16} height={16} fill={theme.font.action} style={{ cursor: 'pointer', margin: '0 20px 0 6px' }} />
                      </EditAssignedTo>
                      {/* <NoteButton isVisible={true} openNotes={() => openNotes(documentRecordData?.getDocumentRecord)} /> */}
                    </AssignedToWrapper>
                  </HeaderInfo>
                  <ArrowScoreWrapper isMobile={isMobile} isChecklist={Boolean(openModalWindow)}>
                    <ArrowWrapper
                      isMobile={isMobile}
                      onClick={() => setIsDropdown(!isDropdown)}
                      onMouseEnter={() => setArrowHover(true)}
                      onMouseLeave={() => setArrowHover(false)}
                    >
                      <>
                        {isDropdown ? (
                          <ArrowUp style={{ cursor: 'pointer' }} fill={arrowHover && !isMobile ? theme.context.light : theme.font.action} />
                        ) : (
                          <ArrowDown
                            style={{
                              cursor: 'pointer',
                              width: '28px',
                              height: '28px',
                              padding: '7px'
                            }}
                            fill={arrowHover && !isMobile ? theme.context.light : theme.font.action}
                          />
                        )}
                      </>
                    </ArrowWrapper>
                  </ArrowScoreWrapper>
                </DropdownHeader>
                <DropdownBody isMobile={isMobile} isDropdown={isDropdown}>
                  <ExpandableWrapp isMobile={isMobile} isDropdown={isDropdown}>
                    {documentRecordData?.getDocumentRecord?.fields?.map((field, index) => (
                      <RowWrapper key={field.id} disabled={false} isMobile={isMobile}>
                        <InfoRowCell>
                          {true ? <RedStarIcon width={10} height={10} /> : <NoRequired />}
                          {field.documentField.name}
                        </InfoRowCell>
                        <ShowText>
                          <div ref={(el) => (containerEditorRefs.current[index] = el)}>
                            <CustomMDXEditor
                              ref={(el) => (editorRefs.current[index] = el)}
                              markdown={field.value || ''}
                              readOnly
                              plugins={[listsPlugin(), headingsPlugin(), linkPlugin(), linkDialogPlugin()]}
                            />
                          </div>
                        </ShowText>
                        <EditTodoButton onClick={() => openEditPage(field)} id="editTodoButton" isMobile={isMobile}>
                          <EditTodoIcon fill={theme.context.light} />
                        </EditTodoButton>
                      </RowWrapper>
                    ))}
                  </ExpandableWrapp>
                </DropdownBody>
              </DropdownWrapper>
            </SectionsWrapper>
            <ButtonWrapper>
              <Button styleType="outline" size="md">
                Download
              </Button>
              {documentRecordData?.getDocumentRecord?.status === 'DRAFT' && (
                <Button onClick={() => openModalWindow('publish-update')} size="md">
                  Publish
                </Button>
              )}
              {documentRecordData?.getDocumentRecord?.status === 'PUBLISHED' && (
                <Button onClick={() => openModalWindow('unpublish-update')} size="md">
                  Unpublish
                </Button>
              )}
            </ButtonWrapper>
          </PaddingWrap>
        </MainWrap>
      </div>
      <AiPane
        handleMouseEnterAiPane={handleMouseEnterAiPane}
        handleMouseLeaveAiPane={handleMouseLeaveAiPane}
        dueDiligenceRecord={{}}
        documentRecordRefetch={documentRecordRefetch}
        files={files}
        setFiles={setFiles}
        isAiPaneOpen={isAiPaneOpen}
        onClose={() => setIsAiPaneOpen(false)}
        handleGenerateAnswersLoadingChange={handleGenerateAnswersLoadingChange}
        freeChatOnly={editFieldPageType !== 'default' && editFieldPageType !== 'advanced-options'}
        openAdvancedOptions={openAdvancedOptions}
      />
    </EditManagerUpdatesWrapper>
  );
};

const EditManagerUpdatesWrapper = styled.div`
  display: flex;
  justify-content: center;
  transition: all 0.3s;
`;

const Status = styled.div<{ isMobile: boolean; published?: boolean }>`
  display: flex;
  align-items: center;
  width: ${({ isMobile }) => (isMobile ? '100%' : 'auto')};
  gap: ${({ isMobile }) => (isMobile ? '5px' : '20px')};
  color: ${({ published }) => (published ? '#3dbd4a' : '#373F4E')};
`;

const AiAssistantButton = styled(Button)`
  &:hover {
    svg {
      path {
        fill: ${({ theme }) => theme.context.light};
      }
    }
  }
`;

const DropDownWrapper = styled.div<{ isMobile: boolean }>`
  padding-left: 10px;
  margin-left: ${({ isMobile }) => (isMobile ? 'auto' : '0')};
`;

const ArrowScoreWrapper = styled.div<{ isMobile: boolean; isChecklist: boolean }>`
  display: flex;
  align-items: center;
  justify-content: ${({ isMobile }) => (isMobile ? 'space-between' : 'flex-start')};
  margin-top: ${({ isMobile }) => (isMobile ? '10px' : '0')};
  gap: ${({ isMobile }) => (isMobile ? '0' : '30px')};
  width: ${({ isMobile, isChecklist }) => (isMobile && isChecklist ? '100%' : 'auto')};
  position: relative;
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  gap: 10px;
  padding: 20px 0;
`;

const EditTodoButton = styled.div<{ isMobile: boolean }>`
  background-color: ${({ theme }) => theme.action.default};
  border-radius: 4px;
  padding: 12px;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: auto;
  cursor: pointer;
  width: ${({ isMobile }) => (isMobile ? '100%' : 'auto')};
  opacity: ${({ isMobile }) => (isMobile ? '1' : '0')};
`;

const CustomMDXEditor = styled(MDXEditor)`
  .mdxeditor-root-contenteditable ul,
  .mdxeditor-root-contenteditable ol {
    padding-left: 26px;
  }
`;
