import { AiAssistantIcon } from '@/assets/static/icons/ai-assistant';
import Textarea from '@/components/basicComponents/textarea';
import { DatePicker } from '@/components/form-field';
import { format } from 'date-fns';
import { useEffect, useState } from 'react';
import { useTheme } from 'styled-components';
import { ISectionFields } from '../types';
import { AiIconWrap, Answer, AnswerWrap, AutoGeneratedField, CommentaryWrap, FieldName } from './dollarContent';
import { EditWrapper } from './editWrapper';

interface DatePageProps {
  handleClose: () => void;
  row: ISectionFields | null;
  updateFieldAnswer: (variables: any) => void;
  investmentId: string | null;
}

export function extractAndFormatDate(input: string) {
  if (!input) return '';
  const dateRegex = /([A-Za-z]+) (\d{1,2}), (\d{4})/;

  const match = input.match(dateRegex);

  if (match) {
    const month = match[1];
    const day = match[2];
    const year = match[3];

    const monthMap = {
      January: 1,
      February: 2,
      March: 3,
      April: 4,
      May: 5,
      June: 6,
      July: 7,
      August: 8,
      September: 9,
      October: 10,
      November: 11,
      December: 12
    };

    const numericMonth = monthMap[month as keyof typeof monthMap];

    const formattedDate = `${year}-${numericMonth.toString().padStart(2, '0')}-${day.toString().padStart(2, '0')}T21:00:00.000Z`;

    return formattedDate;
  } else {
    return '';
  }
}

export const DatePage = ({ handleClose, row, updateFieldAnswer, investmentId }: DatePageProps) => {
  const theme = useTheme();

  const [dateValue, setDateValue] = useState<{
    fieldValue: Date | any;
    commentary: string;
  }>({ fieldValue: null, commentary: '' });
  const [isEditMode, setIsEditMode] = useState(true);

  useEffect(() => {
    if (!row) return;

    if (row?.data.value !== null || row?.data.commentary !== null) {
      setIsEditMode(true);
      setDateValue({
        ...dateValue,
        fieldValue: row?.data.value || dateValue.fieldValue,
        commentary: row?.data.commentary || dateValue.commentary
      });
      return;
    }
    if (row.AI?.answers[0]) {
      setIsEditMode(false);
      setDateValue({
        ...dateValue,
        fieldValue: extractAndFormatDate(row.AI?.answers[0]),
        commentary: row.AI.answers[0]
      });
      return;
    }
    return () => {
      setDateValue({ fieldValue: null, commentary: '' });
    };
  }, [row]);

  const saveValue = () => {
    updateFieldAnswer({
      variables: {
        data: {
          id: row.data.id,
          value: isEditMode ? dateValue.fieldValue : null,
          commentary: isEditMode ? dateValue.commentary : null
        }
      },
      onCompleted: () => {
        setDateValue({ fieldValue: null, commentary: '' });
        handleClose();
      }
    });
  };

  const editSuggested = (suggestion: string) => {
    setDateValue({
      fieldValue: extractAndFormatDate(suggestion),
      commentary: suggestion
    });
    setIsEditMode(true);
  };

  const clearSuggestion = () => {
    setDateValue({ fieldValue: null, commentary: '' });
    setIsEditMode(true);
  };

  const startWithAISuggestion = () => {
    setDateValue({
      fieldValue: extractAndFormatDate(row.AI?.answers[0]),
      commentary: row.AI?.answers[0]
    });
    setIsEditMode(false);
  };

  const revertPriorDraft = () => {
    if (row?.data.value || row?.data.commentary) {
      setDateValue({
        ...dateValue,
        fieldValue: row?.data.value,
        commentary: row?.data.commentary
      });
      setIsEditMode(true);
    }
  };

  if (!row) return <></>;

  return (
    <EditWrapper
      showAiSuggestion={Boolean(row.AI?.answers.length > 0)}
      isEditMode={isEditMode}
      startWithAISuggestion={startWithAISuggestion}
      editSuggested={editSuggested}
      clearSuggestion={clearSuggestion}
      revertPriorDraft={revertPriorDraft}
      showRevertPriorDraft={
        (Boolean(row?.data.value) && row?.data.value !== dateValue.fieldValue) ||
        (Boolean(row?.data.commentary) && row?.data.commentary !== dateValue.commentary)
      }
      handleClose={handleClose}
      row={row}
      investmentId={investmentId}
      confirmButton={{ name: 'Save', onClick: saveValue }}
    >
      <div>
        {isEditMode ? (
          <>
            <FieldName>{row.name}</FieldName>
            <DatePicker
              title={''}
              name={'nextClose'}
              errors={''}
              value={dateValue.fieldValue}
              changeValue={(value: string | string[]) => {
                setDateValue({
                  ...dateValue,
                  fieldValue: value
                });
              }}
            />
          </>
        ) : (
          <>
            <FieldName>{row.name}</FieldName>
            <AutoGeneratedField>
              <AiIconWrap>
                <AiAssistantIcon width={28} height={28} fill={theme.font.weak} />
              </AiIconWrap>
              <div>
                <span style={{ fontWeight: 700 }}>Suggested {row.AI?.citations.length >= 2 && `(${row.AI?.citations.length - 1} more)`}:</span>{' '}
                {dateValue.fieldValue && format(new Date(dateValue.fieldValue), 'LLL d, yyyy')}
              </div>
            </AutoGeneratedField>
          </>
        )}
      </div>
      <CommentaryWrap>
        <FieldName>Commentary</FieldName>
        {isEditMode ? (
          <Textarea
            value={dateValue.commentary}
            textareaHeight="80px"
            isValidValue={!!dateValue.commentary}
            maxLength={250}
            setValue={(value) => setDateValue({ ...dateValue, commentary: value })}
          />
        ) : (
          <AnswerWrap>
            <AiIconWrap>
              <AiAssistantIcon width={28} height={28} fill={theme.font.weak} />
            </AiIconWrap>
            <Answer>
              <span style={{ fontWeight: 700 }}>Suggested {row.AI?.citations.length >= 2 && `(${row.AI?.citations.length - 1} more)`}:</span>{' '}
              {row.AI?.answers[0]}
            </Answer>
          </AnswerWrap>
        )}
      </CommentaryWrap>
    </EditWrapper>
  );
};
