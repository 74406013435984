import { RejectedBannerIcon } from '@/assets/icons/info-icons/rejectedBanner';
import Header from '@/components/fat-header';
import { GoBackButton } from '@/components/fat-header/goBackButton';
import { PageTitle } from '@/components/fat-header/pageTitle';
import EntityPopover from '@/components/fat-pendingInvestments-page/fat-table/popovers/entity';
import { LoaderOnWholeScreen } from '@/components/loaders/loader-on-whole-screen';
import MobileModal from '@/components/mobileModal';
import SuitabilityDetails from '@/components/opportunities-entity/pendingAllocation/suitabilityDetails';
import { initialValuesFromTable } from '@/components/table/constants';
import FilterPage from '@/components/table/fat-filterPage';
import { ISort, SORT_ORDER } from '@/components/table/types';
import { useResponsive } from '@/hooks/use-responsive';
import { useGoBack } from '@/hooks/useGoBack';
import { MainWrap } from '@/styles/common';
import guid from '@/utils/guid';
import { useQuery } from '@apollo/client';
import { useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import styled, { useTheme } from 'styled-components';
import { Details } from '../fat-opportunityDetails-page/details';
import { OfferingMaterials } from '../fat-opportunityDetails-page/offeringMaterials';
import SpecialControlHeader from '../fat-switcher/specialControlHeader';
import InvestorsTable from './fat-investorsTable/table';
import EditAmount from './fat-modals/editAmount';
import FooterModal from './footer';
import { INVESTMENT_QUERY, LIST_INVESTMENT_ENTITY_INTERESTS_QUERY } from './queries';
import { IInvestmentEntity, IListInvestmentEntityInterests } from './types';

export const SeekingInterestPage = () => {
  const goBack = useGoBack();
  const navigate = useNavigate();
  const params = useParams();
  const { isMobile, isTablet } = useResponsive();
  const theme = useTheme();
  const [searchParams, setSearchParams] = useSearchParams();

  const backToTitle = searchParams.get('backToTitle') || '';
  const activeTab = searchParams.get('tab') || 'Investors';
  const q = searchParams.get('q') || '';
  const key = searchParams.get('key') || 'ENTITY_NAME';
  const asc = searchParams.get('asc') === null || searchParams.get('asc') === 'true';
  const position = searchParams.get('position') || 0;
  const selectedEntitiesIds = searchParams.get('selectedEntitiesIds') || '[]';

  const [tabs, setTabs] = useState<{ value: string; amount: null | number }[]>([{ value: 'Investors', amount: null }]);
  const [isTabMenuOpen, setIsTabMenuOpen] = useState(false);
  const [switcher, setSwitcher] = useState(true);
  const [modalWindow, setModalWindow] = useState({
    isOpen: false,
    type: 'edit-amount'
  });
  const [changedRows, setChangedRows] = useState<IInvestmentEntity[]>([]);
  const [listInvestmentEntity, setListInvestmentEntity] = useState<IInvestmentEntity[]>([]);
  const [currentRow, setCurrentRow] = useState<IInvestmentEntity | null>(null);
  const [isSuitabilityOpen, setIsSuitabilityOpen] = useState(false);
  const [suitabilityData, setSuitabilityData] = useState<IInvestmentEntity>();
  const [isEntityOpen, setIsEntityOpen] = useState(false);
  const [entityData, setEntityData] = useState<IInvestmentEntity>();
  const [isFirstLoading, setIsFirstLoading] = useState(true);

  const { data: investmentData, loading: loadingInvestment } = useQuery(INVESTMENT_QUERY, {
    notifyOnNetworkStatusChange: true,
    variables: { id: params?.id },
    skip: !params?.id,
    onError: (error) => {
      console.error(error);
    }
  });

  const {
    data: listInvestmentEntityData,
    refetch: listInvestmentEntityRefetch,
    loading: listInvestmentEntityLoading
  } = useQuery<IListInvestmentEntityInterests>(LIST_INVESTMENT_ENTITY_INTERESTS_QUERY, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'no-cache',
    skip: !params?.id,
    variables: {
      data: {
        investmentId: params?.id,
        sortBy: key,
        sortDirection: asc ? SORT_ORDER.ASC : SORT_ORDER.DESC,
        search: q.toLowerCase().trim(),
        limit: initialValuesFromTable.initialAmountRowsPerPage[0],
        offset: 0,
        showPotential: true
      }
    },
    onCompleted: (data) => {
      const updatedListInvestmentEntity = data.listInvestmentEntityInterests.data.map((item) => {
        const changedRow = changedRows.find((changedRow) => changedRow.id === item.id);
        const isSelected = JSON.parse(selectedEntitiesIds).includes(item.id);

        if (changedRow) {
          return { ...changedRow, checked: isSelected || changedRow.checked };
        }

        return { ...item, checked: isSelected || Boolean(item.investor_interest[0]?.id) };
      });

      setListInvestmentEntity(updatedListInvestmentEntity);
      setIsFirstLoading(false);
      makeScroll(position);
      if (isFirstLoading) {
        setChangedRows(
          data.listInvestmentEntityInterests.data
            .map((item) => {
              const isSelected = JSON.parse(selectedEntitiesIds).includes(item.id);
              return { ...item, checked: isSelected || Boolean(item.investor_interest[0]?.id) };
            })
            .filter((item) => item.checked)
        );
      }
    },
    onError: (error) => {
      console.error(error);
      setIsFirstLoading(false);
    }
  });

  useEffect(() => {
    if (listInvestmentEntityData && investmentData) {
      setTabs([
        ...(investmentData.Investment.offeringMaterialsAttachments.length || investmentData.Investment.offeringMaterialsDescription
          ? [{ value: 'Offering Materials', amount: null }]
          : []),
        { value: 'Investors', amount: listInvestmentEntityData.listInvestmentEntityInterests.total }
      ]);
    }
  }, [investmentData, listInvestmentEntityData]);

  const toggleSwitcher = () => {
    setSwitcher(!switcher);
  };

  const openModalWindow = (type: string, row: IInvestmentEntity) => {
    setModalWindow({ type, isOpen: true });
    setCurrentRow(row);
  };

  const closeModalWindow = () => {
    setModalWindow({ ...modalWindow, isOpen: false });
    setCurrentRow(null);
  };

  const selectRow = (row: IInvestmentEntity) => {
    setListInvestmentEntity((prevList) => {
      const updatedList = prevList.map((item) => (item.id === row.id ? { ...item, checked: !item.checked } : item));
      const isRowChanged = changedRows.some((changedRow) => changedRow?.id === row?.id);

      const updatedSelectedRows = isRowChanged
        ? changedRows.map((item) => (item.id === row.id ? { ...item, checked: !item.checked } : item))
        : [...changedRows, { ...row, checked: !row.checked }];

      setChangedRows(updatedSelectedRows);

      return updatedList;
    });
  };

  const selectAllRows = (isChecked: boolean) => {
    setListInvestmentEntity((prevList) => {
      const updatedList = prevList.map((item) => ({ ...item, checked: isChecked }));

      const updatedChangedRows = prevList.map((item) => {
        const currentChangedAllocation = changedRows.find((changedRow) => changedRow?.id === item?.id);

        if (currentChangedAllocation) {
          return {
            ...currentChangedAllocation,
            checked: isChecked
          };
        }

        return { ...item, checked: isChecked };
      });
      setChangedRows(updatedChangedRows);

      return updatedList;
    });
  };

  const updateAmountInState = (id: string, value: number) => {
    setListInvestmentEntity((prev) => {
      return prev.map((row) =>
        row.id === id
          ? {
              ...row,
              investor_interest: [
                {
                  ...row.investor_interest[0],
                  amount: value
                }
              ]
            }
          : row
      );
    });

    const currentRow = listInvestmentEntity.find((row) => row?.id === id);
    const isRowChanged = changedRows.some((row) => row?.id === currentRow?.id);

    const updatedRow = {
      ...currentRow,
      investor_interest: [
        {
          ...currentRow.investor_interest[0],
          amount: value
        }
      ]
    };

    setChangedRows(isRowChanged ? changedRows.map((row) => (row?.id === currentRow?.id ? updatedRow : row)) : [...changedRows, updatedRow]);
  };

  const openEntityDetails = (investorId: string, entityId: string) => {
    saveScrollPostion();
    navigate(`/investors/${investorId}/${entityId}?backToTitle=Seeking Interest`);
  };

  const openInvestorDetails = (investorId: string) => {
    saveScrollPostion();
    navigate(`/investors/${investorId}?backToTitle=Seeking Interest`);
  };

  const onChangeActiveTab = (value: string) => {
    setSearchParams(
      (prev) => {
        prev.set('tab', value);
        return prev;
      },
      { replace: true }
    );
  };

  const onChangeSort = (value: ISort) => {
    setSearchParams(
      (prev) => {
        prev.set('key', value.key);
        prev.set('asc', value.asc.toString());
        return prev;
      },
      { replace: true }
    );
  };

  const onChangeSearch = (searchValue: string) => {
    setSearchParams(
      (prev) => {
        if (!searchValue) {
          prev.delete('q');
          return prev;
        }
        prev.set('q', searchValue);
        return prev;
      },
      { replace: true }
    );
  };

  const saveScrollPostion = () => {
    const selectedEntitiesIds = changedRows.filter((row) => row.checked).map((row) => row.id);
    setSearchParams(
      (prev) => {
        prev.set('position', window.scrollY.toString());
        prev.set('selectedEntitiesIds', JSON.stringify(selectedEntitiesIds));
        return prev;
      },
      { replace: true }
    );
  };

  const makeScroll = (position: string | number) => {
    if (!position) return;
    setTimeout(() => window.scrollTo({ top: Number(position), behavior: 'smooth' }), 0);
    setSearchParams(
      (prev) => {
        prev.delete('position');
        prev.delete('selectedEntitiesIds');
        return prev;
      },
      { replace: true }
    );
  };

  const openMobileModal = (data: IInvestmentEntity, modalType: string) => {
    if (modalType === 'suitability') {
      setIsSuitabilityOpen(true);
      setSuitabilityData(data);
    }
    if (modalType === 'entity') {
      setIsEntityOpen(true);
      setEntityData(data);
    }
  };

  const getSelectedRows = useMemo(() => {
    return changedRows.filter((row) => row.checked);
  }, [changedRows]);

  if (isFirstLoading || loadingInvestment) {
    return <LoaderOnWholeScreen size={60} />;
  }

  return (
    <>
      {isSuitabilityOpen && suitabilityData && (
        <MobileModal
          title={suitabilityData.suitability}
          onClose={() => {
            setIsSuitabilityOpen(false);
          }}
          onClick={() => null}
        >
          <SuitabilityDetails data={suitabilityData.suitabilityCriteria} key={guid()} />
        </MobileModal>
      )}
      {isEntityOpen && entityData && (
        <MobileModal
          title={entityData?.entityName}
          onClose={() => {
            setIsEntityOpen(false);
          }}
          onClick={() => openEntityDetails(entityData?.family.id, entityData?.id)}
        >
          <EntityPopover
            tooltipContent={`${investmentData?.Investment?.assetClass} corresponds to\n${investmentData?.Investment?.name}.`}
            id={entityData.id}
            allocation={{ legalEntity: { ...entityData }, investment: { assetClass: investmentData?.Investment?.assetClass } }}
            type={investmentData?.Investment?.type.toString()}
            alwaysShow={entityData?.alwaysShow}
          />
        </MobileModal>
      )}
      {modalWindow.type === 'edit-amount' && (
        <EditAmount
          isOpen={modalWindow.isOpen}
          onClose={closeModalWindow}
          row={currentRow}
          refetch={listInvestmentEntityRefetch}
          updateAmountInState={updateAmountInState}
          minimum={investmentData?.Investment.minimum ?? 0}
        />
      )}
      <MainWrap>
        <Header
          specialControl={<SpecialControlHeader switcher={switcher} setSwitcher={toggleSwitcher} title="Include Pending Commitments" />}
          modalControl={<GoBackButton handleClose={() => goBack({ fallBack: '/dealCatalog' })} backToTitle={backToTitle} />}
        />
        <PageTitle title={investmentData?.Investment.name ?? ''} />
        <Details
          tabs={tabs}
          activeTab={activeTab}
          setActiveTab={onChangeActiveTab}
          isTabMenuOpen={isTabMenuOpen}
          setIsTabMenuOpen={setIsTabMenuOpen}
          detailsData={investmentData?.Investment ?? null}
        />
      </MainWrap>
      <MainWrap>
        <PaddingWrap>
          {activeTab === 'Offering Materials' && (
            <OfferingMaterials
              attachments={investmentData?.Investment.offeringMaterialsAttachments ?? []}
              description={investmentData?.Investment.offeringMaterialsDescription ?? ''}
            />
          )}
          {activeTab === 'Investors' && (
            <>
              <BannerWrapper>
                <IconWrap>
                  <RejectedBannerIcon fill={theme.header.base} />
                </IconWrap>
                <Description>
                  Select each investor that will potentially invest in this fund when and if it becomes open for investment. Edit the default
                  commitment amount of each investor if applicable. Once finished, save your selection.
                </Description>
              </BannerWrapper>
              <CustomFilterPage
                isMobile={isMobile}
                isTablet={isTablet}
                search={{
                  value: q,
                  onChange: onChangeSearch,
                  placeholder: 'Search'
                }}
                resultsValue={listInvestmentEntity.length}
                refetch={listInvestmentEntityRefetch}
              />
              <InvestorsTable
                sort={{ key, asc }}
                setSort={onChangeSort}
                listInvestmentEntity={listInvestmentEntity}
                loading={listInvestmentEntityLoading}
                openModalWindow={openModalWindow}
                selectRow={selectRow}
                selectAllRows={selectAllRows}
                openInvestorDetails={openInvestorDetails}
                openEntityDetails={openEntityDetails}
                investment={investmentData?.Investment ?? null}
                openMobileModal={openMobileModal}
              />
            </>
          )}
        </PaddingWrap>
      </MainWrap>
      {!isMobile && activeTab === 'Investors' && (
        <FooterWrap>
          <FooterModal selectedRows={getSelectedRows} handleClosePage={() => goBack({ fallBack: '/dealCatalog' })} />
        </FooterWrap>
      )}
    </>
  );
};

const PaddingWrap = styled.div`
  margin: 20px 0;
  padding: 0 16px;
  @media (min-width: 600px) {
    padding: 0 60px;
  }
`;

const BannerWrapper = styled.div`
  background-color: ${({ theme }) => theme.layer[1]};
  border-radius: 10px;
  display: flex;
  align-items: center;
  gap: 20px;
  padding: 10px 20px;
`;

const FooterWrap = styled.div`
  margin-top: 150px;
`;

const IconWrap = styled.span`
  display: flex;
  align-items: center;
`;

const Description = styled.span`
  color: ${({ theme }) => theme.font.base};
  font-size: 13px;
  font-weight: 400;
  line-height: 18.2px;
  border-left: 1px solid ${({ theme }) => theme.border.base};
  padding-left: 20px;
`;

const CustomFilterPage = styled(FilterPage)<{ isMobile: boolean; isTablet: boolean }>`
  position: sticky;
  top: ${({ isMobile, isTablet }) => (isMobile || isTablet ? '50px' : '0')};
  z-index: 9;
  margin-top: 0;
  margin-left: -1px;
  width: calc(100% + 2px);
  padding: ${({ isMobile }) => (isMobile ? '20px 0 0 0' : '20px 0 10px 0')};
`;
