import { ApprovedIcon } from '@/assets/icons/info-icons/approved';
import { RedStarIcon } from '@/assets/icons/info-icons/redStar';
import FormattingTooltip from '@/components/basicComponents/tooltip/dollarsTooltip';
import Button from '@/components/fat-basicComponents/button';
import NoResults from '@/components/table/noResults';
import { TableRowCell, TableRowWrapper, TextCell } from '@/components/table/styles';
import guid from '@/utils/guid';
import styled, { useTheme } from 'styled-components';
import { IAllocationConflictsTableData } from '../../../types';
import { columns } from '../constants';

interface TableBodyProps {
  tableData: IAllocationConflictsTableData[];
  openResolveAllocationConflicts: (row: IAllocationConflictsTableData) => void;
}

const TableBody = ({ tableData, openResolveAllocationConflicts }: TableBodyProps) => {
  const theme = useTheme();

  if (!tableData?.length) {
    return <NoResults />;
  }

  const getRowStatus = (validated: boolean) => {
    if (validated) {
      return (
        <CompleteStatusCell>
          <ApprovedIcon width={23} height={23} fill={theme.context.success} />
          Validated
        </CompleteStatusCell>
      );
    }

    return (
      <RequiredStatusCell>
        <div style={{ padding: '5px' }}>
          <RedStarIcon width={9} height={11} fill={theme.context.error} />
        </div>
        Conflict
      </RequiredStatusCell>
    );
  };

  return (
    <>
      {tableData.map((row) => (
        <CustomTableRowWrapper key={guid()}>
          <TableRowCell width={columns[0].width}>
            <TextCell>{`${row.entityName} (${row.advisoryFirm})`}</TextCell>
          </TableRowCell>
          <TableRowCell width={columns[1].width}>
            <TextCell>{row.investmentName}</TextCell>
          </TableRowCell>
          <TableRowCell width={columns[2].width}>
            <TextCell>
              <FormattingTooltip zIndex={1000}>{row.importTotal}</FormattingTooltip>
            </TextCell>
          </TableRowCell>
          <TableRowCell width={columns[3].width}>
            <TextCell>{row.importCount}</TextCell>
          </TableRowCell>
          <TableRowCell width={columns[4].width}>
            <TextCell>
              <FormattingTooltip zIndex={1000}>{row.committedTotal}</FormattingTooltip>
            </TextCell>
          </TableRowCell>
          <TableRowCell width={columns[5].width}>
            <TextCell>
              <FormattingTooltip zIndex={1000}>{row.pendingAmount}</FormattingTooltip>
            </TextCell>
          </TableRowCell>
          <TableRowCell width={columns[6].width}>
            <TextCell>{getRowStatus(row.validated)}</TextCell>
          </TableRowCell>
          <TableRowCell width={columns[7].width}>
            <ButtonWrap id="infoWrap">
              <Button size="sm" onClick={() => openResolveAllocationConflicts(row)}>
                {row.validated ? 'View' : 'Resolve'}
              </Button>
            </ButtonWrap>
          </TableRowCell>
        </CustomTableRowWrapper>
      ))}
    </>
  );
};

export default TableBody;

const CustomTableRowWrapper = styled(TableRowWrapper)`
  justify-content: space-between;
`;

const ButtonWrap = styled.div`
  opacity: 0;
`;

const StatusCell = styled.div`
  display: flex;
  gap: 4px;
  align-items: center;
  font-size: 16px;
  font-weight: 600;
  line-height: 22.4px;
`;

const RequiredStatusCell = styled(StatusCell)`
  color: ${({ theme }) => theme.context.error};
`;

const CompleteStatusCell = styled(StatusCell)`
  color: ${({ theme }) => theme.context.success};
`;
