import { useEffect, useRef, useState } from 'react';
import styled, { css } from 'styled-components';

import { IItems } from '@/components/table/types';
import { useClickOutside } from '@/hooks/useClickOutside';
import { FadeAnimation } from '@/styles/animations';

interface BulkDropdownActionsProps {
  items: IItems[];
  disabled?: boolean;
  isMobile?: boolean;
  selected?: boolean;
  isListCenter?: boolean;
  showListItems?: boolean;
  value: string;
  onChange: (value: string) => void;
}

const BulkDropdownActions = ({
  items,
  disabled = false,
  isMobile = false,
  selected = false,
  isListCenter = false,
  showListItems = true,
  value,
  onChange
}: BulkDropdownActionsProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const ref = useRef<HTMLDivElement>(null);
  const allocationInputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    const closeOnEnterKey = (event: KeyboardEvent) => {
      if (event.key === 'Enter') {
        setIsOpen(false);
        allocationInputRef?.current?.blur();
      }
    };

    document.body.addEventListener('keydown', closeOnEnterKey);
    return () => {
      document.body.removeEventListener('keydown', closeOnEnterKey);
    };
  }, []);

  const handleClick = (event: any) => {
    if (event.target.id !== 'modal') {
      setIsOpen(!isOpen);
    }
  };

  useClickOutside(ref, () => setIsOpen(false));

  if (isMobile) {
    return (
      <ClientDropdownWrap isOpen={true} onClick={handleClick} id="infoWrap" ref={ref} disabled={disabled}>
        <AllocationInput id="allocationInput" value={value} onChange={(e) => onChange(e.target.value)} ref={allocationInputRef} />
        {(isOpen || showListItems) && (
          <ClientDropdownModal isOpen={isOpen} isListCenter={isListCenter}>
            {items.map(({ name, onClick }) => (
              <ClientDropdownItem key={name} onClick={onClick}>
                {name}
              </ClientDropdownItem>
            ))}
          </ClientDropdownModal>
        )}
      </ClientDropdownWrap>
    );
  }

  return (
    <ClientDropdownWrap isOpen={true} onClick={handleClick} id="infoWrap" ref={ref} disabled={disabled}>
      <AllocationInput id="allocationInput" value={value} onChange={(e) => onChange(e.target.value)} />
      {(isOpen || showListItems) && (
        <ClientDropdownModal isOpen={isOpen}>
          {items.map(({ name, onClick }) => (
            <ClientDropdownItem key={name} onClick={onClick}>
              {name}
            </ClientDropdownItem>
          ))}
        </ClientDropdownModal>
      )}
    </ClientDropdownWrap>
  );
};

const AllocationInput = styled.input`
  width: 90%;
  color: ${({ theme }) => theme.font.strong};
  background-color: ${({ theme }) => theme.layer[1]};
  border: none;
  border-radius: 3px;
  font-family: Blinker, serif;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 18.2px;
  padding: 5px 8px;

  &:focus {
    outline: none;
  }

  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  &::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;

const ClientDropdownItem = styled.div`
  display: flex;
  cursor: pointer;
  padding: 9px 11px;
  color: ${({ theme }) => theme.font.action};
  background: ${({ theme }) => theme.layer[1]};
  width: 285px;
  text-transform: capitalize;

  &:hover {
    color: ${({ theme }) => theme.layer.base};
    background: ${({ theme }) => theme.font.action};
  }

  font-family: Blinker, serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: 0;
  text-align: left;
`;

const ClientDropdownWrap = styled.div<{
  disabled: boolean;
  isOpen: boolean;
}>`
  cursor: pointer;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  margin-left: auto;
  pointer-events: ${({ disabled }) => (disabled ? 'none' : 'auto')};
`;

const ClientDropdownModal = styled(FadeAnimation)<{ isListCenter?: boolean }>`
  z-index: 10;
  cursor: default;
  top: 45px;
  ${({ isListCenter }) =>
    !isListCenter &&
    css`
      right: 0;
    `};
  display: flex;
  flex-direction: column;
  position: absolute;
  background: ${({ theme }) => theme.layer[1]};
  border: 1px solid ${({ theme }) => theme.border.base};
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  padding: 10px 0;
`;

export default BulkDropdownActions;
