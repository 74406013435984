import { CloseInfoIcon } from '@/assets/icons/info-icons/closeInfo';
import Label from '@/components/basicComponents/label';
import FilterSelect from '@/components/basicComponents/select';
import { AddButton } from '@/components/fat-dealPipeline-page/fat-modify/addButton';
import { useResponsive } from '@/hooks/use-responsive';
import { useQuery } from '@apollo/client';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { UseFormGetValues, UseFormSetValue } from 'react-hook-form';
import styled, { useTheme } from 'styled-components';
import { TwoColumnEntityWrapper } from '..';
import { LabelWrap } from '../../fat-investorDetails-page/fat-entityDetails-page/edit-entity/input';
import { Section } from '../../fat-investorDetails-page/fat-entityDetails-page/edit-entity/section';
import { ToggleCircle } from '../../fat-investorDetails-page/fat-entityDetails-page/edit-entity/toggleCircle';
import { LIST_SECTORS_TO_AVOID_QUERY } from '../../fat-investorDetails-page/fat-entityDetails-page/queries';
import { CreateInvestorDto } from '../types';

const onHoldOptions = ['No', 'Yes'];

interface SetAllocationRestrictionsProps {
  setFormValue: UseFormSetValue<CreateInvestorDto>;
  getValues: UseFormGetValues<CreateInvestorDto>;
}

export const SetAllocationRestrictions = ({ setFormValue, getValues }: SetAllocationRestrictionsProps) => {
  const { isMobile, isTablet } = useResponsive();
  const theme = useTheme();

  const [onHold, setOnHold] = useState<string>(onHoldOptions[1]);

  const [strategiesList, setStrategiesList] = useState<string[]>(['None']);
  const [chosenStrategies, setChosenStrategies] = useState<string[]>([]);

  useEffect(() => {
    setOnHold(getValues('onHold') ? onHoldOptions[1] : onHoldOptions[0]);
    setChosenStrategies(getValues('sectorsToAvoid'));
  }, [getValues('onHold'), getValues('sectorsToAvoid')]);

  const { data: listSectorsToAvoid } = useQuery<{
    listSectorsToAvoid: string[];
  }>(LIST_SECTORS_TO_AVOID_QUERY, {
    fetchPolicy: 'no-cache',
    onCompleted: (data) => {
      setStrategiesList(listSectorsToAvoid.listSectorsToAvoid);
    },
    onError: (error) => {
      console.error(error);
    }
  });

  useEffect(() => {
    if (listSectorsToAvoid?.listSectorsToAvoid?.length && chosenStrategies.length) {
      setStrategiesList(listSectorsToAvoid.listSectorsToAvoid.filter((strategies) => !chosenStrategies.includes(strategies)));
    }
  }, [listSectorsToAvoid, chosenStrategies]);

  const addStrategies = (chosen: string[], setChosen: Dispatch<SetStateAction<string[]>>) => {
    const newStrategies = [...chosen];
    newStrategies.push('None');
    setChosen(newStrategies as string[]);
    setFormValue('sectorsToAvoid', newStrategies);
  };

  const delStrategies = (
    el: string,
    index: number,
    chosen: string[],
    setChosen: Dispatch<SetStateAction<string[]>>,
    list: string[],
    setList: Dispatch<SetStateAction<string[]>>
  ) => {
    const newStrategies = [...chosen];
    newStrategies.splice(index, 1);
    setChosen(newStrategies as string[]);
    setFormValue('sectorsToAvoid', newStrategies);
    if (el !== 'None') {
      setList([...list, el]);
    }
  };

  const onChangeStrategies = (
    value: string,
    index: number,
    chosen: string[],
    setChosen: Dispatch<SetStateAction<string[]>>,
    list: string[],
    setList: Dispatch<SetStateAction<string[]>>
  ) => {
    const newStrategies = [...chosen];
    newStrategies[index] = value;
    setChosen(newStrategies);
    setFormValue('sectorsToAvoid', newStrategies);
    const filteredList = list.filter((item) => item !== value);
    if (chosen[index] !== 'None') {
      setList([chosen[index], ...filteredList]);
      return;
    }
    setList(filteredList);
  };

  const handleOnHoldChange = (value: string) => {
    setOnHold(value);
    setFormValue('onHold', value === onHoldOptions[1]);
  };

  if (!listSectorsToAvoid?.listSectorsToAvoid?.length) return;

  return (
    <Section title="Allocation Restrictions">
      <TwoColumnEntityWrapper isMobile={isMobile}>
        <TogglesWrap isMobile={isMobile}>
          <ToggleCircle
            data={onHold}
            label="On Hold"
            tooltip="Flag this entity for no new commitments."
            name="onHold"
            handleRadioChange={(value) => handleOnHoldChange(value)}
          />
        </TogglesWrap>
        <StrategiesWrap>
          <LabelWrap>
            <Label fontSize="16px" required={false} tooltip="Add any Sectors that should be flagged as unsuitable for this entity.">
              Excluded Sectors
            </Label>
          </LabelWrap>
          {chosenStrategies.map((el, index) => (
            <StrategiesItemWrap key={`strategies-${index}`}>
              <StrategiesFilterSelect
                data={strategiesList}
                selected={chosenStrategies[index]}
                setSelected={(value: string) =>
                  onChangeStrategies(value, index, chosenStrategies, setChosenStrategies, strategiesList, setStrategiesList)
                }
                disabled={chosenStrategies[chosenStrategies.length - 1] !== el}
                width="100%"
                withSearch
                fontSize="16px"
              />
              <DelItemWrap onClick={() => delStrategies(el, index, chosenStrategies, setChosenStrategies, strategiesList, setStrategiesList)}>
                <CloseInfoIcon width={21} height={20} fill={theme.font.action} />
              </DelItemWrap>
            </StrategiesItemWrap>
          ))}
          {strategiesList.length && chosenStrategies[chosenStrategies.length - 1] !== 'None' ? (
            <AddButton text="Add" onClick={() => addStrategies(chosenStrategies, setChosenStrategies)} />
          ) : (
            <></>
          )}
        </StrategiesWrap>
      </TwoColumnEntityWrapper>
    </Section>
  );
};

const TogglesWrap = styled.div<{ isMobile: boolean }>`
  display: flex;
  flex-direction: ${({ isMobile }) => (isMobile ? 'column' : 'row')};
  gap: ${({ isMobile }) => (isMobile ? '15px' : '0')};
`;

const StrategiesWrap = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
`;

const StrategiesFilterSelect = styled(FilterSelect)`
  background: ${({ disabled, theme }) => (disabled ? theme.action.disabled : theme.layer[2])};
  border: 1px solid ${({ theme }) => theme.border.base};
  padding: 10px 12px;
  border-radius: 5px;

  #titleSelect {
    text-transform: none;
    line-height: 22.4px;
  }
`;

const StrategiesItemWrap = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  margin-bottom: 10px;
  position: relative;
`;

const DelItemWrap = styled.div`
  position: relative;
  top: 3px;
  padding-left: 15px;
  cursor: pointer;
`;
