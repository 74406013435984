import client from '@/apollo-client';
import { ApprovedIcon } from '@/assets/icons/info-icons/approved';
import { RejectedBannerIcon } from '@/assets/icons/info-icons/rejectedBanner';
import { Banner } from '@/components/basicComponents/banner';
import RadioButton from '@/components/basicComponents/radioButton';
import Button from '@/components/fat-basicComponents/button';
import Header from '@/components/fat-header';
import { GoBackButton } from '@/components/fat-header/goBackButton';
import { PageTitle } from '@/components/fat-header/pageTitle';
import { OptionsWrapper, RadioInputWrapper, RadioWrapper } from '@/components/fat-investors-page/fat-addInvestors';
import { GET_ASSET_CLASSES } from '@/components/fat-investors-page/fat-addInvestors/queries';
import { IAssetClass } from '@/components/fat-investors-page/fat-addInvestors/types';
import { LoaderOnWholeScreen } from '@/components/loaders/loader-on-whole-screen';
import { CREATE_SETTING, DELETE_SETTING, GET_SETTINGS, UPDATE_SETTING } from '@/components/settings-page/queries';
import { useResponsive } from '@/hooks/use-responsive';
import { useStatusData } from '@/hooks/useStatusData';
import { MainWrap, PaddingWrap } from '@/styles/common';
import { useMutation, useQuery } from '@apollo/client';
import { AnimatePresence, motion } from 'framer-motion';
import { useEffect, useState } from 'react';
import styled, { useTheme } from 'styled-components';

interface AssetClassDefaultTargetsPageProps {
  closeAssetClassTargetsPage: () => void;
  backToTitle?: string;
}

const assetClassOptions = ['Use Curio default asset class targets', "Set my firm's default asset class targets"];

export const AssetClassDefaultTargetsPage = ({ closeAssetClassTargetsPage, backToTitle }: AssetClassDefaultTargetsPageProps) => {
  const { isMobile } = useResponsive();
  const theme = useTheme();
  const { data: statusData } = useStatusData();

  const [assetClasses, setAssetClasses] = useState<IAssetClass[]>([]);
  const [isEditMode, setIsEditMode] = useState(false);
  const [inputValues, setInputValues] = useState<IAssetClass<string>[]>([]);
  const [withSubAssetClasses, setWithSubAssetClasses] = useState<boolean>(false);
  const [assetClassSelectedOption, setAssetClassSelectedOption] = useState(assetClassOptions[0]);

  useEffect(() => {
    const updatedAssetClasses = updateAssetClassesForInputs(assetClasses);
    setInputValues(updatedAssetClasses);

    const hasSubAssetClasses = assetClasses.some((assetClass) => assetClass.subAssetClasses.length > 0);
    setWithSubAssetClasses(hasSubAssetClasses);
  }, [assetClasses]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [createSetting] = useMutation(CREATE_SETTING);
  const [updateSetting] = useMutation(UPDATE_SETTING);
  const [deleteSetting] = useMutation(DELETE_SETTING);

  const { data: settingsData, loading: settingsLoading } = useQuery(GET_SETTINGS, {
    fetchPolicy: 'cache-and-network',
    variables: {
      type: 'assetClassDefaultTargets'
    },
    onCompleted: async (data) => {
      if (!data.Settings.length) {
        const { data: defaultAssetClassesData } = await client.query({
          query: GET_ASSET_CLASSES
        });

        setAssetClasses(defaultAssetClassesData.assetClasses);
        setIsEditMode(false);
        setAssetClassSelectedOption(assetClassOptions[0]);
        return;
      }

      setAssetClasses(JSON.parse(data.Settings[0].value).assetClasses);
      setIsEditMode(true);
      setAssetClassSelectedOption(assetClassOptions[1]);
    },
    onError: (error) => {
      console.error(error);
    }
  });

  const updateAssetClassesForInputs = (assetClasses: IAssetClass[]): IAssetClass<string>[] => {
    const updatedAssetClasses = assetClasses.map((assetClass) => {
      const updatedSubAssetClasses = assetClass.subAssetClasses?.map((subAssetClass) => {
        return {
          ...subAssetClass,
          defaultAllocationPercentage: subAssetClass.defaultAllocationPercentage.toString()
        };
      });
      return {
        ...assetClass,
        defaultAllocationPercentage: assetClass.defaultAllocationPercentage.toString(),
        subAssetClasses: updatedSubAssetClasses
      };
    });

    return updatedAssetClasses;
  };

  const saveAssetClassTargets = (inputValues: IAssetClass<string>[]) => {
    if (assetClassSelectedOption === assetClassOptions[0] && !settingsData.Settings.length) {
      closeAssetClassTargetsPage();
      return;
    }

    if (assetClassSelectedOption === assetClassOptions[0] && settingsData.Settings.length) {
      deleteSetting({
        variables: {
          id: settingsData.Settings[0].id
        },
        onCompleted: () => {
          closeAssetClassTargetsPage();
        }
      });
      return;
    }

    const updatedAssetClasses = inputValues.map((assetClass) => {
      if (statusData?.target_sub_asset_classes && withSubAssetClasses) {
        const updatedSubAssetClasses = assetClass.subAssetClasses?.map((subAssetClass) => {
          return {
            ...subAssetClass,
            defaultAllocationPercentage: Number(subAssetClass.defaultAllocationPercentage)
          };
        });
        return {
          ...assetClass,
          defaultAllocationPercentage: Number(assetClass.defaultAllocationPercentage),
          subAssetClasses: updatedSubAssetClasses
        };
      }

      return {
        ...assetClass,
        defaultAllocationPercentage: Number(assetClass.defaultAllocationPercentage),
        subAssetClasses: []
      };
    });

    if (isEditMode && settingsData.Settings.length) {
      updateSetting({
        variables: {
          data: {
            id: settingsData.Settings[0].id,
            description: 'Asset class defaults',
            value: JSON.stringify({ assetClasses: updatedAssetClasses })
          }
        },
        onCompleted: () => {
          closeAssetClassTargetsPage();
        }
      });
      return;
    }

    createSetting({
      variables: {
        data: {
          description: 'Asset class defaults',
          type: 'assetClassDefaultTargets',
          key: 'assetClassDefaultTargets',
          value: JSON.stringify({ assetClasses: updatedAssetClasses })
        }
      },
      onCompleted: () => {
        closeAssetClassTargetsPage();
      }
    });
  };

  const handleAssetClassInputChange = (assetClassId: string, newValue: string) => {
    const validValue = newValue === '' || newValue.match(/^[0-9.]+%?$/);
    if (!validValue) return;

    const updatedAssetClasses = inputValues.map((assetClass) => {
      if (assetClass.id === assetClassId) {
        return {
          ...assetClass,
          defaultAllocationPercentage: newValue
        };
      }
      return assetClass;
    });

    setInputValues(updatedAssetClasses);
  };

  const handleSubAssetClassInputChange = (assetClassId: string, subAssetClassId: string, newValue: string) => {
    const validValue = newValue === '' || newValue.match(/^[0-9.]+%?$/);
    if (!validValue) return;

    const updatedAssetClasses = inputValues.map((assetClass) => {
      if (assetClass.id === assetClassId) {
        return {
          ...assetClass,
          defaultAllocationPercentage: assetClass.subAssetClasses
            .reduce((acc, current) => {
              if (current.id === subAssetClassId) {
                return acc + Number(newValue);
              }
              return acc + Number(current.defaultAllocationPercentage);
            }, 0)
            .toString(),
          subAssetClasses: assetClass.subAssetClasses.map((subAssetClass) => {
            if (subAssetClass.id === subAssetClassId) {
              return {
                ...subAssetClass,
                defaultAllocationPercentage: newValue
              };
            }
            return subAssetClass;
          })
        };
      }
      return assetClass;
    });

    setInputValues(updatedAssetClasses);
  };

  const calculateTotals = (inputValues: IAssetClass<string>[]) => {
    return inputValues.reduce((acc, current) => acc + Number(current.defaultAllocationPercentage), 0);
  };

  if (settingsLoading) {
    return <LoaderOnWholeScreen />;
  }

  return (
    <>
      <MainWrap>
        <Header modalControl={<GoBackButton handleClose={closeAssetClassTargetsPage} backToTitle={backToTitle} />} />
        <PageTitle title="Asset Class Default Targets" />
      </MainWrap>
      <MainWrap>
        <PaddingWrap>
          <AssetClassTargetsWrap>
            <RadioWrapper>
              Customize asset class allocation targets
              <RadioInputWrapper isMobile={isMobile}>
                <OptionsWrapper>
                  {assetClassOptions.map((option, index) => (
                    <RadioButton
                      key={option + index}
                      name="customizeAssetClassAllocationTargets"
                      radioButtonText={option}
                      selectedOption={assetClassSelectedOption}
                      handleRadioChange={(event) => setAssetClassSelectedOption(event.target.value)}
                    />
                  ))}
                </OptionsWrapper>
                <RadioWrapper>
                  <Banner
                    title=""
                    description="All new investor entities will use these settings by default."
                    icon={<RejectedBannerIcon fill={theme.header.base} />}
                    bgColor={theme.layer[1]}
                    color={theme.font.base}
                  />
                </RadioWrapper>
              </RadioInputWrapper>
            </RadioWrapper>
            <AnimatePresence>
              {assetClassSelectedOption === assetClassOptions[1] && (
                <motion.div
                  initial={{
                    opacity: 0
                  }}
                  animate={{
                    opacity: 1
                  }}
                  exit={{
                    opacity: 0
                  }}
                >
                  <AssetClassTargets>
                    {statusData?.target_sub_asset_classes && withSubAssetClasses ? (
                      <>
                        {inputValues.map((assetClass) => (
                          <AssetClassTableWrap key={assetClass.id}>
                            <AssetClassHeaderWrap>
                              <AssetClassTitle>{assetClass.name}</AssetClassTitle>
                              <AssetClassTotal>{assetClass.defaultAllocationPercentage}%</AssetClassTotal>
                            </AssetClassHeaderWrap>
                            <AssetClassTableBody>
                              {assetClass.subAssetClasses?.map((subAssetClass) => (
                                <AssetClassTableRow key={subAssetClass.id} isMobile={isMobile}>
                                  <AssetClassTitle>{subAssetClass.name}</AssetClassTitle>
                                  <Input
                                    value={subAssetClass.defaultAllocationPercentage}
                                    onChange={(e) => handleSubAssetClassInputChange(assetClass.id, subAssetClass.id, e.target.value)}
                                  />
                                </AssetClassTableRow>
                              ))}
                            </AssetClassTableBody>
                          </AssetClassTableWrap>
                        ))}
                      </>
                    ) : (
                      <AssetClassTableWrap>
                        <AssetClassHeaderWrap>
                          <AssetClassTitle>ALL ASSET CLASSES</AssetClassTitle>
                          <AssetClassTotal>{calculateTotals(inputValues)}%</AssetClassTotal>
                        </AssetClassHeaderWrap>
                        <AssetClassTableBody>
                          {inputValues.map((assetClass) => (
                            <AssetClassTableRow key={assetClass.id} isMobile={isMobile}>
                              <AssetClassTitle>{assetClass.name}</AssetClassTitle>
                              <Input
                                value={assetClass.defaultAllocationPercentage}
                                onChange={(e) => handleAssetClassInputChange(assetClass.id, e.target.value)}
                              />
                            </AssetClassTableRow>
                          ))}
                        </AssetClassTableBody>
                      </AssetClassTableWrap>
                    )}
                    <AssetClassBannerWrap isMobile={isMobile}>
                      <AssetClassBanner>
                        <Banner
                          title={calculateTotals(inputValues).toFixed(2) + '%'}
                          description="Asset Class targets must total 100%"
                          icon={
                            calculateTotals(inputValues) === 100 ? (
                              <ApprovedIcon fill={theme.context.light} />
                            ) : (
                              <RejectedBannerIcon fill={theme.context.light} />
                            )
                          }
                          bgColor={calculateTotals(inputValues) === 100 ? theme.context.success : theme.context.error}
                        />
                      </AssetClassBanner>
                    </AssetClassBannerWrap>
                  </AssetClassTargets>
                </motion.div>
              )}
            </AnimatePresence>
            <ButtonsWraap>
              <Button styleType="outline" size="lg" onClick={closeAssetClassTargetsPage}>
                Cancel
              </Button>
              <Button
                styleType={
                  (settingsData.Settings.length && assetClassSelectedOption === assetClassOptions[0]) ||
                  (assetClassSelectedOption === assetClassOptions[1] && calculateTotals(inputValues) === 100)
                    ? 'default'
                    : 'disabled'
                }
                size="lg"
                onClick={() => saveAssetClassTargets(inputValues)}
              >
                Save
              </Button>
            </ButtonsWraap>
          </AssetClassTargetsWrap>
        </PaddingWrap>
      </MainWrap>
    </>
  );
};

const AssetClassTargetsWrap = styled.div`
  margin: 50px 0 50px 0;
  padding: 55px 40px 40px;
  border-radius: 10px;
  border: 1px solid ${({ theme }) => theme.border.base};
  background-color: ${({ theme }) => theme.layer[1]};
  color: ${({ theme }) => theme.font.base};
`;

const AssetClassTargets = styled.div`
  margin-top: 50px;
  display: flex;
  flex-direction: column;
  gap: 60px;
`;

const AssetClassTableWrap = styled.div``;

const AssetClassTableBody = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 10px 30px;
`;

const AssetClassTableRow = styled.div<{ isMobile: boolean }>`
  display: flex;
  align-items: center;
  flex-direction: ${({ isMobile }) => (isMobile ? 'column' : 'row')};
`;

const AssetClassHeaderWrap = styled.div`
  padding: 10px 30px;
  background-color: ${({ theme }) => theme.layer[2]};
  display: flex;
  align-items: center;
`;

const AssetClassTitle = styled.div`
  max-width: 228px;
  width: 100%;
`;

const AssetClassTotal = styled.div`
  font-weight: 700;
`;

const Input = styled.input<{ disabled?: boolean }>`
  color: ${({ theme }) => theme.font.strong};
  max-width: 225px;
  width: 100%;
  background: ${({ disabled, theme }) => (disabled ? theme.action.disabled : theme.layer[1])};
  border: 1px solid ${({ theme }) => theme.border.base};
  opacity: ${({ disabled }) => (disabled ? 0.4 : 1)};
  font-family: Blinker;
  font-size: 16px;
  font-weight: 400;
  line-height: 22.4px;
  border-radius: 4px;
  padding: 10px;

  &:focus {
    outline: none;
  }
`;

const AssetClassBannerWrap = styled.div<{ isMobile: boolean }>`
  padding: ${({ isMobile }) => (isMobile ? '50px 0 0 0' : '50px 0 0 185px')};
  border-top: 1px solid ${({ theme }) => theme.border.base};
`;

const AssetClassBanner = styled.div`
  max-width: 341px;
  width: 100%;
`;

const ButtonsWraap = styled.div`
  display: flex;
  justify-content: center;
  gap: 10px;
  margin: 50px 0 10px 0;
`;
