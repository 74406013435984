import { getUTCDate } from '@/components/allocations-page/utils';
import { TableColumns } from '@/components/table/types';
import { useEffect, useState } from 'react';
import CommitmentPlanTable from '../../edit-commitment-plan-page/fat-table/table';
import { CreateAllocationsByDateDto, ITransformedAssetClass } from '../../edit-commitment-plan-page/types';
import { transformData } from '../../edit-commitment-plan-page/utils';
import { Section } from '../../edit-entity/section';

interface CommitmentPlanSectionProps {
  planAllocations: CreateAllocationsByDateDto[];
  targetSubAssetClasses: boolean;
}

export const CommitmentPlanSection = ({ planAllocations, targetSubAssetClasses }: CommitmentPlanSectionProps) => {
  const [tableHeaderColumns, setTableHeaderColumns] = useState<TableColumns[]>([]);
  const [tableData, setTableData] = useState<ITransformedAssetClass[]>([]);
  const columnWidth = planAllocations ? 85 / planAllocations.length : 0;

  useEffect(() => {
    if (!planAllocations) return;

    const columnNames = planAllocations.map((data: CreateAllocationsByDateDto) => {
      return {
        title: getUTCDate(new Date(data.date)).toUpperCase(),
        key: null as null,
        width: columnWidth,
        keySort: new Date(data.date).getFullYear().toString()
      };
    });

    const transformedData = transformData(planAllocations);
    setTableData(transformedData);

    setTableHeaderColumns([{ title: 'Asset Class', key: null, width: 15, keySort: 'deal' }, ...columnNames]);
  }, [planAllocations]);

  return (
    <Section id="commitmentPlan" title="Commitment Plan">
      <CommitmentPlanTable
        tableData={tableData}
        tableHeaderColumns={tableHeaderColumns}
        loading={false}
        columnWidth={columnWidth}
        mode="view"
        targetSubAssetClasses={targetSubAssetClasses}
      />
    </Section>
  );
};
