import StyledTooltip from '@/components/muis/styledTooltip';
import React from 'react';
import { styled } from 'styled-components';

interface WrapperHeadProps {
  title: string;
  className?: string;
  isWarning?: boolean;
  tooltip?: string;
}

export const WrapperHead: React.FC<WrapperHeadProps> = ({
  title,
  className,
  isWarning = false,
  tooltip = ''
}) => {
  return (
    <StyledWrapperHead className={className}>
      {isWarning && (
        <img
          style={{ marginRight: 3 }}
          src="/static/icons/warning-star.svg"
          alt="Star"
        />
      )}
      <Name>{title}</Name>
      {tooltip && (
        <StyledTooltip title={tooltip}>
          <Info src={'/static/icons/info-circle.svg'} alt="info" />
        </StyledTooltip>
      )}
    </StyledWrapperHead>
  );
};

const StyledWrapperHead = styled.div`
  display: flex;
  padding-bottom: 5px;
`;

const Name = styled('span')`
  font-weight: 400;
  font-size: 16;
  color: ${(props) => props.theme.font.base};
  font-family: Inter;
`;

const Info = styled('img')`
  font-weight: 600;
  font-size: 16;
  cursor: pointer;
  margin-left: 6px;
`;
