import { Box, FormControl, Select as MuiSelect } from '@mui/material';
import Typography from '@/components/muis/typography';
import MenuItem from '@mui/material/MenuItem';
import { styled } from 'styled-components';
import React, { useEffect } from 'react';
import { useController } from 'react-hook-form';

import { WrapperHead } from '..';

interface SelectProps {
  title: string;
  nameField: string;
  control: any;
  rules: any;
  selects: string[];
  defaultValue?: string;
  setError?: any;
  className?: string;
  isWarning?: boolean;
  tooltip?: string;
  disabled?: boolean;
  onBlur?: () => void;
  errorMessage?: string;
}

export const Select: React.FC<SelectProps> = ({
  title,
  nameField,
  control,
  selects,
  defaultValue = '',
  rules,
  className,
  isWarning,
  tooltip,
  setError,
  disabled = false,
  onBlur = () => {},
  errorMessage
}) => {
  const {
    field: { value, ...filedProperties },
    fieldState: { invalid },
    formState: { errors, isSubmitted }
  } = useController({
    name: nameField,
    control,
    rules,
    defaultValue: defaultValue
  });

  useEffect(() => {
    if (!isSubmitted) return;
    if (setError && value === 'Select') {
      setError(nameField, {
        type: 'chosenItem',
        message: errorMessage ? errorMessage : 'Select items'
      });
    }
  }, [isSubmitted]);

  const handleOnChange = (event: any) => {
    filedProperties.onChange(event);
    onBlur();
  };

  return (
    <Box style={{ width: '100%' }} className={className}>
      <WrapperHead title={title} isWarning={isWarning} tooltip={tooltip} />
      <FormControl
        error
        sx={{
          width: '100%'
        }}
      >
        <CustomSelect
          value={value}
          {...filedProperties}
          valid={invalid.toString()}
          defaultValue={defaultValue}
          SelectDisplayProps={{ style: { padding: '4px 0 5px' } }}
          MenuProps={{ style: { maxHeight: 300 } }}
          disabled={disabled}
          onBlur={onBlur}
          onChange={handleOnChange}
        >
          {selects.map((select, index) => (
            <MenuItem key={select + index} value={select}>
              <Typography sx={{ color: index === 0 ? '#32323280' : 'black' }}>
                {select}
              </Typography>
            </MenuItem>
          ))}
        </CustomSelect>
        {invalid && (
          <Typography sx={{ color: '#C60F0F' }}>
            {String(errors?.[nameField]?.message)}
          </Typography>
        )}
      </FormControl>
    </Box>
  );
};

const CustomSelect = styled(MuiSelect)<{ valid: string }>`
  width: 100%;
  border-radius: 8px;
  padding: 5px 20px;
  color: #32323280;
  box-shadow: none;

  .MuiOutlinedInput-notchedOutline {
    border: none;
  }

  .MuiSelect-icon {
    color: ${(props) => (props.disabled ? '#ABABAB' : props.theme.font.action)};
    transform: scale(1.3);
  }

  background: ${(props) =>
    props.disabled
      ? '#DEDEDE'
      : props.valid === 'true'
        ? '#FFDFE5'
        : props.theme.primary.dark};
  border: ${(props) =>
    props.disabled
      ? '1px solid #C7CBD2'
      : props.valid === 'true'
        ? '3px solid #ED9DAB'
        : `2px solid ${props.theme.secondary.light}`};
`;
