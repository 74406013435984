import { AddButtonIcon } from '@/assets/static/icons/add-button-icon';
import Button from '@/components/fat-basicComponents/button';
import Header from '@/components/fat-header';
import { GoBackButton } from '@/components/fat-header/goBackButton';
import { PageTitle } from '@/components/fat-header/pageTitle';
import FilterPage from '@/components/table/fat-filterPage';
import { ISort } from '@/components/table/types';
import { useResponsive } from '@/hooks/use-responsive';
import { MainWrap } from '@/styles/common';
import { useMutation, useQuery } from '@apollo/client';
import { useEffect, useState } from 'react';
import styled from 'styled-components';
import AddTenantModal from './modals/addTenant';
import DeleteTenantModal from './modals/deleteTenant';
import EditTenantModal from './modals/editTenant';
import { CREATE_TENANT, DELETE_TENANT, EDIT_TENANT, LIST_TENANTS } from './queries';
import TenantsTable from './tenantsTable/table';
import { CreateTenantDto, DeleteTenantDto, ITenant, UpdateTenantDto } from './types';

interface TenantsProps {
  handleClose: () => void;
  backToTitle?: string;
}

export const Tenants = ({ handleClose, backToTitle }: TenantsProps) => {
  const { isMobile, isTablet } = useResponsive();

  const [search, setSearch] = useState('');
  const [sort, setSort] = useState<ISort>({
    key: 'NAME',
    asc: true
  });
  const [tenants, setTenants] = useState<ITenant[]>([]);
  const [modalWindow, setModalWindow] = useState({ isOpen: false, type: 'add', tenant: null as ITenant | null });
  const [addTenantError, setAddTenantError] = useState<{ title: string; message: string } | undefined>();
  const [editTenantError, setEditTenantError] = useState<string | undefined>();
  const [deleteTenantError, setDeleteTenantError] = useState<string | undefined>();

  const { refetch: refetchTenants, loading: loadingTenants } = useQuery<{ allAdvisoryFirmList: ITenant[] }>(LIST_TENANTS, {
    variables: {
      data: {
        search: search || undefined,
        sortBy: sort.key || undefined,
        sortDirection: sort.asc ? 'ASC' : 'DESC'
      }
    },
    notifyOnNetworkStatusChange: true,
    onCompleted: ({ allAdvisoryFirmList }) => {
      setTenants(allAdvisoryFirmList);
    }
  });

  const [createTenant, { loading: createTenantLoading }] = useMutation(CREATE_TENANT, {
    onCompleted: async () => {
      await refetchTenants();
      closeModalWindow();
    },
    onError: (error) => {
      setAddTenantError({ title: 'There was an error creating the Tenant.', message: error?.message });
    }
  });

  const [editTenant, { loading: editTenantLoading }] = useMutation(EDIT_TENANT, {
    onCompleted: async () => {
      await refetchTenants();
      closeModalWindow();
    },
    onError: (error) => {
      setEditTenantError(error?.message);
    }
  });

  const [deleteTenant, { loading: deleteTenantLoading }] = useMutation(DELETE_TENANT, {
    onCompleted: async () => {
      await refetchTenants();
      closeModalWindow();
    },
    onError: (error) => {
      setDeleteTenantError(error?.message);
    }
  });

  const openModalWindow = (type: string, tenant?: ITenant) => {
    setAddTenantError(undefined);
    setEditTenantError(undefined);
    setDeleteTenantError(undefined);
    setModalWindow({ type, isOpen: true, tenant });
  };

  const closeModalWindow = () => {
    setAddTenantError(undefined);
    setEditTenantError(undefined);
    setDeleteTenantError(undefined);
    setModalWindow({ ...modalWindow, isOpen: false, tenant: null as ITenant | null });
  };

  const confirmNewTenant = (data: CreateTenantDto) => {
    const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
    const isValidEmail = emailRegex.test(data.adminEmail);
    if (!isValidEmail) {
      setAddTenantError({
        title: 'Provide a valid email address for the Primary Admin',
        message: 'Please ensure that the email address provided is formatted correctly.'
      });
      return;
    }

    setAddTenantError(undefined);
    createTenant({ variables: { data } });
  };

  const confirmEditTenant = (data: UpdateTenantDto) => {
    setEditTenantError(undefined);
    editTenant({ variables: { data } });
  };

  const confirmDeleteTenant = (data: DeleteTenantDto) => {
    setDeleteTenantError(undefined);
    deleteTenant({ variables: { data } });
  };

  useEffect(() => {
    if (createTenantLoading || editTenantLoading || deleteTenantLoading) return;
    refetchTenants();
  }, [createTenantLoading, editTenantLoading, deleteTenantLoading]);

  return (
    <>
      {modalWindow.isOpen && modalWindow.type === 'add' && (
        <AddTenantModal
          isOpen={modalWindow.isOpen}
          onConfirm={confirmNewTenant}
          onCancel={closeModalWindow}
          isLoading={createTenantLoading}
          error={addTenantError}
        />
      )}
      {modalWindow.isOpen && modalWindow.type === 'edit' && (
        <EditTenantModal
          isOpen={modalWindow.isOpen}
          onConfirm={confirmEditTenant}
          onCancel={closeModalWindow}
          tenant={modalWindow.tenant}
          isLoading={editTenantLoading}
          error={editTenantError}
        />
      )}
      {modalWindow.isOpen && modalWindow.type === 'delete' && (
        <DeleteTenantModal
          isOpen={modalWindow.isOpen}
          onConfirm={confirmDeleteTenant}
          onCancel={closeModalWindow}
          tenant={modalWindow.tenant}
          isLoading={deleteTenantLoading}
          error={deleteTenantError}
        />
      )}
      <MainWrap>
        <Header modalControl={<GoBackButton handleClose={handleClose} backToTitle={backToTitle} />} />
        <PageTitle title="Tenants" />
      </MainWrap>
      <MainWrap>
        <PaddingWrap>
          <AddButtonWrapper>
            <Button size="md" onClick={() => openModalWindow('add')} icon={<AddButtonIcon />}>
              Add New Tenant
            </Button>
          </AddButtonWrapper>
          <CustomFilterPage
            isMobile={isMobile}
            isTablet={isTablet}
            search={{
              value: search,
              onChange: setSearch,
              placeholder: 'Search'
            }}
            resultsValue={tenants.length}
            refetch={() => refetchTenants()}
          />
          <TableWrapper>
            <TenantsTable
              tenantsTableTableData={tenants}
              sort={sort}
              setSort={setSort}
              refetch={refetchTenants}
              loading={loadingTenants}
              openModalWindow={openModalWindow}
            />
          </TableWrapper>
          <FooterModalWrap isMobile={isMobile} isTablet={isTablet} />
        </PaddingWrap>
      </MainWrap>
    </>
  );
};

const PaddingWrap = styled.div`
  padding-left: 16px;
  padding-right: 16px;
  @media (min-width: 600px) {
    padding-left: 50px;
    padding-right: 50px;
  }
`;

const AddButtonWrapper = styled.div`
  margin-top: 20px;
`;

const CustomFilterPage = styled(FilterPage)<{ isMobile: boolean; isTablet: boolean }>`
  position: sticky;
  top: ${({ isMobile, isTablet }) => (isMobile || isTablet ? '50px' : '0')};
  z-index: 9;
  margin-top: 20px;
  margin-left: -1px;
  width: calc(100% + 2px);
  padding: ${({ isMobile }) => (isMobile ? '20px 0 0 0' : '20px 0 10px 0')};
`;

const TableWrapper = styled.div`
  margin-bottom: 110px;
`;

const FooterModalWrap = styled.div<{ isMobile: boolean; isTablet: boolean }>`
  width: ${({ isMobile, isTablet }) => (isMobile || isTablet ? '100%' : 'calc(100% - 80px)')};
  display: flex;
  align-items: center;
  justify-content: center;
  height: 90px;
  padding: 10px 80px;
  position: fixed;
  left: ${({ isMobile, isTablet }) => (isMobile || isTablet ? '0' : '80px')};
  bottom: 0;
  clear: both;
  background-color: ${({ theme }) => theme.layer[1]};
  border-top: 1px solid ${({ theme }) => theme.border.base};
  z-index: 9;
`;
