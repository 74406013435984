import { RejectedBannerIcon } from '@/assets/icons/info-icons/rejectedBanner';
import styled, { useTheme } from 'styled-components';

export const InformationBanner = () => {
  const theme = useTheme();

  return (
    <InformationBannerWrapper>
      <IconWrapper>
        <RejectedBannerIcon fill={theme.context.light} />
      </IconWrapper>
      <StatusWrapper>
        <Status>Complete entity details to see allocation targets and capacity</Status>
        <Description>Enter allocation targets and other details on the Edit Entity Page</Description>
      </StatusWrapper>
    </InformationBannerWrapper>
  );
};

const InformationBannerWrapper = styled.div`
  background: ${({ theme }) => theme.context.warning};
  display: flex;
  align-items: center;
  gap: 20px;
  padding: 10px 20px;
`;

const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-right: 1px solid ${({ theme }) => theme.context.light};
  padding-right: 20px;
`;

const StatusWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const Status = styled.span`
  color: ${({ theme }) => theme.context.light};
  font-weight: 700;
  font-size: 16px;
`;

const Description = styled.span`
  color: ${({ theme }) => theme.context.light};
  font-weight: 400;
  font-size: 13px;
`;
