import { getUTCDate } from '@/components/allocations-page/utils';
import { Investment } from '@/components/fat-dealCatalog-page/types';
import { Dispatch, SetStateAction } from 'react';

import { IOwner } from '@/components/allocations-page/types';
import { defaultSelectValue, managerSelects, subAssetClasses } from '@/components/opportunities-entity/modify/constants';
import { IForm, IListData, PACING_TYPE } from './types';

export const managerSelectsChecked = managerSelects.map((select) => ({
  text: select,
  checked: false
}));

export const subAssetClassesChecked = subAssetClasses.map((select) => ({
  text: select,
  checked: false
}));

export const getOwnerValues = (ownerData: Array<IOwner>) => {
  return ownerData?.map((owner) => `${owner.firstName} ${owner.lastName}`) || [];
};

export const getOwnerName = (ownerData: IOwner | undefined) => {
  return ownerData ? `${ownerData?.firstName} ${ownerData?.lastName}` : defaultSelectValue;
};

export const getOwnerEmailFromName = (ownerName: string, owners: Array<IOwner>) => {
  const owner = owners.filter((owner: IOwner) => ownerName === getOwnerName(owner));
  return owner[0]?.email || null;
};

export const getDefaultFormValues = (investment?: Investment): IForm => {
  return {
    name: investment?.name || '',
    manager: investment?.managerId || defaultSelectValue,
    assetClass: investment?.assetClass?.name || defaultSelectValue,
    subAssetClass: investment?.subAssetClass?.name || defaultSelectValue,
    pacingType: PACING_TYPE[investment?.pacingType] || null,
    pacingStartingYear: investment?.pacingStartingYear ? investment.pacingStartingYear.toString() : new Date().getFullYear().toString(),
    pacingStartingQuarter: investment?.pacingStartingQuarter || 'Q1',
    minimum: investment?.minimum?.toString() || '',
    // custodied: investment?.custodied || 'No',
    ubtiBlocker: investment?.ubtiBlocker ? 'Yes' : 'No',
    sri: investment?.sri === undefined ? defaultSelectValue : investment.sri ? 'Yes' : 'No',
    nextClose: investment?.nextClose ? getUTCDate(new Date(investment.nextClose)) : null,
    finalClose: investment?.finalClose ? getUTCDate(new Date(investment.finalClose)) : null,
    investorDocsDue: investment?.investorDocsDue ? getUTCDate(new Date(investment.investorDocsDue)) : null,
    targetIRR: investment?.targetReturn || '',
    targetYield: investment?.targetYield || '',
    vintage: investment?.vintageYear || '',
    type: investment?.type || defaultSelectValue,
    investmentOwner: getOwnerName(investment?.investmentOwner),
    operationsOwner: getOwnerName(investment?.operationsOwner),
    complianceOwner: getOwnerName(investment?.complianceOwner),
    description: investment?.offeringMaterialsDescription || ''
  };
};

export const isSelectorsValid = (data: IForm) => {
  return (
    data?.type !== defaultSelectValue &&
    data?.assetClass !== defaultSelectValue &&
    data?.investmentOwner !== defaultSelectValue &&
    data?.operationsOwner !== defaultSelectValue &&
    data?.complianceOwner !== defaultSelectValue
  );
};

export const isReadyToSave = (formData: IForm) => {
  return (
    formData?.name !== '' &&
    formData?.manager !== '' &&
    formData?.type !== defaultSelectValue &&
    // formData.symbol !== '' &&
    formData?.minimum !== '' &&
    formData?.manager !== defaultSelectValue &&
    isSelectorsValid(formData)
  );
};

interface IInvestmentVehicle {
  id: string;
  name?: string | null;
}

interface IBuildData {
  id?: string;
  data: IForm;
  investmentStrategies: string[];
  investmentTags: string[];
  allPossibleOwners: IOwner[] | [];
  securityStatus?: string;
  openIvestment?: boolean;
  vehicles?: IInvestmentVehicle[];
}

export const buildData = ({
  id,
  data,
  investmentStrategies,
  investmentTags,
  allPossibleOwners,
  securityStatus,
  openIvestment,
  vehicles
}: IBuildData) => ({
  ...(id && { id }),
  name: data.name,
  managerId: data.manager === defaultSelectValue ? '' : data.manager,
  // custodied: dataFields.custodied.find(({ checked }) => checked)?.label === 'Yes',
  ubtiBlocker: data.ubtiBlocker === 'Yes',
  sri: data.sri === 'Yes' ? true : false,
  type: data.type === defaultSelectValue ? '' : data.type,
  subAssetClasses: investmentStrategies ?? [],
  offeringMaterialsDescription: data.description,
  investmentOwnerEmail: getOwnerEmailFromName(data.investmentOwner, allPossibleOwners),
  operationsOwnerEmail: getOwnerEmailFromName(data.operationsOwner, allPossibleOwners),
  complianceOwnerEmail: getOwnerEmailFromName(data.complianceOwner, allPossibleOwners),
  minimum: Number(data.minimum),
  // ...(securityStatus !== 'OPEN' && { symbol: data.symbol }),
  securityStatus: securityStatus,
  nextClose: data.nextClose ? [data.nextClose] : null,
  finalClose: data.finalClose ? data.finalClose : null,
  investorDocsDue: data.investorDocsDue ? data.investorDocsDue : null,
  ...(data.targetIRR && {
    targetIRR: typeof data.targetIRR === 'string' ? +data.targetIRR.replace(',', '.') : +data.targetIRR
  }),
  ...(data.targetYield && {
    targetYield: typeof data.targetYield === 'string' ? +data.targetYield.replace(',', '.') : +data.targetYield
  }),
  ...(data.vintage && { vintageYear: Number(data.vintage) }),
  ...(investmentTags && investmentTags.length > 0 && { tags: investmentTags ?? [] }),
  ...(vehicles && vehicles.length > 0 && { investmentVehicle: vehicles.map(({ id, name }) => ({ id, name })) }),
  ...(data.pacingType && { pacingType: Object.keys(PACING_TYPE).find((key) => PACING_TYPE[key as keyof typeof PACING_TYPE] === data.pacingType) }),
  ...(data.pacingStartingQuarter && { pacingStartingQuarter: data.pacingStartingQuarter }),
  ...(data.pacingStartingYear && { pacingStartingYear: parseInt(data.pacingStartingYear) })
});

interface IGetAllPossibleOwners {
  investmentOwners: IOwner[];
  operationsOwners: IOwner[];
  complianceOwners: IOwner[];
}

export const getAllPossibleOwners = ({ investmentOwners, operationsOwners, complianceOwners }: IGetAllPossibleOwners): IOwner[] => {
  return [...investmentOwners, ...operationsOwners, ...complianceOwners].filter(Boolean);
};

export const addStrategies = (data: IListData, setData: Dispatch<SetStateAction<IListData>>) => {
  setData({ ...data, chosen: [...data.chosen, 'None'] });
};

export const onChangeStrategies = (value: string, index: number, data: IListData, setData: Dispatch<SetStateAction<IListData>>) => {
  const newChosenValue = [...data.chosen];
  newChosenValue[index] = value;
  const filteredList = data.list.filter((item) => item !== value);

  if (data.chosen[index] !== 'None') {
    setData({
      ...data,
      chosen: newChosenValue,
      list: [data.chosen[index], ...filteredList]
    });
    return;
  }
  setData({ ...data, chosen: newChosenValue, list: filteredList });
};

export const delStrategies = (el: string, index: number, data: IListData, setData: Dispatch<SetStateAction<IListData>>) => {
  const newChosenValue = [...data.chosen];
  newChosenValue.splice(index, 1);
  setData({ ...data, chosen: newChosenValue, list: [...data.list, el] });
};
