import { useMemo } from 'react';

import FormattingTooltip from '@/components/basicComponents/tooltip/dollarsTooltip';
import { Loader } from '@/components/loaders/loader';
import { MobileRow, MobileRowTitle, MobileRowValue, MobileTableRowWrap } from '@/components/table/mobileTable/mobileTable';
import guid from '@/utils/guid';
import styled from 'styled-components';
import { summaryTablecolumns } from '../../constatnts';
import { AssetClassTargetAllocationDto } from '../../types';

interface MobileTableProps {
  investorSummaryRows: AssetClassTargetAllocationDto[];
  loading: boolean;
  ignoreAllocationTargets: boolean;
}

interface IRowSum {
  total: string;
  target: number;
  targetAllocations: number;
  exposure: number;
  capacity: number;
  annualPacingTarget: number;
}

const MobileSumFooter = ({ investorSummaryRows, loading, ignoreAllocationTargets }: MobileTableProps) => {
  const rowSum: IRowSum = useMemo(() => {
    const sum = {
      total: 'Total',
      target: 0,
      targetAllocations: 0,
      exposure: 0,
      capacity: 0,
      annualPacingTarget: 0
    };
    for (let i = 0; i < investorSummaryRows.length; i++) {
      sum.target += investorSummaryRows[i].percentage;
      sum.targetAllocations += investorSummaryRows[i].targetAllocation;
      sum.exposure += investorSummaryRows[i].economicExposure;
      sum.capacity += investorSummaryRows[i].capacity;
      sum.annualPacingTarget += investorSummaryRows[i].pacingTarget?.annualPacingTarget || 0;
    }
    return sum;
  }, [investorSummaryRows]);

  if (ignoreAllocationTargets) {
    return (
      <>
        {!loading ? (
          investorSummaryRows && (
            <MobileSumFooterWrap key={guid()}>
              <MobileRow>
                <MobileRowTitle>{summaryTablecolumns[0].title}</MobileRowTitle>
                <MobileRowValue>{rowSum.total}</MobileRowValue>
              </MobileRow>

              <MobileRow>
                <MobileRowTitle>{summaryTablecolumns[3].title}</MobileRowTitle>
                <MobileRowValue>
                  <FormattingTooltip>{rowSum.exposure}</FormattingTooltip>
                </MobileRowValue>
              </MobileRow>
            </MobileSumFooterWrap>
          )
        ) : (
          <Loader />
        )}
      </>
    );
  }

  return (
    <>
      {!loading ? (
        investorSummaryRows && (
          <MobileSumFooterWrap key={guid()}>
            <MobileRow>
              <MobileRowTitle>{summaryTablecolumns[0].title}</MobileRowTitle>
              <MobileRowValue>{rowSum.total}</MobileRowValue>
            </MobileRow>

            <MobileRow>
              <MobileRowTitle>{summaryTablecolumns[1].title}</MobileRowTitle>
              <MobileRowValue>{rowSum.target.toFixed(0)}%</MobileRowValue>
            </MobileRow>

            <MobileRow>
              <MobileRowTitle>{summaryTablecolumns[2].title}</MobileRowTitle>
              <MobileRowValue>
                <FormattingTooltip>{rowSum.targetAllocations}</FormattingTooltip>
              </MobileRowValue>
            </MobileRow>

            <MobileRow>
              <MobileRowTitle>{summaryTablecolumns[3].title}</MobileRowTitle>
              <MobileRowValue>
                <FormattingTooltip>{rowSum.exposure}</FormattingTooltip>
              </MobileRowValue>
            </MobileRow>

            <MobileRow>
              <MobileRowTitle>{summaryTablecolumns[4].title}</MobileRowTitle>
              <MobileRowValue>
                <FormattingTooltip>{rowSum.capacity}</FormattingTooltip>
              </MobileRowValue>
            </MobileRow>

            <MobileRow>
              <MobileRowTitle>{summaryTablecolumns[5].title}</MobileRowTitle>
              <MobileRowValue>
                <FormattingTooltip>{rowSum.annualPacingTarget}</FormattingTooltip>
              </MobileRowValue>
            </MobileRow>
          </MobileSumFooterWrap>
        )
      ) : (
        <Loader />
      )}
    </>
  );
};

const MobileSumFooterWrap = styled(MobileTableRowWrap)`
  background-color: ${({ theme }) => theme.layer[2]};
`;

export default MobileSumFooter;
