import { ArrowLeft } from '@/assets/icons/arrow-left';
import { ArrowRight } from '@/assets/icons/arrow-right';
import { AiAssistantIcon } from '@/assets/static/icons/ai-assistant';
import Tabs, { ITab } from '@/components/basicComponents/fat-tabs';
import Button from '@/components/fat-basicComponents/button';
import Header from '@/components/fat-header';
import { GoBackButton } from '@/components/fat-header/goBackButton';
import { PageTitle } from '@/components/fat-header/pageTitle';
import { PromptEditor } from '@/components/settings-page/settings-pages/dealFunnelEditor/promptEditor';
import { useResponsive } from '@/hooks/use-responsive';
import { MainWrap, PaddingWrap } from '@/styles/common';
import { any } from 'prop-types';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import styled, { useTheme } from 'styled-components';
import { DatePage } from './edit-pages/dateContent';
import { DollarPage } from './edit-pages/dollarContent';
import { LabelerTool } from './edit-pages/labelerTool';
import { LongFormContentPage } from './edit-pages/longFormContent';
import { NumberPage } from './edit-pages/numberContent';
import { PercentagePage } from './edit-pages/percentageContent';
import { ShortTextPage } from './edit-pages/shortText';
import { ISectionFields } from './types';

const tabs: ITab[] = [
  { value: 'Field Editor', amount: null },
  { value: 'AI Labeler', amount: null }
];

interface EditFieldPageProps {
  handleClose: () => void;
  allFields: ISectionFields[] | null;
  currentField: ISectionFields | null;
  setIsAiPaneOpen: Dispatch<SetStateAction<boolean>>;
  setCurrentField: Dispatch<SetStateAction<ISectionFields | null>>;
  investmentId: string;
  editFieldPage: { isOpen: boolean; typeWindow: string };
  updateFieldAnswer: (variables: any) => void;
  deleteAttachment: (variables: any) => void;
  recordId: string | null;
  refetch: () => void;
}

export const EditFieldPage = ({
  handleClose,
  allFields,
  currentField,
  setIsAiPaneOpen,
  setCurrentField,
  investmentId,
  editFieldPage,
  updateFieldAnswer,
  deleteAttachment,
  recordId,
  refetch
}: EditFieldPageProps) => {
  const { isMobile, isTablet } = useResponsive();
  const theme = useTheme();

  const [activeTab, setActiveTab] = useState('Field Editor');
  const [isPromptEditorOpen, setIsPromptEditorOpen] = useState(false);

  useEffect(() => {
    if (activeTab === 'AI Labeler') {
      window.scrollTo(0, 0);
    }
  }, [currentField]);

  const prevField = () => {
    if (!allFields || !currentField) return;
    const currentIndex = allFields.findIndex((field) => field.id === currentField.id);

    if (currentIndex > 0) {
      if (!allFields[currentIndex - 1].labelerData) {
        setActiveTab('Field Editor');
      }
      setCurrentField(allFields[currentIndex - 1]);
    }
  };

  const nextField = () => {
    if (!allFields || !currentField) return;
    const currentIndex = allFields.findIndex((field) => field.id === currentField.id);

    if (currentIndex !== -1 && currentIndex < allFields.length - 1) {
      if (!allFields[currentIndex + 1].labelerData) {
        setActiveTab('Field Editor');
      }
      setCurrentField(allFields[currentIndex + 1]);
    }
  };

  const savePromptGroup = (promptGroup: { [key: string]: string } | null) => {
    const updatedCurrentField = {
      ...currentField,
      promptGroup: promptGroup
        ? {
            ...currentField.promptGroup,
            id: promptGroup.id,
            prompts: [promptGroup.Question],
            summaryPrompt: promptGroup['Summary Template'],
            contextPrompt: promptGroup['Context Template'],
            rubricTemplates: any,
            useCustomContextPrompt: promptGroup.useCustomContextPrompt,
            useCustomSummaryPrompt: promptGroup.useCustomSummaryPrompt
          }
        : null
    };

    setCurrentField(updatedCurrentField as unknown as ISectionFields);
  };

  if (!currentField) return <></>;

  return (
    <>
      {isPromptEditorOpen ? (
        <PromptEditor
          handleClose={() => setIsPromptEditorOpen(false)}
          handleCloseAll={handleClose}
          currentRow={currentField}
          refetch={refetch}
          savePromptGroup={savePromptGroup}
        />
      ) : (
        <MainWrap>
          <Header
            modalControl={
              <HeaderButtonsWrapper>
                <GoBackButton handleClose={handleClose} backToTitle="" />
                <NavigateButtonsWrapper>
                  <LeftBtn disabled={Boolean(allFields && allFields[0].id === currentField.id)} onClick={prevField}>
                    <ArrowLeft width={18} height={18} />
                  </LeftBtn>
                  <RightBtn disabled={Boolean(allFields && allFields[allFields.length - 1].id === currentField.id)} onClick={nextField}>
                    <ArrowRight width={18} height={18} />
                  </RightBtn>
                </NavigateButtonsWrapper>
              </HeaderButtonsWrapper>
            }
          />
          <PageTitle title={currentField.name} />
          <TabsWrapper isMobile={isMobile}>
            {currentField.labelerData && <Tabs tabs={tabs} setActiveTab={setActiveTab} activeTab={activeTab} />}
            <AiAssistantButton
              isMobile={isMobile}
              styleType="outline"
              onClick={() => setIsAiPaneOpen((prev) => !prev)}
              size="md"
              icon={<AiAssistantIcon fill={theme.font.action} />}
            >
              AI Assistant
            </AiAssistantButton>
          </TabsWrapper>
          {activeTab === 'Field Editor' ? (
            <>
              {editFieldPage.typeWindow === 'LONG_FORM_CONTENT' && (
                <LongFormContentPage
                  row={currentField}
                  deleteAttachment={deleteAttachment}
                  updateFieldAnswer={updateFieldAnswer}
                  handleClose={handleClose}
                  recordId={recordId}
                  investmentId={investmentId}
                />
              )}
              {editFieldPage.typeWindow === 'SHORT_TEXT' && (
                <ShortTextPage row={currentField} updateFieldAnswer={updateFieldAnswer} handleClose={handleClose} investmentId={investmentId} />
              )}
              {editFieldPage.typeWindow === 'DATE' && (
                <DatePage row={currentField} updateFieldAnswer={updateFieldAnswer} handleClose={handleClose} investmentId={investmentId} />
              )}
              {editFieldPage.typeWindow === 'NUMBER' && (
                <NumberPage row={currentField} updateFieldAnswer={updateFieldAnswer} handleClose={handleClose} investmentId={investmentId} />
              )}
              {editFieldPage.typeWindow === 'DOLLAR' && (
                <DollarPage row={currentField} updateFieldAnswer={updateFieldAnswer} handleClose={handleClose} investmentId={investmentId} />
              )}
              {editFieldPage.typeWindow === 'PERCENTAGE' && (
                <PercentagePage row={currentField} updateFieldAnswer={updateFieldAnswer} handleClose={handleClose} investmentId={investmentId} />
              )}
            </>
          ) : (
            <LabelerTool
              labelerData={currentField.labelerData?.value ? JSON.parse(currentField.labelerData.value) : null}
              investmentId={investmentId}
              aiAnswer={currentField.AI.answers[0]}
              refetch={refetch}
              setIsPromptEditorOpen={setIsPromptEditorOpen}
              isLastField={Boolean(allFields && allFields[allFields.length - 1] === currentField)}
              nextField={nextField}
              onClose={handleClose}
              fileName={currentField.labelerData.filename}
              fieldId={currentField.id}
            />
          )}
        </MainWrap>
      )}
    </>
  );
};

const TabsWrapper = styled(PaddingWrap)<{ isMobile: boolean }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: ${({ isMobile }) => (isMobile ? 'column' : 'row')};
  background: ${({ theme }) => theme.layer[1]};
  border-bottom: 1px solid ${({ theme }) => theme.border.base};
`;

const AiAssistantButton = styled(Button)<{ isMobile: boolean }>`
  order: ${({ isMobile }) => (isMobile ? '-1' : '1')};
  margin: 10px 0 10px auto;

  &:hover {
    svg {
      path {
        fill: ${({ theme }) => theme.context.light};
      }
    }
  }
`;

const HeaderButtonsWrapper = styled.div`
  display: flex;
  gap: 10px;
`;

const NavigateButtonsWrapper = styled.div`
  display: flex;
  gap: 5px;
`;

const LeftBtn = styled.div<{ disabled: boolean }>`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ theme, disabled }) => (disabled ? theme.action.disabled : theme.layer[1])};
  cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
  color: ${({ theme, disabled }) => (disabled ? theme.font.disabled : theme.font.action)};
`;

const RightBtn = styled(LeftBtn)``;
