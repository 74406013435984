import Label from '@/components/basicComponents/label';
import ModalWrappedContent from '@/components/fat-basicComponents/modal/modalWrappedContent';
import ErrorNotification from '@/components/recommendations-page/error-notification';
import { ChangeEvent, useEffect, useState } from 'react';
import styled from 'styled-components';
import { Dollar, InputWrap } from '../../fat-allocator-page/fat-modals/editAmount';
import { IInvestmentEntity } from '../types';

interface EditAmountProps {
  onClose: () => void;
  isOpen: boolean;
  row: IInvestmentEntity | null;
  refetch: () => void;
  updateAmountInState: (id: string, value: number) => void;
  minimum: number;
}

interface ErrorMessage {
  title: string;
  message: string;
}

const EditAmount = ({ onClose, isOpen, row, refetch, updateAmountInState, minimum }: EditAmountProps) => {
  const [value, setValue] = useState('');
  const [errorText, setErrorText] = useState<ErrorMessage | null>(null);

  const validateAmount = (amount: number) => {
    let errorMessage: ErrorMessage | null = null;
    if (value[0] === '0') {
      errorMessage = {
        title: 'Cannot be less than 0',
        message: 'Any increase investment value must be greater than 0'
      };
    }
    if (amount < minimum) {
      errorMessage = {
        title: 'Cannot be less than fund minimum',
        message: 'The initial investment amount must be equal or greater than the investment minimum.'
      };
    }

    setErrorText(errorMessage);
    return errorMessage;
  };

  useEffect(() => {
    if (!row) return;

    setValue(row.investor_interest[0].amount.toLocaleString('en-US'));
  }, [row]);

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value.replace(/\D/g, '');
    if (!value) {
      setValue('');
      return;
    }
    const formattedValue = parseFloat(value).toLocaleString('en-US');

    setValue(formattedValue);
  };

  const saveValue = () => {
    const errorMessage = validateAmount(Number(value.replace(/\D/g, '')));
    if (errorMessage) return;

    updateAmountInState(row.id, parseInt(value.replace(/\D/g, '')));
    onClose();
  };

  if (!row) return <></>;

  return (
    <ModalWrappedContent
      isOpen={isOpen}
      onClose={onClose}
      title="Edit Investment Amount"
      confirmButton={{
        name: 'Save',
        onClick: saveValue,
        disabled: !value
      }}
    >
      {errorText && <ErrorNotification title={errorText?.title ?? ''} message={errorText?.message ?? ''} />}
      <Label required>Initial Investment Amount</Label>
      <InputWrap>
        <Input isBackground={!!value} width="100%" type="text" value={value} onChange={handleInputChange} autoFocus />
        <Dollar isBackground={!!value}>$</Dollar>
      </InputWrap>
      <Description>Initial Investment Minimum: ${minimum}</Description>
    </ModalWrappedContent>
  );
};

export default EditAmount;

const Input = styled.input<{ width: string; isBackground: boolean }>`
  width: ${({ width }) => width};
  border-radius: 4px;
  border: ${({ isBackground, theme }) => (isBackground ? `1px solid ${theme.context.success}` : `1px solid  ${theme.border.base}`)};
  background-color: ${({ isBackground, theme }) => (isBackground ? '#efffea' : theme.layer[1])};
  color: ${({ isBackground, theme }) => (isBackground ? '' : theme.font.strong)};
  padding: 10px 20px;
  font-size: 16px;
  font-family: Blinker;
  font-weight: 400;

  &:focus {
    outline: none;
  }
`;

const Description = styled.span`
  padding-top: 10px;
  font-weight: 300;
  font-size: 13px;
  line-height: 18px;
  color: ${({ theme }) => theme.font.weak};
`;
