import { getUTCDate } from '@/components/allocations-page/utils';
import lodash from 'lodash';
import { useEffect, useState } from 'react';
import { Bar, CartesianGrid, Cell, ComposedChart, Legend, Line, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import { Section } from '../../edit-entity/section';
import { ChartTooltip } from '../chartTooltip';
import { AssetClassForecastValueDto } from '../types';

interface AnnualVsTargetAllocationSectionProps {
  assetClassPercentages: AssetClassForecastValueDto[];
  drawdownTarget: number;
}

interface IChartData {
  year: string;
  actual: boolean;
  [key: string]: string | boolean | number;
}

export const AnnualVsTargetAllocationSection = ({ assetClassPercentages, drawdownTarget }: AnnualVsTargetAllocationSectionProps) => {
  const [assetClassNames, setAssetClassNames] = useState<string[]>([]);
  const [chartData, setChartData] = useState<IChartData[]>([]);

  const addItem = (
    transformedChartData: { [key: number]: IChartData },
    item: AssetClassForecastValueDto,
    drawdownTarget: number,
    fieldName: string
  ) => {
    const formattedYear = getUTCDate(new Date(item.date)).toUpperCase();
    const year = new Date(item.date).getFullYear();

    if (!transformedChartData[year]) {
      transformedChartData[year] = {
        year: formattedYear,
        [fieldName]: item.value,
        actual: item.actual
      };
    }
    transformedChartData[year] = {
      ...transformedChartData[year],
      [fieldName]: item.value
    };

    if (transformedChartData[year].actual) {
      transformedChartData[year].targetAllocation = drawdownTarget;
      return;
    }

    if (transformedChartData[year - 1]?.actual && !transformedChartData[year]?.actual) {
      transformedChartData[year].targetAllocation = drawdownTarget;
      transformedChartData[year].targetAllocationForecast = drawdownTarget;
      return;
    }

    transformedChartData[year].targetAllocationForecast = drawdownTarget;
  };

  useEffect(() => {
    if (!assetClassPercentages.length) return;
    const transformedChartData: { [key: number]: IChartData } = {};

    const assetClassesNamesSet: Set<string> = new Set();
    assetClassPercentages.forEach((assetClass) => {
      assetClassesNamesSet.add(lodash.camelCase(assetClass.name));
      addItem(transformedChartData, assetClass, drawdownTarget, lodash.camelCase(assetClass.name));
    });

    setChartData(Object.values(transformedChartData).map((value) => value));
    setAssetClassNames(Array.from(assetClassesNamesSet));
  }, [assetClassPercentages, drawdownTarget]);

  const getFillColor = (key: string) => {
    switch (key) {
      case 'privateEquity':
        return {
          actual: '#97D4F2',
          forecast: '#199BDC'
        };
      case 'privateCredit':
        return {
          actual: '#c388f8',
          forecast: '#9b2dfb'
        };
      case 'privateRealAssets':
        return {
          actual: '#FAD8BE',
          forecast: '#F08532'
        };
      case 'seniorLoans':
        return {
          actual: '#6fbad0',
          forecast: '#076582'
        };
      case 'mezz':
        return {
          actual: '#9CEBC1',
          forecast: '#20CB70'
        };
      case 'distressed':
        return {
          actual: '#E6BCED',
          forecast: '#AD5CB9'
        };
      case 'energy':
        return {
          actual: '#89d1f6',
          forecast: '#23b1f8'
        };
      case 'infrastructure':
        return {
          actual: '#FAA6BC',
          forecast: '#FC265E'
        };
      case 'realEstate':
        return {
          actual: '#F8E8BE',
          forecast: '#FDC538'
        };
      case 'diversifyingStrategies':
        return {
          actual: '#afef6b',
          forecast: '#7ba000'
        };
      default:
        return {
          actual: '',
          forecast: ''
        };
    }
  };

  return (
    <Section id="allocationForecast" title="Allocation Forecast">
      <ResponsiveContainer width="100%" height={400}>
        <ComposedChart data={chartData}>
          <CartesianGrid stroke="#B0B0B0" />
          <XAxis dataKey="year" />
          <YAxis width={85} tickFormatter={(value) => (typeof value === 'number' ? `${value.toFixed(2)}%` : '0.00%')} />
          <Tooltip content={<ChartTooltip formattingType="percentage" />} />
          <Legend />
          {assetClassNames.map((key, index) => (
            <Bar key={index} stackId="a" dataKey={key} fill={getFillColor(key).forecast} name={lodash.startCase(key)}>
              {chartData.map((entry, index) => {
                const color = entry.actual ? getFillColor(key).actual : getFillColor(key).forecast;
                return <Cell key={`cell-${index}`} fill={color} />;
              })}
            </Bar>
          ))}
          {/* <Line type="monotone" dataKey="targetAllocation" stroke="#4BADA0" name="Target Allocation" strokeWidth={4} /> */}
          <Line
            type="monotone"
            dataKey="targetAllocationForecast"
            stroke="#0E718A"
            strokeDasharray="5 5"
            name="Target Allocation"
            strokeWidth={4}
            dot={false}
          />
        </ComposedChart>
      </ResponsiveContainer>
    </Section>
  );
};
