import { GroupedIcon } from '@/assets/icons/info-icons/groupedIcon';
import { MinusIcon } from '@/assets/icons/info-icons/minusIcon';
import { PlusIcon } from '@/assets/icons/info-icons/plusIcon';
import FormattingTooltip from '@/components/basicComponents/tooltip/dollarsTooltip';
import { blinkingFrame, TableRowCell, TitleCell } from '@/components/table/styles';
import React, { useState } from 'react';
import styled, { useTheme } from 'styled-components';
import { TransformedAssetClassForecastValueDto } from '../../../types';

interface TableRowProps {
  row: TransformedAssetClassForecastValueDto;
  columnWidth: number;
  targetSubAssetClasses: boolean;
}

const TableRow = ({ row, columnWidth, targetSubAssetClasses }: TableRowProps) => {
  const theme = useTheme();

  const [showSubAssetClass, setShowSubAssetClass] = useState(false);

  return (
    <>
      <TableRowWrapper>
        <TableRowCell width={15}>
          <AssetClassCell>
            {row.subAssetClasses?.length && targetSubAssetClasses && (
              <PlusIconWrapper onClick={() => setShowSubAssetClass(!showSubAssetClass)}>
                {showSubAssetClass ? <MinusIcon fill={theme.font.base} /> : <PlusIcon fill={theme.font.base} />}
              </PlusIconWrapper>
            )}
            <TextCell>{row.name}</TextCell>
          </AssetClassCell>
        </TableRowCell>
        {row.values.map((item) => (
          <TableRowCell key={row.name + item.date} width={columnWidth}>
            <TextCell>
              <FormattingTooltip zIndex={1000}>{item.value}</FormattingTooltip>
            </TextCell>
          </TableRowCell>
        ))}
      </TableRowWrapper>
      {targetSubAssetClasses && row.subAssetClasses?.length && showSubAssetClass && (
        <>
          {row.subAssetClasses.map((subAssetClass) => {
            const hasSubAssetClassValues = subAssetClass.values.some((valueItem) => valueItem.value !== 0);
            if (!hasSubAssetClassValues) return <React.Fragment key={subAssetClass.name} />;

            return (
              <TableRowWrapper key={subAssetClass.name}>
                <TableRowCell width={15}>
                  <AssetClassCell>
                    <GroupIconWrapper>
                      <GroupedIcon width={26} height={26} />
                    </GroupIconWrapper>
                    <TextCell>{subAssetClass.name}</TextCell>
                  </AssetClassCell>
                </TableRowCell>
                {subAssetClass.values.map((item) => (
                  <TableRowCell key={subAssetClass.name + item.date} width={columnWidth}>
                    <TextCell>
                      <FormattingTooltip zIndex={1000}>{item.value}</FormattingTooltip>
                    </TextCell>
                  </TableRowCell>
                ))}
              </TableRowWrapper>
            );
          })}
        </>
      )}
    </>
  );
};

export default TableRow;

const TableRowWrapper = styled.div<{ addAdaptive?: boolean; loading?: boolean | number }>`
  display: flex;
  align-items: center;
  width: 100%;
  min-height: 60px;
  background-color: ${({ theme }) => theme.layer[1]};
  border-radius: 8px;
  padding: 0 24px;
  gap: 5px;
  opacity: ${({ loading }) => (loading ? 0.5 : 1)};
  pointer-events: ${({ loading }) => (loading ? 'none' : 'auto')};
  animation: ${({ loading }) => (loading ? blinkingFrame : null)} 1.5s infinite;

  &:hover #allocationInput {
    border: 1px solid ${({ theme }) => theme.border.base};
  }

  &:hover {
    background-color: ${({ theme }) => theme.layer[12]};
    transition: background-color 100ms ease;
  }
`;

const AssetClassCell = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 6px;
`;

const PlusIconWrapper = styled.div`
  cursor: pointer;
  border-radius: 12px;
  width: 24px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    background-color: ${({ theme }) => theme.action.hover};
    transition: background-color 100ms ease;
  }
`;

const GroupIconWrapper = styled.div`
  padding: 5px;
  position: relative;
  top: -9px;
  margin-right: 14px;
  margin-left: 5px;
`;

const TextCell = styled(TitleCell)<{ bold?: boolean }>`
  font-weight: ${({ bold }) => (bold ? 600 : 400)};
`;
