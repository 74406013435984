import { useMemo } from 'react';

import BasicTooltip from '@/components/basicComponents/tooltip';
import FormattingTooltip from '@/components/basicComponents/tooltip/dollarsTooltip';
import styled from 'styled-components';
import { simplifiedSummaryTableColumns, summaryTablecolumns } from '../constatnts';
import { AssetClassTargetAllocationDto } from '../types';

interface TableSumFooterProps {
  investorSummaryRows: AssetClassTargetAllocationDto[];
  ignoreAllocationTargets: boolean;
}

interface ISum {
  total: string;
  target: number;
  targetAllocations: number;
  exposure: number;
  capacity: number;
  annualPacingTarget: number;
}

const TableSumFooter = ({ investorSummaryRows, ignoreAllocationTargets }: TableSumFooterProps) => {
  const rowSum: ISum = useMemo(() => {
    const sum = {
      total: 'Total',
      target: 0,
      targetAllocations: 0,
      exposure: 0,
      capacity: 0,
      annualPacingTarget: 0
    };
    for (let i = 0; i < investorSummaryRows.length; i++) {
      sum.target += investorSummaryRows[i].percentage;
      sum.targetAllocations += investorSummaryRows[i].targetAllocation;
      sum.exposure += investorSummaryRows[i].economicExposure;
      sum.capacity += investorSummaryRows[i].capacity;
      sum.annualPacingTarget += investorSummaryRows[i].pacingTarget?.annualPacingTarget || 0;
    }
    return sum;
  }, [investorSummaryRows]);

  if (ignoreAllocationTargets) {
    return (
      <TableSumWrap>
        <TableSumItem width={simplifiedSummaryTableColumns[0].width}>{rowSum.total}</TableSumItem>
        <TableSumItem width={simplifiedSummaryTableColumns[1].width}>
          <FormattingTooltip>{rowSum.exposure}</FormattingTooltip>
        </TableSumItem>
      </TableSumWrap>
    );
  }

  return (
    <TableSumWrap>
      <TableSumItem width={summaryTablecolumns[0].width}>{rowSum.total}</TableSumItem>
      <TableSumItem width={summaryTablecolumns[1].width}>
        <BasicTooltip tooltipContent={`${rowSum.target.toFixed(2)}%`} zIndex={1000}>
          {rowSum.target.toFixed(0)}%
        </BasicTooltip>
      </TableSumItem>
      <TableSumItem width={summaryTablecolumns[2].width}>
        <FormattingTooltip>{rowSum.targetAllocations}</FormattingTooltip>
      </TableSumItem>
      <TableSumItem width={summaryTablecolumns[3].width}>
        <FormattingTooltip>{rowSum.exposure}</FormattingTooltip>
      </TableSumItem>
      <TableSumItem width={summaryTablecolumns[4].width}>
        <FormattingTooltip>{rowSum.capacity}</FormattingTooltip>
      </TableSumItem>
      <TableSumItem width={summaryTablecolumns[5].width}>
        <FormattingTooltip>{rowSum.annualPacingTarget}</FormattingTooltip>
      </TableSumItem>
    </TableSumWrap>
  );
};

const TableSumWrap = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  min-height: 60px;
  background-color: ${({ theme }) => theme.layer[2]};
  border-radius: 8px;
  padding: 0 24px;
`;

const TableSumItem = styled.div<{ width: number }>`
  display: flex;
  justify-content: flex-start;
  width: ${({ width }) => `${width}%`};
  color: ${({ theme }) => theme.font.base};
  font-family: Blinker, serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: 0;
`;

export default TableSumFooter;
