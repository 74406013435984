import NoResults from '@/components/table/noResults';
import guid from '@/utils/guid';
import styled from 'styled-components';
import { CommitmentForecastValueDto } from '../../../types';
import TableRow from './tableRow';
import SubTotalRow from './tableSubTotalRow';

interface TableBodyProps {
  contributionsTableData: CommitmentForecastValueDto[];
  distributionsTableData: CommitmentForecastValueDto[];
  columnWidth: number;
}

const TableBody = ({ contributionsTableData, distributionsTableData, columnWidth }: TableBodyProps) => {
  if (!contributionsTableData?.length) {
    return <NoResults />;
  }

  return (
    <TableBodyWrapper>
      <ContributionsRowWrapper>Contributions</ContributionsRowWrapper>
      {contributionsTableData.map((row) => (
        <TableRow key={guid()} row={row} columnWidth={columnWidth} />
      ))}
      <SubTotalRow tableData={contributionsTableData} columnWidth={columnWidth} />
      <ContributionsRowWrapper>Distribution</ContributionsRowWrapper>
      {distributionsTableData.map((row) => (
        <TableRow key={guid()} row={row} columnWidth={columnWidth} />
      ))}
      <SubTotalRow tableData={distributionsTableData} columnWidth={columnWidth} />
    </TableBodyWrapper>
  );
};

const TableBodyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const ContributionsRowWrapper = styled.div`
  border-radius: 4px;
  background: ${({ theme }) => theme.layer[3]};
  padding: 10px 20px;
  font-size: 16px;
  font-weight: 600;
  line-height: 22.4px;
  color: ${({ theme }) => theme.layer.base};
`;

export default TableBody;
