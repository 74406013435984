import { MainWrap, PaddingWrap } from '@/styles/common';
import styled from 'styled-components';
import Button from '../fat-basicComponents/button';
import Header from '../fat-header';

interface ManagerUpdatesZeroStateProps {
  showViewAllStrategiesButton: boolean;
  onViewAllStrategiesButtonClick: () => void;
}

export const ManagerUpdatesZeroState = ({ showViewAllStrategiesButton, onViewAllStrategiesButtonClick }: ManagerUpdatesZeroStateProps) => {
  return (
    <MainWrap>
      <Header />
      <PaddingWrap>
        <ZeroStateWrapper>
          <ZeroStateTitleWrapper>
            <img src="/static/investors-page/missing-mag-glass-graphic.png" draggable="false" alt="No Investors" />
            <ZeroStateTitle>
              <ZeroStateText fontSize="28px" fontWeight={700}>
                No Strategies Found.
              </ZeroStateText>
              <ZeroStateText fontSize="22px" fontWeight={400}>
                Once strategies are added the system use this module to log manager notes and store quarterly letters.
              </ZeroStateText>
            </ZeroStateTitle>
          </ZeroStateTitleWrapper>
          {showViewAllStrategiesButton && (
            <StyledButton size="md" onClick={onViewAllStrategiesButtonClick}>
              View All Strategies
            </StyledButton>
          )}
        </ZeroStateWrapper>
      </PaddingWrap>
    </MainWrap>
  );
};

const ZeroStateWrapper = styled.div`
  width: 100%;
  margin-top: 120px;
`;

const ZeroStateTitleWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 15px;
  padding: 20px 80px;
  margin: 0 auto;
  width: max-content;
  background: ${({ theme }) => theme.layer[1]};
  color: ${({ theme }) => theme.font.weak};
`;

const ZeroStateTitle = styled.div`
  max-width: 550px;
  width: 100%;
`;

const ZeroStateText = styled.p<{ fontSize: string; fontWeight: number }>`
  font-family: Lato;
  font-size: ${({ fontSize }) => fontSize};
  font-weight: ${({ fontWeight }) => fontWeight};
  line-height: 32px;
`;

const StyledButton = styled(Button)`
  margin: 30px auto;
  display: flex;
  align-self: center;
`;
