import client from '@/apollo-client';
import FileNotSupported from '@/components/dueDiligence-page/modals/fileNotSupported';
import Header from '@/components/fat-header';
import { GoBackButton } from '@/components/fat-header/goBackButton';
import { PageTitle } from '@/components/fat-header/pageTitle';
import { updateEntityMutation } from '@/components/fat-investors-page/fat-investorDetails-page/fat-entityDetails-page/queries';
import { useResponsive } from '@/hooks/use-responsive';
import { MainWrap } from '@/styles/common';
import { useMutation } from '@apollo/client';
import { useState } from 'react';
import styled from 'styled-components';
import Loading from '../fat-modals/loading';
import { EntityCodes } from '../importAllocations/steps/entityCodes';
import { SeriesCodes } from '../importAllocations/steps/seriesCodes';
import { UploadDataFile } from '../importAllocations/steps/uploadDataFile';
import {
  LIST_ENTITIES_QUERY_BASIC,
  LIST_INVESTMENT_VEHICLES_QUERY,
  createInvestmentVehicleMutation,
  importCommitmentsDetailMutation
} from '../queries';
import { CommitmentError, IEntityCodesTableData, ISeriesCodesTableData, MissingIdError } from '../types';
import { removeDuplicates } from '../utils';
import { valuationsColumnsSectionData, valuationsDescriptionSectionData, valuationsSteps } from './constants';
import { Commitments } from './steps/commitments';
import { ImportCommitmentsDetail, ImportValuationsFileData, ImportValuationsTableData } from './types';
import { parse } from 'csv-parse/sync';

interface ImportValuationsProps {
  handleCloseImportValuations: () => void;
  backToTitle?: string;
}

const fileExtensions = ['CSV'];

export const ImportValuations = ({ handleCloseImportValuations, backToTitle }: ImportValuationsProps) => {
  const { isMobile, isTablet } = useResponsive();
  const [importSteps, setImportSteps] = useState(valuationsSteps);
  const [modalWindow, setModalWindow] = useState({ isOpen: false, type: 'not-supported' });
  const [drag, setDrag] = useState(false);
  const [file, setFile] = useState<File | null>(null);

  const [entityCodesTableData, setEntityCodesTableData] = useState<IEntityCodesTableData[]>([]);
  const [seriesCodesTableData, setSeriesCodesTableData] = useState<ISeriesCodesTableData[]>([]);
  const [commitmentsTableData, setCommitmentsTableData] = useState<ImportValuationsTableData[]>([]);

  const [entityCodesErrors, setEntityCodesErrors] = useState<MissingIdError[]>([]);
  const [seriesCodesErrors, setSeriesCodesErrors] = useState<MissingIdError[]>([]);
  const [commitmentErrors, setCommitmentErrors] = useState<CommitmentError[]>([]);

  const [isValidFile, setIsValidFile] = useState(true);
  const [recordsToUpdate, setRecordsToUpdate] = useState<any>([]);
  const [fileData, setFileData] = useState<ImportValuationsFileData[]>([]);

  const [updateEntity] = useMutation(updateEntityMutation);
  const [createInvestmentVehicle] = useMutation(createInvestmentVehicleMutation);
  const [importCommitmentDetail, { loading: importCommitmentsDetailMutationLoading }] = useMutation<{
    importCommitmentsDetail: ImportCommitmentsDetail;
  }>(importCommitmentsDetailMutation, {
    onError: () => {
      setFile(null);
      setIsValidFile(false);
    }
  });

  const allowFileExtensions = (files: FileList | null) => {
    return Array.from(files || []).filter((file: File) => {
      const fileExt = file.name.split('.').pop()?.toLowerCase();
      if ([...fileExtensions.map((item) => item.toLowerCase())].includes(fileExt || '')) {
        return file;
      }
    });
  };

  const importFile = async (file: File) => {
    setIsValidFile(true);

    const text = await file.text();
    const records = parse(text, {
      columns: true,
      skip_empty_lines: true,
      trim: true
    });

    const gruppedFileData = records.map((record: any) => ({
      investmentCode: record['Series Code'],
      seriesName: record['Series Name'],
      entityCode: record['Entity Code'],
      entityName: record['Entity Name'],
      toDate: record['Valuation Update Date'],
      periodNetNAV: Number(record['Amount']),
      advisoryFirm: record['Advisor']
    }));
    setFileData(gruppedFileData);

    const recordsToUpdate = records.map((record: any) => ({
      ...(record['Series Code'] && { investmentCode: record['Series Code'] }),
      ...(record['Entity Code'] && { entityCode: record['Entity Code'] }),
      ...(record['Valuation Update Date'] && { toDate: record['Valuation Update Date'] }),
      ...(record['Amount'] && { periodNetNAV: Number(record['Amount']) })
    }));
    setRecordsToUpdate(recordsToUpdate);

    const { data } = await importCommitmentDetail({
      variables: {
        data: {
          save: false,
          data: recordsToUpdate
        }
      }
    });

    const newEntityCodesErrors = data?.importCommitmentsDetail.errors?.filter((error) =>
      error.message.includes('Missing legal entity for entity code')
    );

    await setEntityCodesStepData(gruppedFileData, newEntityCodesErrors ?? []);
  };

  const onDropHandler = async (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    const uploadFiles = e.dataTransfer.files;

    setDrag(false);
    if (!allowFileExtensions(uploadFiles).length) {
      setModalWindow({ isOpen: true, type: 'not-supported' });
      return;
    }
    if (uploadFiles && uploadFiles.length > 0) {
      setFile(uploadFiles[0]);
      importFile(uploadFiles[0]);
    }
  };

  const handleUploadFile = async (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    const uploadFiles = e.target.files;

    if (!allowFileExtensions(uploadFiles).length) {
      setModalWindow({ isOpen: true, type: 'not-supported' });
      return;
    }
    if (uploadFiles && uploadFiles.length > 0) {
      setFile(uploadFiles[0]);
      importFile(uploadFiles[0]);
    }
  };

  const nextStep = () => {
    const currentStepIndex = importSteps.findIndex((step) => step.status === 'IN_PROGRESS');
    if (currentStepIndex === importSteps.length - 1) return;

    const updatedImportSteps = importSteps.map((step, index) => {
      if (currentStepIndex === index) {
        return { ...step, status: 'COMPLETED' };
      }
      if (currentStepIndex + 1 === index) {
        return { ...step, status: 'IN_PROGRESS' };
      }

      return step;
    });

    setImportSteps(updatedImportSteps);
  };

  const setEntityCodesStepData = async (fileData: ImportValuationsFileData[], errors: MissingIdError[]) => {
    const { data: listEntities } = await client.query({
      query: LIST_ENTITIES_QUERY_BASIC,
      fetchPolicy: 'network-only',
      variables: {
        data: {
          limit: 1000
        }
      }
    });

    const updatedEntityCodesTableData = fileData.map((record) => {
      const errorEntityCode = errors.find((entityCode) => entityCode.id === record.entityCode);
      const matchedEntity = listEntities.listEntities.data.find((item: any) => item.importId === record.entityCode);

      return {
        entityName: record.entityName,
        entityCode: record.entityCode,
        advisoryFirm: record.advisoryFirm,
        entityAssignment: errorEntityCode || !matchedEntity ? 'Select An Entity' : `${matchedEntity.entityName} (${matchedEntity.tenant.name})`,
        entityStatus: errorEntityCode ? 'Required' : 'Assigned',
        changed: false,
        entityId: null
      };
    });

    setEntityCodesErrors(removeDuplicates(errors, 'id'));
    setEntityCodesTableData(removeDuplicates(updatedEntityCodesTableData, 'entityCode'));
  };

  const setSeriesCodesStepData = async (fileData: ImportValuationsFileData[], errors: MissingIdError[]) => {
    const seriesCodes = recordsToUpdate.map((record: any) => record.investmentCode);

    const { data: investmentVehicleData } = await client.query({
      query: LIST_INVESTMENT_VEHICLES_QUERY,
      fetchPolicy: 'network-only',
      variables: {
        seriesCodes: seriesCodes
      }
    });

    const updatedSeriesCodesTableData = fileData.map((record) => {
      const errorSeriesCode = errors.find((seriesCode) => seriesCode.id === record.investmentCode);
      const matchedInvestment = investmentVehicleData.listInvestmentVehicles.find((item: any) => item.id === record.investmentCode);

      return {
        investmentCode: record.investmentCode,
        seriesName: record.seriesName,
        seriesAssignment: errorSeriesCode || !matchedInvestment ? 'Select A Strategy' : matchedInvestment.investment.name,
        seriesStatus: errorSeriesCode ? 'Required' : 'Assigned',
        changed: false,
        investmentId: null
      };
    });

    setSeriesCodesErrors(removeDuplicates(errors, 'id'));
    setSeriesCodesTableData(removeDuplicates(updatedSeriesCodesTableData, 'investmentCode'));
  };

  const setCommitmentsStepData = async (fileData: ImportValuationsFileData[], errors: CommitmentError[]) => {
    const updatedCommitmentsTableData = fileData.map((record) => {
      const errorCommitment = errors.find(
        (commitment) => commitment.entityCode === record.entityCode && commitment.investmentCode === record.investmentCode
      );

      return {
        entityName: record.entityName,
        entityCode: record.entityCode,
        investmentCode: record.investmentCode,
        seriesName: record.seriesName,
        toDate: record.toDate,
        periodNetNAV: Number(record.periodNetNAV),
        commitmentStatus: errorCommitment ? 'Missing Commitment' : 'Validated'
      };
    });

    setCommitmentErrors(errors);
    setCommitmentsTableData(updatedCommitmentsTableData);
  };

  const moveToSeriesCodesStep = async () => {
    setModalWindow({ isOpen: true, type: 'loading' });

    const changedRows = entityCodesTableData.filter((row) => row.changed && row.entityId);
    for await (const row of changedRows) {
      await updateEntity({
        variables: {
          data: {
            id: row.entityId,
            importId: row.entityCode
          }
        }
      });
    }

    const { data } = await importCommitmentDetail({
      variables: {
        data: {
          save: false,
          data: recordsToUpdate
        }
      }
    });

    const newEntityCodesErrors = data?.importCommitmentsDetail.errors?.filter((error) =>
      error.message.includes('Missing legal entity for entity code')
    );

    if (newEntityCodesErrors?.length) {
      await setEntityCodesStepData(fileData, newEntityCodesErrors);
      setModalWindow({ isOpen: false, type: 'loading' });
      return;
    }

    const newSeriesCodesErrors = data?.importCommitmentsDetail.errors?.filter((error) =>
      error.message.includes('Missing investment vehicle for investment code')
    );

    await setSeriesCodesStepData(fileData, newSeriesCodesErrors ?? []);
    setModalWindow({ isOpen: false, type: 'loading' });
    nextStep();
  };

  const moveToCommitmentsStep = async () => {
    setModalWindow({ isOpen: true, type: 'loading' });

    const changedRows = seriesCodesTableData.filter((row) => row.changed && row.investmentId);
    for await (const row of changedRows) {
      await createInvestmentVehicle({
        variables: {
          data: {
            seriesCode: row.investmentCode,
            seriesName: row.seriesName?.replace(` - ${row.investmentCode}`, ''),
            investmentId: row.investmentId
          }
        }
      });
    }

    const { data } = await importCommitmentDetail({
      variables: {
        data: {
          save: false,
          data: recordsToUpdate
        }
      }
    });

    const newSeriesCodesErrors = data?.importCommitmentsDetail.errors?.filter((error) =>
      error.message.includes('Missing investment vehicle for investment code')
    );

    if (newSeriesCodesErrors?.length) {
      await setSeriesCodesStepData(fileData, newSeriesCodesErrors);
      setModalWindow({ isOpen: false, type: 'loading' });
      return;
    }

    await setCommitmentsStepData(fileData, data?.importCommitmentsDetail.commitmentErrors ?? []);
    setModalWindow({ isOpen: false, type: 'loading' });
    nextStep();
  };

  const completeImport = async () => {
    setModalWindow({ isOpen: true, type: 'loading' });

    const { data } = await importCommitmentDetail({
      variables: {
        data: {
          save: true,
          data: recordsToUpdate
        }
      }
    });

    if (data?.importCommitmentsDetail.status === 'done') {
      handleCloseImportValuations();
      setModalWindow({ isOpen: false, type: 'loading' });
      return;
    }

    await setCommitmentsStepData(fileData, data?.importCommitmentsDetail.commitmentErrors ?? []);
    setModalWindow({ isOpen: false, type: 'loading' });
  };

  const startOver = () => {
    if (!file) return;
    importFile(file);
    setImportSteps(valuationsSteps);
  };

  return (
    <>
      {modalWindow.type === 'not-supported' && (
        <FileNotSupported isOpen={modalWindow.isOpen} onClose={() => setModalWindow({ ...modalWindow, isOpen: false })} />
      )}
      {modalWindow.type === 'loading' && <Loading isOpen={modalWindow.isOpen} />}
      <MainWrap>
        <Header modalControl={<GoBackButton handleClose={handleCloseImportValuations} backToTitle={backToTitle} />} />
        <PageTitle title="Import Valuations" />
      </MainWrap>
      <MainWrap>
        <PaddingWrap>
          {importSteps[0].status === 'IN_PROGRESS' && (
            <UploadDataFile
              importSteps={importSteps}
              file={file}
              handleUploadFile={handleUploadFile}
              onDropHandler={onDropHandler}
              drag={drag}
              setDrag={setDrag}
              handleClose={handleCloseImportValuations}
              nextStep={nextStep}
              loading={importCommitmentsDetailMutationLoading}
              isValidFile={isValidFile}
              descriptionSection={valuationsDescriptionSectionData}
              columnsSection={valuationsColumnsSectionData}
            />
          )}
          {importSteps[1].status === 'IN_PROGRESS' && (
            <EntityCodes
              importSteps={importSteps}
              startOver={startOver}
              nextStep={moveToSeriesCodesStep}
              tableData={entityCodesTableData}
              setTableData={setEntityCodesTableData}
              errorEntityCode={entityCodesErrors}
              setErrors={setEntityCodesErrors}
            />
          )}
          {importSteps[2].status === 'IN_PROGRESS' && (
            <SeriesCodes
              importSteps={importSteps}
              startOver={startOver}
              nextStep={moveToCommitmentsStep}
              tableData={seriesCodesTableData}
              setTableData={setSeriesCodesTableData}
              seriesCodesError={seriesCodesErrors}
              setErrors={setSeriesCodesErrors}
            />
          )}
          {importSteps[3].status === 'IN_PROGRESS' && (
            <Commitments
              importSteps={importSteps}
              startOver={startOver}
              nextStep={completeImport}
              commitmentError={commitmentErrors}
              tableData={commitmentsTableData}
            />
          )}
        </PaddingWrap>
      </MainWrap>
    </>
  );
};

const PaddingWrap = styled.div`
  padding-left: 16px;
  padding-right: 16px;
  @media (min-width: 600px) {
    padding-left: 50px;
    padding-right: 50px;
  }
`;
