import ModalWrappedContent from '@/components/basicComponents/modal/modalWrappedContent';
import styled from 'styled-components';

interface FailureProps {
  errorMessage: string;
  onClose: () => void;
  isOpen: boolean;
}

const Failure = ({ errorMessage, onClose, isOpen }: FailureProps) => {
  return (
    <ModalWrappedContent
      isOpen={isOpen}
      onClose={onClose}
      showCancelButton={false}
      confirmButton={{
        name: 'OK',
        onClick: onClose,
        bgColor: '#00AEEF'
      }}
    >
      <Title>Something Went Wrong</Title>
      <ModalBody>{errorMessage}</ModalBody>
    </ModalWrappedContent>
  );
};

export default Failure;

const Title = styled.h1`
  font-family: 'Blinker', serif;
  font-weight: 400;
  font-size: 33px;
  text-align: center;
  color: #2e2e2e;
  margin-bottom: 10px;
`;

const ModalBody = styled.div`
  text-align: center;
`;
