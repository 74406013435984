import { defaultSelectValue } from '@/components/opportunities-entity/modify/constants';
import { CreateInvestorDto, IAssetClass } from './types';

const getDecimalPart = (number: number): number => {
  const decimalPart = parseFloat((number % 1).toFixed(2).slice(2));
  return decimalPart !== 0 ? decimalPart : 0;
};

export const getDefaultDataFields = (entityData: any): CreateInvestorDto => ({
  entityName: entityData.entityName ? entityData.entityName : '',
  familyId: defaultSelectValue,
  ignoreBiteSize: entityData.ignoreBiteSize,
  ignoreAllocationTargets: entityData.ignoreAllocationTargets,
  taxExempt: entityData.taxExempt,
  onHold: entityData.onHold,
  defaultBiteSize1: entityData.defaultBiteSize1 ? entityData.defaultBiteSize1 : 0,
  defaultBiteSize2: entityData.defaultBiteSize2 ? entityData.defaultBiteSize2 : 0,
  overCommitRatio: entityData.overCommitRatio ? getDecimalPart(entityData.overCommitRatio) : null,
  privatePercentage: entityData.privatePercentage ? entityData.privatePercentage : 0,
  totalEntityValue: entityData.totalEntityValue ? entityData.totalEntityValue : 0,
  advisorReportingAccountNumber: entityData.advisorReportingAccountNumber ? entityData.advisorReportingAccountNumber : '',
  advisorCRMId: entityData.advisorCRMId ? entityData.advisorCRMId : '',
  administratorCode: entityData.administratorCode ? entityData.administratorCode : '',
  sectorsToAvoid: entityData?.sectorsToAvoid?.filter((sector: string) => sector !== '')
});

export const buildDataToUpdate = (formData: CreateInvestorDto, entityId: string) => ({
  id: entityId,
  exportId: formData.advisorReportingAccountNumber,
  entityCRMId: formData.advisorCRMId,
  entityName: formData.entityName,
  totalEntityValue: formData.totalEntityValue,
  privatePercentage: formData.privatePercentage,
  overCommitRatio: Number('1.' + formData.overCommitRatio),
  directBiteSize: formData.defaultBiteSize1,
  fundBiteSize: formData.defaultBiteSize2,
  taxExempt: formData.taxExempt,
  onHold: formData.onHold,
  sectorsToAvoid: formData.sectorsToAvoid.filter((item) => item !== 'None'),
  ignoreBiteSize: formData.ignoreBiteSize,
  ignoreAllocationTargets: formData.ignoreAllocationTargets
});

export const getAllocationTargetsToUpdate = (assetClasses: IAssetClass[], targetSubAssetClasses: boolean) => {
  const hasSubAssetClasses = assetClasses.some((assetClass) => assetClass.subAssetClasses.length > 0);

  if (targetSubAssetClasses && hasSubAssetClasses) {
    return assetClasses.flatMap((assetClass) => {
      return assetClass.subAssetClasses.map((subAssetClass) => {
        return {
          subAssetClassId: subAssetClass.id,
          percentage: subAssetClass.defaultAllocationPercentage
        };
      });
    });
  }

  return assetClasses.map((assetClass) => ({
    assetClassId: assetClass.id,
    percentage: assetClass.defaultAllocationPercentage
  }));
};

export const sortByName = <T extends { name: string }>(arr: T[]) => {
  return [...arr].sort((a, b) => a.name.localeCompare(b.name));
};
