import { useMutation } from '@apollo/client';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import client from '@/apollo-client';
import Button from '@/components/basicComponents/button';
import Label from '@/components/basicComponents/label';
import FilterSelect from '@/components/basicComponents/select';
import { StyledTextarea } from '@/components/dueDiligence-page/modals/changeAssignee';
import {
  createGroundTruthMutation,
  updateGroundTruthMutation,
  updateHumanScoreMutation,
  updateRawAnswerMutation
} from '@/components/dueDiligence-page/queries';
import { getAssetSignedUrl } from '@/components/settings-page/queries';
import { useResponsive } from '@/hooks/use-responsive';
import { EditIcon } from '@/assets/icons/info-icons/edit';
import styled from 'styled-components';
import { ILabelerData, IRawAnswers } from '../../types';
import { Table } from './table';
import MobileTable from './tableMobile';

interface LabelerToolProps {
  labelerData: ILabelerData | null;
  investmentId: string | null;
  aiAnswer: string;
  setIsPromptEditorOpen: Dispatch<SetStateAction<boolean>>;
  refetch: any;
  nextField: () => void;
  isLastField: boolean;
  onClose: () => void;
  fileName: string;
  fieldId: string;
}

export const LabelerTool = ({
  labelerData,
  investmentId,
  aiAnswer,
  setIsPromptEditorOpen,
  refetch,
  nextField,
  isLastField,
  onClose,
  fileName,
  fieldId
}: LabelerToolProps) => {
  const { isMobile } = useResponsive();

  const [groundtruthAnswer, setGroundtruthAnswer] = useState('');
  const [documentContainsAnswer, setDocumentContainsAnswer] =
    useState('select');
  const [humanScore, setHumanScore] = useState('select');
  const [notes, setNotes] = useState('');
  const [pageNumbers, setPageNumbers] = useState('');
  const [tableRows, setTableRows] = useState<IRawAnswers[]>([]);

  useEffect(() => {
    if (labelerData) {
      if (labelerData.groundTruth) {
        setGroundtruthAnswer(labelerData.groundTruth?.answer ?? '');
        setDocumentContainsAnswer(
          labelerData.groundTruth?.docContainsAnswer !== null
            ? labelerData.groundTruth.docContainsAnswer.toString()
            : 'select'
        );
        setNotes(labelerData.groundTruth?.notes ?? '');
        setPageNumbers(
          labelerData?.groundTruth?.pageNumber
            ? labelerData.groundTruth.pageNumber
            : ''
        );
      }
      setHumanScore(
        labelerData?.humanScore?.score
          ? labelerData.humanScore.score.toString()
          : 'select'
      );
      setTableRows(labelerData?.rawAnswers ?? []);
    }

    return () => {
      setDocumentContainsAnswer('select');
      setGroundtruthAnswer('');
      setNotes('');
      setPageNumbers('');
      setHumanScore('select');
      setTableRows([]);
    };
  }, [labelerData]);

  const openPdf = async (pageNumber: number) => {
    if (!investmentId || !fileName) return;

    const assetKey = `investments/${investmentId}/ddr/${fileName}`;
    const { data } = await client.query({
      fetchPolicy: 'network-only',
      query: getAssetSignedUrl,
      variables: {
        assetKey: assetKey
      }
    });

    window.open(`${data.getAssetSignedUrl}#page=${pageNumber}`, '_blank');
  };

  const changeField = (id: string, value: string, fieldName: string) => {
    const updatedRows = tableRows.map((row) => {
      if (row.id === id && fieldName === 'hasAnswer') {
        return {
          ...row,
          hasAnswer: value === '--' ? null : value === 'true',
          isChangedRow: true
        };
      }
      if (row.id === id && fieldName === 'score') {
        return {
          ...row,
          score: value === '--' ? null : Number(value),
          isChangedRow: true
        };
      }
      return row;
    });

    setTableRows(updatedRows);
  };

  const [createGroundTruth] = useMutation(createGroundTruthMutation);
  const [updateGroundTruth] = useMutation(updateGroundTruthMutation);
  const [updateHumanScore] = useMutation(updateHumanScoreMutation);
  const [updateRawAnswer] = useMutation(updateRawAnswerMutation);

  const saveLabelerToolData = async () => {
    if (labelerData?.groundTruth?.id) {
      await updateGroundTruth({
        variables: {
          data: {
            id: labelerData?.groundTruth.id,
            notes: notes,
            pageNumber: pageNumbers,
            answer: groundtruthAnswer,
            docContainsAnswer:
              documentContainsAnswer === 'select'
                ? null
                : documentContainsAnswer === 'true'
          }
        }
      });
    } else if (fileName && fieldId) {
      await createGroundTruth({
        variables: {
          data: {
            dueDiligenceFunnelTierSectionFieldId: fieldId,
            filename: fileName,
            notes: notes,
            pageNumber: pageNumbers,
            answer: groundtruthAnswer,
            docContainsAnswer:
              documentContainsAnswer === 'select'
                ? null
                : documentContainsAnswer === 'true'
          }
        }
      });
    }
    if (labelerData?.humanScore?.id) {
      await updateHumanScore({
        variables: {
          data: {
            id: labelerData.humanScore.id,
            score: humanScore === 'select' ? null : Number(humanScore)
          }
        }
      });
    }

    for await (const row of tableRows) {
      if (row.isChangedRow) {
        await updateRawAnswer({
          variables: {
            data: {
              id: row.id,
              score: row.score,
              hasAnswer: row.hasAnswer
            }
          }
        });
      }
    }
    if (isLastField) {
      onClose();
    } else {
      nextField();
    }
    refetch();
  };

  return (
    <LabelerToolWrapper>
      <AIAnswerWrapper>
        <AiAnswerHeader>
          AI Answer
          <EditButton onClick={() => setIsPromptEditorOpen(true)}>
            <EditIcon width={16} height={16} /> Edit Prompt
          </EditButton>
        </AiAnswerHeader>
        <AiAnswer>{aiAnswer}</AiAnswer>
        <ScoreWrapper isMobile={isMobile}>
          <HumanScore>
            <CustomLabel required>Human Score</CustomLabel>
            <CustomFilterSelect
              data={['select', ...Array(11).keys()].map(String)}
              setSelected={(value) => setHumanScore(value)}
              selected={humanScore}
              width={isMobile ? '100%' : '279px'}
              fontSize="16px"
              fontWeight="400"
              minHeight="40px"
              isBackground={humanScore !== 'select'}
            />
          </HumanScore>
          <RubricScore isMobile={isMobile}>
            <CustomLabel required={false}>
              Rubric Score
              {/* <EditButton>
                <EditIcon width={16} height={16} /> Edit Rubric
              </EditButton> */}
            </CustomLabel>
            {labelerData?.rubricScore?.score
              ? labelerData.rubricScore.score
              : 'N/A'}
          </RubricScore>
        </ScoreWrapper>
        <DocumentItem>
          <Text fontWeight="400">
            Document: <Text fontWeight="700">{fileName ?? ''}</Text>
          </Text>
          <EditButton onClick={() => openPdf(1)}>
            <EditIcon width={16} height={16} /> View
          </EditButton>
        </DocumentItem>
        <ContainsAnswerWrapper isMobile={isMobile}>
          <ContainsAnswer>
            <div>
              <CustomLabel required={false}>
                Document Contains Answer
              </CustomLabel>
              <CustomFilterSelect
                data={['select', 'true', 'false']}
                setSelected={(value) => setDocumentContainsAnswer(value)}
                selected={documentContainsAnswer}
                width={isMobile ? '100%' : '279px'}
                fontSize="16px"
                fontWeight="400"
                minHeight="40px"
                isBackground={documentContainsAnswer !== 'select'}
              />
            </div>
            {documentContainsAnswer === 'true' && (
              <div>
                <CustomLabel required={false}>Page Numbers</CustomLabel>
                <Input
                  isBackground={!!pageNumbers}
                  width={isMobile ? '100%' : '279px'}
                  type="text"
                  value={pageNumbers}
                  onChange={(event) => setPageNumbers(event.target.value)}
                />
              </div>
            )}
          </ContainsAnswer>
          {documentContainsAnswer === 'true' && (
            <GroundtruthAnswer>
              <CustomLabel required>Groundtruth Answer</CustomLabel>
              <CustomStyledTextarea
                height="125px"
                value={groundtruthAnswer}
                onChange={(event) => setGroundtruthAnswer(event.target.value)}
                isBackground={!!groundtruthAnswer}
              />
            </GroundtruthAnswer>
          )}
        </ContainsAnswerWrapper>
        <NoteWrapper>
          <CustomLabel required={false}>Notes</CustomLabel>
          <Input
            isBackground={!!notes}
            width="100%"
            type="text"
            value={notes}
            onChange={(event) => setNotes(event.target.value)}
          />
        </NoteWrapper>
      </AIAnswerWrapper>
      <TableWrapper>
        {isMobile ? (
          <MobileTable
            rows={tableRows}
            changeField={changeField}
            openPdf={openPdf}
          />
        ) : (
          <Table rows={tableRows} changeField={changeField} openPdf={openPdf} />
        )}
      </TableWrapper>
      <ButtonWrapper>
        <CancelButton isMobile={isMobile} onClick={onClose} disabled={false}>
          Cancel
        </CancelButton>
        <SaveButton
          isMobile={isMobile}
          onClick={saveLabelerToolData}
          disabled={false}
        >
          Save & Continue
        </SaveButton>
      </ButtonWrapper>
    </LabelerToolWrapper>
  );
};

const LabelerToolWrapper = styled.div`
  font-size: 16px;
  font-weight: 400;
  line-height: 22.4px;
  color: #373f4e;
`;
const AIAnswerWrapper = styled.div`
  margin: 50px 40px;
`;
const AiAnswerHeader = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 4px 0;
`;

const EditButton = styled.div`
  color: #4587ec;
  cursor: pointer;
  font-weight: 600;
  display: flex;
  align-items: center;
  gap: 4px;
`;

const AiAnswer = styled.div`
  text-align: justify;
`;

const ScoreWrapper = styled.div<{ isMobile: boolean }>`
  padding: 20px 0;
  border-bottom: 1px solid #c7cbd2;
  display: flex;
  flex-direction: ${({ isMobile }) => (isMobile ? 'column' : 'row')};
  gap: 30px;
`;
const HumanScore = styled.div``;
const RubricScore = styled.div<{ isMobile: boolean }>`
  display: flex;
  flex-direction: column;
  gap: 10px;
  order: ${({ isMobile }) => (isMobile ? '-1' : '1')};
  font-size: 20px;
`;

const CustomFilterSelect = styled(FilterSelect)<{ isBackground?: boolean }>`
  border-radius: 5px;
  border: 1px solid #c7cbd2;
  border: ${({ isBackground }) =>
    isBackground ? '1px solid #3dbd4a' : '1px solid #C7CBD2'};
  background-color: ${({ isBackground }) =>
    isBackground ? '#efffea' : '#FFFFFF'};

  #dropDownSelect {
    margin-top: 10px;
  }
`;

const CustomLabel = styled(Label)`
  font-size: 16px;
  display: flex;
  gap: 6px;
  padding-bottom: 4px;
`;

const DocumentItem = styled.div`
  display: flex;
  gap: 6px;
  margin-top: 20px;
`;

const Text = styled.span<{ fontWeight: string }>`
  font-weight: ${({ fontWeight }) => fontWeight};
`;

const ContainsAnswerWrapper = styled.div<{ isMobile: boolean }>`
  margin-top: 30px;
  display: flex;
  flex-direction: ${({ isMobile }) => (isMobile ? 'column' : 'row')};
  gap: 30px;
`;
const GroundtruthAnswer = styled.div`
  width: 100%;
`;

const ContainsAnswer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
`;

const Input = styled.input<{ width: string; isBackground: boolean }>`
  width: ${({ width }) => width};
  border-radius: 4px;
  border: ${({ isBackground }) =>
    isBackground ? '1px solid #3dbd4a' : '1px solid #c7cbd2'};
  background-color: ${({ isBackground }) =>
    isBackground ? '#efffea' : '#FFFFFF'};

  padding: 10px;
  font-size: 16px;
  font-family: Blinker;
  font-weight: 400;

  &:focus {
    outline: none;
  }
`;

const CustomStyledTextarea = styled(StyledTextarea)`
  border-radius: 4px;
`;

const NoteWrapper = styled.div`
  margin-top: 15px;
`;

const TableWrapper = styled.div`
  margin: 40px;
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  margin: 0 40px;
`;

const CancelButton = styled(Button)<{ isMobile: boolean }>`
  background: white;
  border: 1px solid #4587ec;
  color: #4587ec;
  font-size: 19px;
  font-weight: 400;
  padding: ${({ isMobile }) => (isMobile ? '10px 40.5px' : '13px 82px')};
`;
const SaveButton = styled(Button)<{ isMobile: boolean }>`
  background: ${({ disabled }) => (disabled ? '#dedede' : '#1c488a')};
  padding: ${({ isMobile }) => (isMobile ? '10px 30px' : '13px 41px')};
  font-size: 19px;
  font-weight: 400;
`;
