import styled from 'styled-components';

interface SectionProps {
  title: string;
  children: React.ReactNode;
  id?: string;
}

export const Section = ({ title, children, id }: SectionProps) => {
  return (
    <SectionWrap id={id}>
      <TitleWrapper>
        <Title>{title}</Title>
      </TitleWrapper>
      <BodyContainer>{children}</BodyContainer>
    </SectionWrap>
  );
};

const SectionWrap = styled.div`
  background-color: ${({ theme }) => theme.layer.base};
`;

const TitleWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  background: transparent;
  border-radius: 10px 10px 0 0;
  padding: 17px 30px;
  border-left: 1px solid ${({ theme }) => theme.border.base};
  border-top: 1px solid ${({ theme }) => theme.border.base};
  border-right: 1px solid ${({ theme }) => theme.border.base};
`;

const BodyContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  gap: 20px;
  padding: 60px 40px 60px;
  border: 1px solid ${({ theme }) => theme.border.base};
  border-radius: 0px 0px 5px 5px;
  background-color: ${({ theme }) => theme.layer[1]};
`;

const Title = styled.span`
  font-family: Lato;
  font-style: normal;
  font-weight: 700;
  font-size: 28px;
  color: ${({ theme }) => theme.font.strong};
  padding: 10px 0 5px 0;
  margin-left: 10px;
`;
