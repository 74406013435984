import { Loader } from '@/components/loaders/loader';
import DropdownActions from '@/components/table/fat-dropdownActions';
import TableHeader from '@/components/table/fat-tableHeader';
import NoResults from '@/components/table/noResults';
import { TableBodyWrapper, TableRowCell, TableRowWrapper, TableWrapper, TextCell } from '@/components/table/styles';
import { IItems, ISort } from '@/components/table/types';
import { useResponsive } from '@/hooks/use-responsive';
import { dateFormatter } from '@/utils/dateFormatter';
import { format } from 'date-fns';
import styled from 'styled-components';
import { updatesTableColumns } from '../../../constatnts';
import { DOCUMENT_RECORD_STATUS_ENUM, IDocumentRecordEntity } from '../../types';

type TableProp = {
  tableData: IDocumentRecordEntity[];
  sort: ISort;
  setSort: (value: ISort) => void;
  loading: boolean;
  dropdownActions: (row: IDocumentRecordEntity) => IItems[];
};

export const Table = ({ tableData, sort, setSort, loading, dropdownActions }: TableProp) => {
  const { isTablet } = useResponsive();

  return (
    <TableWrapper padding="0">
      <CustomTableHeader isTablet={isTablet} refetch={() => {}} columns={updatesTableColumns} savedSort={sort} savedSetSort={setSort} />
      <TableBodyWrapper>
        {loading ? (
          <Loader />
        ) : (
          <>
            {!tableData.length ? (
              <NoResults />
            ) : (
              <>
                {tableData.map((row) => (
                  <TableRowWrapper key={row.id}>
                    <TableRowCell width={updatesTableColumns[0].width}>
                      <TextCell>{row.name}</TextCell>
                    </TableRowCell>
                    <TableRowCell width={updatesTableColumns[1].width}>
                      <TextCell>{`${row.owner.firstName} ${row.owner.lastName}`}</TextCell>
                    </TableRowCell>
                    <TableRowCell width={updatesTableColumns[2].width}>
                      <TextCell>{row.statusUpdatedAt && dateFormatter(format(new Date(row.statusUpdatedAt), 'yyyy-MM-dd'), 'yyyy-MM-dd')}</TextCell>
                    </TableRowCell>
                    <TableRowCell width={updatesTableColumns[3].width}>
                      <TextCell>{DOCUMENT_RECORD_STATUS_ENUM[row.status]}</TextCell>
                    </TableRowCell>
                    <TableRowCell width={updatesTableColumns[4].width}>
                      <DropdownActions items={dropdownActions(row)} />
                    </TableRowCell>
                  </TableRowWrapper>
                ))}
              </>
            )}
          </>
        )}
      </TableBodyWrapper>
    </TableWrapper>
  );
};

const CustomTableHeader = styled(TableHeader)<{ isTablet: boolean }>`
  position: sticky;
  top: ${({ isTablet }) => (isTablet ? '95px' : '45px')};
  z-index: 8;
  padding-top: 0;

  #tableColumnTitle {
    flex-basis: auto;
  }
`;
