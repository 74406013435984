import TableHeader from '@/components/table/fat-tableHeader';
import { ISort, TableColumns } from '@/components/table/types';
import { useResponsive } from '@/hooks/use-responsive';
import styled from 'styled-components';

import { Loader } from '@/components/loaders/loader';
import { TableBodyWrapper, TableWrapper } from '@/components/table/styles';
import useStickyFooter from '@/hooks/useStickyFooter';
import { useRef } from 'react';
import { IInvestorTotalRow, ILegalEntity } from '../types';
import MobileTable from './mobileTable';
import TableBody from './tableBody';
import TableSumFooter from './tableSumFooter';

interface TableProps {
  tableRows: ILegalEntity[];
  sort: ISort;
  setSort: (value: ISort) => void;
  refetch: () => void;
  loading: boolean;
  openEntityDetails: (id: string) => void;
  openEditEntityPage: (id: string) => void;
  onMobileRowClick: (data: ILegalEntity) => void;
  showSpecificColumn: boolean;
  tableColumns: TableColumns[];
  totalRowData: IInvestorTotalRow | null;
}

const Table = ({
  tableRows,
  sort,
  setSort,
  refetch,
  loading,
  openEntityDetails,
  openEditEntityPage,
  onMobileRowClick,
  showSpecificColumn,
  tableColumns,
  totalRowData
}: TableProps) => {
  const { isMobile, isTablet } = useResponsive();

  const containerRef = useRef<HTMLDivElement | null>(null);
  const footerRef = useRef<HTMLDivElement | null>(null);

  useStickyFooter(containerRef, footerRef);

  return (
    <>
      {isMobile ? (
        <>
          {loading ? (
            <Loader />
          ) : (
            <MobileTable
              tableRows={tableRows}
              openEntityDetails={openEntityDetails}
              onMobileRowClick={onMobileRowClick}
              showSpecificColumn={showSpecificColumn}
              totalRowData={totalRowData}
            />
          )}
        </>
      ) : (
        <TableWrapper padding="0">
          <CustomTableHeader isTablet={isTablet} refetch={refetch} columns={tableColumns} savedSort={sort} savedSetSort={setSort} />
          <TableBodyWrapper>
            {loading ? (
              <Loader />
            ) : (
              <>
                <TableBody
                  tableRows={tableRows}
                  openEntityDetails={openEntityDetails}
                  openEditEntityPage={openEditEntityPage}
                  showSpecificColumn={showSpecificColumn}
                  tableColumns={tableColumns}
                />
                <TableSumFooterWrapper ref={footerRef}>
                  <TableSumFooter totalRowData={totalRowData} showSpecificColumn={showSpecificColumn} tableColumns={tableColumns} />
                </TableSumFooterWrapper>
                <div ref={containerRef}></div>
              </>
            )}
          </TableBodyWrapper>
        </TableWrapper>
      )}
    </>
  );
};

export default Table;

const CustomTableHeader = styled(TableHeader)<{ isTablet: boolean }>`
  position: sticky;
  top: ${({ isTablet }) => (isTablet ? '86px' : '36px')};
  z-index: 8;
  padding-top: 0;
`;

const TableSumFooterWrapper = styled.div`
  &.sticky {
    position: sticky;
    bottom: 0;
    z-index: 9;
  }
`;
