import { getUTCDate } from '@/components/allocations-page/utils';
import DropdownActions from '@/components/table/fat-dropdownActions';
import {
  MobileRow,
  MobileRowTitle,
  MobileRowValue,
  MobileTableRowWrap,
  MobileTableWrapper,
  NoResults
} from '@/components/table/mobileTable/mobileTable';
import { useNavigate, useParams } from 'react-router-dom';
import { ICommitmentPlan } from '../../../fat-pacingTab/types';
import { columns } from '../../constatnts';

interface MobileTableProps {
  tableData: ICommitmentPlan[];
  updateLoading: boolean;
  currentRow: ICommitmentPlan | null;
  deleteCommitmentPlan: (row: ICommitmentPlan) => void;
  makeActivePlan: (row: ICommitmentPlan) => void;
}

const MobileTable = ({ tableData, updateLoading, currentRow, deleteCommitmentPlan, makeActivePlan }: MobileTableProps) => {
  const params = useParams();
  const navigate = useNavigate();

  const TableRow = ({ el }: { el: ICommitmentPlan }) => {
    return (
      <MobileTableRowWrap key={el.id} loading={updateLoading && el.id === currentRow?.id ? 1 : 0}>
        <MobileRow>
          <MobileRowTitle>{columns[0].title}</MobileRowTitle>
          <MobileRowValue>{el.name}</MobileRowValue>
        </MobileRow>

        <MobileRow>
          <MobileRowTitle>{columns[1].title}</MobileRowTitle>
          <MobileRowValue>{getUTCDate(new Date(el.startDate))}</MobileRowValue>
        </MobileRow>

        <MobileRow>
          <MobileRowTitle>{columns[2].title}</MobileRowTitle>
          <MobileRowValue>{el?.growthRate && `${el.growthRate.toFixed(2)}%`}</MobileRowValue>
        </MobileRow>

        <MobileRow>
          <MobileRowValue>
            <DropdownActions
              isMobile
              isListCenter
              items={[
                {
                  name: 'Edit Plan',
                  onClick: () =>
                    navigate(
                      `/investors/${params.investorId}/${params.entityId}/editCommitmentPlan?commitmentPlanId=${el.id}&backToTitle=All Commitment Plans`
                    )
                },
                {
                  name: 'View Forecast',
                  onClick: () =>
                    navigate(
                      `/investors/${params.investorId}/${params.entityId}?backToTitle=All Commitment Plans&tab=Pacing&commitmentPlanId=${el.id}`
                    )
                },
                {
                  name: 'Make Active Plan',
                  onClick: () => makeActivePlan(el)
                },
                {
                  name: 'Duplicate Plan',
                  onClick: () =>
                    navigate(
                      `/investors/${params.investorId}/${params.entityId}/editCommitmentPlan?commitmentPlanId=${el.id}&duplicate=true&backToTitle=All Commitment Plans`
                    )
                },
                {
                  name: 'Delete Plan',
                  onClick: () => deleteCommitmentPlan(el)
                }
              ]}
              disabled={updateLoading && el.id === currentRow?.id}
            />
          </MobileRowValue>
        </MobileRow>
      </MobileTableRowWrap>
    );
  };

  return (
    <MobileTableWrapper>
      {tableData.length ? (
        <>
          {tableData.map((el) => {
            return <TableRow el={el} key={el.id} />;
          })}
        </>
      ) : (
        <NoResults>No Results</NoResults>
      )}
    </MobileTableWrapper>
  );
};

export default MobileTable;
