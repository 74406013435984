import { Image as PdfImage, StyleSheet, Text, View } from '@react-pdf/renderer';
import { ISetting } from '@/components/settings-page/settings-pages/firmSettings/types';
import { ISection } from '../dueDiligenceRecord/types';
import { AiIcon } from './AiIcon';
import { templateStyles } from './PDFTemplate';
import { SectionScore } from './sectionScore';
import {
  aiFieldValue,
  fieldValue,
  getFontByKey,
  getTermFontWeight,
  matchFieldWithAI
} from './utils';

interface TwoColumnSectionProps {
  section: ISection;
  templateStylingData: ISetting[];
  includeAiSuggested: boolean;
}

export const TwoColumnSection = ({
  section,
  templateStylingData,
  includeAiSuggested
}: TwoColumnSectionProps) => {
  const styles = StyleSheet.create({
    sectionName: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      fontSize: 20,
      fontFamily: getFontByKey(templateStylingData, 'headerFontStyle')
        .fontFamily
        ? getFontByKey(templateStylingData, 'headerFontStyle').fontFamily
        : 'Roboto Slab',
      color: Boolean(
        getFontByKey(templateStylingData, 'headerFontStyle').fontColor
      )
        ? `#${getFontByKey(templateStylingData, 'headerFontStyle').fontColor}`
        : '#35739C',
      fontWeight: getFontByKey(templateStylingData, 'headerFontStyle')
        .fontWeight
        ? getFontByKey(templateStylingData, 'headerFontStyle').fontWeight
        : 400,
      paddingBottom: '4px',
      borderBottom: '2px solid #F0F1F3',
      marginBottom: '10px'
    },
    aiIconWrap: {
      display: 'flex',
      flexDirection: 'row',
      fontSize: '7px',
      fontFamily: getFontByKey(templateStylingData, 'bodyFontStyle').fontFamily
        ? getFontByKey(templateStylingData, 'bodyFontStyle').fontFamily
        : 'Open Sans',
      color: Boolean(
        getFontByKey(templateStylingData, 'bodyFontStyle').fontColor
      )
        ? `#${getFontByKey(templateStylingData, 'bodyFontStyle').fontColor}`
        : '#757575',
      fontWeight: getTermFontWeight(
        templateStylingData,
        'bodyFontStyle'
      ) as number
    }
  });

  return (
    <View break={true} style={{ marginBottom: '30px' }}>
      <View style={styles.sectionName}>
        <Text>{section.name}</Text>
        {section.userCanScore && (
          <SectionScore
            score={section.data.score}
            templateStylingData={templateStylingData}
          />
        )}
      </View>
      <View style={templateStyles.swotWrapper}>
        {section.fields.map((field, index) => (
          <View
            key={field.data.id}
            style={{
              ...templateStyles.swotItem,
              backgroundColor: (index + 1) % 3 === 1 ? '#F4F5F6' : '#EBF0F4'
            }}
            break={Boolean(
              ((index + 1) % 2 === 1 &&
                field.data.value &&
                field.data.value.length > 1300) ||
                (field.AI?.answers.length && field.AI?.answers[0].length > 1300)
            )}
          >
            <Text style={templateStyles.swotHeader}>
              {!matchFieldWithAI(field) ||
              !field.AI?.answers.length ||
              !includeAiSuggested
                ? field.name
                : `* ${field.name}`}
              :
            </Text>
            {matchFieldWithAI(field) ? (
              <>
                {field.AI?.answers.length && includeAiSuggested ? (
                  <Text>{aiFieldValue(field.type, field.AI.answers[0])}</Text>
                ) : (
                  <Text> Not specified</Text>
                )}
              </>
            ) : (
              <Text>
                {' '}
                {fieldValue(
                  field.type,
                  field.data.value,
                  field.data.commentary
                )}
              </Text>
            )}

            {/* <Text style={templateStyles.swotHeader}>
              {(field.data.value && field.data.value !== field.AI?.answers[0]) || !field.AI?.answers.length || !includeAiSuggested
                ? field.name
                : `* ${field.name}`}
            </Text>
            {Boolean(!field.data.value && !field.data.commentary) ? (
              <Text>{field.AI?.answers.length && includeAiSuggested ? field.AI?.answers[0] : ' Not specified'}</Text>
            ) : (
              <Text> {field.data.value}</Text>
            )} */}
            <View style={templateStyles.previewWrapper}>
              {field.data.attachments.map((attachment) => {
                return (
                  attachment.includeInAutomatedContent && (
                    <View
                      key={attachment.id}
                      style={{
                        ...templateStyles.swotPreviewWrap
                      }}
                    >
                      <PdfImage
                        style={{
                          padding: '2px',
                          border: '1px solid #F0F1F3'
                        }}
                        src={attachment.asset.url}
                      />
                    </View>
                  )
                );
              })}
            </View>
          </View>
        ))}
      </View>
      {section.fields.filter(
        (field) =>
          (!field.data.value || field.data.value === field.AI?.answers[0]) &&
          field.AI?.answers.length
      ).length &&
        includeAiSuggested && (
          <View style={styles.aiIconWrap}>
            <View
              style={{ borderRight: '1px solid #C7CBD2', paddingRight: '3px' }}
            >
              <AiIcon />
            </View>
            <Text style={{ margin: '2px 0 0 5px' }}>
              {section.fields.length > 1
                ? '* These fields are AI Suggested'
                : 'This field is AI Suggested'}
            </Text>
          </View>
        )}
    </View>
  );
};
