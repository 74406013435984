import { RefreshIcon } from '@/assets/icons/info-icons/refresh';
import { RefreshButton, Results, TopWrapper, ViewBySelectSelect, ViewByText } from '@/components/table/fat-filterPage/desktop-filter';
import { TableColumns } from '@/components/table/types';
import { useEffect, useState } from 'react';
import styled, { useTheme } from 'styled-components';
import { Section } from '../../../edit-entity/section';
import { AssetClassForecastValueDto, CommitmentForecastValueDto, TransformedAssetClassForecastValueDto } from '../../types';
import { getUniqueDates, groupAssetClasses } from '../../utils';
import AssetClassTable from './assetClassTable/table';
import CommitmentTable from './commitmentTable/table';

const VIEW_BY_SELECTS = ['Asset Class', 'Existing Commitments'];

interface CashFlowForecastSectionProps {
  assetClassContributions: AssetClassForecastValueDto[];
  assetClassDistributions: AssetClassForecastValueDto[];
  existingCommitmentContributions: CommitmentForecastValueDto[];
  existingCommitmentDistributions: CommitmentForecastValueDto[];
  targetSubAssetClasses: boolean;
}

export const CashFlowForecastSection = ({
  assetClassContributions,
  assetClassDistributions,
  existingCommitmentContributions,
  existingCommitmentDistributions,
  targetSubAssetClasses
}: CashFlowForecastSectionProps) => {
  const theme = useTheme();

  const [viewBySelect, setViewBySelect] = useState('Asset Class');
  const [tableHeaderColumns, setTableHeaderColumns] = useState<TableColumns[]>([]);
  const [contributionsTableData, setContributionsTableData] = useState<TransformedAssetClassForecastValueDto[]>([]);
  const [distributionsTableData, setDistributionsTableData] = useState<TransformedAssetClassForecastValueDto[]>([]);
  const [columnWidth, setColumnWidth] = useState(0);

  useEffect(() => {
    if (assetClassContributions.length) {
      const tableColumns = getUniqueDates(assetClassContributions);
      const tableColumnsWithWidth = tableColumns.map((column) => ({ ...column, width: 85 / tableColumns.length }));
      setColumnWidth(85 / tableColumns.length);
      setTableHeaderColumns([{ title: 'Asset Class', key: null, width: 15, keySort: 'deal' }, ...tableColumnsWithWidth]);
      return;
    }
    if (assetClassDistributions.length) {
      const tableColumns = getUniqueDates(assetClassDistributions);
      const tableColumnsWithWidth = tableColumns.map((column) => ({ ...column, width: 85 / tableColumns.length }));
      setColumnWidth(85 / tableColumns.length);
      setTableHeaderColumns([{ title: 'Asset Class', key: null, width: 15, keySort: 'deal' }, ...tableColumnsWithWidth]);
      return;
    }
  }, [assetClassContributions, assetClassDistributions]);

  useEffect(() => {
    if (!assetClassContributions.length) return;

    const transformedData = groupAssetClasses(assetClassContributions);
    setContributionsTableData(transformedData);
  }, [assetClassContributions]);

  useEffect(() => {
    if (!assetClassDistributions.length) return;

    const transformedData = groupAssetClasses(assetClassDistributions);
    setDistributionsTableData(transformedData);
  }, [assetClassDistributions]);

  const onChangeViewBySelect = (value: string) => {
    setViewBySelect(value);
    const updatedColumns = [...tableHeaderColumns];
    if (updatedColumns.length > 0) {
      updatedColumns[0] = {
        ...updatedColumns[0],
        title: value === 'Asset Class' ? 'Asset Class' : 'Commitment'
      };
    }
    setTableHeaderColumns(updatedColumns);
  };

  return (
    <Section id="cashFlowForecast" title="Cash Flow Forecast">
      <TableWrapper>
        <TopWrapper>
          {viewBySelect === 'Existing Commitments' && (
            <>
              <Results>{existingCommitmentContributions.length + existingCommitmentDistributions.length} Results</Results>
              <RefreshButton onClick={() => {}}>
                <RefreshIcon width={15} height={15} fill={theme.action.primary} />
              </RefreshButton>
            </>
          )}
          <WrapperSelector padding={viewBySelect === 'Existing Commitments' ? '5px 10px 5px 30px' : '5px 0'}>
            <ViewByText>View By</ViewByText>
            <ViewBySelectSelect width={'auto'} selected={viewBySelect} setSelected={onChangeViewBySelect} data={VIEW_BY_SELECTS} />
          </WrapperSelector>
        </TopWrapper>
        {viewBySelect === 'Asset Class' && (
          <AssetClassTable
            contributionsTableData={contributionsTableData}
            distributionsTableData={distributionsTableData}
            tableHeaderColumns={tableHeaderColumns}
            loading={false}
            columnWidth={columnWidth}
            targetSubAssetClasses={targetSubAssetClasses}
          />
        )}
        {viewBySelect === 'Existing Commitments' && (
          <CommitmentTable
            contributionsTableData={existingCommitmentContributions}
            distributionsTableData={existingCommitmentDistributions}
            tableHeaderColumns={tableHeaderColumns}
            loading={false}
            columnWidth={columnWidth}
          />
        )}
      </TableWrapper>
    </Section>
  );
};

const TableWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const WrapperSelector = styled.div<{ padding: string }>`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: ${({ padding }) => padding};
  gap: 6px;
`;
