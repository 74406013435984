import client from '@/apollo-client';
import { ArrowDown } from '@/assets/icons/info-icons/arrowDown';
import { AiAssistantIcon } from '@/assets/static/icons/ai-assistant';
import { DocumentIcon } from '@/assets/static/icons/document';
import { ArrowUp } from '@/assets/static/icons/todo-page/arrow-up';
import Button from '@/components/basicComponents/button';
import FilterSearch from '@/components/basicComponents/input';
import FilterSelect from '@/components/basicComponents/select';
import Header from '@/components/fat-header';
import { GoBackButton } from '@/components/fat-header/goBackButton';
import { PageTitle } from '@/components/fat-header/pageTitle';
import { Loader } from '@/components/loaders/loader';
import { GET_SETTINGS } from '@/components/settings-page/queries';
import { useResponsive } from '@/hooks/use-responsive';
import { MainWrap, PaddingWrap } from '@/styles/common';
import { useMutation, useQuery } from '@apollo/client';
import { format } from 'date-fns';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { User } from 'src/types/user';
import styled from 'styled-components';
import AiSuggestions from '../../modals/aiSuggestions';
import FileNotSupported from '../../modals/fileNotSupported';
import {
  GET_AI_ASSETS,
  GET_AI_RUN_STATUS,
  GET_URL_FOR_AI_ASSET_UPLOAD,
  createDealAssistantAIAsset,
  generateAnswersQuery,
  processDealAssistantAIAsset
} from '../../queries';
import { IRecordData } from '../../types';
import { FileSelector } from '../ai-pane/fileSelector';
import { SuccessToast } from '../ai-pane/successToast';
import { DropFileArea } from '../dropFileArea';
import { IFile } from '../index';

interface AdvancedOptionsProps {
  handleClose: () => void;
  handleGenerateAnswersLoadingChange: (loadingStatus: boolean, showCompleteIcon: boolean) => void;
  files: IFile[];
  setFiles: Dispatch<SetStateAction<IFile[]>>;
  dueDiligenceRecord: IRecordData;
  dueDiligenceRecordsRefetch: any;
  generateAnswersDealRegistry: string[] | undefined;
}

interface AiRunStatus {
  filenames: string[];
  id: string;
  investment: {
    name: string;
  };
  startedAt: Date;
  startedBy: User;
  completedAt: Date;
  status: string;
}

const fileExtensions = ['PDF'];
const processingOptions = ['Text only', 'Text with enhanced table processing', 'Full OCR (required for all image documents)'];

const llms = [
  'openai:gpt-4-turbo-preview',
  'openai:gpt-3.5-turbo-0125',
  'anthropic:claude-3-opus-20240229',
  'anthropic:claude-3-sonnet-20240229',
  'anthropic:claude-3-haiku-20240307'
];

const embeddingModels = ['openai:text-embedding-ada-002', 'openai:text-embedding-3-small', 'openai:text-embedding-3-large'];

const defaultValues = [
  {
    key: 'retrieveModel',
    label: 'Retrieve Model',
    value: 'openai:gpt-4-turbo-preview',
    required: true,
    data: llms,
    type: 'drop-down'
  },
  {
    key: 'summarizeModel',
    label: 'Summarize Model',
    value: 'openai:gpt-4-turbo-preview',
    required: true,
    data: llms,
    type: 'drop-down'
  },
  {
    key: 'embeddingModel',
    label: 'Embedding Model',
    value: 'openai:text-embedding-ada-002',
    required: true,
    data: embeddingModels,
    type: 'drop-down'
  },
  {
    key: 'maximumEmbeddings',
    label: 'Maximum Embeddings to Retrieve per Field',
    value: '10',
    required: true,
    type: 'input'
  },
  {
    key: 'scoringModel',
    label: 'Scoring Model',
    value: 'Do not score',
    required: true,
    data: llms.concat(['Do not score']),
    type: 'drop-down'
  }
];

export const AdvancedOptions = ({
  handleClose,
  handleGenerateAnswersLoadingChange,
  files,
  setFiles,
  dueDiligenceRecordsRefetch,
  generateAnswersDealRegistry,
  dueDiligenceRecord
}: AdvancedOptionsProps) => {
  const { isMobile, isTablet } = useResponsive();
  const [drag, setDrag] = useState(false);
  const [aiRunStatus, setAiRunStatus] = useState<AiRunStatus | null>(null);
  const [advancedOptions, setAdvancedOptions] = useState(defaultValues);
  const [showSuccessBanner, setShowSuccessBanner] = useState(false);
  const [selectedFile, setSelectedFile] = useState('');
  const [isStatusDropDownOpen, setIsStatusDropDownOpen] = useState(true);
  const [showDragonDrop, setShowDragonDrop] = useState(false);
  const [selectedProcessingOption, setSelectedProcessingOption] = useState('Select');
  const [filesForAIAssistant, setFilesForAIAssistant] = useState<IFile[]>([]);
  const [modalWindow, setModalWindow] = useState({ isOpen: false, type: 'ai-suggestions' });

  const [createAIAsset] = useMutation(createDealAssistantAIAsset);
  const [processAIAsset] = useMutation(processDealAssistantAIAsset);

  const [generateAnswers, { data: generateAnswersData, loading: generateAnswersLoading, error: generateAnswersError }] =
    useMutation(generateAnswersQuery);

  const [unstructuredAvailablity, setUnstructuredAvailability] = useState({ useUnstructured: false });

  useQuery(GET_SETTINGS, {
    variables: {
      type: 'dealAssistantAiConfig'
    },
    onCompleted: (data) => {
      const { useUnstructured } = JSON.parse(data.Settings[0].value);
      setUnstructuredAvailability({ useUnstructured });
      setSelectedProcessingOption(useUnstructured ? 'Select' : 'Text only');
    }
  });

  const { data, refetch } = useQuery(GET_AI_ASSETS, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
    variables: {
      data: {
        investmentId: dueDiligenceRecord?.investment.id
      }
    },
    onCompleted: (data) => {
      setFiles(
        data.getDealAssistentAIAssets.map((file: any) => ({
          ...file,
          name: file.filename
        }))
      );
      setFilesForAIAssistant(
        data.getDealAssistentAIAssets.map((file: any) => ({
          ...file,
          name: file.filename
        }))
      );
    }
  });

  const uploadFile = async (file: File, processingOption?: String) => {
    const { data: aiAssetData } = await client.query({
      query: GET_URL_FOR_AI_ASSET_UPLOAD,
      variables: {
        data: {
          contentType: file.type,
          type: 'ddr',
          investmentId: dueDiligenceRecord.investment.id,
          filename: file.name
        }
      }
    });

    await fetch(aiAssetData.getDealAssistantAIAssetUploadUrl.url, {
      method: 'PUT',
      body: file,
      headers: {
        'Content-Type': file.type
      }
    });

    const response = await createAIAsset({
      variables: {
        data: {
          investmentId: dueDiligenceRecord.investment.id,
          assetKey: aiAssetData.getDealAssistantAIAssetUploadUrl.assetKey,
          status: 'CREATED',
          type: 'ddr',
          filename: file.name
        }
      }
    });

    const processingResponse = await processAIAsset({
      variables: {
        data: {
          investmentId: dueDiligenceRecord.investment.id,
          filename: file.name,
          type: 'ddr',
          assetKey: aiAssetData.getDealAssistantAIAssetUploadUrl.assetKey,
          processingOption: processingOption
        }
      }
    });
    refetch();
  };

  const { data: getAIRunStatusData, refetch: getAIRunStatusRefetch } = useQuery(GET_AI_RUN_STATUS, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
    variables: {
      investmentId: dueDiligenceRecord?.investment.id
    },
    onCompleted: (data) => {
      setAiRunStatus(data.getAIRunStatus);
      if (data.getAIRunStatus.status === 'Completed') {
        setShowSuccessBanner(true);
        dueDiligenceRecordsRefetch();
      }
      setSelectedFile(data.getAIRunStatus.filenames[0]);
    }
  });

  useEffect(() => {
    if (generateAnswersDealRegistry.length) {
      generateAnswers({
        variables: {
          data: {
            funnelId: dueDiligenceRecord.funnel.id,
            investmentId: dueDiligenceRecord.investment.id,
            recordId: dueDiligenceRecord?.id,
            type: 'ddr',
            filenames: generateAnswersDealRegistry
          }
        },
        onCompleted: () => {
          setShowSuccessBanner(true);
          getAIRunStatusRefetch();
          dueDiligenceRecordsRefetch();
        }
      });
      setTimeout(() => {
        getAIRunStatusRefetch();
      }, 1000);
    }
  }, []);

  useEffect(() => {
    handleGenerateAnswersLoadingChange(generateAnswersLoading || aiRunStatus?.status === 'In Progress', showSuccessBanner);
  }, [generateAnswersLoading, handleGenerateAnswersLoadingChange, showSuccessBanner]);

  const handleGenerateAnswers = () => {
    setModalWindow({ type: 'ai-suggestions', isOpen: false });
    generateAnswers({
      variables: {
        data: {
          funnelId: dueDiligenceRecord.funnel.id,
          investmentId: dueDiligenceRecord.investment.id,
          recordId: dueDiligenceRecord?.id,
          type: 'ddr',
          filenames: [selectedFile],
          numEmbeddings: Number(advancedOptions.find((option) => option.key === 'maximumEmbeddings')?.value),
          answerModel: advancedOptions.find((option) => option.key === 'retrieveModel')?.value,
          summaryModel: advancedOptions.find((option) => option.key === 'summarizeModel')?.value,
          embeddingModel: advancedOptions.find((option) => option.key === 'embeddingModel')?.value,
          scoreModel: advancedOptions.find((option) => option.key === 'scoringModel')?.value,
          generateScores: advancedOptions.find((option) => option.key === 'scoringModel')?.value !== 'Do not score'
        }
      },
      onCompleted: () => {
        setShowSuccessBanner(true);
        getAIRunStatusRefetch();
        dueDiligenceRecordsRefetch();
        refetch();
      }
    });
    setTimeout(() => {
      getAIRunStatusRefetch();
    }, 1000);
  };

  const allowFileExtensions = (files: FileList | null) => {
    return Array.from(files || []).filter((file: File) => {
      const fileExt = file.name.split('.').pop()?.toLowerCase();
      if ([...fileExtensions.map((item) => item.toLowerCase())].includes(fileExt || '')) {
        return file;
      }
    });
  };

  const onDropHandler = async (e: React.DragEvent<HTMLDivElement>, selectedProcessingOption?: string) => {
    e.preventDefault();
    const uploadFiles = e.dataTransfer.files;

    setDrag(false);
    setShowDragonDrop(false);
    if (!allowFileExtensions(uploadFiles).length) {
      setModalWindow({ isOpen: true, type: 'not-supported' });
      return;
    }
    if (uploadFiles && uploadFiles.length > 0) {
      setFiles([...files, { ...uploadFiles[0], name: uploadFiles[0].name, loading: true }]);
      setFilesForAIAssistant([...files, { ...uploadFiles[0], name: uploadFiles[0].name, loading: true }]);
      await uploadFile(uploadFiles[0], selectedProcessingOption);
    }
  };

  const handleUploadFile = async (e: React.ChangeEvent<HTMLInputElement>, selectedProcessingOption?: string) => {
    e.preventDefault();
    const uploadFiles = e.target.files;

    setShowDragonDrop(false);
    if (!allowFileExtensions(uploadFiles).length) {
      setModalWindow({ isOpen: true, type: 'not-supported' });
      return;
    }
    if (uploadFiles && uploadFiles.length > 0) {
      setFiles([...files, { ...uploadFiles[0], name: uploadFiles[0].name, loading: true }]);
      setFilesForAIAssistant([...files, { ...uploadFiles[0], name: uploadFiles[0].name, loading: true }]);
      await uploadFile(uploadFiles[0], selectedProcessingOption);
    }
  };

  const handleChange = (key: string, value: string) => {
    setAdvancedOptions((prevOptions) => prevOptions.map((option) => (option.key === key ? { ...option, value } : option)));
  };

  return (
    <>
      {modalWindow.isOpen && modalWindow.type === 'ai-suggestions' && (
        <AiSuggestions
          onClose={() => setModalWindow({ type: 'ai-suggestions', isOpen: false })}
          isOpen={modalWindow.isOpen}
          files={files}
          setSelectedFile={setSelectedFile}
          selectedFile={selectedFile}
          handleGenerateAnswers={handleGenerateAnswers}
        />
      )}
      {modalWindow.type === 'not-supported' && (
        <FileNotSupported isOpen={modalWindow.isOpen} onClose={() => setModalWindow({ ...modalWindow, isOpen: false })} />
      )}
      <MainWrap>
        <Header modalControl={<GoBackButton handleClose={handleClose} backToTitle={'AI Assistant'} />} />
        <PageTitle title={'Suggest Answers'} />
        <PaddingWrap>
          <DropdownWrapper>
            <DropdownHeader isMobile={isMobile}>
              <Title isMobile={isMobile}>Parameters</Title>
            </DropdownHeader>
            <DropdownBody isMobile={isMobile}>
              {advancedOptions.map((option) => (
                <div key={option.key}>
                  {option.type === 'drop-down' ? (
                    <CustomSelect
                      isBackground={!!option.value}
                      data={option?.data ?? []}
                      selected={option.value}
                      setSelected={(value) => handleChange(option.key, value)}
                      width="100%"
                      label={option.label}
                      required={option.required}
                    />
                  ) : (
                    <CustomInput
                      onChange={(value) => handleChange(option.key, value)}
                      value={option.value}
                      width="100%"
                      label={option.label}
                      required={option.required}
                      showSearchBtn={false}
                    />
                  )}
                </div>
              ))}
            </DropdownBody>
            {files.length ? (
              <>
                {showSuccessBanner && <SuccessToast setShowSuccessBanner={setShowSuccessBanner} numberFields={6} />}
                <AiPaneBodyWrapper>
                  {aiRunStatus && aiRunStatus.status === 'Completed' && (
                    <>
                      <SelectedDocument withBorderBottom onClick={() => setIsStatusDropDownOpen(!isStatusDropDownOpen)}>
                        <div>
                          Suggestions last generated:
                          <BoldText> {` ${format(new Date(aiRunStatus.completedAt), 'LLL d, yyyy')}`}</BoldText> by
                          <BoldText>{` ${aiRunStatus?.startedBy?.firstName} ${aiRunStatus?.startedBy?.lastName}`}</BoldText>
                        </div>
                        {isStatusDropDownOpen ? (
                          <ArrowUp fill={'#4587EC'} />
                        ) : (
                          <ArrowDown style={{ width: '28px', height: '28px', padding: '7px' }} fill={'#4587EC'} />
                        )}
                      </SelectedDocument>
                      {isStatusDropDownOpen && (
                        <UploadedDocumnts>
                          {aiRunStatus.filenames.map((file, index) => (
                            <UploadedDocumentItem key={file + index} id={'input'}>
                              <CheckBoxWrap>
                                <DocumentIcon fill="#7F7F7F" />
                                {file}
                              </CheckBoxWrap>
                            </UploadedDocumentItem>
                          ))}
                        </UploadedDocumnts>
                      )}
                    </>
                  )}
                  {aiRunStatus && aiRunStatus.status === 'In Progress' && (
                    <>
                      <SelectedDocument>
                        <div>
                          Suggestions generation in progress:
                          <BoldText> {` ${format(new Date(aiRunStatus.startedAt), 'LLL d, yyyy')}`}</BoldText> by
                          <BoldText>{` ${aiRunStatus?.startedBy?.firstName} ${aiRunStatus?.startedBy?.lastName}`}</BoldText>
                        </div>
                      </SelectedDocument>
                      <TextDescription>This processing time will vary based on the size and number of documents that were selected.</TextDescription>
                    </>
                  )}
                  <FileSelector
                    generateAnswersLoading={generateAnswersLoading}
                    files={files}
                    setSelectedFile={setSelectedFile}
                    selectedFile={selectedFile}
                    showRadioButton={!modalWindow.isOpen}
                    showDragonDrop={showDragonDrop}
                    setShowDragonDrop={setShowDragonDrop}
                    handleUploadFile={handleUploadFile}
                    onDropHandler={onDropHandler}
                    drag={drag}
                    setDrag={setDrag}
                    trialMode={false}
                  />
                  <SuggestionButton
                    onClick={() => setModalWindow({ isOpen: true, type: 'ai-suggestions' })}
                    disabled={
                      !selectedFile ||
                      generateAnswersLoading ||
                      aiRunStatus?.status === 'In Progress' ||
                      Boolean(aiRunStatus?.filenames.length && false)
                    }
                  >
                    {generateAnswersLoading || aiRunStatus?.status === 'In Progress' ? (
                      <>
                        <CustomLoader size={28} />
                        Suggesting Answers For Selected Documents
                      </>
                    ) : (
                      <>
                        <AiAssistantIcon
                          width={28}
                          height={28}
                          fill={!selectedFile || Boolean(aiRunStatus?.filenames.length && false) ? '#ABABAB' : '#FAFAFA'}
                        />
                        Suggest Answers
                      </>
                    )}
                  </SuggestionButton>
                </AiPaneBodyWrapper>
              </>
            ) : (
              <AiPaneBodyWrapper>
                <UploadText>Please upload source docs before using the AI Assistant.</UploadText>
                <UploadedFilesWrapper>
                  <CountFiles>0</CountFiles>
                  <DocumentText>Documents</DocumentText>
                </UploadedFilesWrapper>
                {unstructuredAvailablity.useUnstructured && (
                  <ProcessingSelectWrap>
                    <Label>Select a document processing option:</Label>
                    <CustomProcessingSelect
                      data={processingOptions}
                      selected={selectedProcessingOption}
                      setSelected={setSelectedProcessingOption}
                      width="100%"
                    />
                  </ProcessingSelectWrap>
                )}
                {selectedProcessingOption !== 'Select' && (
                  <DropFileArea
                    id={'handle-upload-file'}
                    selectedProcessingOption={selectedProcessingOption}
                    handleUploadFile={handleUploadFile}
                    onDropHandler={onDropHandler}
                    drag={drag}
                    setDrag={setDrag}
                  />
                )}
              </AiPaneBodyWrapper>
            )}
          </DropdownWrapper>
        </PaddingWrap>
      </MainWrap>
    </>
  );
};

const DropdownWrapper = styled.div`
  border: 1px solid ${({ theme }) => theme.border.base};
  border-radius: 10px;
  margin-top: 40px;
`;

const DropdownHeader = styled.div<{ isMobile: boolean }>`
  display: flex;
  justify-content: space-between;
  align-items: ${({ isMobile }) => (isMobile ? 'flex-start' : 'center')};
  flex-direction: ${({ isMobile }) => (isMobile ? 'column' : 'row')};
  padding: ${({ isMobile }) => (isMobile ? '15px 20px' : '30px 40px')};
  position: relative;
  border-radius: 12px 12px 0 0;
  background-color: ${({ theme }) => theme.layer.base};
`;

const Title = styled.div<{ isMobile: boolean }>`
  font-family: Lato;
  font-weight: 700;
  font-size: ${({ isMobile }) => (isMobile ? '20px' : '28px')};
  line-height: ${({ isMobile }) => (isMobile ? '30px' : '22px')};
  color: ${({ theme }) => theme.font.strong};
`;

const DropdownBody = styled.div<{ isMobile: boolean }>`
  background-color: ${({ theme }) => theme.layer[1]};
  border-radius: 0 0 10px 10px;
  border-top: 1px solid ${({ theme }) => theme.border.base};
  opacity: 1;
  padding: ${({ isMobile }) => (isMobile ? '20px' : '40px')};
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const CustomSelect = styled(FilterSelect)<{ isBackground: boolean }>`
  padding: 10px;
  border: ${({ isBackground, theme }) => (isBackground ? `1px solid ${theme.context.success}` : `1px solid ${theme.border.base}`)};
  background-color: ${({ isBackground }) => (isBackground ? '#efffea' : 'transparent')};
  #titleSelect {
    font-family: Blinker;
    font-size: 16px;
    color: ${({ theme, isBackground }) => (isBackground ? '#2E2E2E' : theme.font.strong)};
  }
`;

const CustomInput = styled(FilterSearch)`
  padding: 10px;
  font-family: Blinker;
  font-size: 16px;
`;

const AiPaneBodyWrapper = styled.div`
  padding-top: 40px;
`;

const UploadText = styled.p`
  font-weight: 400;
  font-size: 16px;
  color: #373f4e;
  padding-left: 20px;
`;

const UploadedFilesWrapper = styled.div`
  margin-top: 40px;
  padding-bottom: 40px;
  width: 100%;
  background: #fafafa;
`;

const CountFiles = styled.div`
  font-weight: 300;
  font-size: 60px;
  text-align: center;
  color: #373f4e;
`;

const DocumentText = styled.div`
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #7f7f7f;
  padding-top: 5px;
  border-top: 1px solid #c7cbd2;
  max-width: 120px;
  text-align: center;
  width: 100%;
  margin: 0 auto;
`;

const SelectedDocument = styled.div<{ withBorderBottom?: boolean }>`
  margin: 0 20px;
  padding: 10px 0;
  border-bottom: ${({ withBorderBottom }) => (withBorderBottom ? ' 1px solid #c7cbd2' : 'none')};
  font-weight: 400;
  font-size: 16px;
  color: #7f7f7f;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
`;

const TextDescription = styled.div`
  padding: 0 20px;
  color: #373f4e;
  font-family: Blinker;
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 20px;
`;

const UploadedDocumnts = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
  padding: 15px 20px 0 35px;
  margin-bottom: 5px;
`;

const BoldText = styled.span`
  font-weight: 700;
`;

const CheckBoxWrap = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  font-weight: 600;
  font-size: 16px;
`;

const UploadedDocumentItem = styled.div<{ disabled?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: ${({ disabled }) => (disabled ? '#ABABAB' : '#7F7F7F')};
  cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};

  #input {
    opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};
  }
`;

const SuggestionButton = styled(Button)`
  margin-top: 20px;
  display: flex;
  align-items: center;
  gap: 6px;
  background: ${({ disabled }) => (disabled ? '#dedede' : '#1c488a')};
  color: ${({ disabled }) => (disabled ? '#ABABAB' : '#f4f5f6')};
  width: 100%;
  font-weight: 600;
  font-size: 19px;
`;

const CustomLoader = styled(Loader)`
  padding: 0;
`;

const ProcessingSelectWrap = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 20px;
`;

const Label = styled.span`
  font-family: Blinker, serif;
  font-size: 13px;
  line-height: 18.2px;
`;

const CustomProcessingSelect = styled(FilterSelect)`
  #dropDownSelect {
    padding: 10px 0;
  }
  #dropDownSelectItem {
    color: #4587ec;
    font-family: Blinker;
    font-size: 13px;
    font-style: normal;
    font-weight: 600;
    line-height: 18.2px;
  }
`;
