import { gql } from '@apollo/client';

export const LIST_INVESTMENTS_FOR_MANAGER_UPDATE_QUERY = gql`
  query listInvestmentsForManagerUpdate(
    $page: Int!
    $rowsPerPage: Int!
    $managerUpdateFilter: [String!]
    $quickStatusFilter: String
    $sortBy: String
    $order: String
    $search: String
  ) {
    listInvestmentsForManagerUpdate(
      page: $page
      rowsPerPage: $rowsPerPage
      managerUpdateFilter: $managerUpdateFilter
      quickStatusFilter: $quickStatusFilter
      sortBy: $sortBy
      order: $order
      search: $search
    ) {
      total
      page
      perPage
      filters {
        name
        type
        value
        enabled
        count
      }
      data {
        id
        name
        managerId
        securityStatus
        documentRecordMetadata {
          last_mu_publish_date
          num_of_mu
          ids
          num_of_mu_draft
          num_of_mu_published
        }
      }
    }
  }
`;
