import React from 'react';
import { Controller, useController } from 'react-hook-form';
import { styled } from 'styled-components';

import Label from '@/components/basicComponents/label';

interface FormFieldProps {
  title: string;
  nameField: string;
  type?: string;
  placeHolder?: string;
  isWarning?: boolean;
  tooltip?: string;
  tooltipWidth?: number;
  className?: string;
  control: any;
  rules: any;
}

export const DollarInput: React.FC<FormFieldProps> = ({
  title,
  nameField,
  type = 'text',
  placeHolder = '',
  className,
  isWarning,
  tooltip,
  tooltipWidth,
  control,
  rules
}) => {
  const {
    fieldState: { invalid },
    formState: { errors }
  } = useController({ name: nameField, control, rules, defaultValue: '' });

  return (
    <div style={{ width: '100%' }} className={className}>
      <Label required={isWarning} tooltip={tooltip} tooltipWidth={tooltipWidth}>
        {title}
      </Label>
      <InputWrapper>
        <InputWrap>
          <Controller
            name={nameField}
            control={control}
            rules={rules}
            render={({ field: { onChange, onBlur, value, ref } }) => (
              <FormInput
                type={type}
                value={value}
                placeholder={placeHolder}
                onChange={(e) => {
                  let inputValue = e.target.value;
                  inputValue = inputValue.replace(/[^0-9.]/g, '');
                  const formattedValue = inputValue ? Number(inputValue).toLocaleString('en-US') : '';
                  onChange(formattedValue);
                }}
                onBlur={onBlur}
                ref={ref}
                onWheel={(e) => (e.target as HTMLInputElement).blur()}
              />
            )}
          />
          <Dollar>$</Dollar>
        </InputWrap>
      </InputWrapper>
      {invalid && <ErrorText>{String(errors?.[nameField]?.message)}</ErrorText>}
    </div>
  );
};

const FormInput = styled.input<{ disabled?: boolean; width?: string; withDollarSign?: boolean }>`
  color: ${({ theme }) => theme.font.strong};
  width: ${({ width }) => (width ? width : '100%')};
  background: ${({ disabled, theme }) => (disabled ? theme.action.disabled : theme.layer[2])};
  border: 1px solid ${({ theme }) => theme.border.base};
  opacity: ${({ disabled }) => (disabled ? 0.4 : 1)};
  font-family: Blinker;
  font-size: 16px;
  font-weight: 400;
  line-height: 22.4px;
  border-radius: 5px;

  padding: 10px 20px;

  &:focus {
    outline: none;
  }

  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  &::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;

const ErrorText = styled.p`
  color: ${({ theme }) => theme.context.error};
  font-family: Blinker;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 22.4px;
`;

const InputWrapper = styled.div`
  position: relative;
`;

const InputWrap = styled.div`
  position: relative;
`;

const Dollar = styled.span`
  position: absolute;
  top: 10px;
  left: 10px;
  color: ${({ theme }) => theme.font.strong};
`;
