import { getUTCDate } from '@/components/allocations-page/utils';
import { TableColumns } from '@/components/table/types';
import { AssetClassForecastValueDto, TransformedAssetClassForecastValueDto, TransformedSubAssetClassForecastValueDto } from './types';

export const groupAssetClasses = (data: AssetClassForecastValueDto[]): TransformedAssetClassForecastValueDto[] => {
  const groupedData: any = {};

  data.forEach((item) => {
    if (!groupedData[item.name]) {
      groupedData[item.name] = {
        name: item.name,
        values: [],
        subAssetClasses: []
      };
    }
    groupedData[item.name].values.push({ date: item.date, value: item.value });

    item.subAssetClasses.forEach((subItem) => {
      let subClass = groupedData[item.name].subAssetClasses.find((sub: TransformedSubAssetClassForecastValueDto) => sub.name === subItem.name);
      if (!subClass) {
        subClass = {
          name: subItem.name,
          values: []
        };
        groupedData[item.name].subAssetClasses.push(subClass);
      }
      subClass.values.push({ date: subItem.date, value: subItem.value });
    });
  });

  return Object.values(groupedData);
};

export const getUniqueDates = (data: AssetClassForecastValueDto[]): TableColumns[] => {
  const dateSet: Set<string> = new Set();

  data.forEach((item) => {
    dateSet.add(item.date);
  });

  return Array.from(dateSet).map((date) => ({
    title: getUTCDate(new Date(date)).toUpperCase(),
    key: null,
    width: 0,
    keySort: new Date(date).getFullYear().toString()
  }));
};
