import client from '@/apollo-client';
import { ArrowDown } from '@/assets/icons/info-icons/arrowDown';
import { ArrowUp } from '@/assets/static/icons/todo-page/arrow-up';
import { CitationsType } from '@/components/dueDiligence-page/dueDiligenceRecord/types';
import { getAssetSignedUrl } from '@/components/settings-page/queries';
import { useEffect, useState } from 'react';
import styled from 'styled-components';

interface AllSuggestionsProps {
  investmentId: string | null;
  citations: CitationsType[];
  scrollToBottom: (scrollBehavior: string) => void;
}

export const AllSuggestions = ({ investmentId, citations, scrollToBottom }: AllSuggestionsProps) => {
  const [showAllSuggestions, setShowAllSuggestions] = useState(false);
  const [currentCitation, setCurrentCitation] = useState<CitationsType | null>();

  useEffect(() => {
    if (citations) {
      setCurrentCitation(citations[0]);
    }
  }, [citations]);

  const openPdf = async (pageNumber: string) => {
    if (!investmentId && !currentCitation) return;

    const assetKey = `investments/${investmentId}/ddr/${currentCitation?.filename}`;
    const { data } = await client.query({
      fetchPolicy: 'network-only',
      query: getAssetSignedUrl,
      variables: {
        assetKey: assetKey
      }
    });
    window.open(`${data.getAssetSignedUrl}#page=${pageNumber}`, '_blank');
  };

  const prevSuggestion = () => {
    if (!currentCitation) return;
    const currentIndex = citations.indexOf(currentCitation);

    if (currentIndex > 0) {
      setCurrentCitation(citations[currentIndex - 1]);
    } else {
      setCurrentCitation(citations[citations.length - 1]);
    }

    scrollToBottom('auto');
  };

  const nextSuggestion = () => {
    if (!currentCitation) return;
    const currentIndex = citations.indexOf(currentCitation);

    if (currentIndex !== -1 && currentIndex < citations.length - 1) {
      setCurrentCitation(citations[currentIndex + 1]);
    } else {
      setCurrentCitation(citations[0]);
    }

    scrollToBottom('auto');
  };

  const showSuggestions = () => {
    setShowAllSuggestions(!showAllSuggestions);
    scrollToBottom('smooth');
  };

  return (
    <>
      {currentCitation ? (
        <AllSuggestionsWrapper>
          <SuggestionsHeader showAllSuggestions={showAllSuggestions} onClick={showSuggestions}>
            All Suggestions {`(${citations.length})`}
            {showAllSuggestions ? (
              <ArrowUp width={23} height={23} fill={'#4587EC'} />
            ) : (
              <ArrowDown style={{ position: 'relative', top: '1px', right: '4px' }} width={12} height={12} fill={'#4587EC'} />
            )}
          </SuggestionsHeader>
          <SuggestionsBody showAllSuggestions={showAllSuggestions}>
            <ExpandableWrapp>
              <PageReferenceWrapper>
                Page Reference
                <div>
                  {currentCitation.pageNumbers.map((pageNumber, index) => (
                    <PageNumber key={pageNumber + index} onClick={() => openPdf(currentCitation.pageNumbers[0])}>{`${
                      index ? ' - ' : 'Pages '
                    }${pageNumber}`}</PageNumber>
                  ))}
                </div>
              </PageReferenceWrapper>
              <SuggestedAnswerWrapper>
                Suggested Answer
                <SuggestedAnswer>{currentCitation.answer}</SuggestedAnswer>
              </SuggestedAnswerWrapper>
              <SuggestionNavigate>
                <PrevSuggestion onClick={prevSuggestion}>{'<'}</PrevSuggestion>
                <PageNumbers>
                  Showing {citations.indexOf(currentCitation) + 1} of {citations.length}
                </PageNumbers>
                <NextSuggestion onClick={nextSuggestion}>{'>'}</NextSuggestion>
              </SuggestionNavigate>
            </ExpandableWrapp>
          </SuggestionsBody>
        </AllSuggestionsWrapper>
      ) : (
        <></>
      )}
    </>
  );
};

const AllSuggestionsWrapper = styled.div`
  padding: 0 5px 10px 50px;
`;

const SuggestionsHeader = styled.div<{ showAllSuggestions: boolean }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #7f7f7f;
  font-size: 16px;
  font-weight: 400;
  cursor: pointer;
  border-bottom: ${({ showAllSuggestions }) => (showAllSuggestions ? '1px solid #c7cbd2' : 'none')};
  padding-bottom: 10px;
`;

const ExpandableWrapp = styled.div`
  min-height: 0;
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const SuggestionsBody = styled.div<{ showAllSuggestions: boolean }>`
  display: grid;
  grid-template-rows: ${({ showAllSuggestions }) => (showAllSuggestions ? '1fr' : '0fr')};
  overflow: ${({ showAllSuggestions }) => (showAllSuggestions ? 'visible' : 'hidden')};
  transition: grid-template-rows 300ms;
`;

const PageReferenceWrapper = styled.div`
  margin-top: 10px;
  display: flex;
  gap: 40px;
  color: #373f4e;
  font-size: 16px;
  font-weight: 700;
`;

const PageNumber = styled.span`
  font-weight: 400;
  cursor: pointer;
  color: #4587ec;
`;

const SuggestedAnswerWrapper = styled.div`
  color: #373f4e;
  font-size: 16px;
  font-weight: 700;
`;
const SuggestedAnswer = styled.div`
  color: #373f4e;
  font-size: 16px;
  font-weight: 400;
  line-height: 22.4px;
`;

const SuggestionNavigate = styled.div`
  margin: 20px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
`;

const PrevSuggestion = styled.button`
  cursor: pointer;
  padding: 8px 19px;
  border-radius: 3px;
  border: 1px solid #4587ec;
  background: #fafafa;
  color: #4587ec;
  font-size: 13px;
  font-weight: 400;
`;

const NextSuggestion = styled(PrevSuggestion)``;

const PageNumbers = styled.div`
  color: #7f7f7f;
  font-size: 13px;
  font-weight: 400;
`;
