import ModalWrappedContent from '@/components/fat-basicComponents/modal/modalWrappedContent';
import { Input } from '@/components/fat-form-field/input';
import { Loader } from '@/components/loaders/loader';
import ErrorNotification from '@/components/recommendations-page/error-notification';
import { useForm } from 'react-hook-form';
import styled from 'styled-components';
import { CreateTenantDto } from '../types';

interface AddTenantModalProps {
  onCancel: () => void;
  onConfirm: (data: CreateTenantDto) => void | null;
  isOpen: boolean;
  isLoading?: boolean;
  error?: { title: string; message: string };
}

const defaultFormValues: CreateTenantDto = {
  name: '',
  adminEmail: '',
  adminFirstName: '',
  adminLastName: ''
};

const AddTenantModal = ({ onCancel, onConfirm, isOpen, isLoading, error }: AddTenantModalProps) => {
  const {
    getValues,
    control,
    formState: { errors, isValid }
  } = useForm<CreateTenantDto>({ defaultValues: defaultFormValues as any });

  return (
    <ModalWrappedContent
      isOpen={isOpen}
      onClose={onCancel}
      confirmButton={
        isLoading
          ? {
              name: 'Save',
              disabled: true,
              onClick: () => null
            }
          : {
              name: 'Save',
              disabled: !isValid,
              onClick: () => {
                if (!isValid) return;
                onConfirm(getValues());
              }
            }
      }
      showCancelButton={!isLoading}
      isUseOutsideClick={!isLoading}
      showCloseIcon={!isLoading}
    >
      <ModalContainer>
        {error && <ErrorNotification title={error.title} message={error.message ?? ''} />}
        <ModalTitle>Add New Tenant</ModalTitle>
        <ModalText fontWeight="400">
          {isLoading && (
            <>
              <br /> <br />
              <Loader size={30} />
              Creating new tenant...
            </>
          )}
          {!isLoading && (
            <>
              <br />
              <Input
                title={'Name'}
                nameField={'name'}
                rules={{
                  required: {
                    value: true,
                    message: 'Name is required'
                  }
                }}
                control={control}
                placeHolder={'Name of the firm'}
                isWarning={true}
              />
              <br />
              <Input
                title={'Primary Administrator Email'}
                nameField={'adminEmail'}
                rules={{
                  required: {
                    value: true,
                    message: 'Email is required'
                  }
                }}
                control={control}
                placeHolder={'Valid email address'}
                isWarning={true}
              />
              <br />
              <Input
                title={'Primary Administrator First Name'}
                nameField={'adminFirstName'}
                rules={{
                  required: {
                    value: true,
                    message: 'First name is required'
                  }
                }}
                control={control}
                placeHolder={'First name of the user'}
                isWarning={true}
              />
              <br />
              <Input
                title={'Primary Administrator Last Name'}
                nameField={'adminLastName'}
                rules={{
                  required: {
                    value: true,
                    message: 'Last name is required'
                  }
                }}
                control={control}
                placeHolder={'Last name of the user'}
                isWarning={true}
              />
            </>
          )}
        </ModalText>
      </ModalContainer>
    </ModalWrappedContent>
  );
};

export default AddTenantModal;

const ModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 10px;
  padding: 30px 0;
`;

const ModalTitle = styled.div`
  color: ${({ theme }) => theme.font.strong};
  text-align: center;
  font-family: Blinker;
  font-size: 33px;
  font-style: normal;
  font-weight: 400;
  line-height: 46.2px;
`;

const ModalText = styled.span<{ fontWeight: string }>`
  color: ${({ theme }) => theme.font.base};
  text-align: center;
  font-family: Blinker;
  font-size: 19px;
  font-style: normal;
  font-weight: ${({ fontWeight }) => fontWeight};
  line-height: 26.6px;
`;
