import { CloseInfoIcon } from '@/assets/icons/info-icons/closeInfo';
import { AddButtonIcon } from '@/assets/static/icons/add-button-icon';
import Button from '@/components/basicComponents/button';
import BasicTooltip from '@/components/basicComponents/tooltip';
import { CloseBtn, ModalControlWrap, ModalPageTitle } from '@/components/client-details';
import { IChecklistItem, ISection, ISectionFields, ITier } from '@/components/dueDiligence-page/dueDiligenceRecord/types';
import { IFunnelData } from '@/components/dueDiligence-page/types';
import Header from '@/components/header';
import { Loader } from '@/components/loaders/loader';
import { useResponsive } from '@/hooks/use-responsive';
import { MainWrap, PaddingWrap } from '@/styles/common';
import { useMutation, useQuery } from '@apollo/client';
import { useEffect, useState } from 'react';
import { DragDropContext, DropResult, Droppable } from 'react-beautiful-dnd';
import { useLocation, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import {
  CREATE_DUE_DILIGENCE_CHECKLIST_ITEM,
  CREATE_DUE_DILIGENCE_FIELD,
  CREATE_DUE_DILIGENCE_SECTION,
  DELETE_DUE_DILIGENCE_CHECKLIST_ITEM,
  DELETE_DUE_DILIGENCE_FIELD,
  DELETE_DUE_DILIGENCE_SECTION,
  GET_FUNNEL_QUERY,
  UPDATE_DRAFT_DUE_DILIGENCE_CHECKLIST_ITEM,
  UPDATE_DRAFT_DUE_DILIGENCE_FIELD,
  UPDATE_DRAFT_DUE_DILIGENCE_SECTION,
  UPDATE_DRAFT_DUE_DILIGENCE_TIER
} from '../../queries';
import { FunnelEditorDraggableDropdown } from './draggableDropdown';
import { FunnelEditorDropdown } from './dropdown';
import AddChecklist from './modals/addChecklist';
import AddField from './modals/addField';
import AddSection from './modals/addSection';
import AddTier from './modals/addTier';
import DeleteChecklist from './modals/deleteChecklist';
import DeleteDraft from './modals/deleteDraft';
import DeleteField from './modals/deleteField';
import DeleteSection from './modals/deleteSection';
import DeleteTier from './modals/deleteTier';
import DuplicateSection from './modals/duplicateSection';
import DuplicateTier from './modals/duplicateTier';
import EditFunnel from './modals/editFunnel';
import LinkedFieldError from './modals/linkedFieldError';
import PublishFunnel from './modals/publishFunnel';
import SuccessDialog from './modals/successDialog';
import { PromptEditor } from './promptEditor';
import { SectionFields } from './sectionFields';
import { TierChecklistItems } from './tierChecklistItems';

interface FunnelEditorEditProps {
  handleClose: () => void;
}

export const FunnelEditorEdit = ({ handleClose }: FunnelEditorEditProps) => {
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const { isMobile, isTablet } = useResponsive();

  const [modalWindow, setModalWindow] = useState({
    isOpen: false,
    type: 'add-field'
  });
  const [currentTier, setCurrentTier] = useState<ITier | null>(null);
  const [currentSection, setCurrentSection] = useState<ISection | null>(null);
  const [currentField, setCurrentField] = useState<ISectionFields | null>(null);
  const [currentChecklistItem, setCurrentChecklistItem] = useState<IChecklistItem | null>(null);
  const [currentId, setCurrentId] = useState('');
  const [tiersData, setTiersData] = useState<ITier[]>([]);
  const [windowType, setWindowType] = useState({
    isOpen: false,
    type: 'default'
  });
  const [scrollTo, setScrollTo] = useState(0);
  const [showPublishFunnelButton, setShowPublishFunnelButton] = useState(false);
  const [linkedFieldsError, setLinkedFieldsError] = useState({
    title: '',
    description: ''
  });
  const [isSecondDraft, setIsSecondDraft] = useState(false);
  const [isLimitedEdits, setIsLimitedEdits] = useState(false);
  const [canDragging, setCanDragging] = useState({ action: true, id: '' });
  const [isFirstLoading, setIsFirstLoading] = useState(true);

  const {
    data: funnelData,
    loading,
    error,
    refetch: funnelRefetch,
    networkStatus
  } = useQuery<{ getDueDiligenceFunnel: IFunnelData }>(GET_FUNNEL_QUERY, {
    fetchPolicy: 'cache-and-network',
    notifyOnNetworkStatusChange: true,
    variables: {
      id: queryParams.get('funnelId')
    },
    skip: !queryParams.get('funnelId'),
    onCompleted: (data) => {
      setTiersData(data.getDueDiligenceFunnel.tiers);
      setIsLimitedEdits(data.getDueDiligenceFunnel.status === 'PUBLISHED');
      setIsFirstLoading(false);

      setShowPublishFunnelButton(false);
      let showButton = false;
      for (const tier of data.getDueDiligenceFunnel.tiers) {
        for (const section of tier.sections) {
          if (section.fields.length > 0) {
            showButton = true;
            setShowPublishFunnelButton(true);
            break;
          }
        }
        if (showButton) {
          break;
        }
      }

      if (!showButton) {
        for (const tier of data.getDueDiligenceFunnel.tiers) {
          if (tier.checklistItems.length > 0) {
            showButton = true;
            setShowPublishFunnelButton(true);
            break;
          }
        }
      }
    },
    onError: () => {
      setIsFirstLoading(false);
    }
  });

  useEffect(() => {
    if (windowType.type === 'default') {
      window.scrollTo(0, scrollTo);
    }
  }, [windowType.type]);

  useEffect(() => {
    if (queryParams.get('isSecondDraft')) {
      setIsSecondDraft(JSON.parse(queryParams.get('isSecondDraft') as string));
    }
  }, [queryParams.get('isSecondDraft')]);

  const openErrorModal = (type: string, title: string, description: string) => {
    setModalWindow({ isOpen: true, type: type });
    setLinkedFieldsError({ title, description });
  };

  const openModalWindow = (type: string, id?: string, row?: ITier | ISection | ISectionFields | IChecklistItem, rowType?: string) => {
    setModalWindow({ isOpen: true, type: type });
    setCurrentId(id ?? '');

    if (row) {
      switch (rowType) {
        case 'tier':
          setCurrentTier(row as ITier);
          break;
        case 'section':
          setCurrentSection(row as ISection);
          break;
        case 'field':
          setCurrentField(row as ISectionFields);
          break;
        case 'checklist':
          setCurrentChecklistItem(row as IChecklistItem);
          break;
      }
    }
  };

  const closeModalWindow = () => {
    setModalWindow({ ...modalWindow, isOpen: false });
    setCurrentTier(null);
    setCurrentSection(null);
    setCurrentField(null);
    setCurrentChecklistItem(null);
  };

  const openPromptEditor = (field: ISectionFields) => {
    const newUrl = `/settings?action=${queryParams.get('action')}&funnelId=${queryParams.get('funnelId')}&funnelName=${queryParams.get(
      'funnelName'
    )}&mode=${queryParams.get('mode')}&isSecondDraft=${queryParams.get('isSecondDraft')}&fieldId=${field.id}`;
    navigate(newUrl, { replace: true });

    setWindowType({ isOpen: true, type: 'prompt-editor' });
    setCurrentField(field);
    setScrollTo(window.scrollY);
  };

  const closePromptEditor = () => {
    const newUrl = `/settings?action=${queryParams.get('action')}&funnelId=${queryParams.get('funnelId')}&funnelName=${queryParams.get(
      'funnelName'
    )}&mode=${queryParams.get('mode')}&isSecondDraft=${queryParams.get('isSecondDraft')}`;
    navigate(newUrl, { replace: true });
    setWindowType({ isOpen: false, type: 'default' });
    setCurrentField(null);
  };
  const handleCloseAll = () => {
    navigate(`/settings?action=${queryParams.get('action')}`, {
      replace: true
    });
    setWindowType({ isOpen: false, type: 'default' });
    setCurrentField(null);
  };

  const [createField] = useMutation(CREATE_DUE_DILIGENCE_FIELD);
  const [updateDraftField] = useMutation(UPDATE_DRAFT_DUE_DILIGENCE_FIELD);
  const [deleteField] = useMutation(DELETE_DUE_DILIGENCE_FIELD);

  const onDragEndField = async (result: DropResult) => {
    const { source, destination, draggableId } = result;

    if (!draggableId || !destination) return;

    setCanDragging({ action: false, id: draggableId });

    const sourceSectionId = source.droppableId;
    const destinationSectionId = destination.droppableId;

    if (sourceSectionId === destinationSectionId && source.index !== destination.index) {
      const currentSection = tiersData.flatMap((tier) => tier.sections).find((section) => section.id === destinationSectionId);
      if (!currentSection) return;

      const sourceIndex = source.index;
      const destinationIndex = destination.index;

      const reorderedSectionFields = [...currentSection.fields];
      const [removedField] = reorderedSectionFields.splice(sourceIndex, 1);
      reorderedSectionFields.splice(destinationIndex, 0, removedField);

      const updatedTierData = tiersData.map((tier) => {
        const updatedSections = tier.sections.map((section) => {
          if (currentSection.id === section.id) {
            return { ...section, fields: reorderedSectionFields };
          }
          return section;
        });
        return { ...tier, sections: updatedSections };
      });

      setTiersData(updatedTierData);

      for await (const [index, field] of reorderedSectionFields.entries()) {
        await updateDraftField({
          variables: {
            data: {
              id: field.id,
              order: index + 1
            }
          }
        });
      }
      funnelRefetch();
    } else if (sourceSectionId && destinationSectionId && sourceSectionId !== destinationSectionId) {
      const draggedField = tiersData
        .flatMap((tier) => tier.sections)
        .find((section) => section.id === sourceSectionId)
        ?.fields.find((field) => field.id === draggableId);

      if (draggedField) {
        const updatedTierData = tiersData.map((tier) => ({
          ...tier,
          sections: tier.sections.map((section) => ({
            ...section,
            fields:
              section.id === sourceSectionId
                ? section.fields.filter((field) => field.id !== draggableId)
                : section.id === destinationSectionId
                  ? [...section.fields.slice(0, destination.index), draggedField, ...section.fields.slice(destination.index)]
                  : section.fields
          }))
        }));

        setTiersData(updatedTierData);

        const sourceSection = tiersData.flatMap((tier) => tier.sections).find((section) => section.id === sourceSectionId);

        const destinationSection = tiersData.flatMap((tier) => tier.sections).find((section) => section.id === destinationSectionId);

        if (sourceSection && destinationSection) {
          let linkedField: ISectionFields | undefined = undefined;

          for await (const tier of tiersData) {
            for await (const section of tier.sections) {
              for await (const field of section.fields) {
                if (draggedField.id === field.linkedFieldId) {
                  linkedField = field;
                  await updateDraftField({
                    variables: {
                      data: {
                        id: field.id,
                        linkedFieldId: null
                      }
                    }
                  });
                }
              }
            }
          }

          await deleteField({
            variables: {
              id: draggedField.id
            }
          });

          const filteredFields = sourceSection.fields.filter((field) => field.id !== draggedField.id);
          for await (const [index, field] of filteredFields.entries()) {
            await updateDraftField({
              variables: {
                data: {
                  id: field.id,
                  order: index + 1
                }
              }
            });
          }

          const { data } = await createField({
            variables: {
              data: {
                funnelTierSectionId: destinationSection.id,
                name: draggedField.name,
                order: destination.index,
                type: draggedField.type,
                linkedFieldId: draggedField.linkedFieldId,
                required: draggedField.required
              }
            }
          });

          for await (const [index, field] of [
            ...destinationSection.fields.slice(0, destination.index),
            data.createDueDiligenceFunnelTierSectionField,
            ...destinationSection.fields.slice(destination.index)
          ].entries()) {
            await updateDraftField({
              variables: {
                data: {
                  id: field.id,
                  order: index + 1
                }
              }
            });
          }
          if (linkedField) {
            await updateDraftField({
              variables: {
                data: {
                  id: linkedField.id,
                  name: linkedField.name,
                  type: linkedField.type,
                  linkedFieldId: data.createDueDiligenceFunnelTierSectionField.id,
                  required: linkedField.required
                }
              }
            });
          }
          funnelRefetch();
        }
      }
    }
    setCanDragging({ action: true, id: '' });
  };

  const [createChecklistItem] = useMutation(CREATE_DUE_DILIGENCE_CHECKLIST_ITEM);
  const [updateDraftChecklistItem] = useMutation(UPDATE_DRAFT_DUE_DILIGENCE_CHECKLIST_ITEM);
  const [deleteChecklistItem] = useMutation(DELETE_DUE_DILIGENCE_CHECKLIST_ITEM);

  const onDragEndChecklist = async (result: DropResult) => {
    const { source, destination, draggableId } = result;

    if (!draggableId || !destination) return;
    setCanDragging({ action: false, id: draggableId });

    if (source.droppableId === destination.droppableId && source.index !== destination.index) {
      const currentTier = tiersData.find((tier) => tier.id === source.droppableId);

      if (currentTier) {
        const reorderedChecklistItems = [...currentTier.checklistItems];
        const [removedItem] = reorderedChecklistItems.splice(source.index, 1);
        reorderedChecklistItems.splice(destination.index, 0, removedItem);

        const updatedTierData = tiersData.map((tier) => {
          if (currentTier.id === tier.id) {
            return {
              ...tier,
              checklistItems: reorderedChecklistItems.map((item, index) => ({
                ...item,
                order: index + 1
              }))
            };
          }
          return tier;
        });

        setTiersData(updatedTierData);

        for await (const [index, item] of reorderedChecklistItems.entries()) {
          await updateDraftChecklistItem({
            variables: {
              data: {
                id: item.id,
                order: index + 1
              }
            }
          });
        }
        funnelRefetch();
      }
    } else if (source.droppableId !== destination.droppableId) {
      const draggedChecklistItem = tiersData.flatMap((tier) => tier.checklistItems).find((item) => item.id === draggableId);

      if (draggedChecklistItem) {
        const updatedTierData = tiersData.map((tier) => ({
          ...tier,
          checklistItems:
            tier.id === source.droppableId
              ? tier.checklistItems.filter((item) => item.id !== draggableId)
              : tier.id === destination.droppableId
                ? [...tier.checklistItems.slice(0, destination.index), draggedChecklistItem, ...tier.checklistItems.slice(destination.index)]
                : tier.checklistItems
        }));
        setTiersData(updatedTierData);

        const sourceTier = tiersData.find((tier) => tier.id === source.droppableId);

        const destinationTier = tiersData.find((tier) => tier.id === destination.droppableId);

        if (sourceTier && destinationTier) {
          await deleteChecklistItem({
            variables: {
              id: draggedChecklistItem.id
            }
          });

          const filteredChecklistItems = sourceTier.checklistItems.filter((item) => item.id !== draggedChecklistItem.id);
          for await (const [index, item] of filteredChecklistItems.entries()) {
            await updateDraftChecklistItem({
              variables: {
                data: {
                  id: item.id,
                  order: index + 1
                }
              }
            });
          }

          const { data } = await createChecklistItem({
            variables: {
              data: {
                funnelTierId: destinationTier.id,
                name: draggedChecklistItem.name,
                required: draggedChecklistItem.required,
                order: destination.index
              }
            }
          });

          for await (const [index, item] of [
            ...destinationTier.checklistItems.slice(0, destination.index),
            data.createDueDiligenceFunnelTierChecklistItem,
            ...destinationTier.checklistItems.slice(destination.index)
          ].entries()) {
            await updateDraftChecklistItem({
              variables: {
                data: {
                  id: item.id,
                  order: index + 1
                }
              }
            });
          }
          funnelRefetch();
        }
      }
    }
    setCanDragging({ action: true, id: '' });
  };

  const [updateDraftTier] = useMutation(UPDATE_DRAFT_DUE_DILIGENCE_TIER);

  const onDragEndTier = async (result: DropResult) => {
    const { source, destination, draggableId } = result;

    if (!destination) return;

    if (source.droppableId === destination.droppableId && source.index === destination.index) return;

    setCanDragging({ action: false, id: draggableId });

    const reorderedTiers = [...tiersData];

    const sourceIndex = source.index;
    const destinationIndex = destination.index;

    const [removedTier] = reorderedTiers.splice(sourceIndex, 1);
    reorderedTiers.splice(destinationIndex, 0, removedTier);

    const updatedTiersOrder = reorderedTiers.map((tier, index) => ({
      ...tier,
      order: index + 1
    }));

    setTiersData(updatedTiersOrder);

    for await (const [index, tier] of reorderedTiers.entries()) {
      await updateDraftTier({
        variables: {
          data: {
            id: tier.id,
            order: index + 1
          }
        }
      });
    }
    funnelRefetch();
    setCanDragging({ action: true, id: '' });
  };

  const [createSection] = useMutation(CREATE_DUE_DILIGENCE_SECTION);
  const [updateDraftSection] = useMutation(UPDATE_DRAFT_DUE_DILIGENCE_SECTION);
  const [deleteSection] = useMutation(DELETE_DUE_DILIGENCE_SECTION);

  const onDragEndSection = async (result: DropResult) => {
    const { source, destination, draggableId } = result;

    if (!draggableId || !destination) return;

    setCanDragging({ action: false, id: draggableId });

    const sourceTierId = source.droppableId.substring(4);
    const destinationTierId = destination.droppableId.substring(4);

    if (sourceTierId === destinationTierId && source.index !== destination.index) {
      const currentTier = tiersData.find((tier) => tier.id === destinationTierId);
      if (!currentTier) return;

      const sourceIndex = source.index;
      const destinationIndex = destination.index;

      const reorderedTierSections = [...currentTier.sections];
      const [removedSection] = reorderedTierSections.splice(sourceIndex, 1);
      reorderedTierSections.splice(destinationIndex, 0, removedSection);

      const updatedTierData = tiersData.map((tier) => {
        if (currentTier.id === tier.id) {
          return {
            ...tier,
            sections: reorderedTierSections.map((section, index) => ({
              ...section,
              order: index + 1
            }))
          };
        }

        return tier;
      });

      setTiersData(updatedTierData);

      for await (const [index, section] of reorderedTierSections.entries()) {
        await updateDraftSection({
          variables: {
            data: {
              id: section.id,
              order: index + 1
            }
          }
        });
      }
      funnelRefetch();
    } else if (sourceTierId && destinationTierId && sourceTierId !== destinationTierId) {
      const draggedSection = tiersData.find((tier) => tier.id === sourceTierId)?.sections.find((section) => section.id === draggableId);

      if (draggedSection) {
        const updatedTierData = tiersData.map((tier) => ({
          ...tier,
          sections:
            tier.id === sourceTierId
              ? tier.sections.filter((section) => section.id !== draggableId)
              : tier.id === destinationTierId
                ? [...tier.sections.slice(0, destination.index), draggedSection, ...tier.sections.slice(destination.index)]
                : tier.sections
        }));

        setTiersData(updatedTierData);

        const sourceTier = tiersData.find((tier) => tier.id === sourceTierId);

        const destinationTier = tiersData.find((tier) => tier.id === destinationTierId);

        const updatedFields = draggedSection.fields.map((field) => {
          return {
            id: field.id,
            name: field.name,
            required: field.required,
            type: field.type,
            linkedFieldId: field.linkedFieldId
          };
        });

        if (sourceTier && destinationTier) {
          const { errors: deleteSectionError } = await deleteSection({
            variables: {
              id: draggedSection.id
            },
            onError: () => {
              setTiersData(tiersData);
              openErrorModal(
                'linked-field-error',
                'Cannot Move This Section',
                'You cannot move this section because there are other fields linked to it. Please unlink the fields before moving this one.'
              );
            }
          });
          if (!deleteSectionError) {
            const filteredSections = sourceTier.sections.filter((section) => section.id !== draggedSection.id);
            for await (const [index, section] of filteredSections.entries()) {
              await updateDraftSection({
                variables: {
                  data: {
                    id: section.id,
                    order: index + 1
                  }
                }
              });
            }

            const { data } = await createSection({
              variables: {
                data: {
                  funnelTierId: destinationTier.id,
                  name: draggedSection.name,
                  userCanScore: draggedSection.userCanScore,
                  order: destination.index,
                  pdfLayout: draggedSection.pdfLayout,
                  fields: updatedFields
                }
              }
            });

            for await (const [index, section] of [
              ...destinationTier.sections.slice(0, destination.index),
              data.createDueDiligenceFunnelTierSection,
              ...destinationTier.sections.slice(destination.index)
            ].entries()) {
              await updateDraftSection({
                variables: {
                  data: {
                    id: section.id,
                    order: index + 1
                  }
                }
              });
            }
            funnelRefetch();
          }
        }
      }
    }
    setCanDragging({ action: true, id: '' });
  };

  const onDragEnd = (result: DropResult) => {
    switch (result.type) {
      case 'TIERS':
        onDragEndTier(result);
        break;
      case 'SECTIONS':
        onDragEndSection(result);
        break;
      case 'FIELDS':
        onDragEndField(result);
        break;
      case 'CHECKLIST':
        onDragEndChecklist(result);
        break;
      default:
        break;
    }
  };

  if (isFirstLoading) {
    return <CustomLoader size={60} />;
  }

  return (
    <>
      {modalWindow.type === 'add-field' && modalWindow.isOpen && (
        <AddField
          isOpen={modalWindow.isOpen}
          onClose={closeModalWindow}
          currentField={currentField}
          tiersData={tiersData}
          currentId={currentId}
          sectionId={currentSection?.id ?? null}
          numberOfFields={currentSection?.fields.length ?? 0}
          isLimitedEdits={isLimitedEdits}
        />
      )}
      {modalWindow.type === 'add-tier' && modalWindow.isOpen && (
        <AddTier
          isOpen={modalWindow.isOpen}
          onClose={closeModalWindow}
          currentTier={currentTier}
          currentId={currentId}
          funnelId={funnelData?.getDueDiligenceFunnel.id ?? null}
          numberOfTiers={tiersData.length}
          isLimitedEdits={isLimitedEdits}
        />
      )}
      {modalWindow.type === 'add-section' && modalWindow.isOpen && (
        <AddSection
          isOpen={modalWindow.isOpen}
          onClose={closeModalWindow}
          currentSection={currentSection}
          currentId={currentId}
          tierId={currentTier?.id ?? null}
          numberOfSections={currentTier?.sections.length ?? 0}
          isLimitedEdits={isLimitedEdits}
        />
      )}
      {modalWindow.type === 'delete-field' && modalWindow.isOpen && (
        <DeleteField
          isOpen={modalWindow.isOpen}
          onClose={closeModalWindow}
          currentId={currentId}
          tiersData={tiersData}
          currentSection={currentSection}
          funnelRefetch={funnelRefetch}
        />
      )}
      {modalWindow.type === 'delete-section' && modalWindow.isOpen && (
        <DeleteSection
          isOpen={modalWindow.isOpen}
          onClose={closeModalWindow}
          currentId={currentId}
          currentTier={currentTier}
          openErrorModal={openErrorModal}
          funnelRefetch={funnelRefetch}
        />
      )}
      {modalWindow.type === 'delete-tier' && modalWindow.isOpen && (
        <DeleteTier
          isOpen={modalWindow.isOpen}
          onClose={closeModalWindow}
          currentId={currentId}
          tierName={currentTier?.name ?? ''}
          openErrorModal={openErrorModal}
          tiersData={tiersData}
          funnelRefetch={funnelRefetch}
        />
      )}
      {modalWindow.type === 'duplicate-section' && modalWindow.isOpen && (
        <DuplicateSection
          isOpen={modalWindow.isOpen}
          onClose={closeModalWindow}
          tiersData={tiersData}
          tierId={currentId}
          currentSection={currentSection}
        />
      )}
      {modalWindow.type === 'duplicate-tier' && modalWindow.isOpen && (
        <DuplicateTier
          isOpen={modalWindow.isOpen}
          onClose={closeModalWindow}
          currentTier={currentTier}
          funnelId={funnelData?.getDueDiligenceFunnel.id ?? null}
          numberOfTiers={tiersData.length}
        />
      )}
      {modalWindow.type === 'add-checklist' && modalWindow.isOpen && (
        <AddChecklist
          isOpen={modalWindow.isOpen}
          onClose={closeModalWindow}
          currentChecklistItem={currentChecklistItem}
          currentId={currentId}
          tierId={currentTier?.id ?? null}
          numberOfChecklistItems={currentTier?.checklistItems.length ?? 0}
          isLimitedEdits={isLimitedEdits}
        />
      )}
      {modalWindow.type === 'delete-checklist' && modalWindow.isOpen && (
        <DeleteChecklist
          isOpen={modalWindow.isOpen}
          onClose={closeModalWindow}
          currentId={currentId}
          checklistName={currentChecklistItem?.name ?? ''}
        />
      )}
      {modalWindow.type === 'publish-funnel' && modalWindow.isOpen && (
        <PublishFunnel
          isOpen={modalWindow.isOpen}
          onClose={closeModalWindow}
          funnelName={funnelData?.getDueDiligenceFunnel.name ?? (queryParams.get('funnelName') as string)}
          openModalWindow={openModalWindow}
          funnelStaticId={funnelData?.getDueDiligenceFunnel.funnelStaticId ?? null}
          isSecondDraft={isSecondDraft}
        />
      )}
      {modalWindow.type === 'success' && modalWindow.isOpen && (
        <SuccessDialog
          isOpen={modalWindow.isOpen}
          onClose={handleClose}
          funnelName={funnelData?.getDueDiligenceFunnel.name ?? (queryParams.get('funnelName') as string)}
        />
      )}
      {modalWindow.type === 'delete-draft' && modalWindow.isOpen && (
        <DeleteDraft isOpen={modalWindow.isOpen} onClose={closeModalWindow} closeAll={handleClose} />
      )}
      {modalWindow.type === 'linked-field-error' && modalWindow.isOpen && (
        <LinkedFieldError
          isOpen={modalWindow.isOpen}
          onClose={closeModalWindow}
          title={linkedFieldsError.title}
          description={linkedFieldsError.description}
        />
      )}
      {modalWindow.type === 'edit-funnel' && modalWindow.isOpen && (
        <EditFunnel
          isOpen={modalWindow.isOpen}
          onClose={closeModalWindow}
          funnelId={funnelData?.getDueDiligenceFunnel.id ?? null}
          funnelName={funnelData?.getDueDiligenceFunnel.name ?? null}
        />
      )}
      {windowType.type === 'prompt-editor' && (
        <PromptEditor handleClose={closePromptEditor} handleCloseAll={handleCloseAll} currentRow={currentField} refetch={funnelRefetch} />
      )}
      {windowType.type === 'default' && (
        <FunnelEditorMainWrap>
          <Header
            modalControl={
              <ModalControlWrap>
                <CloseBtn onClick={handleClose}>
                  <CloseInfoIcon width={18} height={18} />
                </CloseBtn>
                <ModalPageTitle isTablet={isTablet} isMobile={isMobile}>
                  {funnelData?.getDueDiligenceFunnel.name ?? queryParams.get('funnelName')}
                </ModalPageTitle>
              </ModalControlWrap>
            }
          />
          <PaddingWrap>
            <ButtonsWrap>
              <EditFunnelButton onClick={() => openModalWindow('edit-funnel')}>Edit Funnel</EditFunnelButton>
              {!isLimitedEdits && (
                <>
                  <DeleteDraftButton onClick={() => openModalWindow('delete-draft')}>Delete Draft</DeleteDraftButton>
                  <BasicTooltip
                    translateX={-63}
                    shouldShowContent={!showPublishFunnelButton}
                    tooltipContent={'Add at least one field or checklist item before publishing this draft.'}
                  >
                    <PublishButton onClick={() => openModalWindow('publish-funnel')} disabled={!showPublishFunnelButton}>
                      Publish Funnel
                    </PublishButton>
                  </BasicTooltip>
                </>
              )}
            </ButtonsWrap>
            <DragDropContext onDragEnd={onDragEnd}>
              <Droppable droppableId="droppable" type="TIERS">
                {(provided) => (
                  <TiersWrapper ref={provided.innerRef} {...provided.droppableProps}>
                    {tiersData.map((tier, tierIndex) => (
                      <FunnelEditorDraggableDropdown
                        key={tier.id}
                        title={tier.name}
                        handleEdit={() => openModalWindow('add-tier', tier.id, tier, 'tier')}
                        handleDelete={() => openModalWindow('delete-tier', tier.id, tier, 'tier')}
                        handleDuplicate={() => openModalWindow('duplicate-tier', tier.id, tier, 'tier')}
                        isLimitedEdits={isSecondDraft || isLimitedEdits}
                        isDragDisabled={!canDragging.action || loading}
                        canDragging={canDragging}
                        draggableId={tier.id}
                        index={tierIndex}
                      >
                        <Droppable droppableId={'tier' + tier.id} type="SECTIONS">
                          {(provided) => (
                            <SectionsWrapper ref={provided.innerRef} {...provided.droppableProps}>
                              {tier.sections.map((section, sectionIndex) => (
                                <FunnelEditorDraggableDropdown
                                  key={section.id}
                                  title={section.name}
                                  handleEdit={() => openModalWindow('add-section', section.id, section, 'section')}
                                  handleDelete={() => openModalWindow('delete-section', section.id, tier, 'tier')}
                                  handleDuplicate={() => openModalWindow('duplicate-section', tier.id, section, 'section')}
                                  isLimitedEdits={isLimitedEdits}
                                  isDragDisabled={!canDragging.action || loading}
                                  canDragging={canDragging}
                                  draggableId={section.id}
                                  index={sectionIndex}
                                >
                                  <SectionFields
                                    fields={section.fields}
                                    isLimitedEdits={isLimitedEdits}
                                    section={section}
                                    isDragDisabled={!canDragging.action || loading}
                                    canDragging={canDragging}
                                    openPromptEditor={openPromptEditor}
                                    openModalWindow={openModalWindow}
                                  />
                                  {!section.fields.length && (
                                    <ZeroState>
                                      <span style={{ fontWeight: 700 }}>There are currently no fields configured.</span> Please add a field.
                                    </ZeroState>
                                  )}
                                  {!isLimitedEdits && (
                                    <AddButton onClick={() => openModalWindow('add-field', section.id, section, 'section')}>
                                      <AddButtonIcon />
                                      Add New Field
                                    </AddButton>
                                  )}
                                </FunnelEditorDraggableDropdown>
                              ))}
                              {provided.placeholder}
                            </SectionsWrapper>
                          )}
                        </Droppable>
                        {!tier.sections.length && (
                          <ZeroState>
                            <span style={{ fontWeight: 700 }}>There are currently no sections configured.</span> Add a section before adding fields to
                            this tier.
                          </ZeroState>
                        )}
                        {!isLimitedEdits && (
                          <AddButton onClick={() => openModalWindow('add-section', tier.id, tier, 'tier')}>
                            <AddButtonIcon />
                            Add New Section
                          </AddButton>
                        )}
                        <ChecklistWrapper>
                          <FunnelEditorDropdown title="Checklist" showDragAndDropIcon={false}>
                            <TierChecklistItems
                              checklistItems={tier.checklistItems}
                              isLimitedEdits={isLimitedEdits}
                              tierId={tier.id}
                              isDragDisabled={!canDragging.action || loading}
                              canDragging={canDragging}
                              openModalWindow={openModalWindow}
                            />
                            {!tier.checklistItems.length && (
                              <ZeroState>
                                <span style={{ fontWeight: 700 }}>There are currently no checklist items configured. </span>
                                To omit the checklist section entirely simply leave the list of checklist items empty.
                              </ZeroState>
                            )}
                            {!isLimitedEdits && (
                              <AddButton onClick={() => openModalWindow('add-checklist', tier.id, tier, 'tier')}>
                                <AddButtonIcon />
                                Add New Checklist Item
                              </AddButton>
                            )}
                          </FunnelEditorDropdown>
                        </ChecklistWrapper>
                      </FunnelEditorDraggableDropdown>
                    ))}
                    {provided.placeholder}
                  </TiersWrapper>
                )}
              </Droppable>
            </DragDropContext>
            {!tiersData.length && (
              <ZeroState>
                <span style={{ fontWeight: 700 }}>There are currently no tiers configured.</span> Due Diligence funnels require at least one tier to
                be published.
              </ZeroState>
            )}
            {!isSecondDraft && !isLimitedEdits && (
              <AddButton onClick={() => openModalWindow('add-tier')}>
                <AddButtonIcon />
                Add New Tier
              </AddButton>
            )}
          </PaddingWrap>
        </FunnelEditorMainWrap>
      )}
    </>
  );
};

const FunnelEditorMainWrap = styled(MainWrap)`
  margin-bottom: 20px;
`;

const CustomLoader = styled(Loader)`
  height: 100%;
  align-items: center;
`;

const TiersWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 20px;
`;

const SectionsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const ZeroState = styled.div`
  padding: 10px 20px;
`;

const ChecklistWrapper = styled.div`
  margin-top: 20px;
`;

const AddButton = styled(Button)`
  height: max-content;
  display: flex;
  background: ${({ disabled }) => (disabled ? '#dedede' : '#1C488A')};
  align-items: center;
  gap: 8px;
  margin: 20px 0 0 20px;
  padding: 10px 30px;
`;

const DeleteDraftButton = styled(Button)`
  height: max-content;
  display: flex;
  background: ${({ disabled }) => (disabled ? '#dedede' : '#FAFAFA')};
  border-radius: 5px;
  border: 1px solid #4587ec;
  color: ${({ disabled }) => (disabled ? '#ABABAB' : '#4587ec')};
  align-items: center;
  gap: 8px;
  padding: 10px 30px;
`;

const EditFunnelButton = styled(DeleteDraftButton)``;

const PublishButton = styled(Button)`
  height: max-content;
  display: flex;
  background: ${({ disabled }) => (disabled ? '#dedede' : '#4587EC')};
  border: ${({ disabled }) => (disabled ? '1px solid #dedede' : '1px solid #4587ec')};
  align-items: center;
  gap: 8px;
  padding: 10px 30px;
`;

const ButtonsWrap = styled.div`
  display: flex;
  justify-content: flex-end;
  margin: 0;
  gap: 10px;
  margin-top: 50px;
`;
