import { useMemo } from 'react';
import styled from 'styled-components';
import { IAssetClass } from '../types';
import { AssetClassRow } from './assetClassRow';

interface AssetClassProps {
  assetClasses: IAssetClass[];
  targetSubAssetClasses: boolean;
}

const AssetClassTable = ({ assetClasses, targetSubAssetClasses }: AssetClassProps) => {
  const footerSum = useMemo(() => {
    const target = assetClasses.reduce((acc, row) => acc + row.defaultAllocationPercentage, 0);
    return target;
  }, [assetClasses]);

  return (
    <SimpleTableWrap>
      <SimpleTableHeader>
        <HeaderItem>ASSET CLASS</HeaderItem>
        <HeaderItem>TARGET</HeaderItem>
      </SimpleTableHeader>
      <SimpleTableBody>
        {assetClasses.map((row) => (
          <AssetClassRow key={row.id} row={row} targetSubAssetClasses={targetSubAssetClasses} />
        ))}
      </SimpleTableBody>
      <SimpleTableFooter>
        <FooterItem>TOTAL</FooterItem>
        <FooterItem>{footerSum.toFixed(2)}%</FooterItem>
      </SimpleTableFooter>
    </SimpleTableWrap>
  );
};

const SimpleTableBody = styled.div`
  display: flex;
  flex-direction: column;
  //max-height: 150px;
  //overflow-y: scroll;
`;

const FooterItem = styled.div`
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-family: Blinker, serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: 0;
  color: ${({ theme }) => theme.font.strong};
`;

const SimpleTableFooter = styled.div`
  display: flex;
  border-radius: 4px;
  align-items: center;
  justify-content: space-between;
  background-color: ${({ theme }) => theme.layer[2]};
  padding: 5px 24px;
`;

const HeaderItem = styled.div`
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-family: Blinker, serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: 0;
  color: ${({ theme }) => theme.header.action};
`;

const SimpleTableHeader = styled.div`
  display: flex;
  border-radius: 4px;
  align-items: center;
  justify-content: space-between;
  background-color: ${({ theme }) => theme.header.base};
  padding: 5px 24px;
`;

const SimpleTableWrap = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 3px;
`;

export default AssetClassTable;
