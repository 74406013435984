import { ArrowDown } from '@/assets/icons/info-icons/arrowDown';
import { RefreshIcon } from '@/assets/icons/info-icons/refresh';
import FilterSelect, { Divider, FilterItems, PrimaryFilterItem, SearchInput, SearchWrap } from '@/components/basicComponents/select';
import SpecialControlHeader from '@/components/fat-dealCatalog-page/fat-switcher/specialControlHeader';
import { RefreshButton } from '@/components/table/filterPage/desktop-filter';
import { ISort, SORT_ORDER } from '@/components/table/types';
import { useResponsive } from '@/hooks/use-responsive';
import { useClickOutside } from '@/hooks/useClickOutside';
import { MainWrap } from '@/styles/common';
import guid from '@/utils/guid';
import { ChangeEvent, useEffect, useMemo, useRef, useState } from 'react';
import styled, { useTheme } from 'styled-components';

import { ITab } from '@/components/basicComponents/fat-tabs';
import { IFilterBarData } from '@/components/basicComponents/filterBar';
import { INotesData } from '@/components/client-details/types';
import { Investment } from '@/components/fat-dealCatalog-page/types';
import Header from '@/components/fat-header';
import { AdditionalOptionWrap, GoBackButton, GoBackButtonWrap } from '@/components/fat-header/goBackButton';
import { PageTitle } from '@/components/fat-header/pageTitle';
import { LoaderOnWholeScreen } from '@/components/loaders/loader-on-whole-screen';
import MobileModal from '@/components/mobileModal';
import { initialValuesFromTable } from '@/components/table/constants';
import FilterPage from '@/components/table/fat-filterPage';
import { Results, TopWrapper } from '@/components/table/fat-filterPage/desktop-filter';
import TablePagination from '@/components/table/tablePagination';
import { useGoBack } from '@/hooks/useGoBack';
import { useStatusData } from '@/hooks/useStatusData';
import { NetworkStatus, useQuery } from '@apollo/client';
import { useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { sortByName } from '../../fat-addInvestors/utils';
import { GET_INVESTOR_QUERY_BASIC } from '../queries';
import { IInvestorQuery, ILegalEntity } from '../types';
import { allocationsTableSort, commitmentsTableSort, transactionsFilterBarItems, transactionsTableSort } from './constatnts';
import Details from './details';
import TableTransactions from './far-tableTransactions/table';
import DeleteNote from './fat-modals/deleteNote';
import EditAmount from './fat-modals/editAmount';
import EditNote from './fat-modals/editNote';
import { PricingTab } from './fat-pacingTab';
import TableAllocations from './fat-tableAllocations/table';
import TableCommitments from './fat-tableCommitments/table';
import TableSummary from './fat-tableSummary/table';
import { InformationBanner } from './informationBanner';
import { MobileNotes } from './mobileNotes';
import Notes from './notes';
import { ClientDetailsCommitments, ClientsDetailsAllocations, GET_LEGAL_ENTITY_SUMMARY, LIST_ENTITY_TRANSACTIONS } from './queries';
import {
  AssetClassTargetAllocationDto,
  ClientEntityAllocationsTableType,
  ClientEntityDetailsAllocations,
  ClientEntityDetailsCommitments,
  IListEntityTransactions,
  IListEntityTransactionsData,
  TabsEnum
} from './types';

const defaultTabs: ITab[] = [
  { value: TabsEnum.SUMMARY, amount: null },
  { value: TabsEnum.COMMITMENTS, amount: null },
  { value: TabsEnum.ALLOCATIONS, amount: null }
];

export const EntityDetailsPage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const goBack = useGoBack();
  const params = useParams();
  const theme = useTheme();
  const { data: statusData } = useStatusData();

  const [searchParams, setSearchParams] = useSearchParams();

  const backToTitle = searchParams.get('backToTitle') || '';
  const openNotes = searchParams.get('openNotes') === 'true' || false;
  const currentTab = (searchParams.get('tab') || TabsEnum.SUMMARY) as TabsEnum;
  const key = searchParams.get('key') || getDefaultSort().key;
  const asc = getDefaultSort().asc;
  const position = searchParams.get('position') || 0;
  const switcher = searchParams.get('switcher') === null || searchParams.get('switcher') === 'true';
  const q = searchParams.get('q') || '';
  const filterBarParams = searchParams.get('filterBar');
  const primaryFilter = searchParams.get('primaryFilter') || 'All';
  const limit = searchParams.get('limit') || initialValuesFromTable.initialAmountRowsPerPage[0];
  const offset = searchParams.get('offset') || 0;

  const { isMobile, isTablet } = useResponsive();

  const ref = useRef<HTMLDivElement | null>(null);
  useClickOutside(ref, () => setIsOpen(false));

  const [isNotesOpen, setIsNotesOpen] = useState(false);
  const [isTabMenuOpen, setIsTabMenuOpen] = useState(false);
  const [selected, setSelected] = useState('');
  const [isOpen, setIsOpen] = useState(false);
  const [search, setSearch] = useState('');
  const [entityList, setEntityList] = useState<ILegalEntity[]>([]);
  const [list, setList] = useState<ILegalEntity[]>([]);
  const [modalWindow, setModalWindow] = useState({
    isOpen: false,
    type: 'delete-note'
  });
  const [title, setTitle] = useState('');
  const [ignoreAllocationTargets, setIgnoreAllocationTargets] = useState(false);
  const [ignoreBiteSize, setIgnoreBiteSize] = useState(false);
  const [currentRow, setCurrentRow] = useState<ClientEntityAllocationsTableType | null>(null);
  const [currentNote, setCurrentNote] = useState<INotesData | null>(null);
  const [filterBar, setFilterBar] = useState<IFilterBarData[]>(transactionsFilterBarItems);
  const [summaryTableData, setSummaryTableData] = useState<AssetClassTargetAllocationDto[]>([]);
  const [listEntityTransactions, setListEntityTransactions] = useState<IListEntityTransactionsData[]>([]);
  const [tabs, setTabs] = useState<ITab[]>(defaultTabs);
  const [primaryFilterItems, setPrimaryFilterItems] = useState<Investment[]>([]);
  const [isFirstLoading, setIsFirstLoading] = useState(true);
  const [mobileSortItems, setMobileSortItems] = useState<any[]>([]);
  const [mobileSort, setMobileSort] = useState('');
  const sortItem = mobileSortItems.find((item) => item.title === mobileSort);
  const mobileSortList = mobileSortItems.map((item) => item.title);

  function getDefaultSort() {
    const asc = searchParams.get('asc') === null || searchParams.get('asc') === 'true';
    switch (currentTab) {
      case TabsEnum.SUMMARY:
        return { key: 'capacity', asc };
      case TabsEnum.COMMITMENTS:
        return { key: 'name', asc };
      case TabsEnum.ALLOCATIONS:
        return { key: 'investorDocsDue', asc };
      case TabsEnum.ACTIVITY:
        return { key: 'DATE', asc: searchParams.get('asc') === 'true' || false };
      default:
        return { key: '', asc: true };
    }
  }

  useEffect(() => {
    if (!filterBarParams) return;
    const matchedFilterBarValues = transactionsFilterBarItems.map((item) => {
      const matchedFilterItem = JSON.parse(filterBarParams).find((filterItem: string) => filterItem === item.value);
      return {
        ...item,
        enabled: Boolean(matchedFilterItem)
      };
    });
    setFilterBar(matchedFilterBarValues);
  }, []);

  useEffect(() => {
    if (!openNotes) return;

    setIsNotesOpen(true);
    setSearchParams(
      (prev) => {
        prev.delete('openNotes');
        return prev;
      },
      { replace: true }
    );
  }, [openNotes]);

  useEffect(() => {
    if (!isMobile) return;

    switch (currentTab) {
      case TabsEnum.COMMITMENTS:
        setMobileSortItems(commitmentsTableSort);
        setMobileSort(commitmentsTableSort[0].title);
        return;
      case TabsEnum.ALLOCATIONS:
        setMobileSortItems(allocationsTableSort);
        setMobileSort(allocationsTableSort[0].title);
        return;
      case TabsEnum.ACTIVITY:
        setMobileSortItems(transactionsTableSort);
        setMobileSort(transactionsTableSort[0].title);
        return;

      default:
        break;
    }
  }, [currentTab, isMobile]);

  useEffect(() => {
    if (!isMobile) return;

    if (currentTab === TabsEnum.COMMITMENTS) {
      commitmentsRefetch({
        order: sortItem?.asc ? SORT_ORDER.ASC : SORT_ORDER.DESC,
        sort: sortItem?.key
      });
      return;
    }

    if (currentTab === TabsEnum.ALLOCATIONS) {
      allocationsRefetch({
        order: sortItem?.asc ? SORT_ORDER.ASC : SORT_ORDER.DESC,
        sort: sortItem?.key
      });
      return;
    }
  }, [mobileSort]);

  const { data: investorData } = useQuery<IInvestorQuery>(GET_INVESTOR_QUERY_BASIC, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'cache-and-network',
    skip: !params?.investorId,
    variables: {
      data: {
        id: params?.investorId
      }
    },
    onCompleted: (data) => {
      setEntityList(data.getInvestor.legalEntities);
      setList([...data.getInvestor.legalEntities].sort((a, b) => a.entityName.localeCompare(b.entityName)));
    },
    onError: (error) => {
      console.error(error);
    }
  });

  const {
    data: legalEntityData,
    loading: legalEntityLoading,
    refetch: legalEntityRefetch,
    networkStatus: legalEntityNetworkStatus
  } = useQuery(GET_LEGAL_ENTITY_SUMMARY, {
    notifyOnNetworkStatusChange: true,
    skip: !params?.entityId,
    variables: {
      id: params?.entityId,
      includingProposed: switcher
    },
    onCompleted: (data) => {
      setTitle(data.legalEntity.entityName);
      setIgnoreAllocationTargets(data.legalEntity.ignoreAllocationTargets);
      setIgnoreBiteSize(data.legalEntity.ignoreBiteSize);

      const sortedAssetClasses: AssetClassTargetAllocationDto[] = sortByName(data.legalEntity.assetClassTargets as AssetClassTargetAllocationDto[]);

      sortedAssetClasses.forEach((item) => {
        item.subAssetClasses = sortByName(item.subAssetClasses);
      });

      setSummaryTableData(sortedAssetClasses);

      if (data.legalEntity.ignoreAllocationTargets) return;
      const isPacingTabExist = tabs.some((tab) => tab.value === TabsEnum.PACING);
      if (!isPacingTabExist) {
        setTabs([...tabs, { value: TabsEnum.PACING, amount: null }]);
      }
    }
  });

  const {
    data: commitmentsData,
    loading: commitmentsLoading,
    refetch: commitmentsRefetch
  } = useQuery<ClientEntityDetailsCommitments>(ClientDetailsCommitments, {
    notifyOnNetworkStatusChange: true,
    skip: currentTab !== TabsEnum.COMMITMENTS || !params?.entityId,
    variables: {
      includeRecommendation: switcher,
      id: params?.entityId,
      sort: 'assetClass',
      order: SORT_ORDER.ASC,
      search: ''
    },
    onCompleted: () => {
      makeScroll(position);
    }
  });

  const {
    data: allocationsData,
    loading: allocationsLoading,
    refetch: allocationsRefetch
  } = useQuery<ClientEntityDetailsAllocations>(ClientsDetailsAllocations, {
    notifyOnNetworkStatusChange: true,
    skip: currentTab !== TabsEnum.ALLOCATIONS || !params?.entityId,
    variables: {
      includeRecommendation: switcher,
      id: params?.entityId,
      order: asc ? SORT_ORDER.ASC : SORT_ORDER.DESC,
      sort: key
    },
    onCompleted: () => {
      makeScroll(position);
    }
  });

  const {
    data: listEntityTransactionsData,
    loading: listEntityTransactionsLoading,
    refetch: listEntityTransactionsRefetch
  } = useQuery<{ ListEntityTransactions: IListEntityTransactions }>(LIST_ENTITY_TRANSACTIONS, {
    notifyOnNetworkStatusChange: true,
    skip: !isFirstLoading && currentTab !== TabsEnum.ACTIVITY,
    variables: {
      legalEntityId: params?.entityId,
      page: Number(offset) / Number(limit) + 1,
      rowsPerPage: Number(limit),
      sortBy: currentTab === TabsEnum.ACTIVITY ? key : 'DATE',
      order: asc ? SORT_ORDER.ASC : SORT_ORDER.DESC,
      search: q.toLowerCase().trim(),
      transactionTypeFilter: filterBar.filter((item) => item.enabled).map((item) => item.value),
      investmentId: primaryFilter === 'All' ? undefined : primaryFilterItems.find((item) => item.name === primaryFilter)?.id
    },
    onCompleted: (data) => {
      setFilterBar(
        filterBar.map((item) => ({ ...item, count: data.ListEntityTransactions.filters.find((filter) => filter.value === item.value)?.count ?? 0 }))
      );

      setListEntityTransactions(data.ListEntityTransactions.data);
      setPrimaryFilterItems(data.ListEntityTransactions.investments);
      if (data.ListEntityTransactions.data.length && isFirstLoading) {
        const beginAllocationTabIndex = tabs.findIndex((tab) => tab.value === TabsEnum.ALLOCATIONS);
        const newTabs = [
          ...tabs.slice(0, beginAllocationTabIndex + 1),
          { value: TabsEnum.ACTIVITY, amount: null },
          ...tabs.slice(beginAllocationTabIndex + 1)
        ];

        setTabs(newTabs);
      }

      setIsFirstLoading(false);
    },
    onError: (error) => {
      console.error(error);
    }
  });

  const toggleNotes = () => {
    setIsNotesOpen(!isNotesOpen);
    if (isMobile) return;

    const html = document.querySelector('html');
    if (html) {
      html.style.overflowY = isNotesOpen ? 'visible' : 'hidden';
    }
  };

  const handleSelectCLick = (event: any) => {
    if (event.target.id !== 'input') {
      setIsOpen(!isOpen);
    }
  };

  const onItemClick = (entityId: string, investorId: string) => {
    setSelected(entityId);
    setIsFirstLoading(true);
    setTabs(defaultTabs);

    navigate(`/investors/${investorId}/${entityId}?backToTitle=${backToTitle}`, { replace: true });
  };

  const onInvestorNameClick = () => {
    navigate(`/investors/${params?.investorId}?backToTitle=Investors`, { replace: true });
  };

  const handleClickMenuItem = (tab: TabsEnum) => {
    onChangeActiveTab(tab);
    setIsTabMenuOpen(!isTabMenuOpen);
  };

  const saveScrollPostion = () => {
    setSearchParams(
      (prev) => {
        prev.set('position', window.scrollY.toString());
        return prev;
      },
      { replace: true }
    );
  };

  const makeScroll = (position: string | number) => {
    if (!position) return;
    setTimeout(() => window.scrollTo({ top: Number(position), behavior: 'smooth' }), 0);
    setSearchParams(
      (prev) => {
        prev.delete('position');
        return prev;
      },
      { replace: true }
    );
  };

  const toggleSwitcher = () => {
    setSearchParams(
      (prev) => {
        prev.set('switcher', Boolean(!switcher).toString());
        return prev;
      },
      { replace: true }
    );
  };

  const onChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (entityList) {
      const value = event.target.value;
      setSearch(value);
      const newList = [...entityList];
      const filteredList = newList.filter((item) => item.entityName.toLowerCase().indexOf(event.target.value.toLowerCase()) !== -1);
      if (value === '' && filteredList.length === 0) {
        setList(entityList);
      } else {
        setList(filteredList);
      }
    }
  };

  const openNoteModalWindow = (type: string, note?: INotesData) => {
    if (isMobile) {
      toggleNotes();
    }
    setModalWindow({ type, isOpen: true });
    if (note) {
      setCurrentNote(note);
    }
  };

  const openModalWindow = (type: string, row?: ClientEntityAllocationsTableType) => {
    setModalWindow({ type, isOpen: true });
    if (row) {
      setCurrentRow(row);
    }
  };

  const closeModalWindow = () => {
    setModalWindow({ ...modalWindow, isOpen: false });
    setCurrentRow(null);
    setCurrentNote(null);
    if (isMobile) {
      toggleNotes();
    }
  };

  const openOpportunityDetailsPage = (id: string) => {
    saveScrollPostion();
    navigate(`opportunityDetails/${id}?backToTitle=Investor Entity`);
  };

  const openEditEntityPage = () => {
    navigate(`/investors/create-investor?investorId=${params?.investorId}&entityId=${params?.entityId}&backToTitle=Investor Entity`);
  };

  const onChangeActiveTab = (value: TabsEnum) => {
    setSearchParams({ backToTitle, tab: value }, { replace: true });
  };

  const onChangeSort = (value: ISort) => {
    setSearchParams(
      (prev) => {
        prev.set('key', value.key);
        prev.set('asc', value.asc.toString());
        return prev;
      },
      { replace: true }
    );
  };

  const onChangeSearch = (searchValue: string) => {
    setSearchParams(
      (prev) => {
        if (!searchValue) {
          prev.delete('q');
          return prev;
        }
        prev.set('q', searchValue);
        prev.delete('limit');
        prev.delete('offset');
        return prev;
      },
      { replace: true }
    );
  };

  const onChangeFilterBar = (filterBarItems: IFilterBarData[]) => {
    setFilterBar(filterBarItems);
    const filterBarValues = filterBarItems.filter((item) => item.enabled).map((item) => item.value);
    setSearchParams(
      (prev) => {
        if (!filterBarValues.length) {
          prev.delete('filterBar');
          return prev;
        }
        prev.set('filterBar', JSON.stringify(filterBarValues));
        prev.delete('limit');
        prev.delete('offset');
        return prev;
      },
      { replace: true }
    );
  };

  const onChangePrimaryFilter = (filter: string) => {
    setSearchParams(
      (prev) => {
        prev.set('primaryFilter', filter);
        prev.delete('limit');
        prev.delete('offset');
        return prev;
      },
      { replace: true }
    );
  };

  const onChangePaginationData = (limit: number, offset: number) => {
    setSearchParams(
      (prev) => {
        prev.set('limit', limit.toString());
        prev.set('offset', offset.toString());
        return prev;
      },
      { replace: true }
    );
  };

  const detailsData = useMemo(() => {
    switch (currentTab) {
      case TabsEnum.SUMMARY:
        if (legalEntityData?.legalEntity) {
          return {
            ...legalEntityData?.legalEntity,
            totalEntitiesValue: legalEntityData?.legalEntity.totalEntityValue,
            biteSize: {
              fundBiteSize: legalEntityData?.legalEntity.defaultBiteSize1,
              directBiteSize: legalEntityData?.legalEntity.defaultBiteSize2
            }
          };
        }
        return null;
      case TabsEnum.COMMITMENTS:
        return commitmentsData?.ClientEntityDetailsCommitments;
      case TabsEnum.ALLOCATIONS:
        return allocationsData?.ClientEntityDetailsAllocations;
      default:
        break;
    }
  }, [currentTab, legalEntityData, commitmentsData, allocationsData]);

  if (legalEntityNetworkStatus === NetworkStatus.loading) {
    return <LoaderOnWholeScreen size={60} />;
  }

  return (
    <>
      {modalWindow.isOpen && modalWindow.type === 'edit-amount' && (
        <EditAmount
          ignoreBiteSize={legalEntityData?.legalEntity?.ignoreBiteSize ?? null}
          isOpen={modalWindow.isOpen}
          onClose={closeModalWindow}
          row={currentRow}
          refetch={allocationsRefetch}
        />
      )}
      {modalWindow.isOpen && modalWindow.type === 'delete-note' && (
        <DeleteNote
          currentNote={currentNote}
          notes={legalEntityData?.legalEntity.notes ?? null}
          isOpen={modalWindow.isOpen}
          onClose={closeModalWindow}
          refetch={legalEntityRefetch}
        />
      )}
      {modalWindow.isOpen && modalWindow.type === 'edit-note' && (
        <EditNote
          currentNote={currentNote}
          isOpen={modalWindow.isOpen}
          onClose={closeModalWindow}
          notes={legalEntityData?.legalEntity.notes ?? null}
          refetch={legalEntityRefetch}
        />
      )}
      {isMobile && isNotesOpen && (
        <MobileModal onClose={toggleNotes} title="Notes" onClick={() => {}}>
          <MobileNotes data={legalEntityData?.legalEntity.notes} entityName={title} openNoteModalWindow={openNoteModalWindow} />
        </MobileModal>
      )}
      <MainWrap>
        <Header
          specialControl={<SpecialControlHeader switcher={switcher} setSwitcher={toggleSwitcher} title="Include Pending Commitments" />}
          modalControl={
            <GoBackButtonWrap>
              <GoBackButton handleClose={() => goBack({ fallBack: '/investors' })} backToTitle={backToTitle} withAdditionalOption />
              {entityList?.length ? (
                <AdditionalOptionWrap ref={ref} onClick={(event) => handleSelectCLick(event)}>
                  <ArrowDown fill={theme.font.action} />
                  <SelectWrap isMobile={isMobile} openZIndex={10} onClick={(event) => handleSelectCLick(event)} width={'200px'} isOpen={isOpen}>
                    <SearchWrap id="input">
                      <SearchInput id="input" placeholder={'Type Here To Filter Results'} value={search} onChange={onChange} />
                      <Divider />
                    </SearchWrap>
                    <>
                      <SelectItem key={guid()} onClick={onInvestorNameClick}>
                        {investorData?.getInvestor.name ?? ''}
                      </SelectItem>
                    </>
                    {list?.map((el) => (
                      <SelectItem isActive={el.id === selected} key={guid()} onClick={() => onItemClick(el.id, el.family.id)}>
                        {'- ' + el.entityName}
                      </SelectItem>
                    ))}
                  </SelectWrap>
                </AdditionalOptionWrap>
              ) : (
                <></>
              )}
            </GoBackButtonWrap>
          }
        />
        {ignoreAllocationTargets && <InformationBanner />}
        <PageTitle title={title} onEditClick={openEditEntityPage} withBorder={false} />
        <Notes
          data={legalEntityData?.legalEntity.notes}
          entityName={title}
          onClick={toggleNotes}
          isOpen={isNotesOpen}
          openNoteModalWindow={openNoteModalWindow}
          isModalWindowOpen={modalWindow.isOpen}
        />
        <Details
          currentTab={currentTab}
          setCurrentTab={onChangeActiveTab}
          data={detailsData}
          notesData={legalEntityData?.legalEntity.notes ?? null}
          setIsNotesOpen={toggleNotes}
          isTabMenuOpen={isTabMenuOpen}
          setIsTabMenuOpen={setIsTabMenuOpen}
          isGrouped={false}
          ignoreAllocationTargets={ignoreAllocationTargets}
          ignoreBiteSize={ignoreBiteSize}
          handleClickMenuItem={handleClickMenuItem}
          disableDetails={currentTab === TabsEnum.ACTIVITY || currentTab === TabsEnum.PACING}
          assetClassTableData={summaryTableData}
          tabs={tabs}
        />
      </MainWrap>

      {(!isTabMenuOpen || !isMobile) && (
        <MainWrap>
          {currentTab === TabsEnum.SUMMARY && (
            <PaddingWrap isMobile={isMobile}>
              <TableSummary
                investorSummaryRows={summaryTableData}
                refetch={legalEntityRefetch}
                // loading={!isNotesOpen ? legalEntityLoading : false}
                loading={false}
                ignoreAllocationTargets={ignoreAllocationTargets}
                targetSubAssetClasses={statusData?.target_sub_asset_classes || false}
              />
            </PaddingWrap>
          )}
          {currentTab === TabsEnum.COMMITMENTS && (
            <PaddingWrap isMobile={isMobile}>
              {isMobile && (
                <SortSelectWrapper>
                  <FilterSelect
                    isMobile
                    label={'Sort By'}
                    paddingContainer={'0 20px'}
                    width={'100%'}
                    data={mobileSortList}
                    selected={mobileSort}
                    setSelected={setMobileSort}
                  />
                </SortSelectWrapper>
              )}
              <TableCommitments
                investorCommitmentRows={commitmentsData?.ClientEntityDetailsCommitments.commitments}
                sort={{ key, asc }}
                setSort={onChangeSort}
                refetch={commitmentsRefetch}
                loading={commitmentsLoading}
                openOpportunityDetailsPage={openOpportunityDetailsPage}
              />
            </PaddingWrap>
          )}
          {currentTab === TabsEnum.ALLOCATIONS && (
            <PaddingWrap isMobile={isMobile}>
              {isMobile && (
                <SortSelectWrapper>
                  <FilterSelect
                    isMobile
                    label={'Sort By'}
                    paddingContainer={'0 20px'}
                    width={'100%'}
                    data={mobileSortList}
                    selected={mobileSort}
                    setSelected={setMobileSort}
                  />
                </SortSelectWrapper>
              )}
              <CustomRefetch isMobile={isMobile} isTablet={isTablet}>
                <Results>{allocationsData?.ClientEntityDetailsAllocations.allocations.length ?? 0} Results</Results>
                <RefreshButton onClick={() => allocationsRefetch()}>
                  <RefreshIcon fill={theme.action.primary} />
                </RefreshButton>
              </CustomRefetch>
              <TableAllocations
                investorAllocationRows={allocationsData?.ClientEntityDetailsAllocations.allocations}
                sort={{ key, asc }}
                setSort={onChangeSort}
                refetch={allocationsRefetch}
                loading={allocationsLoading}
                openModalWindow={openModalWindow}
                openOpportunityDetailsPage={openOpportunityDetailsPage}
              />
            </PaddingWrap>
          )}
          {currentTab === TabsEnum.ACTIVITY && (
            <PaddingWrap isMobile={isMobile}>
              {isMobile && (
                <SortSelectWrapper>
                  <FilterSelect
                    isMobile
                    label={'Sort By'}
                    paddingContainer={'0 20px'}
                    width={'100%'}
                    data={mobileSortList}
                    selected={mobileSort}
                    setSelected={setMobileSort}
                  />
                </SortSelectWrapper>
              )}
              <CustomFilterPage
                isMobile={isMobile}
                isTablet={isTablet}
                search={{
                  value: q,
                  onChange: onChangeSearch,
                  placeholder: 'Search'
                }}
                refetch={listEntityTransactionsRefetch}
                resultsValue={listEntityTransactionsData?.ListEntityTransactions?.total ?? 0}
                filterBar={filterBar}
                onChangeFilterBar={onChangeFilterBar}
                primaryFilter={{
                  value: primaryFilter,
                  onChange: onChangePrimaryFilter,
                  selects: ['All', ...primaryFilterItems.map((item) => item.name)]
                }}
                mobileSort={{
                  value: mobileSort,
                  selects: mobileSortList,
                  onChange: setMobileSort
                }}
              />
              <TableTransactions
                tableRows={listEntityTransactions}
                sort={{ key, asc }}
                setSort={onChangeSort}
                loading={listEntityTransactionsLoading}
              />
              <PaginationWrap>
                <TablePagination
                  savePagination={onChangePaginationData}
                  paginationValues={{
                    limit: Number(limit),
                    offset: Number(offset),
                    total: listEntityTransactionsData?.ListEntityTransactions?.total ?? 0
                  }}
                  refetch={() => null as any}
                />
              </PaginationWrap>
            </PaddingWrap>
          )}
          {currentTab === TabsEnum.PACING && <PricingTab entityName={title} totalEntityValue={legalEntityData?.legalEntity.totalEntityValue ?? 0} />}
        </MainWrap>
      )}
    </>
  );
};

const PaddingWrap = styled.div<{ isMobile: boolean }>`
  padding: ${({ isMobile }) => (isMobile ? '20px 16px 0 16px' : '40px 16px 0 16px')};
  @media (min-width: 600px) {
    padding-left: 50px;
    padding-right: 50px;
  }
`;

const CustomFilterPage = styled(FilterPage)<{ isMobile: boolean; isTablet: boolean }>`
  position: sticky;
  top: ${({ isMobile, isTablet }) => (isMobile || isTablet ? '50px' : '0')};
  z-index: 9;
  margin-top: 0;
  margin-left: -1px;
  width: calc(100% + 2px);
  padding: ${({ isMobile }) => (isMobile ? '20px 0 0 0' : '20px 0 10px 0')};
`;

const PaginationWrap = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
`;

const CustomRefetch = styled(TopWrapper)<{ isMobile: boolean; isTablet: boolean }>`
  position: sticky;
  top: ${({ isMobile, isTablet }) => (isMobile ? '120px' : isTablet ? '50px' : '0')};
  padding: 10px;
  z-index: 10;
  background-color: ${({ theme }) => theme.layer.base};
`;

const SelectWrap = styled(FilterItems)<{ isMobile: boolean; isOpen: boolean; width: string; openZIndex: number }>`
  width: 245px;
  top: 65px;
  left: ${({ isMobile }) => (isMobile ? '40px' : '60px')};
  overflow-x: hidden;
`;

const SelectItem = styled(PrimaryFilterItem)`
  padding: 11px 9.5px;
  font-size: 16px;
  font-weight: 600;
  color: ${({ theme }) => theme.action.primary};
`;

const SortSelectWrapper = styled.div`
  position: sticky;
  top: 50px;
  z-index: 11;
  background-color: ${({ theme }) => theme.layer.base};
  margin-top: 0;
  padding: 10px 0;
`;
