import FormattingTooltip from '@/components/basicComponents/tooltip/dollarsTooltip';
import { Loader } from '@/components/loaders/loader';
import {
  MobileRow,
  MobileRowTitle,
  MobileRowValue,
  MobileTableRowWrap,
  MobileTableWrapper,
  NoResults
} from '@/components/table/mobileTable/mobileTable';
import { TableColumns } from '@/components/table/types';
import { formatPercent } from '@/utils/currency';
import guid from '@/utils/guid';
import { InvestmentEntityByAdvisoryFirm } from '../../types';

interface MobileTableAdvisoryFirmProps {
  columns: TableColumns[];
  advisoryFirmTableRows: InvestmentEntityByAdvisoryFirm[];
  loading: boolean;
}

const MobileTableAdvisoryFirm = ({ columns, advisoryFirmTableRows, loading }: MobileTableAdvisoryFirmProps) => {
  if (loading) return <Loader />;

  const TableRow = ({ el }: { el: InvestmentEntityByAdvisoryFirm }) => {
    return (
      <MobileTableRowWrap key={guid()}>
        <MobileRow>
          <MobileRowTitle>{columns[0].title}</MobileRowTitle>
          <MobileRowValue>{el.tenant.name}</MobileRowValue>
        </MobileRow>

        <MobileRow>
          <MobileRowTitle>{columns[1].title}</MobileRowTitle>
          <MobileRowValue>{el.investorEntities}</MobileRowValue>
        </MobileRow>

        <MobileRow>
          <MobileRowTitle>{columns[2].title}</MobileRowTitle>
          <MobileRowValue>
            <FormattingTooltip zIndex={1000} key={guid()}>
              {el.committedCapital}
            </FormattingTooltip>
          </MobileRowValue>
        </MobileRow>

        <MobileRow>
          <MobileRowTitle>{columns[3].title}</MobileRowTitle>
          <MobileRowValue>
            <FormattingTooltip zIndex={1000} key={guid()}>
              {el.capitalCalled}
            </FormattingTooltip>
          </MobileRowValue>
        </MobileRow>

        <MobileRow>
          <MobileRowTitle>{columns[4].title}</MobileRowTitle>
          <MobileRowValue>{formatPercent(el.percentOfCapitalCalled)}</MobileRowValue>
        </MobileRow>

        {/* <MobileRow>
          <MobileRowTitle>{columns[5].title}</MobileRowTitle>
          <MobileRowValue>{formatPercent(el.IRR)}</MobileRowValue>
        </MobileRow>

        <MobileRow>
          <MobileRowTitle>{columns[6].title}</MobileRowTitle>
          <MobileRowValue>{el.TVPI}x</MobileRowValue>
        </MobileRow> */}
      </MobileTableRowWrap>
    );
  };

  return (
    <MobileTableWrapper>
      {advisoryFirmTableRows.length ? (
        advisoryFirmTableRows.map((el, index) => {
          return <TableRow el={el} key={index} />;
        })
      ) : (
        <NoResults>No Results</NoResults>
      )}
    </MobileTableWrapper>
  );
};

export default MobileTableAdvisoryFirm;
