import { RedStarIcon } from '@/assets/icons/info-icons/redStar';
import ShowText from '@/components/dueDiligence-page/dueDiligenceRecord/showText';
import { TierDropdown } from '@/components/dueDiligence-page/dueDiligenceRecord/tierDropdown';
import { InfoRowCell, NoRequired, RowWrapper } from '@/components/dueDiligence-page/dueDiligenceRecord/tierSection';
import Button from '@/components/fat-basicComponents/button';
import { useResponsive } from '@/hooks/use-responsive';
import styled from 'styled-components';
import { IGetDocumentRecordQuery } from '../../types';

interface LatestUpdateSectionProps {
  documentRecordData: IGetDocumentRecordQuery | null;
  openEditManagerUpdatePage: (updateId: string) => void;
}

export const LatestUpdateSection = ({ documentRecordData, openEditManagerUpdatePage }: LatestUpdateSectionProps) => {
  const { isMobile } = useResponsive();

  if (!documentRecordData) return <></>;

  return (
    <TierDropdown title={`Latest Update (${documentRecordData.name})`}>
      {documentRecordData.fields.map((field) => (
        <RowWrapper key={field.id} disabled={false} isMobile={isMobile}>
          <InfoRowCell>
            {true ? <RedStarIcon width={10} height={10} /> : <NoRequired />}
            {field.documentField.name}
          </InfoRowCell>
          <ShowText>
            {field.value && field.commentary
              ? `${field.value} | ${field.commentary}`
              : (field.value && field.value) || (field.commentary && field.commentary)}
          </ShowText>
        </RowWrapper>
      ))}
      <ButtonWrapper>
        <Button styleType="outline" size="md">
          Download
        </Button>
        <Button size="md" onClick={() => openEditManagerUpdatePage(documentRecordData.id)}>
          Open Update
        </Button>
      </ButtonWrapper>
    </TierDropdown>
  );
};

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  gap: 10px;
  padding: 20px 0;
`;
