import { useFormik } from 'formik';
import type { FC } from 'react';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import * as Yup from 'yup';

import { useAuth } from '@/hooks/use-auth';
import { useMounted } from '@/hooks/use-mounted';
import { useResponsive } from '@/hooks/use-responsive';
import { MultiFactorAssertion, PhoneAuthProvider, PhoneMultiFactorGenerator, RecaptchaVerifier, getMultiFactorResolver } from 'firebase/auth';

import styled, { useTheme } from 'styled-components';
import Label from '../basicComponents/label';
import Button from '../fat-basicComponents/button';
import { InputFieldProps } from '../input-fields/fat-index';
import { LinksProps } from '../links';
import { PasswordToggleButton } from '../passwordToggleButton';
import EnterCode from './fat-modals/enter-code';
import useRefresh from '../../hooks/use-refresh';
import packageJson from '../../../package.json';

export const FirebaseLogin: FC = (props) => {
  const theme = useTheme();

  const [modalWindow, setModalWindow] = useState({
    isOpen: false,
    type: 'enter-code'
  });
  const [verificationCode, setVerificationCode] = useState<string>();
  const [verificationId, setVerificationId] = useState<string>();
  const [multiFactorAssertion, setMultiFactorAssertion] = useState<MultiFactorAssertion>();
  const [multiFactorResolver, setMultiFactorResolver] = useState<any>();
  const [isLoading, setIsLoading] = useState(false);
  const [isPasswordMasked, setIsPasswordMasked] = useState(true);
  const { isMobile, isTablet, isPortrait } = useResponsive();
  const { signInWithEmailAndPassword, user, auth } = useAuth();
  const isMounted = useMounted();
  const navigate = useNavigate();
  const location = useLocation();

  let recaptchaVerifier = null as any;
  const openModalWindow = (type: string) => {
    setModalWindow({ type, isOpen: true });
  };
  const closeModalWindow = () => {
    setModalWindow({ ...modalWindow, isOpen: false });
  };
  useEffect(() => {
    recaptchaVerifier = new RecaptchaVerifier(
      'sign-in-button',
      {
        size: 'invisible',
        callback: function (response: any) {}
      },
      auth
    );
  }, []);

  useEffect(() => {
    if (verificationCode && verificationId) {
      setIsLoading(true);
      async function verify2FACode(verificationCode: string, verificationId: string) {
        try {
          const cred = PhoneAuthProvider.credential(verificationId, verificationCode);
          const multiFactorAssertion = PhoneMultiFactorGenerator.assertion(cred);
          setMultiFactorAssertion(multiFactorAssertion);
          await multiFactorResolver.resolveSignIn(multiFactorAssertion);
        } catch (error: any) {
          if (error.code == 'auth/invalid-verification-code') {
            if (isMounted()) {
              //TODO: need an error message?
              console.log('throw error message');
            }
          }
          setIsLoading(false);
        }
      }
      verify2FACode(verificationCode, verificationId);
    }
  }, [verificationCode]);

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
      submit: null,
      isSubmitting: true
    },
    validationSchema: Yup.object({
      email: Yup.string().email('Must be a valid email').max(255).required('Email is required'),
      password: Yup.string().required('Password is required')
    }),
    onSubmit: async (values, helpers): Promise<void> => {
      setIsLoading(true);
      try {
        const { appSetting } = await signInWithEmailAndPassword(values.email, values.password);
      } catch (error: any) {
        if (error.code == 'auth/multi-factor-auth-required') {
          const resolver = getMultiFactorResolver(auth, error);
          setMultiFactorResolver(resolver);
          if (resolver.hints[0].factorId === PhoneMultiFactorGenerator.FACTOR_ID) {
            const phoneInfoOptions = {
              multiFactorHint: resolver.hints[0],
              session: resolver.session
            };
            const phoneAuthProvider = new PhoneAuthProvider(auth);
            recaptchaVerifier = new RecaptchaVerifier(
              'sign-in-button',
              {
                size: 'invisible',
                callback: function (response: any) {}
              },
              auth
            );
            try {
              const verificationId = await phoneAuthProvider.verifyPhoneNumber(phoneInfoOptions, recaptchaVerifier);
              setVerificationId(verificationId);
              openModalWindow('enter-code');
            } catch (error) {
              console.log(error);
            }
          } else {
            if (isMounted()) {
              helpers.setStatus({ success: false });
              helpers.setErrors({
                password: 'We do not recognize your login credentials'
              });
              helpers.setSubmitting(true);
            }
          }
        } else if (error.code == 'auth/wrong-password') {
          if (isMounted()) {
            helpers.setStatus({ success: false });
            helpers.setErrors({
              password: 'We do not recognize your login credentials'
            });
            helpers.setSubmitting(true);
          }
        }
      } finally {
        setIsLoading(false);
      }
    }
  });

  useEffect(() => {
    if (user && user.tenant) {
      const path = user.tenant.type === 'advisoryFirm' ? '/dealCatalog' : '/dealPipeline';
      navigate(path, { state: { isFirstOpen: true } });
    }
  }, [user]);

  useRefresh();

  return (
    <>
      {modalWindow.type === 'enter-code' && (
        <EnterCode
          isOpen={modalWindow.isOpen}
          onClose={closeModalWindow}
          updateCodeInState={(value: string) => {
            setVerificationCode(value);
          }}
        />
      )}
      <LoginWrapper isMobile={isMobile} isPortrait={isPortrait} isTablet={isTablet}>
        <LoginForm noValidate onSubmit={formik.handleSubmit} {...props}>
          <div>
            <InputLabelWrap>
              <Label required>Enter Your Email</Label>
            </InputLabelWrap>
            <InputFieldProps
              error={Boolean(formik.touched.email && formik.errors.email)}
              helperText={formik.touched.email && formik.errors.email}
              name="email"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              type="email"
              value={formik.values.email}
            />
          </div>
          <div>
            <InputLabelWrap>
              <Label required>Enter Your Password</Label>
            </InputLabelWrap>
            <InputFieldProps
              error={Boolean(formik.touched.password && formik.errors.password)}
              helperText={formik.touched.password && formik.errors.password}
              name="password"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              type={isPasswordMasked ? 'password' : 'text'}
              value={formik.values.password}
              icon={<PasswordToggleButton isPasswordMasked={isPasswordMasked} setIsPasswordMasked={setIsPasswordMasked} />}
            />
            <SignInButtonWrapper>
              <Button
                id="sign-in-button"
                styleType={formik.isSubmitting || !formik.isValid || isLoading ? 'disabled' : 'default'}
                isLoading={isLoading}
                type="submit"
                size="lg"
                width="100%"
              >
                Sign In
              </Button>
            </SignInButtonWrapper>
          </div>
          <CustomLinksProps underline="none" textColor={theme.font.action} url={'/password-recovery'}>
            Forgot Password
          </CustomLinksProps>
        </LoginForm>
      </LoginWrapper>
      {packageJson.version}
    </>
  );
};

const LoginWrapper = styled.div<{ isMobile: boolean; isPortrait: boolean; isTablet: boolean }>`
  color: #373f4e;
  font-family: 'Blinker', serif;
  font-size: 19px;
  font-weight: 400;
  line-height: 26.6px;
  width: ${({ isMobile, isPortrait, isTablet }) => (isMobile || (isPortrait && isTablet) ? '70%' : '50%')};
`;

const LoginForm = styled.form`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const SignInButtonWrapper = styled.div`
  margin-top: 30px;
`;

export const InputLabelWrap = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const CustomLinksProps = styled(LinksProps)`
  display: flex;
  justify-content: center;
  font-weight: 600;
`;
