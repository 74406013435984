import { InfoIcon } from '@/assets/icons/info-icons/info';
import { Banner } from '@/components/basicComponents/banner';
import Label from '@/components/basicComponents/label';
import ModalLoader from '@/components/fat-basicComponents/modal/modalLoader';
import ModalWrappedContent from '@/components/fat-basicComponents/modal/modalWrappedContent';
import { useMutation } from '@apollo/client';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import styled, { useTheme } from 'styled-components';
import { createDocumentRecordMutation } from '../queries';

interface AddUpdateProps {
  onClose: () => void;
  isOpen: boolean;
  manager: string | null;
  openEditManagerUpdatePage: (updateId: string) => void;
}

const DEFAULT_ERROR_MESSAGE = `We're sorry. This process did not complete. Please try again.`;

const AddUpdate = ({ onClose, isOpen, manager, openEditManagerUpdatePage }: AddUpdateProps) => {
  const params = useParams();
  const theme = useTheme();

  const [value, setValue] = useState('');
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const [createDocumentRecord, { loading: createDocumentRecordLoading }] = useMutation(createDocumentRecordMutation, {
    onError: (error) => {
      console.error(error);
      setErrorMessage(DEFAULT_ERROR_MESSAGE);
    }
  });

  const saveValue = () => {
    setErrorMessage(null);
    if (!manager) {
      setErrorMessage(DEFAULT_ERROR_MESSAGE);
      return;
    }

    createDocumentRecord({
      variables: {
        data: {
          name: value,
          status: 'DRAFT',
          documentType: 'mu',
          manager,
          investmentId: params?.strategyId
        }
      },
      onCompleted: (data) => {
        openEditManagerUpdatePage(data.createDocumentRecord.id);
        onClose();
      }
    });
  };

  return (
    <ModalWrappedContent
      isOpen={isOpen}
      onClose={onClose}
      title={'Add Update'}
      confirmButton={{
        name: 'Save',
        onClick: saveValue,
        disabled: !value.length || createDocumentRecordLoading
      }}
      showRequiredFields={!createDocumentRecordLoading}
      showCloseIcon={!createDocumentRecordLoading}
      showCancelButton={!createDocumentRecordLoading}
      isUseOutsideClick={!createDocumentRecordLoading}
    >
      {createDocumentRecordLoading ? (
        <ModalLoader />
      ) : (
        <>
          {errorMessage && (
            <ErrorBannerWrapper>
              <Banner icon={<InfoIcon width={26} height={26} />} title="Error" description={errorMessage} bgColor={theme.context.error} />
            </ErrorBannerWrapper>
          )}
          <Label required>Name</Label>
          <MainSearch isBackground={!!value.length} type="text" value={value} onChange={(event) => setValue(event.target.value)} autoFocus />
        </>
      )}
    </ModalWrappedContent>
  );
};

export default AddUpdate;

const MainSearch = styled.input<{ isBackground: boolean }>`
  width: 100%;
  border: ${({ isBackground, theme }) => (isBackground ? `1px solid ${theme.context.success}` : `1px solid  ${theme.border.base}`)};
  background-color: ${({ isBackground, theme }) => (isBackground ? '#efffea' : theme.layer[1])};
  color: ${({ isBackground, theme }) => (isBackground ? '' : theme.font.strong)};
  border-radius: 5px;
  padding: 10px 12px;
  font-size: 19px;
  font-family: Blinker;
  font-weight: 400;

  &:focus {
    outline: none;
  }
`;

const ErrorBannerWrapper = styled.div`
  margin-bottom: 20px;
`;
