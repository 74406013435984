import * as React from 'react';

export const BackInfoIcon = ({ ...props }: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M18 7.87626H4.31272L10.5962 1.58605L9.00337 0L0 8.99662L9.00337 18L10.5894 16.4139L4.31272 10.1237H18V7.87626Z"
        fill={props.fill || '#4587EC'}
      />
    </svg>
  );
};
