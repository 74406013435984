import { CloseInfoIcon } from '@/assets/icons/info-icons/closeInfo';
import BasicTooltip from '@/components/basicComponents/tooltip';
import ModalWrappedContent from '@/components/fat-basicComponents/modal/modalWrappedContent';
import { dateFormatter } from '@/utils/dateFormatter';
import { format } from 'date-fns';
import React, { useEffect, useMemo, useState } from 'react';
import { Pie, PieChart, ResponsiveContainer, Sector } from 'recharts';
import styled, { useTheme } from 'styled-components';
import { DrawdownTargetPercentagesDto, DrawdownTargetsDto } from '../types';

interface DrawdownTargetProps {
  onClose: () => void;
  isOpen: boolean;
  drawdownTargetDialogData?: DrawdownTargetsDto | null;
}

const renderActiveShape = (props: any) => {
  const RADIAN = Math.PI / 180;
  const { cx, cy, midAngle, innerRadius, outerRadius, startAngle, endAngle, payload, value } = props;
  const sin = Math.sin(-RADIAN * midAngle);
  const cos = Math.cos(-RADIAN * midAngle);
  const sx = cx + (outerRadius + 10) * cos;
  const sy = cy + (outerRadius + 10) * sin;
  const mx = cx + (outerRadius + 30) * cos;
  const my = cy + (outerRadius + 30) * sin;
  const ex = mx + (cos >= 0 ? 1 : -1) * 22;
  const ey = my;
  const textAnchor = cos >= 0 ? 'start' : 'end';

  const activeFill = '#199BDC';

  return (
    <g>
      <text x={cx} y={cy} dy={8} textAnchor="middle" fill={activeFill}>
        {payload.name}
      </text>
      <Sector cx={cx} cy={cy} innerRadius={innerRadius} outerRadius={outerRadius} startAngle={startAngle} endAngle={endAngle} fill={activeFill} />
      <Sector
        cx={cx}
        cy={cy}
        startAngle={startAngle}
        endAngle={endAngle}
        innerRadius={outerRadius + 6}
        outerRadius={outerRadius + 10}
        fill={activeFill}
      />
      <path d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`} stroke={activeFill} fill="none" />
      <circle cx={ex} cy={ey} r={2} fill={activeFill} stroke="none" />
      <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} textAnchor={textAnchor} fill={activeFill}>
        {payload.name}
      </text>
      <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} dy={18} textAnchor={textAnchor} fill="#919195">
        {`${value.toFixed(2)}%`}
      </text>
    </g>
  );
};

const DrawdownTarget = ({ onClose, isOpen, drawdownTargetDialogData }: DrawdownTargetProps) => {
  const theme = useTheme();

  const [tabs, setTabs] = useState(['Target']);
  const [activeTab, setActiveTab] = useState('Target');
  const [title, setTitle] = useState('');
  const [drawdownData, setDrawdownData] = useState<DrawdownTargetPercentagesDto[]>([]);
  const [evergreenData, setEvergreenData] = useState<DrawdownTargetPercentagesDto[]>([]);
  const [chartData, setChartData] = useState<{ name: string; value: number }[]>([]);
  const [chartActiveIndex, setChartActiveIndex] = useState(0);

  useEffect(() => {
    if (!drawdownTargetDialogData) return;

    const hasDrawdownValues = drawdownTargetDialogData?.actuals?.drawdown.some(
      (item) => item.portfolioPercentage !== 0 || item.privateCapitalPercentage !== 0
    );
    const hasEvergreenValues = drawdownTargetDialogData?.actuals?.evergreen.some(
      (item) => item.portfolioPercentage !== 0 || item.privateCapitalPercentage !== 0
    );

    if (hasDrawdownValues || hasEvergreenValues) {
      setTabs(['Target', `As of ${dateFormatter(format(new Date(), 'yyyy-MM-dd'), 'yyyy-MM-dd')}`]);
    }
  }, [drawdownTargetDialogData]);

  useEffect(() => {
    if (!drawdownTargetDialogData) return;

    if (activeTab === tabs[0]) {
      setDrawdownData(drawdownTargetDialogData?.targets?.drawdown || []);
      setEvergreenData(drawdownTargetDialogData?.targets?.evergreen || []);
      return;
    }

    if (activeTab === tabs[1]) {
      setDrawdownData(drawdownTargetDialogData?.actuals?.drawdown || []);
      setEvergreenData(drawdownTargetDialogData?.actuals?.evergreen || []);
      return;
    }
  }, [activeTab, drawdownTargetDialogData]);

  const drawdownTotals = useMemo(() => {
    return drawdownData.reduce(
      (acc, item) => {
        return {
          portfolioPercentage: acc.portfolioPercentage + item.portfolioPercentage,
          privateCapitalPercentage: acc.privateCapitalPercentage + item.privateCapitalPercentage
        };
      },
      { portfolioPercentage: 0, privateCapitalPercentage: 0 }
    );
  }, [drawdownData]);

  const evergreenTotals = useMemo(() => {
    return evergreenData.reduce(
      (acc, item) => {
        return {
          portfolioPercentage: acc.portfolioPercentage + item.portfolioPercentage,
          privateCapitalPercentage: acc.privateCapitalPercentage + item.privateCapitalPercentage
        };
      },
      { portfolioPercentage: 0, privateCapitalPercentage: 0 }
    );
  }, [evergreenData]);

  useEffect(() => {
    if (activeTab === tabs[0]) {
      setTitle('Drawdown Target');
      return;
    }
    if (activeTab === tabs[1]) {
      setTitle(`Drawdown Allocation as of ${dateFormatter(format(new Date(), 'yyyy-MM-dd'), 'yyyy-MM-dd')}`);
      return;
    }
  }, [activeTab]);

  useEffect(() => {
    setChartData([
      { name: 'Drawdown', value: drawdownTotals.portfolioPercentage },
      { name: 'Evergreen', value: evergreenTotals.portfolioPercentage },
      { name: 'non-PC', value: 100 - drawdownTotals.portfolioPercentage - evergreenTotals.portfolioPercentage }
    ]);
  }, [drawdownTotals, evergreenTotals]);

  const onPieEnter = (index: number) => {
    setChartActiveIndex(index);
  };

  const changeActiveTab = (tab: string) => {
    setActiveTab(tab);
    setChartActiveIndex(0);
  };

  if (!drawdownTargetDialogData || !drawdownTargetDialogData?.actuals?.drawdown || !drawdownTargetDialogData?.actuals?.evergreen) return <></>;

  return (
    <CustomModalWrappedContent isOpen={isOpen} onClose={onClose} showCancelButton={false} showCloseIcon={false} width="900px">
      <ModalHeader>
        <Title>
          <p>{title}</p>
          <p>{drawdownTotals.portfolioPercentage.toFixed(2)}%</p>
        </Title>
        <CloseInfoIcon style={{ cursor: 'pointer' }} onClick={onClose} />
      </ModalHeader>
      <SectionsNavWrapper>
        {tabs.map((tab) => (
          <SectionsNavItem key={tab} isActive={activeTab === tab} onClick={() => changeActiveTab(tab)}>
            {tab}
          </SectionsNavItem>
        ))}
      </SectionsNavWrapper>
      <ModalBodyWrapper>
        <ChartWrapper>
          <ResponsiveContainer width="100%" height={300}>
            <PieChart width={100} height={100}>
              <Pie
                activeIndex={chartActiveIndex}
                activeShape={renderActiveShape}
                data={chartData}
                cx="50%"
                cy="50%"
                innerRadius={60}
                outerRadius={80}
                fill="#D6D6D6"
                dataKey="value"
                onMouseEnter={(_, index) => onPieEnter(index)}
              />
            </PieChart>
          </ResponsiveContainer>
        </ChartWrapper>
        <TableWrapper>
          <TableRow>
            <TableColumn width="75%">
              <PortfolioHeaderItem>Portfolio %</PortfolioHeaderItem>
            </TableColumn>
            <TableColumn width="25%">
              <PcHeaderItem color={theme.font.weak}>PC %</PcHeaderItem>
            </TableColumn>
          </TableRow>
          <TableBodyWrapper>
            <TableBody>
              <TableRow>
                <PortfolioWrapper withBgColor>
                  <CellKey bold>Drawdown</CellKey>
                  <CellValue bold>{drawdownTotals.portfolioPercentage.toFixed(2)}%</CellValue>
                </PortfolioWrapper>
                <PrivateCapitalPercentage withBgColor>
                  <CellValue color={theme.font.weak}>{drawdownTotals.privateCapitalPercentage.toFixed(2)}%</CellValue>
                </PrivateCapitalPercentage>
              </TableRow>
              {drawdownData.map((item) => (
                <React.Fragment key={item.name}>
                  {item.portfolioPercentage !== 0 || item.privateCapitalPercentage !== 0 ? (
                    <TableRow>
                      <PortfolioWrapper withBgColor>
                        <CellKey>{item.name}</CellKey>
                        <CellValue>{item.portfolioPercentage.toFixed(2)}%</CellValue>
                      </PortfolioWrapper>
                      <PrivateCapitalPercentage withBgColor>
                        <CellValue color={theme.font.weak}>{item.privateCapitalPercentage.toFixed(2)}%</CellValue>
                      </PrivateCapitalPercentage>
                    </TableRow>
                  ) : (
                    <></>
                  )}
                </React.Fragment>
              ))}
            </TableBody>
            {evergreenTotals.portfolioPercentage || evergreenTotals.privateCapitalPercentage ? (
              <>
                <TableBody>
                  <TableRow>
                    <PortfolioWrapper withBgColor>
                      <CellKey bold>Evergreen</CellKey>
                      <CellValue bold>{evergreenTotals.portfolioPercentage.toFixed(2)}%</CellValue>
                    </PortfolioWrapper>
                    <PrivateCapitalPercentage withBgColor>
                      <CellValue color={theme.font.weak}>{evergreenTotals.privateCapitalPercentage.toFixed(2)}%</CellValue>
                    </PrivateCapitalPercentage>
                  </TableRow>
                  {evergreenData.map((item) => (
                    <React.Fragment key={item.name}>
                      {item.portfolioPercentage !== 0 || item.privateCapitalPercentage !== 0 ? (
                        <TableRow>
                          <PortfolioWrapper withBgColor>
                            <CellKey>{item.name}</CellKey>
                            <CellValue>{item.portfolioPercentage.toFixed(2)}%</CellValue>
                          </PortfolioWrapper>
                          <PrivateCapitalPercentage withBgColor>
                            <CellValue color={theme.font.weak}>{item.privateCapitalPercentage.toFixed(2)}%</CellValue>
                          </PrivateCapitalPercentage>
                        </TableRow>
                      ) : (
                        <></>
                      )}
                    </React.Fragment>
                  ))}
                </TableBody>
                <TableBody>
                  <TableRow>
                    <PortfolioWrapper>
                      <CellKey bold>Total</CellKey>
                      <CellValue bold>
                        <BasicTooltip
                          tooltipContent={`${(drawdownTotals.portfolioPercentage + evergreenTotals.portfolioPercentage).toFixed(2)}%`}
                          zIndex={1000}
                        >
                          {(drawdownTotals.portfolioPercentage + evergreenTotals.portfolioPercentage).toFixed(0)}%
                        </BasicTooltip>
                      </CellValue>
                    </PortfolioWrapper>
                    <PrivateCapitalPercentage>
                      <CellValue bold color={theme.font.weak}>
                        <BasicTooltip
                          tooltipContent={`${(drawdownTotals.privateCapitalPercentage + evergreenTotals.privateCapitalPercentage).toFixed(2)}%`}
                          zIndex={1000}
                        >
                          {(drawdownTotals.privateCapitalPercentage + evergreenTotals.privateCapitalPercentage).toFixed(0)}%
                        </BasicTooltip>
                      </CellValue>
                    </PrivateCapitalPercentage>
                  </TableRow>
                </TableBody>
              </>
            ) : (
              <></>
            )}
          </TableBodyWrapper>
        </TableWrapper>
      </ModalBodyWrapper>
    </CustomModalWrappedContent>
  );
};

export default DrawdownTarget;

const CustomModalWrappedContent = styled(ModalWrappedContent)`
  padding: 20px 30px 30px;
`;

const ModalHeader = styled.div`
  color: ${({ theme }) => theme.font.strong};
  font-size: 22px;
  font-weight: 400;
  line-height: 22.4px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0;
  border-bottom: 1px solid ${({ theme }) => theme.border.base};
`;

const Title = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
`;

const ModalBodyWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const SectionsNavWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  padding: 10px 0;
  background-color: ${({ theme }) => theme.layer.base};
`;

const SectionsNavItem = styled.div<{ isActive: boolean }>`
  display: flex;
  align-items: center;
  gap: 5px;
  font-size: 13px;
  font-weight: 600;
  line-height: 20px;
  color: ${({ theme, isActive }) => (isActive ? theme.font.base : theme.font.action)};
  cursor: pointer;
  border-bottom: ${({ isActive }) => (isActive ? '4px solid #fbb161' : '4px solid transparent')};
  width: max-content;
  transition:
    color 0.3s,
    border-bottom 0.3s;
`;

const ChartWrapper = styled.div`
  width: 55%;

  .recharts-pie * {
    outline: none !important;
  }
`;

const TableWrapper = styled.div`
  color: ${({ theme }) => theme.font.base};
  width: 45%;
`;

const TableBodyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 13px;
`;

const TableBody = styled.div`
  display: flex;
  flex-direction: column;
  gap: 3px;
`;

const TableRow = styled.div`
  display: flex;
  gap: 3px;
`;

const PortfolioHeaderItem = styled.div`
  margin-left: auto;
  padding: 5px 20px;
  white-space: nowrap;
`;

const PcHeaderItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px 20px;
  white-space: nowrap;
  color: ${({ theme }) => theme.font.weak};
`;

const TableColumn = styled.div<{ width: string }>`
  width: ${({ width }) => width};
  display: flex;
  flex-direction: column;
  gap: 3px;
`;

const PortfolioWrapper = styled.div<{ withBgColor?: boolean }>`
  display: flex;
  justify-content: space-between;
  padding: 5px 20px;
  width: 75%;
  background-color: ${({ theme, withBgColor }) => (withBgColor ? theme.layer[1] : 'transparent')};
`;

const PrivateCapitalPercentage = styled.div<{ withBgColor?: boolean }>`
  display: flex;
  justify-content: center;
  padding: 5px 20px;
  width: 25%;
  background-color: ${({ theme, withBgColor }) => (withBgColor ? theme.layer[1] : 'transparent')};
`;

const CellKey = styled.div<{ bold?: boolean; color?: string }>`
  font-weight: ${({ bold }) => (bold ? 600 : 400)};
  color: ${({ color }) => color || 'inherit'};
  word-break: break-all;
`;

const CellValue = styled.div<{ bold?: boolean; color?: string }>`
  font-weight: ${({ bold }) => (bold ? 600 : 400)};
  color: ${({ color }) => color || 'inherit'};
  white-space: nowrap;
`;
