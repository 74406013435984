import { InfoIcon } from '@/assets/icons/info-icons/info';
import { Banner } from '@/components/basicComponents/banner';
import ModalLoader from '@/components/fat-basicComponents/modal/modalLoader';
import ModalWrappedContent from '@/components/fat-basicComponents/modal/modalWrappedContent';
import { DatePicker } from '@/components/fat-form-field';
import { useMutation } from '@apollo/client';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import styled, { useTheme } from 'styled-components';
import { updateDocumentRecordStatusMutation } from '../queries';

interface PublishUpdateProps {
  onClose: () => void;
  isOpen: boolean;
  updateId: string | null;
  ownerEmail: string | null;
  refetch: () => void;
}

const DEFAULT_ERROR_MESSAGE = `We're sorry. This process did not complete. Please try again.`;

const PublishUpdate = ({ onClose, isOpen, updateId, ownerEmail, refetch }: PublishUpdateProps) => {
  const theme = useTheme();
  const {
    handleSubmit,
    getValues,
    control,
    formState: { errors, isValid }
  } = useForm({ defaultValues: { publishDate: new Date() } });

  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const [updateDocumentRecordStatus, { loading: updateDocumentRecordStatusLoading }] = useMutation(updateDocumentRecordStatusMutation, {
    onError: (error) => {
      console.error(error);
      setErrorMessage(DEFAULT_ERROR_MESSAGE);
    }
  });

  const saveValue = () => {
    setErrorMessage(null);

    if (!isValid) return;

    if (!updateId || !ownerEmail) {
      setErrorMessage(DEFAULT_ERROR_MESSAGE);
      return;
    }

    const { publishDate } = getValues();

    updateDocumentRecordStatus({
      variables: {
        data: {
          id: updateId,
          status: 'PUBLISHED',
          statusUpdatedAt: publishDate,
          publisherEmail: ownerEmail
        }
      },
      onCompleted: () => {
        refetch();
        onClose();
      }
    });
  };

  return (
    <ModalWrappedContent
      isOpen={isOpen}
      onClose={onClose}
      title={'Publish Update'}
      confirmButton={{
        name: 'Publish',
        onClick: handleSubmit(saveValue),
        disabled: updateDocumentRecordStatusLoading
      }}
      isUseOutsideClick={false}
      showRequiredFields={!updateDocumentRecordStatusLoading}
      showCloseIcon={!updateDocumentRecordStatusLoading}
      showCancelButton={!updateDocumentRecordStatusLoading}
    >
      {updateDocumentRecordStatusLoading ? (
        <ModalLoader />
      ) : (
        <>
          {errorMessage && (
            <ErrorBannerWrapper>
              <Banner icon={<InfoIcon width={26} height={26} />} title="Error" description={errorMessage} bgColor={theme.context.error} />
            </ErrorBannerWrapper>
          )}
          <DatePicker
            title={'Publish Date'}
            nameField={'publishDate'}
            control={control}
            rules={{
              required: {
                value: true,
                message: 'Publish Date is required'
              }
            }}
            errors={errors}
            tooltip={'Set the Next Close date that you wish to track.  You can edit this date at any time.'}
            tooltipWidth={250}
          />
        </>
      )}
    </ModalWrappedContent>
  );
};

export default PublishUpdate;

const ErrorBannerWrapper = styled.div`
  margin-bottom: 20px;
`;
