import FormattingTooltip from '@/components/basicComponents/tooltip/dollarsTooltip';
import NoResults from '@/components/table/noResults';
import RedirectTitleCell from '@/components/table/redirectTitleCell';
import { TableRowCell, TableRowWrapper, TextCell } from '@/components/table/styles';
import { InvestmentManager } from '@/types/investments';
import guid from '@/utils/guid';
import { managersColumns } from '../constatnts';

interface TableBodyProps {
  tableData: InvestmentManager[];
  handleManagerClick: (name: string) => void;
}

const TableBody = ({ tableData, handleManagerClick }: TableBodyProps) => {
  if (!tableData?.length) {
    return <NoResults />;
  }

  return (
    <>
      {tableData.map((row: InvestmentManager) => (
        <TableRowWrapper key={guid()}>
          <TableRowCell width={managersColumns[0].width}>
            <RedirectTitleCell id="actionCurioText" onClick={() => handleManagerClick(row.name)}>
              {row.name}
            </RedirectTitleCell>
          </TableRowCell>
          <TableRowCell width={managersColumns[1].width}>
            <TextCell>{row.countOfFunds}</TextCell>
          </TableRowCell>
          <TableRowCell width={managersColumns[2].width}>
            <TextCell>{row.countOfInvestors}</TextCell>
          </TableRowCell>
          <TableRowCell width={managersColumns[3].width}>
            <TextCell>
              <FormattingTooltip key={guid()}>{row.committedCapital}</FormattingTooltip>
            </TextCell>
          </TableRowCell>
        </TableRowWrapper>
      ))}
    </>
  );
};

export default TableBody;
