import { gql } from '@apollo/client';

export const DUE_DILIGENCE_FUNNEL_LIST_QUERY = gql`
  query ListDueDiligenceFunnels($data: DueDiligenceFunnelListDto!) {
    listDueDiligenceFunnels(data: $data) {
      id
      funnelStaticId
      name
      createdAt
      status
      records {
        id
        investment {
          name
          securityStatus
        }
      }
    }
  }
`;

export const DUE_DILIGENCE_FUNNEL_QUERY = gql`
  query GetDueDiligenceFunnel($id: String!, $sort: DueDiligenceRecordsSortBy!, $order: DueDiligenceRecordsSortDirection!) {
    getDueDiligenceFunnel(id: $id) {
      id
      funnelStaticId
      name
      externalId
      tiers {
        id
        name
        order
        tasksCount
        records(sortInput: { sortBy: $sort, sortDirection: $order }) {
          id
          status
          investment {
            id
            name
            assetClass
            managerId
            finalClose
            securityStatus
          }
          owner {
            id
            firstName
            lastName
            email
          }
          activeTierOpenTasksCount
        }
      }
    }
  }
`;

export const DUE_DILIGENCE_RECORD_QUERY = gql`
  query GetDueDiligenceRecord($id: String!) {
    getDueDiligenceRecord(id: $id) {
      id
      activeTier {
        id
        name
        order
      }
      activeTierOpenTasksCount
      status
      approver {
        id
        firstName
        lastName
      }
      statusUpdatedAt
      note
      createdAt
      updatedAt
      investment {
        id
        name
        securityStatus
        description
      }
      owner {
        id
        firstName
        lastName
      }
      funnel {
        id
        funnelStaticId
        externalId
        name
        tiers {
          id
          name
          order
          tasksCount
          data {
            id
            status
            approver {
              id
              firstName
              lastName
            }
            statusUpdatedAt
            note
            createdAt
            updatedAt
          }
          sections {
            id
            name
            order
            pdfLayout
            userCanScore
            data {
              id
              updatedAt
              completedBy {
                id
                firstName
                lastName
              }
              completedAt
              assignedTo {
                id
                firstName
                lastName
              }
              assignedAt
              assignedBy {
                id
                firstName
                lastName
              }
              note
              score
              completed
            }
            fields {
              id
              name
              type
              order
              type
              required
              promptGroup {
                id
                prompts
                summaryPrompt
                contextPrompt
                rubricTemplates
                useCustomContextPrompt
                useCustomSummaryPrompt
              }
              data {
                id
                value
                commentary
                linked
                attachments {
                  id
                  asset {
                    assetKey
                    url
                  }
                  includeInAutomatedContent
                }
              }
              linkedField {
                id
                name
                funnelTierSection {
                  funnelTier {
                    name
                  }
                }
              }
              AI {
                answers
                citations {
                  answer
                  prompt
                  filename
                  embeddingId
                  pageNumbers
                }
                summary
              }
              labelerData {
                filename
                value
              }
            }
          }
          checklistItems {
            id
            name
            required
            data {
              id
              completed
              completedAt
              completedBy {
                id
                firstName
                lastName
              }
              note
              createdAt
              updatedAt
              assignedTo {
                firstName
                lastName
              }
              assignedBy {
                firstName
                lastName
              }
            }
          }
        }
      }
    }
  }
`;

export const GET_DUE_DILIGENCE_FUNNEL = gql`
  query GetDueDiligenceFunnel($id: String!) {
    getDueDiligenceFunnel(id: $id) {
      latestFunnel {
        tiers {
          id
          name
          order
        }
      }
    }
  }
`;

export const DUE_DILIGENCE_RECORD_QUERY_MIN = gql`
  query GetDueDiligenceRecord($id: String!) {
    getDueDiligenceRecord(id: $id) {
      id
      status
      manager
      investment {
        id
        name
        subAssetClasses
        targetIRR
        targetYield
        vintageYear
        finalClose
        assetClass
        tags
        contact
        referral
        description
      }
      owner {
        id
        firstName
        lastName
      }
      funnel {
        id
        funnelStaticId
        externalId
        name
      }
    }
  }
`;

export const ASSIGN_CHECKLIST_ITEM_MUTATION = gql`
  mutation assignDueDiligenceRecordChecklistItem($data: DueDiligenceRecordAssignChecklistItemDto!) {
    assignDueDiligenceRecordChecklistItem(data: $data) {
      id
      note
      assignedTo {
        id
        firstName
        lastName
      }
      assignedBy {
        id
        firstName
        lastName
      }
      updatedAt
    }
  }
`;

export const ASSIGN_SECTION_MUTATION = gql`
  mutation assignDueDiligenceRecordSection($data: DueDiligenceRecordAssignSectionDto!) {
    assignDueDiligenceRecordSection(data: $data) {
      id
      note
      assignedTo {
        id
        firstName
        lastName
      }
      assignedBy {
        id
        firstName
        lastName
      }
      updatedAt
    }
  }
`;

export const updateDueDiligenceRecordSectionScoreMutation = gql`
  mutation updateDueDiligenceRecordSectionScore($data: DueDiligenceRecordUpdateSectionScoreDto!) {
    updateDueDiligenceRecordSectionScore(data: $data) {
      id
      score
    }
  }
`;

export const LIST_USERS_QUERY = gql`
  query ListUsers($filters: ListUsersFilterDto!) {
    listUsers(filters: $filters) {
      id
      firstName
      lastName
      email
      roles
    }
  }
`;

export const UPDATE_FIELD_ANSWER_MUTATION = gql`
  mutation updateDueDiligenceRecordFieldAnswer($data: DueDiligenceRecordFieldAnswerUpdateDto!) {
    updateDueDiligenceRecordFieldAnswer(data: $data) {
      id
      value
      commentary
    }
  }
`;

export const UPDATE_FIELD_ANSWERS_MUTATION = gql`
  mutation updateDueDiligenceRecordFieldAnswers($data: [DueDiligenceRecordFieldAnswerUpdateDto!]!) {
    updateDueDiligenceRecordFieldAnswers(data: $data) {
      id
      value
    }
  }
`;

export const TOGGLE_SECTION_COMPLETION_MUTATION = gql`
  mutation toggleDueDiligenceRecordSectionCompletion($data: DueDiligenceRecordToggleSectionCompletionDto!) {
    toggleDueDiligenceRecordSectionCompletion(data: $data) {
      id
      completed
      note
      completedBy {
        id
        firstName
        lastName
      }
      completedAt
    }
  }
`;

export const UPDATE_TIER_STATUS_MUTATION = gql`
  mutation updateDueDiligenceRecordTierStatus($data: DueDiligenceRecordUpdateTierStatusDto!) {
    updateDueDiligenceRecordTierStatus(data: $data) {
      id
      note
    }
  }
`;

export const UPDATE_CHECKLIST_ITEM_STATUS_MUTATION = gql`
  mutation toggleDueDiligenceRecordChecklistItem($data: DueDiligenceRecordToggleChecklistItemDto!) {
    toggleDueDiligenceRecordChecklistItem(data: $data) {
      id
      completed
      note
      completedBy {
        id
        firstName
        lastName
      }
      completedAt
    }
  }
`;

export const UPDATE_RECORD_STATUS_MUTATION = gql`
  mutation updateDueDiligenceRecordStatus($data: DueDiligenceRecordUpdateStatusDto!) {
    updateDueDiligenceRecordStatus(data: $data) {
      id
      note
    }
  }
`;

export const createDueDiligenceRecordMutation = gql`
  mutation createDueDiligenceRecord($data: DueDiligenceRecordCreateDto!) {
    createDueDiligenceRecord(data: $data) {
      id
    }
  }
`;

export const UPDATE_INVESTMENT = gql`
  mutation updateInvestment($data: InvestmentEntityUpdateDto!) {
    updateInvestment(data: $data) {
      id
    }
  }
`;

export const updateDueDiligenceRecordMutation = gql`
  mutation updateDueDiligenceRecord($data: DueDiligenceRecordUpdateDto!) {
    updateDueDiligenceRecord(data: $data) {
      id
    }
  }
`;

export const GET_URL_FOR_UPLOAD = gql`
  query getDueDiligenceRecordFieldAnswerAttachmentUploadUrl($data: DueDiligenceRecordFieldAnswerAttachmentUploadUrlDto!) {
    getDueDiligenceRecordFieldAnswerAttachmentUploadUrl(data: $data) {
      assetKey
      url
    }
  }
`;

export const GET_URL_FOR_AI_ASSET_UPLOAD = gql`
  query getDealAssistantAIAssetUploadUrl($data: DealAssistantAIAssetUploadUrlDto!) {
    getDealAssistantAIAssetUploadUrl(data: $data) {
      assetKey
      url
    }
  }
`;

export const GET_AI_ASSETS = gql`
  query getDealAssistentAIAssets($data: FetchDealAssistantAIAssetArgs!) {
    getDealAssistentAIAssets(data: $data) {
      investmentId
      filename
      status
    }
  }
`;

export const GET_AI_RUN_STATUS = gql`
  query getAIRunStatus($investmentId: String, $AIRunId: String) {
    getAIRunStatus(investmentId: $investmentId, AIRunId: $AIRunId) {
      id
      status
      startedAt
      investment {
        name
      }
      completedAt
      startedBy {
        firstName
        lastName
      }
      filenames
    }
  }
`;

export const GET_AI_CHAT_THREAD = gql`
  query getChatThread($data: DealAssistantChatThreadArgs!) {
    getChatThread(data: $data) {
      answers {
        prompt
        answer
        citations {
          answer
          pageNumbers
          filename
        }
      }
    }
  }
`;

export const createDealAssistantAIAsset = gql`
  mutation createDealAssistantAIAsset($data: CreateAIAssetDto!) {
    createDealAssistantAIAsset(data: $data) {
      id
    }
  }
`;

export const processDealAssistantAIAsset = gql`
  mutation processDealAssistantAIAsset($data: DealAssistantProcessPDFArgs!) {
    processDealAssistantAIAsset(data: $data) {
      id
    }
  }
`;

export const generateAnswersQuery = gql`
  mutation ($data: DealAssistantGenerateAnswersAIRunArgs!) {
    generateAnswers(data: $data) {
      id
      status
    }
  }
`;

export const generateChatReplyQuery = gql`
  mutation ($data: DealAssistantGenerateChatReplyArgs!) {
    generateChatReply(data: $data) {
      answers {
        answer
        citations {
          answer
          prompt
          filename
          embeddingId
          pageNumbers
        }
      }
    }
  }
`;

export const addDueDiligenceRecordAttachment = gql`
  mutation addDueDiligenceRecordFieldAnswerAttachment($data: DueDiligenceRecordFieldAnswerAddAttachmentDto!) {
    addDueDiligenceRecordFieldAnswerAttachment(data: $data) {
      id
      asset {
        assetKey
        url
      }
      includeInAutomatedContent
    }
  }
`;

export const updateDueDiligenceRecordAttachment = gql`
  mutation updateDueDiligenceRecordFieldAnswerAttachment($data: DueDiligenceRecordFieldAnswerAddAttachmentUpdateDto!) {
    updateDueDiligenceRecordFieldAnswerAttachment(data: $data) {
      id
      includeInAutomatedContent
    }
  }
`;

export const deleteDueDiligenceRecordAttachment = gql`
  mutation deleteDueDiligenceRecordFieldAnswerAttachment($id: String!) {
    deleteDueDiligenceRecordFieldAnswerAttachment(id: $id)
  }
`;

export const getCurrentManagers = gql`
  query getCurrentManagers {
    getCurrentManagers {
      values
    }
  }
`;

export const getCurrentSubAssetClasses = gql`
  query getCurrentSubAssetClasses {
    getCurrentSubAssetClasses {
      values
    }
  }
`;

export const getCurrentTags = gql`
  query getCurrentTags {
    getCurrentTags {
      values
    }
  }
`;

export const IMPORT_DUE_DILIGENCE_RECORD_MUTATION = gql`
  mutation importDueDiligenceRecords($data: DueDiligenceRecordImportRequestDto!) {
    importDueDiligenceRecords(data: $data) {
      status
    }
  }
`;

export const GET_DUE_DILIGENCE_LIMITS = gql`
  query getDueDiligenceLimits {
    getDueDiligenceLimits {
      trialMode
      canCreateDeal
      currentDDRRecordCount
      maxDeals
    }
  }
`;

export const relinkDueDiligenceRecordFieldAnswer = gql`
  mutation relinkDueDiligenceRecordFieldAnswer($id: String!) {
    relinkDueDiligenceRecordFieldAnswer(id: $id) {
      id
    }
  }
`;

export const updateGroundTruthMutation = gql`
  mutation updateGroundTruth($data: DealAssistantAIGroundTruthUpdateDto!) {
    updateGroundTruth(data: $data) {
      status
    }
  }
`;

export const createGroundTruthMutation = gql`
  mutation createGroundTruth($data: CreateDealAssistantAIGroundTruthDto!) {
    createGroundTruth(data: $data) {
      status
    }
  }
`;

export const updateHumanScoreMutation = gql`
  mutation updateHumanScore($data: DealAssistantAILogUpdateDto!) {
    updateHumanScore(data: $data) {
      status
    }
  }
`;

export const updateRawAnswerMutation = gql`
  mutation updateRawAnswer($data: DealAssistantAILogUpdateDto!) {
    updateRawAnswer(data: $data) {
      status
    }
  }
`;
