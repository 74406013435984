import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import { styled } from 'styled-components';
import TextField from '@/components/muis/textField';
import { format } from 'date-fns';
import React, { useState } from 'react';
import { WrapperHead } from '..';

interface DatePickerProps {
  title: string;
  name: string;
  errors: any;
  value: Date | Date[] | null;
  changeValue: (value: string | string[]) => void;
  className?: string;
  isWarning?: boolean;
  chooseSeveralDates?: boolean;
  tooltip?: string;
}

export const DatePicker: React.FC<DatePickerProps> = ({
  title,
  errors,
  name,
  value,
  changeValue,
  className,
  isWarning,
  chooseSeveralDates,
  tooltip
}) => {
  const [open, setOpen] = useState(false);
  return (
    <div style={{ width: '100%' }} className={className}>
      <WrapperHead title={title} isWarning={isWarning} tooltip={tooltip} />
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-start',
          gap: '8px',
          width: '100%'
        }}
      >
        {chooseSeveralDates && (
          <AddDate onClick={() => setOpen(true)}>
            <ImagePlus src="/static/icons/plus-circle.svg" alt="Plus" />
            <AddDateText>Add Another</AddDateText>
          </AddDate>
        )}
      </div>
    </div>
  );
};

const AddDate = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px 10px;
  gap: 6px;
  cursor: pointer;
`;

const AddDateText = styled('span')`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 16;
  color: #578fe4;
`;

const Placeholder = styled(TextField)(() => ({
  '&.MuiTextField-root .MuiOutlinedInput-input': {
    padding: '11.5px 14px',
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: 400,
    color: 'black'
  }
}));

const ImagePlus = styled('img')`
  height: 20;
  width: 20;
`;
