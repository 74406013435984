import { gql } from '@apollo/client';

export const MANAGER_INVESTMENTS_MIN_QUERY = gql`
  query ManagerInvestments($managerId: String, $page: Int, $rowsPerPage: Int, $sort: String, $order: String) {
    ManagerInvestments(managerId: $managerId, page: $page, rowsPerPage: $rowsPerPage, sort: $sort, order: $order) {
      investments {
        id
        name
      }
    }
  }
`;

export const GET_DOCUMENT_RECORD_QUERY = gql`
  query GetDocumentRecord($id: String!) {
    getDocumentRecord(id: $id) {
      id
      name
      status
      statusUpdatedAt
      note
      createdAt
      updatedAt
      deletedAt
      assignee {
        id
        firstName
        lastName
        email
      }
      publisher {
        id
        firstName
        lastName
        email
      }
      owner {
        id
        firstName
        lastName
        email
      }
      fields {
        id
        value
        commentary
        createdAt
        updatedAt
        deletedAt
        recordId
        fieldId
        labelerData
        documentField {
          id
          name
          type
          order
        }
      }
    }
  }
`;

export const LIST_DOCUMENT_RECORDS_QUERY = gql`
  query ListDocumentRecords($data: DocumentRecordsListDto!) {
    listDocumentRecords(data: $data) {
      total
      limit
      offset
      records {
        id
        name
        status
        statusUpdatedAt
        owner {
          id
          firstName
          lastName
          email
        }
      }
    }
  }
`;

export const INVESTMENT_DETAILS_QUERY = gql`
  query Investment($id: String!) {
    Investment(id: $id) {
      id
      symbol
      name
      managerId
      assetClass {
        id
        name
      }
      sectors
      targetReturn
      targetYield
      vintageYear
      nextClose
      finalClose
      description
    }
  }
`;

export const createDocumentRecordMutation = gql`
  mutation CreateDocumentRecord($data: DocumentRecordCreateDto!) {
    createDocumentRecord(data: $data) {
      id
    }
  }
`;

export const updateDocumentRecordMutation = gql`
  mutation updateDocumentRecord($data: DocumentRecordUpdateDto!) {
    updateDocumentRecord(data: $data) {
      id
    }
  }
`;

export const updateDocumentRecordStatusMutation = gql`
  mutation updateDocumentRecordStatus($data: DocumentRecordUpdateStatusDto!) {
    updateDocumentRecordStatus(data: $data) {
      id
    }
  }
`;

export const updateDocumentFieldAnswerMutation = gql`
  mutation updateDocumentFieldAnswer($data: DocumentFieldAnswerUpdateDto!) {
    updateDocumentFieldAnswer(data: $data) {
      id
    }
  }
`;

export const deleteDocumentRecordMutation = gql`
  mutation deleteDocumentRecord($id: String!) {
    deleteDocumentRecord(id: $id)
  }
`;
