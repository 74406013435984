import { Input } from '@/components/fat-form-field';
import { useResponsive } from '@/hooks/use-responsive';
import { useTheme } from 'styled-components';
import { SectionsWrap, TwoColumnEntityWrapper } from '..';
import { Section } from '../../fat-investorDetails-page/fat-entityDetails-page/edit-entity/section';

interface DataIntegrationProps {
  control: any;
}

export const DataIntegration = ({ control }: DataIntegrationProps) => {
  const theme = useTheme();
  const { isMobile } = useResponsive();

  return (
    <SectionsWrap>
      <Section title="Data Integration">
        <TwoColumnEntityWrapper isMobile={isMobile}>
          <Input
            title={'Advisor CRM ID'}
            nameField={'advisorCRMId'}
            rules={{}}
            control={control}
            tooltipWidth={400}
            tooltip="This identifier is used when bulk updating entities using the investor entity upload file or through an integration with an external CRM."
          />
          <Input
            title={'Advisor Reporting Account Number'}
            nameField={'advisorReportingAccountNumber'}
            rules={{}}
            control={control}
            tooltipWidth={400}
            tooltip="This identifier is used when you export reporting data for upload into your reporting system. If you do not provide an ID reporting data for this entity will not be included in the reporting data export."
          />
        </TwoColumnEntityWrapper>
        <TwoColumnEntityWrapper isMobile={isMobile}>
          <Input
            title={'Administrator Code'}
            nameField={'administratorCode'}
            rules={{}}
            control={control}
            tooltipWidth={400}
            tooltip="This is a static identifier assigned by the fund administrator"
            disabled={true}
          />
        </TwoColumnEntityWrapper>
      </Section>
    </SectionsWrap>
  );
};
