import Label from '@/components/basicComponents/label';
import ModalWrappedContent from '@/components/fat-basicComponents/modal/modalWrappedContent';
import { Dollar, InputWrap } from '@/components/fat-dealCatalog-page/fat-allocator-page/fat-modals/editAmount';
import { Count, CountWrap, Text } from '@/components/fat-dealCatalog-page/fat-allocator-page/fat-modals/submitTo';
import { createAllocationMutation } from '@/components/opportunities-entity/pendingAllocation/constants';
import ErrorNotification from '@/components/recommendations-page/error-notification';
import { PROPOSAL_TYPE } from '@/types/investments';
import { useMutation } from '@apollo/client';
import { AnimatePresence, motion } from 'framer-motion';
import { ChangeEvent, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import styled from 'styled-components';
import { ClientEntityAllocationsTableType } from '../types';

interface EditAmountProps {
  onClose: () => void;
  isOpen: boolean;
  row: ClientEntityAllocationsTableType | null;
  refetch: () => void;
  ignoreBiteSize?: boolean | null;
}

interface ErrorMessage {
  title: string;
  message: string;
}

const EditAmount = ({ onClose, isOpen, row, refetch, ignoreBiteSize = false }: EditAmountProps) => {
  const params = useParams();

  const [value, setValue] = useState('');
  const [errorText, setErrorText] = useState<ErrorMessage | null>(null);
  const [isConfirmationDialogOpen, setIsConfirmationDialogOpen] = useState(false);

  useEffect(() => {
    if (!row) return;

    setValue(ignoreBiteSize ? row.minimum.toLocaleString('en-US') : row.committedCapital.toLocaleString('en-US'));
  }, [row]);

  const [createAllocations] = useMutation(createAllocationMutation);

  const validateAmount = (amount: number, allocation: ClientEntityAllocationsTableType) => {
    let errorMessage: ErrorMessage | null = null;
    if (value[0] === '0') {
      errorMessage = {
        title: 'Cannot be less than 0',
        message: 'Any increase investment value must be greater than 0'
      };
    }
    if (allocation.proposalType === PROPOSAL_TYPE.INITIAL) {
      if (amount < allocation.minimum) {
        errorMessage = {
          title: 'Cannot be less than fund minimum',
          message: 'The initial investment amount must be equal or greater than the investment minimum.'
        };
      }
    }
    if (allocation.proposalType === PROPOSAL_TYPE.INCREASE) {
      if (amount <= 0) {
        errorMessage = {
          title: 'Cannot be less than 0',
          message: 'Any increase investment value must be greater than 0'
        };
      }
    }

    setErrorText(errorMessage);
    return errorMessage;
  };

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value.replace(/\D/g, '');
    if (!value) {
      setValue('');
      return;
    }
    const formattedValue = parseFloat(value).toLocaleString('en-US');

    setValue(formattedValue);
  };

  const saveValue = () => {
    const errorMessage = validateAmount(Number(value.replace(/\D/g, '')), row);
    if (errorMessage) return;
    if (!isConfirmationDialogOpen) {
      setIsConfirmationDialogOpen(true);
      return;
    }
    createAllocations({
      variables: {
        data: [
          {
            investmentId: row.investmentId,
            legalEntityId: params?.entityId,
            committedCapital: Number(value.replace(/\D/g, '')),
            allocationStatus: 'Outstanding',
            proposalType: row.proposalType,
            suitability: row.suitability,
            decision: true,
            reason: ''
          }
        ]
      },
      onCompleted: () => {
        setValue('');
        refetch();
        onClose();
      }
    });

    return;
  };

  if (!row) return <></>;

  return (
    <ModalWrappedContent
      isOpen={isOpen}
      onClose={onClose}
      title={!isConfirmationDialogOpen ? 'Edit Investment Amount' : 'Confirmation'}
      confirmButton={{
        name: isConfirmationDialogOpen ? 'Confirm' : 'Confirm Allocation',
        onClick: saveValue,
        disabled: !value
      }}
    >
      <AnimatePresence>
        {isConfirmationDialogOpen ? (
          <motion.div
            initial={{
              opacity: 0
            }}
            animate={{
              opacity: 1
            }}
          >
            <ModalBody>
              <Text fontWeight="400">Please review.</Text>
              <Text fontWeight="400">
                <CountWrap>
                  <Count>1</Count>
                </CountWrap>
                allocation(s) will move to <Text fontWeight="700">Pending Investments</Text>
              </Text>
            </ModalBody>
          </motion.div>
        ) : (
          <>
            {errorText && <ErrorNotification title={errorText?.title ?? ''} message={errorText?.message ?? ''} />}
            <Label required>{row.proposalType === 'Increase' ? 'Follow-on Investment Amount' : 'Initial Investment Amount'}</Label>
            <InputWrap>
              <Input isBackground={!!value} width="100%" type="text" value={value} onChange={handleInputChange} autoFocus />
              <Dollar isBackground={!!value}>$</Dollar>
            </InputWrap>
            <Description>{row.proposalType === 'Increase' ? '' : `Initial Investment minimum: $${row.minimum}`}</Description>
          </>
        )}
      </AnimatePresence>
    </ModalWrappedContent>
  );
};

export default EditAmount;

const Input = styled.input<{ width: string; isBackground: boolean }>`
  width: ${({ width }) => width};
  border-radius: 4px;
  border: ${({ isBackground, theme }) => (isBackground ? `1px solid ${theme.context.success}` : `1px solid  ${theme.border.base}`)};
  background-color: ${({ isBackground, theme }) => (isBackground ? '#efffea' : theme.layer[1])};
  color: ${({ isBackground, theme }) => (isBackground ? '' : theme.font.strong)};
  padding: 10px 20px;
  font-size: 16px;
  font-family: Blinker;
  font-weight: 400;

  &:focus {
    outline: none;
  }
`;

const Description = styled.span`
  padding-top: 10px;
  font-weight: 300;
  font-size: 13px;
  line-height: 18px;
  color: ${({ theme }) => theme.font.weak};
`;

const ModalBody = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  gap: 10px;
  margin: 30px 0;
`;
