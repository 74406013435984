import React, { useState, useRef } from 'react';
import styled from 'styled-components';

const TooltipContainer = styled.div`
  position: relative;
  display: inline-block;
`;

const TooltipText = styled.div<{ show: boolean }>`
  visibility: ${(props) => (props.show ? 'visible' : 'hidden')};
  padding: 20px;
  font-size: 12px;
  font-family: 'Inter';
  font-weight: 400;
  color: ${(props) =>
    props.theme.info.contrastText}; // adjust based on your theme
  background: #2a2e3f;
  text-align: center;
  border-radius: 6px;
  position: absolute;
  z-index: 1;

  /* Positioning and Arrow (adjust as needed) */
  bottom: 100%;
  left: 50%;
  transform: translateX(-50%);
  margin-bottom: 5px;

  &::after {
    content: '';
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: #2a2e3f transparent transparent transparent;
  }
`;

type TooltipProps = {
  children: React.ReactNode;
  title: string;
};

const StyledTooltip: React.FC<TooltipProps> = ({ children, title }) => {
  const [showTooltip, setShowTooltip] = useState(false);

  const handleMouseEnter = () => setShowTooltip(true);
  const handleMouseLeave = () => setShowTooltip(false);

  return (
    <TooltipContainer
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      {children}
      <TooltipText show={showTooltip}>{title}</TooltipText>
    </TooltipContainer>
  );
};

export default StyledTooltip;
