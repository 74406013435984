import { Box, Divider, InputBase } from '@mui/material';
import Typography from '@/components/muis/typography';
import { styled } from 'styled-components';
import React, { Dispatch, SetStateAction, useState } from 'react';

import { ISelect } from '../../../types/opportunity/modify-opportunity';
import { Button } from '@/components/button';

interface ListSelectsProps {
  searchValue: string;
  setSearchValue: Dispatch<SetStateAction<string>>;
  selects: ISelect[];
  updateSelects: (value: ISelect[]) => void;
  setIsAdding: Dispatch<SetStateAction<boolean>>;
  handleClose: () => void;
  chooseOnlyOneSelect: boolean;
  addNewText: string;
}

export const ListSelects: React.FC<ListSelectsProps> = ({
  searchValue,
  setSearchValue,
  selects,
  chooseOnlyOneSelect,
  updateSelects,
  setIsAdding,
  addNewText,
  handleClose
}) => {
  const [localSelects, setLocalSelects] = useState<ISelect[]>(selects);
  const filterSelects = () =>
    searchValue.length
      ? localSelects.filter(({ text }) =>
          text.toLowerCase().includes(searchValue.toLowerCase())
        )
      : localSelects;
  const addContent = () =>
    localSelects.some(
      ({ text }) => text.toLowerCase() === searchValue.toLowerCase()
    )
      ? ''
      : searchValue;
  const toggleSelect = (text: string) =>
    localSelects.map((select) =>
      select.text === text
        ? { ...select, checked: !select.checked }
        : {
            ...select,
            checked: chooseOnlyOneSelect ? false : select.checked
          }
    );
  const getSelectsWithoutDeletedSelect = (
    event: React.MouseEvent<HTMLImageElement>,
    text: string
  ) => {
    event.stopPropagation();
    return localSelects.filter((select) => select.text !== text);
  };
  return (
    <ListSelectsWrap>
      <InputBase
        sx={{
          width: '100%',
          padding: '8.5px 10px',
          border: '1px solid #C7CBD2',
          borderRadius: '4px',
          marginBottom: '10px'
        }}
        onChange={(event) => setSearchValue(event.target.value)}
        onKeyDown={(e) => e.stopPropagation()}
        value={searchValue}
        placeholder="Type here and press enter..."
      />
      <Divider style={{ marginLeft: -10, marginRight: -10 }} />
      <Selects>
        {filterSelects().map(({ text, checked }) => (
          <SelectItem
            checked={checked}
            key={text}
            onClick={() => setLocalSelects(toggleSelect(text))}
          >
            <CheckBox checked={checked}>
              <ImageCross
                checked={checked}
                src={'/static/icons/light-tick.svg'}
                alt="Cross"
              />
            </CheckBox>
            <Typography sx={{ color: '#373F4E' }}>{text}</Typography>
          </SelectItem>
        ))}
      </Selects>
      <AddNewStrategy>
        <NewStrategy>{`Add ${addContent()} new ${addNewText}`}</NewStrategy>
        <ImagePlus
          onClick={() => setIsAdding(true)}
          src="/static/icons/dark-plus.svg"
          alt="Plus"
        />
      </AddNewStrategy>
      <ButtonsWrap>
        <CancelButton onClick={handleClose}>Cancel</CancelButton>
        <DoneButton
          onClick={() => {
            handleClose();
            updateSelects(localSelects);
          }}
        >
          Done
        </DoneButton>
      </ButtonsWrap>
    </ListSelectsWrap>
  );
};

const ListSelectsWrap = styled('div')(() => ({
  width: '100%',
  background: '#F0F1F3',
  padding: 10
}));

const ButtonsWrap = styled('div')(() => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  gap: 10
}));

const CancelButton = styled(Button)`
  width: 50%;
  &.MuiButton-root: {
    background: ${(props) => props.theme.primary.main},
    color: ${(props) => props.theme.font.action}
  };
  border: ${(props) => `1px solid ${props.theme.font.action}`};
`;

const DoneButton = styled(Button)`
  width: 50%;
  &.MuiButton-root: {
    background: #172D4D;
    color: ${(props) => props.theme.secondary.contrastText}};
  }
`;

const CheckBox = styled('div')<{ checked: boolean }>`
  cursor: pointer;
  width: 24px;
  height: 24px;
  border-radius: 4px;
  border: 1px solid #c7cbd2;
  background: ${(props) => (props.checked ? '#373F4E' : 'transparent')};
`;

const AddNewStrategy = styled(Box)`
  padding: 13px 20px;
  display: flex;
  justify-content: space-between;
`;

const NewStrategy = styled('span')`
  color: #454857;
  text-decoration: underline;
`;

const Selects = styled(Box)`
  max-height: 190;
  overflow-y: auto;
`;

const SelectItem = styled(Box)<{ checked: boolean }>`
  cursor: pointer;
  padding: 7px 20px;
  color: #fff;
  display: flex;
  justify-content: flex-start;
  gap: 6px;
  align-items: center;
`;

const ImagePlus = styled('img')`
  cursor: pointer;
`;

const ImageCross = styled('img')<{ checked: boolean }>`
  height: ${(props) => (props.checked ? 14 : 22)};
  width: ${(props) => (props.checked ? 21 : 22)};
`;
