import FormattingTooltip from '@/components/basicComponents/tooltip/dollarsTooltip';
import { useStatusData } from '@/hooks/useStatusData';
import { useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import { AssetClassTargetAllocationDto } from '../types';
import { AssetClassRow } from './assetClassRow';

type SimpleTableProps = {
  assetClassTableData: AssetClassTargetAllocationDto[];
};

const SimpleTable = ({ assetClassTableData }: SimpleTableProps) => {
  const { data: statusData } = useStatusData();

  const [assetClassTargets, setAssetClassTargets] = useState<AssetClassTargetAllocationDto[]>([]);

  useEffect(() => {
    if (!assetClassTableData) return;

    setAssetClassTargets(assetClassTableData);
  }, [assetClassTableData]);

  const totalRow = useMemo(() => {
    return assetClassTargets.reduce(
      (acc, row) => ({
        total: 'Total',
        target: acc.target + row.percentage,
        capacity: acc.capacity + row.capacity
      }),
      {
        total: 'Total',
        target: 0,
        capacity: 0
      }
    );
  }, [assetClassTableData]);

  return (
    <SimpleTableWrap>
      <SimpleTableHeader>
        <HeaderItem>ASSET CLASS</HeaderItem>
        <HeaderItem>TARGET</HeaderItem>
        <HeaderItem>CAPACITY</HeaderItem>
      </SimpleTableHeader>
      <SimpleTableBody>
        {assetClassTargets?.map((assetClass) => (
          <AssetClassRow key={assetClass.id} assetClass={assetClass} targetSubAssetClasses={statusData?.target_sub_asset_classes || false} />
        ))}
      </SimpleTableBody>
      <SimpleTableFooter>
        <FooterItem>TOTAL</FooterItem>
        <FooterItem>{totalRow.target.toFixed(2)}%</FooterItem>
        <FooterItem>
          <FormattingTooltip zIndex={1000}>{totalRow.capacity}</FormattingTooltip>
        </FooterItem>
      </SimpleTableFooter>
    </SimpleTableWrap>
  );
};

const SimpleTableBody = styled.div`
  display: flex;
  flex-direction: column;
  /* max-height: 200px;
  overflow-y: auto; */
`;

const FooterItem = styled.div`
  width: 33%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-family: Blinker, serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: 0;
  color: ${({ theme }) => theme.font.strong};
`;

const SimpleTableFooter = styled.div`
  display: flex;
  border-radius: 4px;
  align-items: center;
  justify-content: space-between;
  background-color: ${({ theme }) => theme.layer[2]};
  padding: 5px 24px;
`;

const HeaderItem = styled.div`
  width: 33%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-family: Blinker, serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: 0;
  color: ${({ theme }) => theme.header.action};
`;

const SimpleTableHeader = styled.div`
  display: flex;
  border-radius: 4px;
  align-items: center;
  justify-content: space-between;
  background-color: ${({ theme }) => theme.header.base};
  padding: 5px 24px;
`;

const SimpleTableWrap = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 3px;
`;

export default SimpleTable;
