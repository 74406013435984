import { EditIcon } from '@/assets/icons/edit';
import { ExportDataIcon } from '@/assets/icons/exportDataIcon';
import { getUTCDate } from '@/components/allocations-page/utils';
import FilterSelect from '@/components/basicComponents/select';
import FormattingTooltip from '@/components/basicComponents/tooltip/dollarsTooltip';
import { useResponsive } from '@/hooks/use-responsive';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled, { useTheme } from 'styled-components';
import AssetClassTable from './assetClassTable';
import DrawdownTarget from './fat-modals/drawdownTarget';
import { AssetClassForecastSummaryDto, DrawdownTargetsDto, ICommitmentPlan } from './types';
import ZeroStateAssetClassTable from './zeroStateAssetClassTable';

interface PacingDetailsProps {
  mode: 'zeroState' | 'editCommitmentPlan' | 'pacingTab';
  detailsData: ICommitmentPlan | null;
  assetClassForecasts?: AssetClassForecastSummaryDto[];
  entityName?: string;
  commitmentPlanId?: string;
  commitmentPlanList?: ICommitmentPlan[];
  drawdownTargetDialogData?: DrawdownTargetsDto | null;
  onChangeCommitmentPlan?: (commitmentPlanName: string) => void;
  onEditButtonClick?: () => void;
}

export const PacingDetails = ({
  mode,
  detailsData,
  entityName,
  assetClassForecasts,
  commitmentPlanId,
  commitmentPlanList,
  drawdownTargetDialogData,
  onChangeCommitmentPlan,
  onEditButtonClick
}: PacingDetailsProps) => {
  const { isMobile } = useResponsive();
  const navigate = useNavigate();
  const theme = useTheme();

  const [modalWindow, setModalWindow] = useState({
    isOpen: false,
    type: 'drawdown-target'
  });

  const openModalWindow = (type: string) => {
    setModalWindow({ type, isOpen: true });
  };

  const closeModalWindow = () => {
    setModalWindow({ ...modalWindow, isOpen: false });
  };

  const getSelectedPlan = () => {
    const commitmentPlan = commitmentPlanList.find((plan) => plan.id === commitmentPlanId);
    if (commitmentPlan) {
      return commitmentPlan.active ? `${commitmentPlan.name} (Active)` : commitmentPlan.name;
    }
    return '';
  };

  return (
    <>
      {modalWindow.isOpen && modalWindow.type === 'drawdown-target' && (
        <DrawdownTarget isOpen={modalWindow.isOpen} onClose={closeModalWindow} drawdownTargetDialogData={drawdownTargetDialogData} />
      )}
      <PricingDetailsWrapper>
        <TopSectionWrapper>
          {(mode === 'editCommitmentPlan' || mode === 'pacingTab') && (
            <SelectedPlan>
              <Text>Selected Plan</Text>
              <SelectWrapper>
                {mode === 'editCommitmentPlan' && <>{detailsData?.name}</>}
                {mode === 'pacingTab' && (
                  <FilterSelect
                    data={commitmentPlanList.map((plan) => (plan.active ? `${plan.name} (Active)` : plan.name))}
                    selected={getSelectedPlan()}
                    setSelected={onChangeCommitmentPlan}
                    width="100%"
                    additionalOption={
                      <AdditionalOptionWrapper
                        onClick={() => {
                          navigate(`allCommitmentPlans?entityName=${entityName}&backToTitle=Investor Entity`);
                        }}
                      >
                        <EditIcon fill={theme.font.action} />
                        <AddText>Edit Commitment Plans</AddText>
                      </AdditionalOptionWrapper>
                    }
                  />
                )}
              </SelectWrapper>
            </SelectedPlan>
          )}

          <TimescaleWrapper>
            {/* <Timescale>
              <Text>Timescale</Text>
              <CustomSelect width={'auto'} selected={'Annual'} setSelected={() => {}} data={[]} />
            </Timescale> */}
            {/* {mode === 'pacingTab' && (
              <Timescale>
                <Text>Show Actuals Through</Text>
                <CustomSelect width={'auto'} selected={'SEP 30, 2024'} setSelected={() => {}} data={[]} />
              </Timescale>
            )} */}
          </TimescaleWrapper>
        </TopSectionWrapper>

        <MoreDetails isMobile={isMobile}>
          <MoreDetailItem isMobile={isMobile}>
            {mode !== 'zeroState' && (
              <ItemRow>
                <ItemTitle>Plan Start</ItemTitle>
                <ItemValue>{detailsData?.startDate && getUTCDate(new Date(detailsData.startDate))}</ItemValue>
              </ItemRow>
            )}
            <ItemRow>
              <ItemTitle>Starting Portfolio Value</ItemTitle>
              <ItemValue>
                <FormattingTooltip zIndex={1000}>{detailsData?.startingPortfolioValue}</FormattingTooltip>
              </ItemValue>
            </ItemRow>
            <ItemRow>
              <ItemTitle>Drawdown Target</ItemTitle>
              <ItemValue isRedirect onClick={() => openModalWindow('drawdown-target')}>
                {`${typeof detailsData?.drawdownTarget === 'number' ? detailsData.drawdownTarget.toFixed(2) : 0}%`}
                <svg xmlns="http://www.w3.org/2000/svg" width="14" height="13" viewBox="0 0 14 13" fill="none">
                  <path
                    d="M10.9699 11.531C9.86293 12.4774 8.45483 12.9982 6.99842 13C3.53717 13 0.732422 10.075 0.732422 6.5C0.732422 2.925 3.53717 0 6.99842 0V6.5L10.9699 11.531Z"
                    fill={theme.font.action}
                  />
                  <path
                    d="M7.98633 0.0810547V5.45978H13.1863C12.9953 4.1303 12.39 2.8949 11.4564 1.92925C10.5228 0.963605 9.3086 0.316889 7.98633 0.0810547ZM8.53233 0.854556C10.5863 1.53381 11.7823 2.68103 12.4323 4.89428H8.53233V0.854556Z"
                    fill={theme.font.action}
                  />
                  <path d="M11.5808 10.9395C12.672 9.71869 13.2728 8.13735 13.2676 6.5H7.98633L11.5808 10.9395Z" fill={theme.font.action} />
                </svg>
              </ItemValue>
            </ItemRow>
            <ItemRow>
              <ItemTitle>Starting Allocation</ItemTitle>
              <ItemValue>{`${typeof detailsData?.startingAllocation === 'number' ? detailsData.startingAllocation.toFixed(2) : 0}%`}</ItemValue>
            </ItemRow>
            {mode !== 'zeroState' && (
              <ItemRow>
                <ItemTitle>Growth Rate Ex. Drawdowns</ItemTitle>
                <ItemValue>{`${typeof detailsData?.growthRate === 'number' ? detailsData.growthRate.toFixed(2) : 0}%`}</ItemValue>
              </ItemRow>
            )}
            {mode === 'editCommitmentPlan' && (
              <CommitmentPlanButton onClick={onEditButtonClick}>
                <EditIcon fill={theme.action.primary} />
                Edit Options
              </CommitmentPlanButton>
            )}
            {mode === 'pacingTab' && (
              <CommitmentPlanButton onClick={onEditButtonClick}>
                <ExportDataIcon fill={theme.action.primary} />
                Export
              </CommitmentPlanButton>
            )}
          </MoreDetailItem>
          {mode !== 'zeroState' && assetClassForecasts && (
            <AssetClassTableWrapper isMobile={isMobile}>
              <AssetClassTable assetClassForecasts={assetClassForecasts} />
            </AssetClassTableWrapper>
          )}
          {mode === 'zeroState' && detailsData?.assetClassDrawdownTargets && (
            <AssetClassTableWrapper isMobile={isMobile}>
              <ZeroStateAssetClassTable assetClassTableData={detailsData?.assetClassDrawdownTargets ?? null} />
            </AssetClassTableWrapper>
          )}
        </MoreDetails>
      </PricingDetailsWrapper>
    </>
  );
};

const PricingDetailsWrapper = styled.div`
  padding: 10px 60px;
  background-color: ${({ theme }) => theme.layer[1]};
  color: ${({ theme }) => theme.font.base};
`;

const TopSectionWrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 10px;
`;

const Text = styled.p`
  color: ${({ theme }) => theme.font.base};
  font-size: 16px;
  font-weight: 700;
  line-height: 22.4px;
  padding: 10px;
`;

const SelectedPlan = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  width: 50%;
`;

const SelectWrapper = styled.div`
  max-width: 535px;
  width: 100%;
`;

const TimescaleWrapper = styled.div`
  margin-left: auto;
  display: flex;
  align-items: center;
  gap: 20px;
`;

const Timescale = styled.div`
  display: flex;
  align-items: center;
`;

const CustomSelect = styled(FilterSelect)`
  padding: 0;
  background: transparent;
  border: 0;
  justify-content: flex-start;

  svg {
    margin: 0 6px;
  }

  #titleSelect {
    color: ${({ theme }) => theme.font.action};
    white-space: normal;
    font-family: 'Blinker';
    font-weight: 400;
    font-size: 14px;
  }

  #dropDownSelect {
    top: 26px;
    width: max-content;
  }
`;

const AdditionalOptionWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
  cursor: pointer;
`;

const AddText = styled.div`
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  color: ${({ theme }) => theme.font.action};
`;

const MoreDetails = styled.div<{ isMobile: boolean }>`
  display: flex;
  flex-direction: ${({ isMobile }) => (isMobile ? 'column' : 'row')};
  gap: ${({ isMobile }) => (isMobile ? '14px' : '30px')};
  justify-content: space-between;
  padding: 10px;
`;

const MoreDetailItem = styled.div<{ isMobile: boolean }>`
  display: flex;
  flex-direction: column;
  width: ${({ isMobile }) => (isMobile ? '100%' : '40%')};
`;

const AssetClassTableWrapper = styled.div<{ isMobile: boolean }>`
  width: ${({ isMobile }) => (isMobile ? '100%' : '60%')};
`;

const ItemRow = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 30px;
  padding: 8px 10px;
`;

const ItemValue = styled.div<{ isRedirect?: boolean }>`
  font-family: Blinker, serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 22px;
  color: ${({ theme, isRedirect }) => (isRedirect ? theme.font.action : theme.font.base)};
  cursor: ${({ isRedirect }) => (isRedirect ? 'pointer' : 'default')};
  white-space: nowrap;
  display: flex;
  align-items: center;
  gap: 8px;
`;

const ItemTitle = styled.div`
  font-family: Blinker, serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  color: ${({ theme }) => theme.font.base};
  white-space: nowrap;
  width: 160px;
`;

const CommitmentPlanButton = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  padding: 10px;
  color: ${({ theme }) => theme.action.primary};
  cursor: pointer;
  width: max-content;
`;
