export const transactionsSteps = [
  { name: 'Upload Data File', status: 'IN_PROGRESS' },
  { name: 'Entity Codes', status: 'NOT_STARTED' },
  { name: 'Series Codes', status: 'NOT_STARTED' },
  { name: 'Errors', status: 'NOT_STARTED' }
];

export const transactionsDescriptionSectionData = {
  title: 'Import Transactions  performs the following actions',
  description: [
    'Replaces all transactions with those listed in the import file.',
    'Aborts the import and shows an error if there is no commitment (entity x series ) that corresponds to each transaction.',
    'Regenerates aggregate values for each commitment, such as capital called, distributions, and market value.'
  ]
};

export const transactionsColumnsSectionData = {
  title: 'Provide the following columns in the upload file',
  columns: [
    { name: 'Entity Code', isRequired: true },
    { name: 'Series Code', isRequired: true },
    { name: 'Trade Date', isRequired: true },
    { name: 'Transaction Type', isRequired: true },
    { name: 'Series Name', isRequired: false },
    { name: 'Investment Amount', isRequired: true },
    { name: 'Entity Name', isRequired: false },
    { name: 'Transaction ID', isRequired: false },
    { name: 'Total Amount', isRequired: true },
    { name: 'Advisory Firm', isRequired: false }
  ]
};
