import { AddButtonIcon } from '@/assets/static/icons/add-button-icon';
import { initialValuesFromTable } from '@/components/table/constants';
import TablePagination from '@/components/table/tablePagination';
import { ISort, SORT_ORDER } from '@/components/table/types';
import { useResponsive } from '@/hooks/use-responsive';
import { MainWrap } from '@/styles/common';
import { useQuery } from '@apollo/client';
import { useEffect, useRef, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import styled from 'styled-components';
import { IFilterBarData } from '../basicComponents/filterBar';
import Button from '../fat-basicComponents/button';
import { IInvestmentWorkflowTransitionTask, Investment } from '../fat-dealCatalog-page/types';
import Header from '../fat-header';
import MobileModal from '../mobileModal';
import FilterPage from '../table/fat-filterPage';
import { dealPipelineMobileSort, initialInvestmentStatusFilter, securityStatuses } from './constatnts';
import { StepsList } from './fat-stepLists';
import Table from './fat-table/table';
import { INVESTMENTS_QUERY, LIST_INVESTMENT_WORKFLOW_TRANSITION_TASKS_QUERY } from './queries';
import { IInvestmentsQuery } from './types';

export const DealPipeline = () => {
  const { isMobile, isTablet } = useResponsive();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();

  const q = searchParams.get('q') || '';
  const filterBarParams = searchParams.get('filterBar');
  const key = searchParams.get('key') || 'LAST_UPDATED';
  const asc = searchParams.get('asc') === 'true' || false;
  const limit = searchParams.get('limit') || initialValuesFromTable.initialAmountRowsPerPage[0];
  const offset = searchParams.get('offset') || 0;
  const position = searchParams.get('position') || 0;

  const [tableRows, setTableRows] = useState<Investment[]>([]);
  const [updatesPopoverData, setUpdatesPopoverData] = useState<Investment | null>(null);
  const [statuses, setStatuses] = useState<IInvestmentWorkflowTransitionTask[]>([]);
  const [filterBar, setFilterBar] = useState<IFilterBarData[]>(initialInvestmentStatusFilter);
  const [filterPageHeight, setFilterPageHeight] = useState(0);
  const [mobileSort, setMobileSort] = useState(dealPipelineMobileSort[0].title);
  const sortItem = dealPipelineMobileSort.find((item) => item.title === mobileSort);
  const mobileSortList = dealPipelineMobileSort.map((item) => item.title);

  const filterRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (!filterBarParams) return;

    const matchedFilterBarValues = initialInvestmentStatusFilter.map((item) => {
      const matchedFilterItem = JSON.parse(filterBarParams).find((filterItem: string) => filterItem === item.value);

      return {
        ...item,
        enabled: Boolean(matchedFilterItem)
      };
    });
    setFilterBar(matchedFilterBarValues);
  }, []);

  useEffect(() => {
    if (!filterRef.current) return;
    setFilterPageHeight(filterRef.current.offsetHeight);

    const handleResize = () => {
      if (!filterRef.current) return;
      setFilterPageHeight(filterRef.current.offsetHeight);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    if (!isMobile) return;
    investmentsRefetch({
      sort: sortItem?.key,
      order: sortItem?.asc ? SORT_ORDER.ASC : SORT_ORDER.DESC,
      limit: Number(limit),
      offset: Number(offset)
    });
  }, [mobileSort]);

  const {
    data: investmentsData,
    refetch: investmentsRefetch,
    loading: investmentsLoading,
    networkStatus: investmentNetworkStatus
  } = useQuery<{ Investments: IInvestmentsQuery }>(INVESTMENTS_QUERY, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'cache-and-network',
    variables: {
      sort: key,
      order: asc ? SORT_ORDER.ASC : SORT_ORDER.DESC,
      search: q.toLowerCase().trim(),
      rowsPerPage: Number(limit),
      page: Number(offset) / Number(limit) + 1,
      statusFilter: filterBar.filter((item) => item.enabled).map((item) => item.value)
    },
    onCompleted: (data) => {
      const { data: tableData, filters } = data.Investments;

      setTableRows(tableData);

      const filterBarWithCounts = filterBar.map((filterBarItem) => {
        const matchedFilterItem = filters.find((filterItem) => filterItem.value === filterBarItem.value);

        return { ...filterBarItem, count: matchedFilterItem?.count ?? 0 };
      });
      setFilterBar(filterBarWithCounts);

      makeScroll(position);
    },
    onError: (error) => {
      console.error(error);
    }
  });

  useQuery<{
    listInvestmentWorkflowTransitionTasks: IInvestmentWorkflowTransitionTask[];
  }>(LIST_INVESTMENT_WORKFLOW_TRANSITION_TASKS_QUERY, {
    notifyOnNetworkStatusChange: true,
    onCompleted: (data) => {
      setStatuses(data.listInvestmentWorkflowTransitionTasks);
    }
  });

  const saveScrollPostion = () => {
    setSearchParams(
      (prev) => {
        prev.set('position', window.scrollY.toString());
        return prev;
      },
      { replace: true }
    );
  };

  const makeScroll = (position: string | number) => {
    if (!position) return;
    setTimeout(() => window.scrollTo({ top: Number(position), behavior: 'smooth' }), 0);
    setSearchParams(
      (prev) => {
        prev.delete('position');
        return prev;
      },
      { replace: true }
    );
  };

  const openModifyInvestment = (id?: string) => {
    saveScrollPostion();
    navigate(`/dealPipeline/modify?backToTitle=Manager Pipeline${id ? `&investment=${id}` : ''}`, {
      state: {
        investment: id
      }
    });
  };

  const openInvestorInterestPage = (id: string, name: string) => {
    saveScrollPostion();
    navigate(`${id}/${name}?backToTitle=Manager Pipeline`);
  };

  const openInvestmentForAllocations = (row: Investment) => {
    saveScrollPostion();

    const currentStatusIndex = statuses.findIndex((status) => status.id === row.investmentWorkflowTransitionTask.id);
    const newStatus = statuses[currentStatusIndex + 1];
    navigate(`/dealPipeline/modify?investment=${row.id}&backToTitle=Manager Pipeline`, {
      state: {
        investment: row.id,
        taskId: newStatus.id,
        securityStatus: 'OPEN',
        pageTitle: 'Open to Investors',
        bannerTitle: 'Allow Advisory Firms to allocate investor funds',
        bannerDescription: 'Review all details and settings before making this opportunity available to investors.'
      }
    });
  };

  const publishToAdvisoryFirms = (row: Investment) => {
    saveScrollPostion();

    const currentStatusIndex = statuses.findIndex((status) => status.id === row.investmentWorkflowTransitionTask.id);
    const newStatus = statuses[currentStatusIndex + 1];

    const currentSecurityStatusIndex = securityStatuses.findIndex(
      (securityStatus) => securityStatus.toLowerCase().replace('_', ' ') === row.securityStatus.toLowerCase()
    );
    const newSecurityStatus = securityStatuses[currentSecurityStatusIndex + 1];
    if (!newSecurityStatus) return;

    navigate(`/dealPipeline/modify?investment=${row.id}&backToTitle=Manager Pipeline`, {
      state: {
        investment: row.id,
        taskId: newStatus.id,
        securityStatus: newSecurityStatus,
        pageTitle: 'Publish Strategy',
        bannerTitle: 'Strategy will be visible to advisors',
        bannerDescription:
          'This action will display this strategy to Advisory Firms.  Limit to specific Advisory Firms using the Advisory Firm Restrictions options below.'
      }
    });
  };

  const onChangeSearch = (searchValue: string) => {
    setSearchParams(
      (prev) => {
        if (!searchValue) {
          prev.delete('q');
          return prev;
        }
        prev.set('q', searchValue);
        prev.delete('limit');
        prev.delete('offset');
        return prev;
      },
      { replace: true }
    );
  };

  const onChangeFilterBar = (filterBarItems: IFilterBarData[]) => {
    setFilterBar(filterBarItems);
    const filterBarValues = filterBarItems.filter((item) => item.enabled).map((item) => item.value);
    setSearchParams(
      (prev) => {
        if (!filterBarValues.length) {
          prev.delete('filterBar');
          return prev;
        }
        prev.delete('limit');
        prev.delete('offset');
        prev.set('filterBar', JSON.stringify(filterBarValues));
        return prev;
      },
      { replace: true }
    );
  };

  const onChangeSort = (value: ISort) => {
    setSearchParams(
      (prev) => {
        prev.set('key', value.key);
        prev.set('asc', value.asc.toString());
        return prev;
      },
      { replace: true }
    );
  };

  const onChangePaginationData = (limit: number, offset: number) => {
    setSearchParams(
      (prev) => {
        prev.set('limit', limit.toString());
        prev.set('offset', offset.toString());
        return prev;
      },
      { replace: true }
    );
  };

  const openMobileModal = (data: Investment, modalType: string) => {
    if (modalType === 'updates') {
      setUpdatesPopoverData(data);
    }
  };

  return (
    <>
      {updatesPopoverData && (
        <MobileModal
          title={'Updates'}
          onClose={() => {
            setUpdatesPopoverData(null);
          }}
          onClick={() => null}
        >
          <StepsList statuses={statuses} row={updatesPopoverData} initialDate={updatesPopoverData.updatedAt} isMobile />
        </MobileModal>
      )}
      <MainWrap>
        <Header />
      </MainWrap>
      <MainWrap>
        <PaddingWrap>
          <AddButtonWrapper>
            <Button size="md" onClick={() => openModifyInvestment()} icon={<AddButtonIcon />}>
              Add New Strategy
            </Button>
          </AddButtonWrapper>
          <FilterPageWrap isMobile={isMobile} isTablet={isTablet} ref={filterRef}>
            <CustomFilterPage
              isMobile={isMobile}
              search={{
                value: q,
                onChange: onChangeSearch,
                placeholder: 'Search'
              }}
              refetch={investmentsRefetch}
              resultsValue={investmentsData?.Investments.total ?? 0}
              filterBar={filterBar}
              onChangeFilterBar={onChangeFilterBar}
              mobileSort={{
                value: mobileSort,
                selects: mobileSortList,
                onChange: setMobileSort
              }}
            />
          </FilterPageWrap>
          <Table
            tableRows={tableRows}
            sort={{ key, asc }}
            setSort={onChangeSort}
            refetch={investmentsRefetch}
            investmentNetworkStatus={investmentNetworkStatus}
            openModifyInvestment={openModifyInvestment}
            openInvestorInterestPage={openInvestorInterestPage}
            openInvestmentForAllocations={openInvestmentForAllocations}
            publishToAdvisoryFirms={publishToAdvisoryFirms}
            statuses={statuses}
            filterPageHeight={filterPageHeight}
            openMobileModal={openMobileModal}
          />
          <PaginationWrap>
            <TablePagination
              savePagination={onChangePaginationData}
              paginationValues={{
                limit: Number(limit),
                offset: Number(offset),
                total: investmentsData?.Investments.total ?? 0
              }}
              refetch={investmentsRefetch}
              usePage
            />
          </PaginationWrap>
        </PaddingWrap>
      </MainWrap>
    </>
  );
};

const PaddingWrap = styled.div`
  padding-left: 16px;
  padding-right: 16px;
  @media (min-width: 600px) {
    padding-left: 50px;
    padding-right: 50px;
  }
`;

const AddButtonWrapper = styled.div`
  margin-top: 20px;
`;

const FilterPageWrap = styled.div<{ isMobile: boolean; isTablet: boolean }>`
  position: sticky;
  top: ${({ isMobile, isTablet }) => (isMobile || isTablet ? '50px' : '0')};
  z-index: 9;
`;

const CustomFilterPage = styled(FilterPage)<{ isMobile: boolean }>`
  margin-top: 0;
  margin-left: -1px;
  width: calc(100% + 2px);
  padding: ${({ isMobile }) => (isMobile ? '20px 0 0 0' : '20px 0 10px 0')};
`;

const PaginationWrap = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
`;
