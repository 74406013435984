import NoResults from '@/components/table/noResults';
import React from 'react';
import { TransformedAssetClassForecastValueDto } from '../../../types';
import TableRow from './tableRow';

interface TableBodyProps {
  tableData: TransformedAssetClassForecastValueDto[];
  columnWidth: number;
  targetSubAssetClasses: boolean;
}

const TableBody = ({ tableData, columnWidth, targetSubAssetClasses }: TableBodyProps) => {
  if (!tableData?.length) {
    return <NoResults />;
  }

  return (
    <>
      {tableData.map((row) => {
        const hasAssetClassValues = row.values.some((valueItem) => valueItem.value !== 0);
        if (!hasAssetClassValues) return <React.Fragment key={row.name} />;

        return <TableRow key={row.name} row={row} columnWidth={columnWidth} targetSubAssetClasses={targetSubAssetClasses} />;
      })}
    </>
  );
};

export default TableBody;
