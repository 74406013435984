import client from '@/apollo-client';
import { InfoIcon } from '@/assets/icons/info-icons/info';
import { getUTCDate } from '@/components/allocations-page/utils';
import { Banner } from '@/components/basicComponents/banner';
import { FormFooter } from '@/components/fat-basicComponents/formFooter';
import { SuccessToast } from '@/components/fat-basicComponents/toasts/success';
import Header from '@/components/fat-header';
import { GoBackButton } from '@/components/fat-header/goBackButton';
import { PageTitle } from '@/components/fat-header/pageTitle';
import Loading from '@/components/fat-investors-page/fat-addInvestors/fat-modals/loading';
import { LoaderOnWholeScreen } from '@/components/loaders/loader-on-whole-screen';
import { TableColumns } from '@/components/table/types';
import { useGoBack } from '@/hooks/useGoBack';
import { useStatusData } from '@/hooks/useStatusData';
import { MainWrap, PaddingWrap } from '@/styles/common';
import { useMutation, useQuery } from '@apollo/client';
import { debounce } from 'lodash';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useLocation, useParams, useSearchParams } from 'react-router-dom';
import styled from 'styled-components';
import { PacingDetails } from '../fat-pacingTab/pacingDetails';
import { AssetClassForecastSummaryDto, ForecastSummaryDto, ICommitmentPlan } from '../fat-pacingTab/types';
import { COMMITMENT_PLAN_QUERY, createCommitmentPlanMutation, FORECAST_SUMMARY_QUERY, updateCommitmentPlanMutation } from '../queries';
import ConfirmDialog from './fat-modals/confirmDialog';
import DiscardChanges from './fat-modals/discardChanges';
import ModelOptions from './fat-modals/modelOptions';
import CommitmentPlanTable from './fat-table/table';
import { CreateAllocationsByDateDto, ITransformedAllocation, ITransformedAssetClass } from './types';
import { resetUpdatedFlag, transformBackData, transformData, updateAssetClassAllocationValue, updateSubAssetClassAllocationValue } from './utils';

export const ASSET_CLASS_COLUMN_TOOLTIP =
  'Provide the total commitment amount to make in each asset class for each annual period.  The dates shown here correspond to the period end.  Provide the amount to be committed prior to each period end.';

export const EditCommitmentPlanPage = () => {
  const goBack = useGoBack();
  const params = useParams();
  const location = useLocation();
  const { data: statusData, loading: statusDataLoading } = useStatusData();

  const [searchParams, setSearchParams] = useSearchParams();
  const backToTitle = searchParams.get('backToTitle') || '';
  const commitmentPlanId = searchParams.get('commitmentPlanId') || '';
  const duplicate = searchParams.get('duplicate') || '';

  const [commitmentPlanData, setCommitmentPlanData] = useState<ICommitmentPlan | null>(null);
  const [showToast, setShowToast] = useState(false);
  const [modalWindow, setModalWindow] = useState({
    isOpen: false,
    type: 'model-options'
  });
  const [assetClassForecasts, setAssetClassForecasts] = useState<AssetClassForecastSummaryDto[]>([]);
  const [tableHeaderColumns, setTableHeaderColumns] = useState<TableColumns[]>([]);
  const [tableData, setTableData] = useState<ITransformedAssetClass[]>([]);
  const [copyTableData, setCopyTableData] = useState<ITransformedAssetClass[]>([]);
  const [columnWidth, setColumnWidth] = useState(0);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const timerRef = useRef(null);

  const [createCommitmentPlan, { loading: createCommitmentPlanLoading }] = useMutation(createCommitmentPlanMutation, {
    onError: (error) => {
      console.error(error);
      setErrorMessage(error.message);
    }
  });

  const [updateCommitmentPlan, { loading: updateCommitmentPlanLoading }] = useMutation(updateCommitmentPlanMutation, {
    onError: (error) => {
      console.error(error);
      setErrorMessage(error.message);
    }
  });

  const { loading: commitmentPlanLoading } = useQuery<{ commitmentPlan: ICommitmentPlan }>(COMMITMENT_PLAN_QUERY, {
    notifyOnNetworkStatusChange: true,
    skip: !commitmentPlanId,
    variables: {
      id: commitmentPlanId
    },
    onCompleted: (data) => {
      setErrorMessage(null);
      setAssetClassForecasts(data.commitmentPlan.forecastSummary.assetClassForecasts);
      if (duplicate) {
        setCommitmentPlanData({ ...data.commitmentPlan, name: `${data.commitmentPlan.name} - Copy` });
        return;
      }
      setCommitmentPlanData(data.commitmentPlan);
    },
    onError: (error) => {
      setErrorMessage(error.message);
    }
  });

  useEffect(() => {
    const autoPlanData: ICommitmentPlan | null = location.state?.autoPlanData ? JSON.parse(location.state.autoPlanData) : null;

    if (!autoPlanData) return;
    setAssetClassForecasts(autoPlanData.forecastSummary.assetClassForecasts);
    setCommitmentPlanData(autoPlanData);
  }, [location.state]);

  useEffect(() => {
    if (!commitmentPlanData || tableData.length) return;

    const newColumnWidth = commitmentPlanData?.planAllocations ? 85 / commitmentPlanData.planAllocations.length : 0;

    const columnItems = commitmentPlanData.planAllocations.map((data: CreateAllocationsByDateDto) => {
      return {
        title: getUTCDate(new Date(data.date)).toUpperCase(),
        key: null as null,
        width: newColumnWidth,
        keySort: new Date(data.date).getFullYear().toString()
      };
    });

    const transformedData = transformData(commitmentPlanData.planAllocations);
    setTableData(transformedData);

    setColumnWidth(newColumnWidth);
    setTableHeaderColumns([
      { title: 'Asset Class', key: null, width: 15, keySort: 'asset class', tooltip: ASSET_CLASS_COLUMN_TOOLTIP },
      ...columnItems
    ]);
  }, [commitmentPlanData]);

  const handleAssetClassAllocationChange = (assetClassId: string, date: string, newValue: string) => {
    const cleanValue = newValue.replace(/[^\d.]/g, '').replace(/(\..*)\./g, '$1');
    const updatedTableData = updateAssetClassAllocationValue(tableData, assetClassId, date, cleanValue);
    setTableData(updatedTableData);
  };

  const handleSubAssetClassAllocationChange = (
    assetClassId: string,
    subAssetClassId: string,
    sacAllocation: ITransformedAllocation,
    newValue: string
  ) => {
    const cleanValue = newValue.replace(/[^\d.]/g, '').replace(/(\..*)\./g, '$1');
    const updatedTableData = updateSubAssetClassAllocationValue(tableData, assetClassId, subAssetClassId, sacAllocation, cleanValue);
    setTableData(updatedTableData);

    debouncedUpdateAssetClassForecasts(updatedTableData, commitmentPlanData.startDate, commitmentPlanData.growthRate);
  };

  const updateAssetClassForecasts = async (tableData: ITransformedAssetClass[], startDate: Date, growthRate: number) => {
    const transformedData = transformBackData(tableData);

    try {
      const { data } = await client.query<{ forecastSummary: ForecastSummaryDto }>({
        query: FORECAST_SUMMARY_QUERY,
        variables: {
          legalEntityId: params.entityId,
          allocations: transformedData,
          startDate: startDate,
          growthRate: growthRate
        }
      });
      setAssetClassForecasts(data.forecastSummary.assetClassForecasts);
    } catch (error) {
      console.error(error);
    }
  };

  const debouncedUpdateAssetClassForecasts = useCallback(debounce(updateAssetClassForecasts, 1000), []);

  const startToast = (copyTableData: ITransformedAssetClass[]) => {
    setCopyTableData(copyTableData);
    setShowToast(true);

    if (timerRef.current) {
      clearTimeout(timerRef.current);
    }

    timerRef.current = setTimeout(() => {
      closeToast();
    }, 10000);
  };

  const closeToast = () => {
    setCopyTableData([]);
    setShowToast(false);
  };

  const undoAction = () => {
    if (!copyTableData.length) return;
    setTableData(copyTableData);
    debouncedUpdateAssetClassForecasts(copyTableData, commitmentPlanData.startDate, commitmentPlanData.growthRate);
    endFlashAnimation(copyTableData);
    closeToast();
  };

  const timers: { [key: string]: NodeJS.Timeout } = {};

  const clearAndSetTimer = (key: string, callback: () => void, delay: number) => {
    if (timers[key]) {
      clearTimeout(timers[key]);
    }
    timers[key] = setTimeout(() => {
      callback();
      delete timers[key];
    }, delay);
  };

  const endFlashAnimation = (updatedTableData: ITransformedAssetClass[]) => {
    updatedTableData.forEach((data) => {
      data.allocations.forEach((allocationItem) => {
        if (allocationItem.updated) {
          const key = `assetClass-${data.assetClassId}-allocation-${allocationItem.id}`;
          clearAndSetTimer(
            key,
            () => {
              setTableData((prevTableData) => resetUpdatedFlag(prevTableData, data.assetClassId, allocationItem.id));
            },
            3000
          );
        }
      });

      data.subAssetClasses.forEach((subAssetClass) => {
        subAssetClass.allocations.forEach((allocationItem) => {
          if (allocationItem.updated) {
            const key = `subAssetClass-${subAssetClass.subAssetClassId}-allocation-${allocationItem.id}`;
            clearAndSetTimer(
              key,
              () => {
                setTableData((prevTableData) => resetUpdatedFlag(prevTableData, data.assetClassId, allocationItem.id, subAssetClass.subAssetClassId));
              },
              3000
            );
          }
        });
      });
    });
  };

  const applyValueToAllAssetClassRows = (assetClassId: string, acAllocation: ITransformedAllocation) => {
    const copyTableData = tableData.map((data) => {
      if (data.assetClassId === assetClassId) {
        const updatedAllocations = data.allocations.map((allocationItem) => {
          if (allocationItem.id !== acAllocation.id) {
            return { ...allocationItem, updated: true };
          }
          return allocationItem;
        });
        return { ...data, allocations: updatedAllocations };
      }

      return data;
    });
    startToast(copyTableData);

    const updatedTableData = tableData.map((data) => {
      if (data.assetClassId === assetClassId) {
        const updatedAllocations = data.allocations.map((allocationItem) => {
          if (allocationItem.id === acAllocation.id) {
            return { ...allocationItem, allocation: acAllocation.allocation };
          }
          return { ...allocationItem, allocation: acAllocation.allocation, updated: true };
        });
        return { ...data, allocations: updatedAllocations };
      }

      return data;
    });

    setTableData(updatedTableData);
    debouncedUpdateAssetClassForecasts(updatedTableData, commitmentPlanData.startDate, commitmentPlanData.growthRate);
    endFlashAnimation(updatedTableData);
  };

  const applyValueToAllGoingForwardAssetClassRows = (assetClassId: string, acAllocation: ITransformedAllocation) => {
    const copyTableData = tableData.map((data) => {
      if (data.assetClassId === assetClassId) {
        const updatedAllocations = data.allocations.map((allocationItem) => {
          if (new Date(allocationItem.date) > new Date(acAllocation.date)) {
            return { ...allocationItem, updated: true };
          }
          return allocationItem;
        });
        return { ...data, allocations: updatedAllocations };
      }

      return data;
    });
    startToast(copyTableData);

    const updatedTableData = tableData.map((data) => {
      if (data.assetClassId === assetClassId) {
        const updatedAllocations = data.allocations.map((allocationItem) => {
          if (new Date(allocationItem.date) > new Date(acAllocation.date)) {
            return { ...allocationItem, allocation: acAllocation.allocation, updated: true };
          }
          return allocationItem;
        });
        return { ...data, allocations: updatedAllocations };
      }

      return data;
    });

    setTableData(updatedTableData);
    debouncedUpdateAssetClassForecasts(updatedTableData, commitmentPlanData.startDate, commitmentPlanData.growthRate);
    endFlashAnimation(updatedTableData);
  };

  const applyValueToAllSubAssetClassRows = (assetClassId: string, subAssetClassId: string, sacAllocation: ITransformedAllocation) => {
    const copyTableData = tableData.map((data) => {
      if (data.assetClassId === assetClassId) {
        const updatedSubAssetClasses = data.subAssetClasses.map((subAssetClass) => {
          if (subAssetClass.subAssetClassId === subAssetClassId) {
            const updatedAllocations = subAssetClass.allocations.map((allocationItem) => {
              if (allocationItem.id !== sacAllocation.id) {
                return { ...allocationItem, updated: true };
              }
              return allocationItem;
            });

            return { ...subAssetClass, allocations: updatedAllocations };
          }
          return subAssetClass;
        });

        return { ...data, subAssetClasses: updatedSubAssetClasses };
      }

      return data;
    });

    startToast(copyTableData);

    const updatedTableData = tableData.map((data) => {
      if (data.assetClassId === assetClassId) {
        const updatedSubAssetClasses = data.subAssetClasses.map((subAssetClass) => {
          if (subAssetClass.subAssetClassId === subAssetClassId) {
            const updatedAllocations = subAssetClass.allocations.map((allocationItem) => {
              const allocation = sacAllocation.allocation === '' || sacAllocation.allocation === '-' ? '-' : sacAllocation.allocation;
              if (allocationItem.id === sacAllocation.id) {
                return { ...allocationItem, allocation };
              }

              return { ...allocationItem, allocation, updated: true };
            });

            return { ...subAssetClass, allocations: updatedAllocations };
          }
          return subAssetClass;
        });

        const summedAllocations = updatedSubAssetClasses.reduce((acc, subAssetClass) => {
          subAssetClass.allocations.forEach((allocationItem, index) => {
            const currentAllocation = parseFloat(allocationItem.allocation) || 0;
            const accumulatedAllocation = parseFloat(acc[index]?.allocation) || 0;
            const newAllocation = accumulatedAllocation + currentAllocation;
            acc[index] = { ...allocationItem, allocation: newAllocation === 0 ? '-' : newAllocation.toString(), updated: false };
          });
          return acc;
        }, []);

        return { ...data, allocations: summedAllocations, subAssetClasses: updatedSubAssetClasses };
      }

      return data;
    });

    setTableData(updatedTableData);
    debouncedUpdateAssetClassForecasts(updatedTableData, commitmentPlanData.startDate, commitmentPlanData.growthRate);
    endFlashAnimation(updatedTableData);
  };

  const applyValueToAllGoingForwardSubAssetClassRows = (assetClassId: string, subAssetClassId: string, sacAllocation: ITransformedAllocation) => {
    const copyTableData = tableData.map((data) => {
      if (data.assetClassId === assetClassId) {
        const updatedSubAssetClasses = data.subAssetClasses.map((subAssetClass) => {
          if (subAssetClass.subAssetClassId === subAssetClassId) {
            const updatedAllocations = subAssetClass.allocations.map((allocationItem) => {
              if (new Date(allocationItem.date) > new Date(sacAllocation.date)) {
                return { ...allocationItem, updated: true };
              }
              return allocationItem;
            });

            return { ...subAssetClass, allocations: updatedAllocations };
          }
          return subAssetClass;
        });

        return { ...data, subAssetClasses: updatedSubAssetClasses };
      }

      return data;
    });
    startToast(copyTableData);

    const updatedTableData = tableData.map((data) => {
      if (data.assetClassId === assetClassId) {
        const updatedSubAssetClasses = data.subAssetClasses.map((subAssetClass) => {
          if (subAssetClass.subAssetClassId === subAssetClassId) {
            const updatedAllocations = subAssetClass.allocations.map((allocationItem) => {
              if (new Date(allocationItem.date) > new Date(sacAllocation.date)) {
                return { ...allocationItem, allocation: sacAllocation.allocation, updated: true };
              }
              return allocationItem;
            });

            return { ...subAssetClass, allocations: updatedAllocations };
          }
          return subAssetClass;
        });

        const summedAllocations = updatedSubAssetClasses.reduce((acc, subAssetClass) => {
          subAssetClass.allocations.forEach((allocationItem, index) => {
            const currentAllocation = parseFloat(allocationItem.allocation) || 0;
            const accumulatedAllocation = parseFloat(acc[index]?.allocation) || 0;
            const newAllocation = accumulatedAllocation + currentAllocation;
            acc[index] = { ...allocationItem, allocation: newAllocation === 0 ? '-' : newAllocation.toString(), updated: false };
          });
          return acc;
        }, []);

        return { ...data, allocations: summedAllocations, subAssetClasses: updatedSubAssetClasses };
      }

      return data;
    });

    setTableData(updatedTableData);
    debouncedUpdateAssetClassForecasts(updatedTableData, commitmentPlanData.startDate, commitmentPlanData.growthRate);
    endFlashAnimation(updatedTableData);
  };

  const onSave = () => {
    if (commitmentPlanId && !duplicate) {
      openModalWindow('confirm-dialog');
      return;
    }
    createPlan();
  };

  const createPlan = () => {
    setErrorMessage(null);
    if (!commitmentPlanData) return;

    const transformedData = transformBackData(tableData);
    const { name, startDate, startingPortfolioValue, drawdownTarget, startingAllocation, growthRate } = commitmentPlanData;

    createCommitmentPlan({
      variables: {
        input: {
          legalEntityId: params.entityId,
          name: name,
          startDate: startDate,
          startingPortfolioValue: startingPortfolioValue,
          drawdownTarget: drawdownTarget,
          startingAllocation: startingAllocation,
          growthRate: growthRate,
          active: true,
          planAllocations: transformedData
        }
      },
      onCompleted: () => {
        goBack({ fallBack: '/investors' });
      }
    });
  };

  const updatePlan = () => {
    closeModalWindow();
    setErrorMessage(null);
    if (!commitmentPlanData) return;

    const transformedData = transformBackData(tableData);
    const { id, name, startDate, startingPortfolioValue, drawdownTarget, startingAllocation, growthRate } = commitmentPlanData;

    updateCommitmentPlan({
      variables: {
        input: {
          id: id,
          legalEntityId: params.entityId,
          name: name,
          startDate: startDate,
          startingPortfolioValue: startingPortfolioValue,
          drawdownTarget: drawdownTarget,
          startingAllocation: startingAllocation,
          growthRate: growthRate,
          planAllocations: transformedData
        }
      },
      onCompleted: () => {
        goBack({ fallBack: '/investors' });
      }
    });
  };

  const openModalWindow = (type: string) => {
    setModalWindow({ type, isOpen: true });
  };

  const closeModalWindow = () => {
    setModalWindow({ ...modalWindow, isOpen: false });
  };

  const saveModelOptions = (name: string, growthRate: number) => {
    setCommitmentPlanData((prevState) => {
      return { ...prevState, name, growthRate };
    });
    debouncedUpdateAssetClassForecasts(tableData, commitmentPlanData.startDate, growthRate);
    closeModalWindow();
  };

  if (statusDataLoading || commitmentPlanLoading) {
    return <LoaderOnWholeScreen />;
  }

  return (
    <>
      {(createCommitmentPlanLoading || updateCommitmentPlanLoading) && (
        <Loading
          isOpen={createCommitmentPlanLoading || updateCommitmentPlanLoading}
          title="Saving Commitment Plan"
          description="Please allow a few moment for this process to complete."
        />
      )}
      {modalWindow.isOpen && modalWindow.type === 'model-options' && (
        <ModelOptions
          isOpen={modalWindow.isOpen}
          onClose={closeModalWindow}
          commitmentPlanData={commitmentPlanData}
          saveModelOptions={saveModelOptions}
        />
      )}
      {modalWindow.isOpen && modalWindow.type === 'discard-changes' && <DiscardChanges isOpen={modalWindow.isOpen} onClose={closeModalWindow} />}
      {modalWindow.isOpen && modalWindow.type === 'confirm-dialog' && (
        <ConfirmDialog isOpen={modalWindow.isOpen} onClose={closeModalWindow} onSave={updatePlan} />
      )}
      <MainWrap>
        <SuccessToast showToast={showToast} undoAction={undoAction} closeToast={closeToast} />
        <Header modalControl={<GoBackButton handleClose={() => goBack({ fallBack: '/investors' })} backToTitle={backToTitle} />} />
        <PageTitle title="Edit Commitment Plan" />
        {errorMessage && <Banner icon={<InfoIcon width={26} height={26} />} title="Error" description={errorMessage} bgColor="#D63B4B" />}
        <PacingDetails
          mode="editCommitmentPlan"
          detailsData={commitmentPlanData}
          onEditButtonClick={() => openModalWindow('model-options')}
          assetClassForecasts={assetClassForecasts}
          drawdownTargetDialogData={commitmentPlanData?.drawdownTargets ?? null}
        />
        <CustomPaddingWrap>
          <CommitmentPlanTable
            tableData={tableData}
            tableHeaderColumns={tableHeaderColumns}
            loading={false}
            columnWidth={columnWidth}
            mode="edit"
            targetSubAssetClasses={statusData?.target_sub_asset_classes || false}
            handleAssetClassAllocationChange={handleAssetClassAllocationChange}
            handleSubAssetClassAllocationChange={handleSubAssetClassAllocationChange}
            applyValueToAllAssetClassRows={applyValueToAllAssetClassRows}
            applyValueToAllGoingForwardAssetClassRows={applyValueToAllGoingForwardAssetClassRows}
            applyValueToAllSubAssetClassRows={applyValueToAllSubAssetClassRows}
            applyValueToAllGoingForwardSubAssetClassRows={applyValueToAllGoingForwardSubAssetClassRows}
          />
          <FormFooter
            onCancel={() => openModalWindow('discard-changes')}
            disableSaveButton={false}
            onSave={onSave}
            showRequiredFieldsWarning={false}
          />
        </CustomPaddingWrap>
      </MainWrap>
    </>
  );
};

const CustomPaddingWrap = styled(PaddingWrap)`
  padding-top: 40px;
`;
