import Label from '@/components/basicComponents/label';
import { AmountCharacters } from '@/components/dueDiligence-page/modals/changeAssignee';
import { ChangeEvent } from 'react';
import styled from 'styled-components';

interface PromptTemplateProps {
  title: string;
  tooltip: string;
  value: string;
  setValue: (value: string) => void;
  selectedOption: string;
  setSelectedOption: (option: string) => void;
  isValid: boolean;
  errorMessage: string;
}

const radioButtonItems = ['Use default', 'Customize'];

export const PromptTemplate = ({
  title,
  tooltip,
  value,
  setValue,
  selectedOption,
  setSelectedOption,
  isValid,
  errorMessage
}: PromptTemplateProps) => {
  const handleRadioChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSelectedOption(event.target.value);
  };

  return (
    <div>
      <Label tooltip={tooltip} tooltipWidth={250} required>
        {title}
      </Label>
      <RadioButtonsWrap>
        {radioButtonItems.map((item, index) => (
          <RadioButtonContainer key={item + index}>
            <RadioButton name={title} value={item} checked={selectedOption === item} onChange={handleRadioChange} />
            <RadioText>{item}</RadioText>
          </RadioButtonContainer>
        ))}
      </RadioButtonsWrap>
      {selectedOption === 'Customize' && (
        <>
          <StyledTextarea
            height="110px"
            background={isValid ? '#FFFFFF' : '#FFEAEC'}
            borderColor={isValid ? '#C7CBD2' : '#D63B4B'}
            maxLength={1000}
            value={value}
            onChange={(event) => setValue(event.target.value)}
          />
          <CharactersRemainder background={isValid ? '#f0f1f3' : '#FFEAEC'} borderColor={isValid ? '#c7cbd2' : '#D63B4B'}>
            <AmountCharacters>{1000 - value.length > 0 ? 1000 - value.length : 0}</AmountCharacters> Characters Remaining
          </CharactersRemainder>
          {!isValid && <Error>{errorMessage}</Error>}
        </>
      )}
    </div>
  );
};

const RadioButtonsWrap = styled.label`
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 10px;
`;

const RadioButtonContainer = styled.label`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  cursor: pointer;
`;

const RadioButton = styled.input.attrs({ type: 'radio' })`
  appearance: none;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 1px solid #c7cbd2;
  margin-right: 8px;
  outline: none;
  position: relative;
  cursor: pointer;

  &:after {
    content: '';
    display: block;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: #373f4e;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    opacity: 0;
  }

  &:checked {
    border-color: #373f4e;

    &:after {
      opacity: 1;
    }
  }

  &:focus {
    box-shadow: 0 0 2px 2px rgba(55, 63, 78, 0.3);
  }
`;

const RadioText = styled.span`
  font-family: Blinker;
  font-size: 16px;
  font-weight: 400;
  color: #202020;
  cursor: pointer;
`;

const StyledTextarea = styled.textarea<{
  height: string;
  background: string;
  borderColor: string;
}>`
  font-family: 'Blinker';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: #2e2e2e;
  width: 100%;
  height: ${({ height }) => (height ? height : '120px')};
  border: ${({ borderColor }) => `1px solid ${borderColor}`};
  border-radius: 4px 4px 0px 0px;
  resize: none;
  outline: 0;
  background-color: ${({ background }) => background};
  padding: 10px;
`;

const CharactersRemainder = styled.div<{
  background: string;
  borderColor: string;
}>`
  background: ${({ background }) => background};
  padding: 6px 10px;
  margin-top: -8px;
  border: ${({ borderColor }) => `1px solid ${borderColor}`};
  border-radius: 0px 0px 4px 4px;
  font-weight: 400;
  font-size: 13px;
  color: #7f7f7f;
`;

const Error = styled.p`
  color: #d63b4b;
  font-size: 16px;
  font-weight: 400;
`;
