import CheckBox from '@/components/basicComponents/checkbox';
import FilterSelect from '@/components/basicComponents/select';
import Button from '@/components/fat-basicComponents/button';
import ModalWrappedContent from '@/components/fat-basicComponents/modal/modalWrappedContent';
import { GET_SETTINGS } from '@/components/settings-page/queries';
import { useQuery } from '@apollo/client';
import { PDFDownloadLink } from '@react-pdf/renderer';
import { MouseEvent, useEffect, useState } from 'react';
import styled from 'styled-components';
import { PDFTemplate } from '../DDR-PDF/PDFTemplate';
import { ITier } from '../dueDiligenceRecord/types';
import { DUE_DILIGENCE_RECORD_QUERY } from '../queries';

interface DownloadPDFProps {
  onClose: () => void;
  isOpen: boolean;
  activeTab?: string;
  recordId: string | null;
}

const DownloadPDF = ({ isOpen, onClose, activeTab, recordId }: DownloadPDFProps) => {
  const [selectedTemplate, setSelectedTempalte] = useState('Select');
  const [includeAiSuggested, setIncludeAiSuggested] = useState(true);
  const [isPdfReady, setIsPdfReady] = useState(false);
  const [tiers, setTiers] = useState<ITier[]>([]);

  useEffect(() => {
    if (activeTab) {
      setSelectedTempalte(activeTab);
      return;
    }
    setSelectedTempalte('Select');
    return () => {
      setSelectedTempalte('Select');
    };
  }, [isOpen]);

  const { data } = useQuery(DUE_DILIGENCE_RECORD_QUERY, {
    fetchPolicy: 'network-only',
    variables: {
      id: recordId
    },
    skip: !recordId,
    onCompleted: (data) => {
      setTiers(data.getDueDiligenceRecord.funnel.tiers);
    }
  });

  const { data: firmSettingsData } = useQuery(GET_SETTINGS, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
    variables: {
      type: 'firmSetting'
    }
  });

  const { data: templateStylingData } = useQuery(GET_SETTINGS, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
    variables: {
      type: 'templateStyling'
    }
  });

  const changeSelectedTemplate = (template: string) => {
    setIsPdfReady(false);
    setSelectedTempalte(template);
  };

  const changeCheckboxValue = () => {
    setIsPdfReady(false);
    setIncludeAiSuggested((prev) => !prev);
  };

  const generatingPdf = (isPdfReady: boolean) => {
    if (isPdfReady) {
      setTimeout(() => {
        setIsPdfReady(isPdfReady);
      }, 2000);
    }
  };

  const handleCloseModal = () => {
    onClose();
    setIsPdfReady(false);
  };

  const openUrlInNewTab = (event: MouseEvent<HTMLButtonElement>, blob: Blob | null) => {
    event.preventDefault();

    if (blob) {
      const url = URL.createObjectURL(blob);
      window.open(url, '_blank');
    }
  };

  if (!tiers.length) return <></>;

  return (
    <ModalWrappedContent isOpen={isOpen} onClose={handleCloseModal} title={'Download PDF'} showCancelButton={false} showRequiredFields>
      <Container>
        <CustomFilterSelect
          data={tiers.map((tier) => tier.name)}
          selected={selectedTemplate}
          setSelected={(value) => changeSelectedTemplate(value)}
          required
          label={'Select Tier'}
          width="100%"
          minHeight="50px"
          fontSize="19px"
          fontWeight="300"
        />
        <CheckboxWrapper id={'input'} onClick={changeCheckboxValue}>
          <CheckBox id={'input'} isActive={includeAiSuggested} /> Include AI Suggested Content
        </CheckboxWrapper>
        {selectedTemplate === 'Select' ? (
          <DownloadWrappper>
            <Button styleType="disabled" size="md">
              Download
            </Button>
          </DownloadWrappper>
        ) : (
          <DownloadWrappper>
            <PDFDownloadLink
              document={
                <PDFTemplate
                  firmSettings={firmSettingsData?.Settings ?? []}
                  templateStylingData={templateStylingData?.Settings ?? []}
                  tier={tiers.find((tier) => tier.name === selectedTemplate) as ITier}
                  investmentName={data?.getDueDiligenceRecord.investment.name}
                  investmentDescription={data?.getDueDiligenceRecord.investment.description}
                  includeAiSuggested={includeAiSuggested}
                  generatingPdf={generatingPdf}
                />
              }
              style={{ width: 'max-content' }}
              fileName="test.pdf"
            >
              {({ blob }) => {
                return !isPdfReady ? (
                  <Button styleType="disabled" size="md">
                    Loading PDF Template ...
                  </Button>
                ) : (
                  <Button
                    onClick={(event: React.MouseEvent<HTMLButtonElement>) => openUrlInNewTab(event, blob ?? null)}
                    styleType={selectedTemplate === 'Select' ? 'disabled' : 'default'}
                    size="md"
                  >
                    Download
                  </Button>
                );
              }}
            </PDFDownloadLink>
          </DownloadWrappper>
        )}
      </Container>
    </ModalWrappedContent>
  );
};

export default DownloadPDF;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const CustomFilterSelect = styled(FilterSelect)`
  #titleSelect {
    text-transform: none;
  }
  #dropDownSelect {
    margin-top: 15px;
  }
`;

const CheckboxWrapper = styled.div`
  cursor: pointer;
  display: flex;
  gap: 9px;
  margin-top: 20px;
  padding: 18px 0 30px 0;
  border-top: 1px solid ${({ theme }) => theme.border.base};
  color: ${({ theme }) => theme.font.base};
  font-size: 16px;
  font-weight: 400;
`;

const DownloadWrappper = styled.div`
  display: flex;
  justify-content: center;
`;
