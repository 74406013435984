import { GroupedIcon } from '@/assets/icons/info-icons/groupedIcon';
import { MinusIcon } from '@/assets/icons/info-icons/minusIcon';
import { PlusIcon } from '@/assets/icons/info-icons/plusIcon';
import BasicTooltip from '@/components/basicComponents/tooltip';
import FormattingTooltip from '@/components/basicComponents/tooltip/dollarsTooltip';
import { formatterClient } from '@/components/clients-page/utils';
import { TableBodyWrapper, TableRowCell, TableRowWrapper, TitleCell } from '@/components/table/styles';
import { formatCash } from '@/utils/formatCash';
import { useState } from 'react';
import styled, { useTheme } from 'styled-components';
import { simplifiedSummaryTableColumns, summaryTablecolumns } from '../constatnts';
import { AssetClassTargetAllocationDto, IAllocationPacingTarget } from '../types';

interface TableRowProps {
  row: AssetClassTargetAllocationDto;
  ignoreAllocationTargets: boolean;
  targetSubAssetClasses: boolean;
}

const pacingTargetToolTipContent = (row: IAllocationPacingTarget) => {
  return (
    <TableBodyWrapper>
      <TableRowCell width={100}>
        <TextCell>Annual Pacing Target: {formatterClient.format(row.annualPacingTarget)}</TextCell>
      </TableRowCell>
      <TableRowCell width={100}>
        <TextCell>Commitments This Year: {formatterClient.format(row.commitmentsThisYear)}</TextCell>
      </TableRowCell>
      <TableRowCell width={100}>
        <TextCell>Remaining Pacing Target: {formatterClient.format(row.remainingPacingTarget)}</TextCell>
      </TableRowCell>
    </TableBodyWrapper>
  );
};

const TableRow = ({ row, ignoreAllocationTargets, targetSubAssetClasses }: TableRowProps) => {
  const theme = useTheme();

  const [showSubAssetClass, setShowSubAssetClass] = useState(false);

  if (ignoreAllocationTargets) {
    return (
      <>
        <TableRowWrapper>
          <TableRowCell width={simplifiedSummaryTableColumns[0].width}>
            <AssetClassCell>
              {targetSubAssetClasses && row.subAssetClasses && (
                <PlusIconWrapper onClick={() => setShowSubAssetClass(!showSubAssetClass)}>
                  {showSubAssetClass ? <MinusIcon fill={theme.font.base} /> : <PlusIcon fill={theme.font.base} />}
                </PlusIconWrapper>
              )}
              <TextCell>{row.name}</TextCell>
            </AssetClassCell>
          </TableRowCell>
          <TableRowCell width={simplifiedSummaryTableColumns[1].width}>
            <TextCell bold={Boolean(targetSubAssetClasses && row.subAssetClasses)}>
              <FormattingTooltip zIndex={1000}>{row.economicExposure}</FormattingTooltip>
            </TextCell>
          </TableRowCell>
        </TableRowWrapper>
        {targetSubAssetClasses && row.subAssetClasses && showSubAssetClass && (
          <>
            {row.subAssetClasses.map((subAssetClass) => (
              <TableRowWrapper key={subAssetClass.id}>
                <TableRowCell width={simplifiedSummaryTableColumns[0].width}>
                  <AssetClassCell>
                    <GroupIconWrapper>
                      <GroupedIcon width={26} height={26} />
                    </GroupIconWrapper>
                    <TextCell>{subAssetClass.name}</TextCell>
                  </AssetClassCell>
                </TableRowCell>
                <TableRowCell width={simplifiedSummaryTableColumns[1].width}>
                  <TextCell>
                    <FormattingTooltip zIndex={1000}>{subAssetClass.economicExposure}</FormattingTooltip>
                  </TextCell>
                </TableRowCell>
              </TableRowWrapper>
            ))}
          </>
        )}
      </>
    );
  }

  return (
    <>
      <TableRowWrapper>
        <TableRowCell width={summaryTablecolumns[0].width}>
          <AssetClassCell>
            {targetSubAssetClasses && row.subAssetClasses && (
              <PlusIconWrapper onClick={() => setShowSubAssetClass(!showSubAssetClass)}>
                {showSubAssetClass ? <MinusIcon fill={theme.font.base} /> : <PlusIcon fill={theme.font.base} />}
              </PlusIconWrapper>
            )}
            <TextCell>{row.name}</TextCell>
          </AssetClassCell>
        </TableRowCell>
        <TableRowCell width={summaryTablecolumns[1].width}>
          <TextCell bold={Boolean(targetSubAssetClasses && row.subAssetClasses)}>
            {row.percentage === null ? (
              '--'
            ) : (
              <BasicTooltip tooltipContent={`${row.percentage.toFixed(2)}%`} zIndex={1000}>
                {row.percentage.toFixed(0)}%
              </BasicTooltip>
            )}
          </TextCell>
        </TableRowCell>
        <TableRowCell width={summaryTablecolumns[2].width}>
          <TextCell bold={Boolean(targetSubAssetClasses && row.subAssetClasses)}>
            <FormattingTooltip zIndex={1000}>{row.targetAllocation}</FormattingTooltip>
          </TextCell>
        </TableRowCell>
        <TableRowCell width={summaryTablecolumns[3].width}>
          <TextCell bold={Boolean(targetSubAssetClasses && row.subAssetClasses)}>
            <FormattingTooltip zIndex={1000}>{row.economicExposure}</FormattingTooltip>
          </TextCell>
        </TableRowCell>
        <TableRowCell width={summaryTablecolumns[4].width}>
          <TextCell bold={Boolean(targetSubAssetClasses && row.subAssetClasses)}>
            <FormattingTooltip zIndex={1000}>{row.capacity}</FormattingTooltip>
          </TextCell>
        </TableRowCell>
        <TableRowCell width={summaryTablecolumns[5].width}>
          <TextCell bold={Boolean(targetSubAssetClasses && row.subAssetClasses)}>
            <TextCell>
              {row.pacingTarget ? (
                <CellWithTooltip>
                  {formatCash(row.pacingTarget.remainingPacingTarget)}
                  <TooltipWrapper id="infoWrap">
                    <BasicTooltip tooltipContent={pacingTargetToolTipContent(row.pacingTarget)} withTooltipIcon zIndex={1000} />
                  </TooltipWrapper>
                </CellWithTooltip>
              ) : (
                <>--</>
              )}
            </TextCell>
          </TextCell>
        </TableRowCell>
      </TableRowWrapper>
      {targetSubAssetClasses && row.subAssetClasses && showSubAssetClass && (
        <>
          {row.subAssetClasses.map((subAssetClass) => (
            <TableRowWrapper key={subAssetClass.id}>
              <TableRowCell width={summaryTablecolumns[0].width}>
                <AssetClassCell>
                  <GroupIconWrapper>
                    <GroupedIcon width={26} height={26} />
                  </GroupIconWrapper>
                  <TextCell>{subAssetClass.name}</TextCell>
                </AssetClassCell>
              </TableRowCell>
              <TableRowCell width={summaryTablecolumns[1].width}>
                <TextCell>
                  {subAssetClass.percentage === null ? (
                    '--'
                  ) : (
                    <BasicTooltip tooltipContent={`${subAssetClass.percentage.toFixed(2)}%`} zIndex={1000}>
                      {subAssetClass.percentage.toFixed(0)}%
                    </BasicTooltip>
                  )}
                </TextCell>
              </TableRowCell>
              <TableRowCell width={summaryTablecolumns[2].width}>
                <TextCell>
                  <FormattingTooltip zIndex={1000}>{subAssetClass.targetAllocation}</FormattingTooltip>
                </TextCell>
              </TableRowCell>
              <TableRowCell width={summaryTablecolumns[3].width}>
                <TextCell>
                  <FormattingTooltip zIndex={1000}>{subAssetClass.economicExposure}</FormattingTooltip>
                </TextCell>
              </TableRowCell>
              <TableRowCell width={summaryTablecolumns[4].width}>
                <TextCell>
                  <FormattingTooltip zIndex={1000}>{subAssetClass.capacity}</FormattingTooltip>
                </TextCell>
              </TableRowCell>
              <TableRowCell width={summaryTablecolumns[5].width}>
                <TextCell>
                  <TextCell>
                    {subAssetClass.pacingTarget ? (
                      <CellWithTooltip>
                        {formatCash(subAssetClass.pacingTarget.remainingPacingTarget)}
                        <TooltipWrapper id="infoWrap">
                          <BasicTooltip tooltipContent={pacingTargetToolTipContent(subAssetClass.pacingTarget)} withTooltipIcon zIndex={1000} />
                        </TooltipWrapper>
                      </CellWithTooltip>
                    ) : (
                      <>--</>
                    )}
                  </TextCell>
                </TextCell>
              </TableRowCell>
            </TableRowWrapper>
          ))}
        </>
      )}
    </>
  );
};

export default TableRow;

const AssetClassCell = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 6px;
`;

const PlusIconWrapper = styled.div`
  cursor: pointer;
  border-radius: 12px;
  width: 24px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    background-color: ${({ theme }) => theme.action.hover};
    transition: background-color 100ms ease;
  }
`;

const GroupIconWrapper = styled.div`
  padding: 5px;
  position: relative;
  top: -9px;
  margin-right: 14px;
  margin-left: 5px;
`;

const TextCell = styled(TitleCell)<{ bold?: boolean }>`
  font-weight: ${({ bold }) => (bold ? 600 : 400)};
`;

const CellWithTooltip = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const TooltipWrapper = styled.div`
  opacity: 0;
`;
