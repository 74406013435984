import { useQuery } from '@apollo/client';
import { useEffect, useState } from 'react';

import FilterSelect from '@/components/basicComponents/select';
import { User } from '@/types/user';
import ModalWrappedContent from '../fat-basicComponents/modal/modalWrappedContent';
import { LIST_USERS_QUERY } from './queries';
import { IRecordData } from './types';

interface EditOwnerProps {
  row: IRecordData | null;
  onClose: () => void;
  isOpen: boolean;
  updateOwner: (variables: any) => void;
}

const EditOwner = ({ row, onClose, isOpen, updateOwner }: EditOwnerProps) => {
  const [owner, setOwner] = useState('');

  useEffect(() => {
    if (!row) return;

    setOwner(`${row.owner.firstName} ${row.owner.lastName}`);
  }, [row]);

  const { data } = useQuery(LIST_USERS_QUERY, {
    notifyOnNetworkStatusChange: true,
    variables: {
      filters: {
        roles: ['INVESTMENT_LEAD', 'INVESTMENT_MEMBER']
      }
    }
  });

  const saveSelectors = () => {
    const newOwner = data.listUsers.find((item: User) => `${item.firstName} ${item.lastName}` === owner);

    updateOwner({
      variables: {
        data: {
          id: row.id,
          name: row.name,
          ownerEmail: newOwner.email,
          assetClass: row.assetClass,
          closeDate: row.closeDate,
          investmentId: row.investment?.id,
          tags: row.tags
        }
      }
    });
    onClose();
  };

  if (!row) return <></>;

  return (
    <ModalWrappedContent
      isOpen={isOpen}
      onClose={onClose}
      title={'Edit Owner'}
      confirmButton={{ name: 'Save', onClick: saveSelectors }}
      showRequiredFields
    >
      <FilterSelect
        data={data?.listUsers?.map((owner: User) => `${owner.firstName} ${owner.lastName}`) || []}
        selected={owner}
        setSelected={setOwner}
        label={'Owner'}
        width={'100%'}
        required
      />
    </ModalWrappedContent>
  );
};

export default EditOwner;
