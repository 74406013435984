import { updatesTableColumns } from '@/components/managerUpdates-page/constatnts';
import DropdownActions from '@/components/table/fat-dropdownActions';
import {
  MobileRow,
  MobileRowTitle,
  MobileRowValue,
  MobileTableRowWrap,
  MobileTableWrapper,
  NoResults
} from '@/components/table/mobileTable/mobileTable';
import { IItems } from '@/components/table/types';
import { dateFormatter } from '@/utils/dateFormatter';
import { format } from 'date-fns';
import styled from 'styled-components';
import { DOCUMENT_RECORD_STATUS_ENUM, IDocumentRecordEntity } from '../../types';

interface MobileTableProps {
  tableData: IDocumentRecordEntity[];
  dropdownActions: (row: IDocumentRecordEntity) => IItems[];
}

const MobileTable = ({ tableData, dropdownActions }: MobileTableProps) => {
  return (
    <MobileTableWrapper>
      {tableData?.length ? (
        <>
          {tableData.map((el) => {
            return (
              <MobileTableRowWrap key={el.id}>
                <MobileRow>
                  <MobileRowTitle>{updatesTableColumns[0].title}</MobileRowTitle>
                  <MobileRowValue>{el.name}</MobileRowValue>
                </MobileRow>

                <MobileRow>
                  <MobileRowTitle>{updatesTableColumns[1].title}</MobileRowTitle>
                  <MobileRowValue>{`${el.owner.firstName} ${el.owner.lastName}`}</MobileRowValue>
                </MobileRow>

                <MobileRow>
                  <MobileRowTitle>{updatesTableColumns[2].title}</MobileRowTitle>
                  <MobileRowValue>
                    {el.statusUpdatedAt && dateFormatter(format(new Date(el.statusUpdatedAt), 'yyyy-MM-dd'), 'yyyy-MM-dd')}
                  </MobileRowValue>
                </MobileRow>

                <MobileRow>
                  <MobileRowTitle>{updatesTableColumns[3].title}</MobileRowTitle>
                  <MobileRowValue>{DOCUMENT_RECORD_STATUS_ENUM[el.status]}</MobileRowValue>
                </MobileRow>

                <DropdownActionsWrapper>
                  <DropdownActions items={dropdownActions(el)} isMobile isListCenter />
                </DropdownActionsWrapper>
              </MobileTableRowWrap>
            );
          })}
        </>
      ) : (
        <NoResults>No Results</NoResults>
      )}
    </MobileTableWrapper>
  );
};

export default MobileTable;

const DropdownActionsWrapper = styled.div`
  margin: 0 auto;
`;
