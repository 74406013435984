import styled from 'styled-components';

import Button from '@/components/fat-basicComponents/button';
import { useResponsive } from '@/hooks/use-responsive';

interface FooterModalProps {
  startOver: () => void;
  confirmButton: {
    name: string;
    onClick: () => void;
    disabled?: boolean;
  };
}

const FooterModal = ({ startOver, confirmButton }: FooterModalProps) => {
  const { isMobile, isTablet } = useResponsive();

  return (
    <FooterModalWrap isMobile={isMobile} isTablet={isTablet}>
      <ButtonsControl>
        <Button styleType="outline" size="lg" onClick={startOver}>
          Start Over
        </Button>
        <Button size="lg" styleType={confirmButton.disabled ? 'disabled' : 'default'} onClick={confirmButton.onClick}>
          {confirmButton.name}
        </Button>
      </ButtonsControl>
    </FooterModalWrap>
  );
};

const FooterModalWrap = styled.div<{ isMobile: boolean; isTablet: boolean }>`
  width: ${({ isMobile, isTablet }) => (isMobile || isTablet ? '100%' : 'calc(100% - 80px)')};
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px 80px;
  position: fixed;
  left: ${({ isMobile, isTablet }) => (isMobile || isTablet ? '0' : '80px')};
  bottom: 0;
  clear: both;
  background-color: ${({ theme }) => theme.layer[1]};
  border-top: 1px solid ${({ theme }) => theme.border.base};
  max-height: 90px;
  z-index: 30;
`;

const ButtonsControl = styled.div`
  display: flex;
  justify-content: center;
  gap: 10px;
`;

export default FooterModal;
