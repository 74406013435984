import ModalWrappedContent from '@/components/fat-basicComponents/modal/modalWrappedContent';
import styled from 'styled-components';

interface ComingSoonProps {
  onClose: () => void;
  isOpen: boolean;
}

const ComingSoon = ({ onClose, isOpen }: ComingSoonProps) => {
  return (
    <ModalWrappedContent isOpen={isOpen} onClose={onClose} showCancelButton={false}>
      <ModalBody>Coming Soon</ModalBody>
    </ModalWrappedContent>
  );
};

export default ComingSoon;

const ModalBody = styled.div`
  color: ${({ theme }) => theme.font.strong};
  text-align: center;
  font-size: 33px;
  font-weight: 400;
  line-height: 46.2px;
  padding: 30px 0;
`;
