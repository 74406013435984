import { useMutation, useQuery } from '@apollo/client';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import Label from '@/components/basicComponents/label';
import FilterSelect from '@/components/basicComponents/select';
import Textarea from '@/components/basicComponents/textarea';
import ModalWrappedContent from '@/components/fat-basicComponents/modal/modalWrappedContent';
import { User } from '@/types/user';
import { ASSIGN_CHECKLIST_ITEM_MUTATION, ASSIGN_SECTION_MUTATION, LIST_USERS_QUERY } from '../queries';

interface ChangeAssigneeProps {
  onClose: () => void;
  isOpen: boolean;
  row: any;
  owner?: User;
}

const ChangeAssignee = ({ isOpen, onClose, row, owner }: ChangeAssigneeProps) => {
  const [select, setSelect] = useState('');
  const [noteValue, setNoteValue] = useState('');
  const [assignees, setAssignees] = useState([]);

  useEffect(() => {
    setSelect(row.data.assignedTo ? `${row.data.assignedTo.firstName} ${row.data.assignedTo.lastName}` : `${owner?.firstName} ${owner?.lastName}`);
  }, [row]);

  const { data } = useQuery(LIST_USERS_QUERY, {
    notifyOnNetworkStatusChange: true,
    variables: {
      filters: {
        roles: ['INVESTMENT_LEAD', 'INVESTMENT_MEMBER']
      }
    }
  });

  useEffect(() => {
    if (data?.listUsers) {
      setAssignees(data.listUsers.map((user: User) => `${user.firstName} ${user.lastName}`));
    }
  }, [data]);

  const [updateChecklistAssignee] = useMutation(ASSIGN_CHECKLIST_ITEM_MUTATION);
  const [updateSectionAssignee] = useMutation(ASSIGN_SECTION_MUTATION);

  const changeAssignee = () => {
    const assignedId = data.listUsers.find((item: User) => `${item.firstName} ${item.lastName}` === select);

    if (row.__typename.includes('Section')) {
      updateSectionAssignee({
        variables: {
          data: {
            id: row.data.id,
            assignedToId: assignedId.id,
            note: noteValue
          }
        },
        onCompleted: () => {
          setNoteValue('');
          setSelect('');
        }
      });

      onClose();
      return;
    }

    if (row.__typename.includes('Checklist')) {
      updateChecklistAssignee({
        variables: {
          data: {
            id: row.data.id,
            assignedToId: assignedId.id,
            note: noteValue
          }
        },
        onCompleted: () => {
          setNoteValue('');
          setSelect('');
        }
      });

      onClose();
      return;
    }
  };

  const onChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setNoteValue(e?.target.value);
  };

  return (
    <ModalWrappedContent
      isOpen={isOpen}
      onClose={onClose}
      title={'Change Assignee'}
      confirmButton={{ name: 'Assign', onClick: changeAssignee }}
      showRequiredFields
    >
      <ChangeAssigneeWrapper>
        <FilterSelect
          width={'auto'}
          label={'Select An Advisor'}
          selected={select}
          setSelected={(value) => setSelect(value)}
          data={assignees || []}
          required
        />
        <NotesWrapper>
          <Label required={false} fontSize="16px">
            Notes
          </Label>
          <Textarea maxLength={250} value={noteValue} setValue={setNoteValue} />
        </NotesWrapper>
      </ChangeAssigneeWrapper>
    </ModalWrappedContent>
  );
};

export default ChangeAssignee;

const ChangeAssigneeWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
`;

const NotesWrapper = styled.div``;

export const StyledTextarea = styled.textarea<{
  height?: string;
  isBackground?: boolean;
}>`
  font-family: 'Blinker';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: #2e2e2e;
  width: 100%;
  height: ${({ height }) => (height ? height : '120px')};
  border: ${({ isBackground }) => (isBackground ? '1px solid #3dbd4a' : '1px solid #C7CBD2')};
  border-radius: 4px 4px 0px 0px;
  resize: none;
  outline: 0;
  background-color: ${({ isBackground }) => (isBackground ? '#efffea' : '#FFFFFF')};
  padding: 10px;
`;

export const TitleTask = styled.div`
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: #373f4e;
  display: flex;
  align-items: center;
  gap: 7px;
  padding-bottom: 4px;
`;

export const CharactersRemainder = styled.div`
  background: #f0f1f3;
  padding: 6px 10px;
  margin-top: -8px;
  border: 1px solid #c7cbd2;
  border-radius: 0px 0px 4px 4px;
  font-weight: 400;
  font-size: 13px;
  color: #7f7f7f;
`;
export const AmountCharacters = styled.span`
  font-weight: 700;
`;
