import { ApprovedIcon } from '@/assets/icons/info-icons/approved';
import { RedStarIcon } from '@/assets/icons/info-icons/redStar';
import FormattingTooltip from '@/components/basicComponents/tooltip/dollarsTooltip';
import Button from '@/components/fat-basicComponents/button';
import {
  MobileRow,
  MobileRowTitle,
  MobileRowValue,
  MobileTableRowWrap,
  MobileTableWrapper,
  NoResults
} from '@/components/table/mobileTable/mobileTable';
import guid from '@/utils/guid';
import styled, { useTheme } from 'styled-components';
import { IAllocationConflictsTableData } from '../../../types';
import { columns } from '../constants';

interface MobileTableProps {
  tableData: IAllocationConflictsTableData[];
  openResolveAllocationConflicts: (row: IAllocationConflictsTableData) => void;
}

const MobileTable = ({ tableData, openResolveAllocationConflicts }: MobileTableProps) => {
  const theme = useTheme();

  const getRowStatus = (validated: boolean) => {
    if (validated) {
      return (
        <CompleteStatusCell>
          <ApprovedIcon width={23} height={23} fill={theme.context.success} />
          Validated
        </CompleteStatusCell>
      );
    }

    return (
      <RequiredStatusCell>
        <div style={{ padding: '5px' }}>
          <RedStarIcon width={9} height={11} fill={theme.context.error} />
        </div>
        Conflict
      </RequiredStatusCell>
    );
  };

  const TableRow = ({ el }: { el: IAllocationConflictsTableData }) => {
    return (
      <MobileTableRowWrap key={guid()}>
        <MobileRow>
          <MobileRowTitle>{columns[0].title}</MobileRowTitle>
          <MobileRowValue>{`${el.entityName} (${el.advisoryFirm})`}</MobileRowValue>
        </MobileRow>

        <MobileRow>
          <MobileRowTitle>{columns[1].title}</MobileRowTitle>
          <MobileRowValue>{el.investmentName}</MobileRowValue>
        </MobileRow>

        <MobileRow>
          <MobileRowTitle>{columns[2].title}</MobileRowTitle>
          <MobileRowValue>
            <FormattingTooltip zIndex={1000}>{el.importTotal}</FormattingTooltip>
          </MobileRowValue>
        </MobileRow>

        <MobileRow>
          <MobileRowTitle>{columns[3].title}</MobileRowTitle>
          <MobileRowValue>{el.importCount}</MobileRowValue>
        </MobileRow>

        <MobileRow>
          <MobileRowTitle>{columns[4].title}</MobileRowTitle>
          <MobileRowValue>
            <FormattingTooltip zIndex={1000}>{el.committedTotal}</FormattingTooltip>
          </MobileRowValue>
        </MobileRow>

        <MobileRow>
          <MobileRowTitle>{columns[5].title}</MobileRowTitle>
          <MobileRowValue>
            <FormattingTooltip zIndex={1000}>{el.pendingAmount}</FormattingTooltip>
          </MobileRowValue>
        </MobileRow>

        <MobileRow>
          <MobileRowTitle>{columns[6].title}</MobileRowTitle>
          <MobileRowValue>{getRowStatus(el.validated)}</MobileRowValue>
        </MobileRow>

        <MobileRow>
          <MobileRowTitle>{columns[7].title}</MobileRowTitle>
          <MobileRowValue>
            <Button size="sm" onClick={() => openResolveAllocationConflicts(el)}>
              {el.validated ? 'View' : 'Resolve'}
            </Button>
          </MobileRowValue>
        </MobileRow>
      </MobileTableRowWrap>
    );
  };

  return (
    <MobileTableWrapper>
      {tableData.length ? (
        tableData.map((el, index) => {
          return <TableRow el={el} key={index} />;
        })
      ) : (
        <NoResults>No Results</NoResults>
      )}
    </MobileTableWrapper>
  );
};

export default MobileTable;

const StatusCell = styled.div`
  display: flex;
  gap: 4px;
  align-items: center;
  font-size: 16px;
  font-weight: 600;
  line-height: 22.4px;
`;

const RequiredStatusCell = styled(StatusCell)`
  color: ${({ theme }) => theme.context.error};
`;

const CompleteStatusCell = styled(StatusCell)`
  color: ${({ theme }) => theme.context.success};
`;
