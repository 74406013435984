import client from '@/apollo-client';
import { InfoIcon } from '@/assets/icons/info-icons/info';
import { Banner } from '@/components/basicComponents/banner';
import { FormFooter } from '@/components/fat-basicComponents/formFooter';
import Header from '@/components/fat-header';
import { GoBackButton } from '@/components/fat-header/goBackButton';
import { PageTitle } from '@/components/fat-header/pageTitle';
import { LoaderOnWholeScreen } from '@/components/loaders/loader-on-whole-screen';
import { defaultSelectValue } from '@/components/opportunities-entity/modify/constants';
import { useGoBack } from '@/hooks/useGoBack';
import { useStatusData } from '@/hooks/useStatusData';
import { MainWrap } from '@/styles/common';
import { useMutation, useQuery } from '@apollo/client';
import { useState } from 'react';
import { useForm, useWatch } from 'react-hook-form';
import { useNavigate, useSearchParams } from 'react-router-dom';
import styled from 'styled-components';
import { GET_INVESTOR_EDIT_QUERY } from '../fat-investorDetails-page/fat-editInvestor/queries';
import { IInvestorQuery } from '../fat-investorDetails-page/types';
import { LIST_INVESTORS_QUERY } from '../queries';
import { IListInvestors, IListInvestorsQuery, ListCallback } from '../types';
import { assetClassOptions, AssetClassTargetsPage } from './fat-assetClassTargets';
import Loading from './fat-modals/loading';
import { createLegalEntityMutation, GET_ASSET_CLASSES, GET_LEGAL_ENTITY, updateLegalEntityMutation } from './queries';
import { DataIntegration } from './sections/dataIntegration';
import { InvestorDetails } from './sections/investorDetails';
import { SetAllocationBiteSize } from './sections/setAllocationBiteSize';
import { SetAllocationRestrictions } from './sections/setAllocationRestrictions';
import { SetAllocationTargets } from './sections/setAllocationTargets';
import { CreateInvestorDto, IAssetClass, ILegalEntity } from './types';
import { getAllocationTargetsToUpdate, getDefaultDataFields, sortByName } from './utils';

export const clientAdvisorSectionOptions = ['None', 'Assign a client advisor'];

export const houseHOldSectionOptions = ['Create a new Investor Household', 'Assign to existing'];
export const taxExemptSectionOptions = ['No', 'Yes'];

const defaultFormValues: CreateInvestorDto = {
  entityName: '',
  familyId: defaultSelectValue,
  ignoreAllocationTargets: true,
  ignoreBiteSize: true,
  sectorsToAvoid: [],
  taxExempt: false,
  onHold: false,
  defaultBiteSize1: 0,
  defaultBiteSize2: 0,
  overCommitRatio: null,
  privatePercentage: 0,
  totalEntityValue: 0,
  advisorReportingAccountNumber: '',
  advisorCRMId: '',
  administratorCode: ''
};

export const CreateInvestorPage = () => {
  const navigate = useNavigate();
  const goBack = useGoBack();
  const { data: statusData } = useStatusData();

  const [searchParams] = useSearchParams();
  const backToTitle = searchParams.get('backToTitle') || '';
  const tenantId = searchParams.get('tenantId');
  const tenantName = searchParams.get('tenantName') || null;
  const investorId = searchParams.get('investorId') || '';
  const entityId = searchParams.get('entityId') || '';

  const [isEditMode, setIsEditMode] = useState<boolean>(!!entityId);
  const [householdSelectedOption, setHouseholdSelectedOption] = useState<string>(houseHOldSectionOptions[0]);
  const [taxExemptSelectedOption, setTaxExemptSelectedOption] = useState<string>(taxExemptSectionOptions[0]);
  const [householdList, setHouseholdList] = useState<IListInvestors[]>([]);
  const [isAssetClassTargetsPageOpen, setIsAssetClassTargetsPageOpen] = useState<boolean>(false);
  const [scrollPosition, setScrollPosition] = useState<number>(0);
  const [updateError, setUpdateError] = useState<string | null>(null);
  const [assetClasses, setAssetClasses] = useState<IAssetClass[]>([]);
  const [defaultAssetClasses, setDefaultAssetClasses] = useState<IAssetClass[]>([]);
  const [assetClassSelectedOption, setAssetClassSelectedOption] = useState(assetClassOptions[0]);

  const [isBiteSizeValid, setIsBiteSizeValid] = useState<boolean>(false);
  const [isAllocationTargetsValid, setIsAllocationTargetsValid] = useState<boolean>(false);

  const [createLegalEntity, { loading: createLegalEntityLoading }] = useMutation(createLegalEntityMutation, {
    onError: (error) => {
      window.scrollTo(0, 0);
      setUpdateError(error.message);
    }
  });

  const [updateEntity, { loading: updateEntityLoading }] = useMutation(updateLegalEntityMutation, {
    onError: (error) => {
      window.scrollTo(0, 0);
      setUpdateError(error.message);
    }
  });

  const {
    getValues,
    setValue,
    formState: { errors, isValid },
    control,
    watch,
    reset
  } = useForm<CreateInvestorDto>({ defaultValues: defaultFormValues });

  const householdSelectedValue = useWatch({
    control,
    name: 'familyId'
  });

  const { loading: legalEntityLoading } = useQuery<{ legalEntity: ILegalEntity }>(GET_LEGAL_ENTITY, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'no-cache',
    skip: !entityId,
    variables: {
      id: entityId
    },
    onCompleted: async (data) => {
      reset(getDefaultDataFields(data.legalEntity));
      setTaxExemptSelectedOption(data.legalEntity.taxExempt ? taxExemptSectionOptions[1] : taxExemptSectionOptions[0]);

      const { data: defaultAssetClasses } = await client.query({
        query: GET_ASSET_CLASSES,
        variables: {
          tenantId: data.legalEntity.tenant.id
        }
      });

      setDefaultAssetClasses(defaultAssetClasses.assetClasses as IAssetClass[]);
      if (!data.legalEntity.allocationTargets.length) {
        setAssetClassSelectedOption(assetClassOptions[0]);
        setAssetClasses(defaultAssetClasses.assetClasses as IAssetClass[]);
        return;
      }

      const groupedByAssetClassId = data.legalEntity.allocationTargets.reduce((acc: Record<string, IAssetClass>, { percentage, assetClass }) => {
        const { id, name, subAssetClass } = assetClass;

        if (!acc[id]) {
          acc[id] = { id, name, defaultAllocationPercentage: 0, subAssetClasses: [] };
        }

        if (statusData?.target_sub_asset_classes && subAssetClass) {
          acc[id].subAssetClasses.push({
            id: subAssetClass.id,
            name: subAssetClass.name,
            defaultAllocationPercentage: percentage
          });
        }

        acc[id].defaultAllocationPercentage += percentage;

        return acc;
      }, {});
      const structuredAssetClasses: IAssetClass[] = sortByName(Object.values(groupedByAssetClassId));

      setAssetClassSelectedOption(assetClassOptions[1]);
      setAssetClasses(structuredAssetClasses);
    }
  });

  const { data: investorData, loading: investorLoading } = useQuery<IInvestorQuery>(GET_INVESTOR_EDIT_QUERY, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'cache-and-network',
    skip: !investorId,
    variables: {
      data: {
        id: investorId
      }
    },
    onError: (error) => {
      console.error(error);
    }
  });

  const { loading: listHouseholdsLoading, error: listHouseholdsError } = useQuery<IListInvestorsQuery>(LIST_INVESTORS_QUERY, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'cache-and-network',
    variables: {
      data: {
        sortBy: 'INVESTOR',
        sortDirection: 'ASC',
        search: null,
        limit: 1000,
        offset: 0,
        includeRecommendations: true,
        filter: tenantName
      }
    },
    onCompleted: ({ listInvestors }: { listInvestors: ListCallback<IListInvestors> }) => {
      setHouseholdList(listInvestors.data);
    },
    onError: (error) => {
      console.error(error);
    }
  });

  const { loading: assetClassesLoading } = useQuery<{ assetClasses: IAssetClass[] }>(GET_ASSET_CLASSES, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'cache-and-network',
    skip: isEditMode,
    variables: {
      tenantId
    },
    onCompleted: ({ assetClasses }) => {
      setAssetClasses(assetClasses);
      setDefaultAssetClasses(assetClasses);
    },
    onError: (error) => {
      console.error(error);
    }
  });

  const handleUseExistingEntityChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setHouseholdSelectedOption(event.target.value);
  };

  const handleTaxExemptChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setTaxExemptSelectedOption(event.target.value);
    setValue('taxExempt', event.target.value === taxExemptSectionOptions[1]);
  };

  const onCreateInvestor = () => {
    setUpdateError(null);
    const { administratorCode, familyId, ...formData } = getValues();
    const allocationTargets = getAllocationTargetsToUpdate(assetClasses, statusData?.target_sub_asset_classes);

    if (isEditMode) {
      updateEntity({
        variables: {
          data: {
            id: entityId,
            ...formData,
            allocationTargets: assetClassSelectedOption === assetClassOptions[0] ? [] : allocationTargets,
            overCommitRatio: Number('1.' + getValues().overCommitRatio)
          }
        },
        onCompleted: () => {
          setUpdateError(null);
          goBack({ fallBack: '/investors' });
        }
      });
      return;
    }
    createLegalEntity({
      variables: {
        data: {
          ...formData,
          familyId:
            householdSelectedOption === houseHOldSectionOptions[1] ? householdList.find((item) => item.name === getValues().familyId)?.id : null,
          tenantId,
          allocationTargets: assetClassSelectedOption === assetClassOptions[0] ? [] : allocationTargets,
          overCommitRatio: Number('1.' + getValues().overCommitRatio)
        }
      },
      onCompleted: ({ createLegalEntity }) => {
        navigate(`/investors/${createLegalEntity.family.id}/${createLegalEntity.id}?backToTitle=Investors`, { replace: true });
      }
    });
  };

  const openAssetClassTargetsPage = () => {
    setIsAssetClassTargetsPageOpen(true);
    setScrollPosition(window.scrollY);
  };

  const closeAssetClassTargetsPage = () => {
    setIsAssetClassTargetsPageOpen(false);
    setTimeout(() => {
      window.scrollTo(0, scrollPosition);
    }, 0);
    setScrollPosition(0);
  };

  const resetAssetClasses = () => {
    setAssetClasses(defaultAssetClasses);
    closeAssetClassTargetsPage();
  };

  const saveAssetClassTargets = (inputValues: IAssetClass<string>[]) => {
    const updatedAssetClasses = inputValues.map((assetClass) => {
      const updatedSubAssetClasses = assetClass.subAssetClasses.map((subAssetClass) => {
        return {
          ...subAssetClass,
          defaultAllocationPercentage: Number(subAssetClass.defaultAllocationPercentage)
        };
      });
      return {
        ...assetClass,
        defaultAllocationPercentage: Number(assetClass.defaultAllocationPercentage),
        subAssetClasses: updatedSubAssetClasses
      };
    });

    setAssetClasses(updatedAssetClasses);
    closeAssetClassTargetsPage();
  };

  if (listHouseholdsLoading || legalEntityLoading || investorLoading || assetClassesLoading) {
    return <LoaderOnWholeScreen size={60} />;
  }

  return isAssetClassTargetsPageOpen ? (
    <AssetClassTargetsPage
      assetClasses={assetClasses}
      targetSubAssetClasses={statusData?.target_sub_asset_classes}
      saveAssetClassTargets={saveAssetClassTargets}
      resetAssetClasses={resetAssetClasses}
      closeAssetClassTargetsPage={closeAssetClassTargetsPage}
      isEditMode={isEditMode}
      assetClassSelectedOption={assetClassSelectedOption}
      setAssetClassSelectedOption={setAssetClassSelectedOption}
    />
  ) : (
    <>
      {(createLegalEntityLoading || updateEntityLoading) && (
        <Loading
          isOpen={createLegalEntityLoading || updateEntityLoading}
          title="Saving Entity"
          description="Please allow a few moment for this process to complete."
        />
      )}
      <MainWrap>
        <Header modalControl={<GoBackButton handleClose={() => goBack({ fallBack: '/investors' })} backToTitle={backToTitle} />} />
        <PageTitle title={isEditMode ? 'Edit Entity' : 'Add New Investor'} />
      </MainWrap>
      <MainWrap>
        {updateError && <Banner icon={<InfoIcon width={26} height={26} />} title="Error" description={updateError} bgColor="#D63B4B" />}
        <PaddingWrap>
          <SectionsWrap>
            <InvestorDetails
              householdSelectedOption={householdSelectedOption}
              handleUseExistingEntityChange={handleUseExistingEntityChange}
              taxExemptSelectedOption={taxExemptSelectedOption}
              handleTaxExemptChange={handleTaxExemptChange}
              householdList={householdList}
              control={control}
              isEditMode={isEditMode}
              investorName={investorData?.getInvestor?.name ?? ''}
            />
            <SetAllocationTargets
              setFormValue={setValue}
              getValues={getValues}
              setIsAllocationTargetsValid={setIsAllocationTargetsValid}
              openAssetClassTargetsPage={openAssetClassTargetsPage}
              assetClasses={assetClasses}
              targetSubAssetClasses={statusData?.target_sub_asset_classes}
            />
            <SetAllocationRestrictions setFormValue={setValue} getValues={getValues} />
            <SetAllocationBiteSize setFormValue={setValue} getValues={getValues} setIsBiteSizeValid={setIsBiteSizeValid} />
            <DataIntegration control={control} />
          </SectionsWrap>
          <FormFooter
            onCancel={() => goBack({ fallBack: '/investors' })}
            onSave={onCreateInvestor}
            disableSaveButton={
              !isValid ||
              (householdSelectedOption === houseHOldSectionOptions[1] && householdSelectedValue === defaultSelectValue) ||
              !isAllocationTargetsValid ||
              !isBiteSizeValid
            }
          />
        </PaddingWrap>
      </MainWrap>
    </>
  );
};

const PaddingWrap = styled.div`
  padding: 50px 16px 0 16px;
  @media (min-width: 600px) {
    padding-left: 50px;
    padding-right: 50px;
  }
`;

export const SectionsWrap = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

export const TwoColumnEntityWrapper = styled.div<{ isMobile: boolean }>`
  display: grid;
  grid-template-columns: ${({ isMobile }) => (isMobile ? '1fr' : '1fr 1fr')};
  gap: 20px;
`;

export const RadioWrapper = styled.div`
  color: ${({ theme }) => theme.font.base};
`;

export const RadioInputWrapper = styled.div<{ isMobile: boolean }>`
  display: flex;
  align-items: ${({ isMobile }) => (isMobile ? 'flex-start' : 'center')};
  flex-direction: ${({ isMobile }) => (isMobile ? 'column-reverse' : 'row')};
  gap: ${({ isMobile }) => (isMobile ? '15px' : '0')};
`;

export const OptionsWrapper = styled.div`
  max-width: 300px;
  width: 100%;
  font-size: 16px;
  font-weight: 400;
  line-height: 22.4px;
`;
