import { ACTIONS } from '@/components/recommendations-page/types';
import { useQuery } from '@apollo/client';
import { subject } from '@casl/ability';
import { useEffect, useMemo, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import styled from 'styled-components';

import { AddButtonIcon } from '@/assets/static/icons/add-button-icon';
import { LIST_DUE_DILIGENCE_RECORD } from '@/components/dealRegistry-page/queries';
import { IRecordData } from '@/components/dueDiligence-page/types';
import Button from '@/components/fat-basicComponents/button';
import { Loader } from '@/components/loaders/loader';
import { buildAbilityFor } from '@/config/ability';
import { useAuth } from '@/hooks/use-auth';
import { useResponsive } from '@/hooks/use-responsive';
import { MainWrap, PaddingWrap } from '@/styles/common';
import { Opportunity } from '@/types/opportunity';
import { User } from '@/types/user';
import { formatPercent, formatPrice } from '@/utils/currency';
import Header from '../fat-header';
import { initialValuesFromTable } from '../table/constants';
import DropdownActions from '../table/fat-dropdownActions';
import FilterPage from '../table/fat-filterPage';
import TableHeader from '../table/fat-tableHeader';
import RedirectTitleCell from '../table/redirectTitleCell';
import { TableBodyWrapper, TableRowCell, TableRowWrapper, TableWrapper, TextCell } from '../table/styles';
import TablePagination from '../table/tablePagination';
import { ISort, SORT_ORDER } from '../table/types';
import { allocationListSort, header } from './constants';
import MobileTable from './mobileTable';
import ApprovedInvestment from './modals/approvedInvestment';
import { ALLOCATION_QUERY } from './queries';
import { getUTCDate } from './utils';

const Allocation = () => {
  const navigate = useNavigate();
  const { user } = useAuth();

  const [searchParams, setSearchParams] = useSearchParams();

  const limit = searchParams.get('limit') || initialValuesFromTable.initialAmountRowsPerPage[0];
  const offset = searchParams.get('offset') || 0;
  const q = searchParams.get('q') || '';
  const key = searchParams.get('key') || 'name';
  const asc = searchParams.get('asc') === null || searchParams.get('asc') === 'true';
  const position = searchParams.get('position') || 0;

  const [total, setTotal] = useState(0);
  const [opportunitiesData, setOpportunitiesData] = useState<Opportunity[]>([]);
  const [mobileSort, setMobileSort] = useState(allocationListSort[0].title);
  const sortItem = allocationListSort.find((item) => item.title === mobileSort);
  const mobileSortList = allocationListSort.map((item) => item.title);

  const [modalWindow, setModalWindow] = useState<{ isOpen: boolean; type: string }>({ isOpen: false, type: 'approved-investment' });
  const [recordList, setRecordList] = useState<IRecordData[]>([]);

  const { isMobile, isTablet } = useResponsive();
  const { data, loading, error, refetch, networkStatus } = useQuery(ALLOCATION_QUERY, {
    notifyOnNetworkStatusChange: true,
    variables: {
      page: Number(offset) / Number(limit) + 1,
      rowsPerPage: Number(limit),
      sort: key,
      order: asc ? SORT_ORDER.ASC : SORT_ORDER.DESC,
      search: q.toLowerCase()
    }
  });

  const { data: recordListData } = useQuery(LIST_DUE_DILIGENCE_RECORD, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
    variables: {
      data: {
        sortBy: 'CREATED_DATE',
        sortDirection: 'ASC',
        statusFilter: 'APPROVED',
        limit: 1000
      }
    },
    onCompleted: (data) => {
      setRecordList(data.listDueDiligenceRecords.records.filter((record: IRecordData) => record.investment.securityStatus === 'APPROVED'));
    }
  });

  const ability = useMemo(() => buildAbilityFor(user as User), [user]);
  const canCreate = useMemo(() => ability?.can(ACTIONS.CREATE_INVESTMENT, subject('Investment', {})), [user]);
  const canEdit = useMemo(() => ability?.can(ACTIONS.EDIT_INVESTMENT, subject('Investment', {})), [user]);

  useEffect(() => {
    if (isMobile) {
      refetch({
        order: sortItem?.asc ? SORT_ORDER.ASC : SORT_ORDER.DESC,
        sort: sortItem?.key
      });
    }
  }, [mobileSort]);

  useEffect(() => {
    if (data && !error && !loading) {
      setTotal(data.OpenOpportunities.total);
      setOpportunitiesData(data.OpenOpportunities.investments);
      makeScroll(position);
    }
  }, [data, loading, error]);

  const openModalWindow = (type: string) => {
    setModalWindow({ ...modalWindow, isOpen: true, type });
  };

  const handleClick = (id: string, name: string) => {
    saveScrollPostion();
    navigate(`allocator/${id}`);
  };

  const openAllocationsModify = (investmentId?: string) => {
    saveScrollPostion();
    navigate(`modify${investmentId ? `${`?investment=${investmentId}&backToTitle=Allocations`}` : '?backToTitle=Allocations'}`);
  };

  const onChangePaginationData = (limit: number, offset: number) => {
    setSearchParams(
      (prev) => {
        prev.set('limit', limit.toString());
        prev.set('offset', offset.toString());
        return prev;
      },
      { replace: true }
    );
  };

  const onChangeSearch = (searchValue: string) => {
    setSearchParams(
      (prev) => {
        if (!searchValue) {
          prev.delete('q');
          return prev;
        }
        prev.set('q', searchValue);
        return prev;
      },
      { replace: true }
    );
  };

  const onChangeSort = (value: ISort) => {
    setSearchParams(
      (prev) => {
        prev.set('key', value.key);
        prev.set('asc', value.asc.toString());
        return prev;
      },
      { replace: true }
    );
  };

  const saveScrollPostion = () => {
    setSearchParams(
      (prev) => {
        prev.set('position', window.scrollY.toString());
        return prev;
      },
      { replace: true }
    );
  };

  const makeScroll = (position: string | number) => {
    if (!position) return;
    setTimeout(() => window.scrollTo({ top: Number(position), behavior: 'smooth' }), 0);
    setSearchParams(
      (prev) => {
        prev.delete('position');
        return prev;
      },
      { replace: true }
    );
  };

  return (
    <>
      {modalWindow.isOpen && modalWindow.type === 'approved-investment' && (
        <ApprovedInvestment isOpen={modalWindow.isOpen} onClose={() => setModalWindow({ ...modalWindow, isOpen: false })} recordList={recordList} />
      )}
      <MainWrap>
        <Header />
      </MainWrap>
      <PaddingWrap>
        <WrapperFilter isMobile={isMobile} isTablet={isTablet}>
          <CustomFilterPage
            search={{
              value: q,
              onChange: onChangeSearch,
              placeholder: 'Search'
            }}
            resultsValue={total}
            refetch={refetch}
            mobileSort={{
              value: mobileSort,
              onChange: setMobileSort,
              selects: mobileSortList
            }}
          />
          {!isMobile && canCreate && (
            <>
              <AddButton onClick={() => openAllocationsModify()} size="md" icon={<AddButtonIcon />}>
                Add New Investment
              </AddButton>
              {recordList.length ? (
                <AddButton onClick={() => openModalWindow('approved-investment')} size="md" icon={<AddButtonIcon />}>
                  Add Approved Investment
                </AddButton>
              ) : null}
            </>
          )}
        </WrapperFilter>

        <TableWrapper padding="0 0 30px">
          {isMobile ? (
            <>
              {loading ? (
                <Loader />
              ) : (
                <>
                  <MobileTable handleClick={handleClick} opportunities={opportunitiesData} />
                  <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <TablePagination
                      savePagination={onChangePaginationData}
                      paginationValues={{
                        limit: Number(limit),
                        offset: Number(offset),
                        total: total
                      }}
                      refetch={refetch}
                      usePage
                    />
                  </div>
                </>
              )}
            </>
          ) : (
            <>
              <CustomTableHeader
                isTablet={isTablet}
                columns={header}
                refetch={(): void => null}
                savedSort={{ key, asc }}
                savedSetSort={onChangeSort}
                addAdaptive
              />
              <TableBodyWrapper>
                <OpportunitiesList
                  handleClick={handleClick}
                  loading={loading}
                  opportunities={opportunitiesData}
                  canEdit={canEdit}
                  openAllocationsModify={openAllocationsModify}
                />
              </TableBodyWrapper>
              <TableFooter>
                <TablePagination
                  savePagination={onChangePaginationData}
                  paginationValues={{
                    limit: Number(limit),
                    offset: Number(offset),
                    total: total
                  }}
                  refetch={refetch}
                  usePage
                />
              </TableFooter>
            </>
          )}
        </TableWrapper>
      </PaddingWrap>
    </>
  );
};

const OpportunitiesList = (props: {
  loading: boolean;
  opportunities: Opportunity[];
  handleClick: (value: string, name: string) => void;
  canEdit: boolean | undefined;
  openAllocationsModify: (investmentId?: string) => void;
}) => {
  const { loading, opportunities, handleClick, canEdit = false, openAllocationsModify } = props;

  if (loading) {
    return (
      <WrapperLoader>
        <Loader />
      </WrapperLoader>
    );
  }

  if (opportunities.length === 0) {
    return (
      <TableRowWrapper>
        <TextCell>No Open Opportunities Found</TextCell>
      </TableRowWrapper>
    );
  }

  const handleClickManager = (event: any, id: string, name: string) => {
    handleClick(id, name);
  };

  return (
    <>
      {opportunities.map((opportunity) => (
        <TableRowWrapper key={opportunity.id} addAdaptive>
          <TableRowCell width={header[0].width} onClick={(e) => handleClickManager(e, opportunity.id, opportunity.name)}>
            <RedirectTitleCell id="actionCurioText">{opportunity.name}</RedirectTitleCell>
          </TableRowCell>
          <TableRowCell width={header[1].width}>
            <TextCell>{opportunity.managerId}</TextCell>
          </TableRowCell>
          <TableRowCell width={header[2].width}>
            <TextCell>{opportunity.assetClass}</TextCell>
          </TableRowCell>
          <TableRowCell width={header[3].width}>
            <TextCell>{opportunity.nextClose ? getUTCDate(opportunity.nextClose) : ''}</TextCell>
          </TableRowCell>
          <TableRowCell width={header[4].width}>
            <TextCell>{opportunity.finalClose ? getUTCDate(opportunity.finalClose) : ''}</TextCell>
          </TableRowCell>
          <TableRowCell width={header[5].width}>
            <TextCell>{formatPrice(opportunity.minimum)}</TextCell>
          </TableRowCell>
          <TableRowCell width={header[6].width}>
            <TextCell>{opportunity.custodied ? 'Yes' : 'No'}</TextCell>
          </TableRowCell>
          <TableRowCell width={header[7].width}>
            <TextCell>{opportunity.ubtiBlocker ? 'Yes' : 'No'}</TextCell>
          </TableRowCell>
          <TableRowCell width={header[8].width}>
            <TextCell>{opportunity.sri ? 'Yes' : 'No'}</TextCell>
          </TableRowCell>
          <TableRowCell width={header[9].width}>
            <TextCell>{formatPercent(opportunity.targetIRR)}</TextCell>
          </TableRowCell>
          <TableRowCell width={header[10].width}>
            <TextCell>{formatPercent(opportunity.targetYield)}</TextCell>
          </TableRowCell>
          <TableRowCell width={header[11].width}>
            <TextCell>
              {canEdit ? (
                <DropdownActions
                  items={[
                    {
                      name: 'Edit Investment',
                      onClick: () => openAllocationsModify(opportunity.id)
                    }
                  ]}
                />
              ) : null}
            </TextCell>
          </TableRowCell>
        </TableRowWrapper>
      ))}
    </>
  );
};

export default Allocation;

const TableFooter = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const WrapperLoader = styled(TableRowWrapper)`
  justify-content: center;
`;

const AddButton = styled(Button)`
  white-space: nowrap;
  margin-bottom: 4px;
`;

const WrapperFilter = styled.div<{ isMobile: boolean; isTablet: boolean }>`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: flex-end;
  gap: 8px;
  margin-top: 10px;
  padding: ${({ isMobile }) => (isMobile ? '0' : '0 10px 10px 10px')};
  position: sticky;
  top: ${({ isMobile, isTablet }) => (isMobile || isTablet ? '50px' : '0')};
  z-index: 9;
  background-color: ${({ theme }) => theme.layer.base};
  margin-left: -1px;
  width: calc(100% + 2px);
`;

const CustomTableHeader = styled(TableHeader)<{ isTablet: boolean }>`
  position: sticky;
  top: ${({ isTablet }) => (isTablet ? '130px' : '80px')};
  z-index: 8;
  padding-top: 0;
  justify-content: flex-start;
`;

const CustomFilterPage = styled(FilterPage)`
  position: sticky;
  width: calc(100% + 2px);
`;
