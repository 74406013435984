import { BurgerMenuIcon } from '@/assets/static/icons/burger-menu';
import { CloseMenuIcon } from '@/assets/static/icons/close-menu';
import Tabs, {
  CounterTitle,
  MainTabsMobile,
  TabMobileWrap,
  TabTitle,
  TabsControl,
  TabsDetailsTitleMobile,
  TabsWrapMobile
} from '@/components/basicComponents/fat-tabs';
import { useResponsive } from '@/hooks/use-responsive';
import { MainWrap } from '@/styles/common';
import { useQuery } from '@apollo/client';
import { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import styled, { useTheme } from 'styled-components';
import SpecialControlHeader from '../../components/fat-dealCatalog-page/fat-switcher/specialControlHeader';
import { IInvestorInterestList, IInvestorInterestsQuery, Investment, TypeWindow } from '../../components/fat-dealCatalog-page/types';
import { IFilterBarData } from '../basicComponents/filterBar';
import Header from '../fat-header';
import { GET_ASSET_CLASSES } from '../fat-investors-page/fat-addInvestors/queries';
import { IAssetClass } from '../fat-investors-page/fat-addInvestors/types';
import { IMobileSort } from '../fat-pendingInvestments-page/types';
import { initialValuesFromTable } from '../table/constants';
import FilterPage from '../table/fat-filterPage';
import TablePagination from '../table/tablePagination';
import { ISort, SORT_ORDER } from '../table/types';
import { initialStatusFilter, opportunitiesTableSort, seekingInterestTableSort } from './constants';
import { default as OpportunitiesTable } from './fat-opportunitiesTable/table';
import OtherRecommendedTable from './fat-otherRecommendedTable/table';
import SeekingInterestTable from './fat-seekingInterestTable/table';
import {
  GET_INVESTOR_INTERESTS_QUERY,
  GET_IN_RESEARCH_INVESTMENTS_QUERY,
  GET_OTHER_RECOMMENDED_INVESTMENTS_QUERY,
  OPEN_OPPORTUNITIES_QUERY
} from './queries';
import { getAssetClassId } from './utils';

const tabs = [
  { value: 'Accepting Commitments', amount: null as number | null },
  { value: 'Seeking Interest', amount: null },
  { value: 'Other Recommended', amount: null },
  { value: 'In Research', amount: null }
];

export const DealCatalog = () => {
  const { isMobile, isTablet, isDesktop, isLargeDesktop } = useResponsive();
  const navigate = useNavigate();
  const theme = useTheme();
  const [searchParams, setSearchParams] = useSearchParams();

  const activeTab = searchParams.get('tab') || tabs[0].value;
  const q = searchParams.get('q') || '';
  const primaryFilter = searchParams.get('primaryFilter') || 'All Asset Classes';
  const key = searchParams.get('key') || getDefaultSortKey();
  const asc = searchParams.get('asc') === null || searchParams.get('asc') === 'true';
  const limit = searchParams.get('limit') || initialValuesFromTable.initialAmountRowsPerPage[0];
  const offset = searchParams.get('offset') || 0;
  const filterBarParams = searchParams.get('filterBar');
  const position = searchParams.get('position') || 0;

  const [switcher, setSwitcher] = useState(true);
  const [isCurioImgLoaded, setIsCurioImgLoaded] = useState(false);
  const [isTabMenuOpen, setIsTabMenuOpen] = useState(false);

  const [openOpportunitiesTableData, setOpenOpportunitiesTableData] = useState<Investment[]>([]);
  const [investorInterestsTableData, setInvestorInterestsTableData] = useState<IInvestorInterestList[]>([]);
  const [otherRecommendedData, setOtherRecommendedData] = useState<IInvestorInterestList[]>([]);
  const [inResearchData, setInResearchData] = useState<IInvestorInterestList[]>([]);

  const [filterBar, setFilterBar] = useState<IFilterBarData[]>(filterBarData());
  const [listAssetClasses, setListAssetClasses] = useState([]);

  const [mobileSortItems, setMobileSortItems] = useState<IMobileSort[]>([]);
  const [mobileSort, setMobileSort] = useState('');
  const mobileSortList = mobileSortItems.map((item) => item.title);

  function filterBarData() {
    if (!filterBarParams) return initialStatusFilter;
    const matchedFilterBarValues = initialStatusFilter.map((item) => {
      const matchedFilterItem = JSON.parse(filterBarParams).find((filterItem: string) => item.type === activeTab && filterItem === item.value);

      return {
        ...item,
        enabled: Boolean(matchedFilterItem)
      };
    });
    return matchedFilterBarValues;
  }

  function getDefaultSortKey() {
    if (activeTab === tabs[0].value) {
      return 'INVESTMENT_TYPE';
    }

    return 'INVESTOR_DOCS_DUE';
  }

  useEffect(() => {
    if (!isMobile) return;
    if (activeTab === tabs[0].value) {
      setMobileSortItems(opportunitiesTableSort);
      setMobileSort(opportunitiesTableSort[5].title);
      return;
    }
    if (activeTab === tabs[1].value) {
      setMobileSortItems(seekingInterestTableSort);
      setMobileSort(seekingInterestTableSort[6].title);
      return;
    }
    if (activeTab === tabs[2].value || activeTab === tabs[3].value) {
      setMobileSortItems([...seekingInterestTableSort].splice(0, seekingInterestTableSort.length - 2));
      setMobileSort(seekingInterestTableSort[6].title);
      return;
    }
  }, [activeTab, isMobile]);

  const { data: assetClassesData } = useQuery<{ assetClasses: IAssetClass[] }>(GET_ASSET_CLASSES, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'cache-and-network',
    onCompleted: ({ assetClasses }) => {
      setListAssetClasses(assetClasses.map((assetClass) => assetClass.name));
    },
    onError: (error) => {
      console.error(error);
    }
  });

  const {
    data: openOpportunitiesData,
    loading: openOpportunitiesLoading,
    refetch: openOpportunitiesRefetch
  } = useQuery(OPEN_OPPORTUNITIES_QUERY, {
    fetchPolicy: 'no-cache',
    notifyOnNetworkStatusChange: true,
    skip: activeTab !== tabs[0].value || !assetClassesData,
    variables: {
      sort: key,
      order: asc ? SORT_ORDER.ASC : SORT_ORDER.DESC,
      search: q.toLowerCase().trim(),
      rowsPerPage: Number(limit),
      page: Number(offset) / Number(limit) + 1,
      assetClassQuickFilter: primaryFilter !== 'All Asset Classes' ? getAssetClassId(primaryFilter, assetClassesData?.assetClasses) : '',
      statusFilter: filterBar.filter((item) => item.enabled && item.type === tabs[0].value).map((item) => item.value)
    },
    onCompleted: (data) => {
      const { data: openOpportunitiesData, filters } = data.OpenOpportunities;

      setOpenOpportunitiesTableData(openOpportunitiesData);

      const filterBarWithCounts = filterBar.map((filterBarItem) => {
        const matchedFilterItem = filters.find((filterItem: IFilterBarData) => filterItem.value === filterBarItem.value);

        return { ...filterBarItem, count: matchedFilterItem?.count ?? 0 };
      });

      setFilterBar(filterBarWithCounts);
      makeScroll(position);
    }
  });

  const {
    data: investorInterestsData,
    refetch: investorInterestsRefetch,
    loading: investorInterestsLoading,
    networkStatus: investorInterestsNetworkStatus
  } = useQuery<{ getInvestorInterests: IInvestorInterestsQuery }>(GET_INVESTOR_INTERESTS_QUERY, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'no-cache',
    skip: activeTab !== tabs[1].value || !assetClassesData,
    variables: {
      data: {
        sortBy: key,
        sortDirection: asc ? SORT_ORDER.ASC : SORT_ORDER.DESC,
        assetClassFilter: primaryFilter !== 'All Asset Classes' ? [getAssetClassId(primaryFilter, assetClassesData?.assetClasses)] : [],
        search: q.toLowerCase().trim(),
        limit: Number(limit),
        offset: Number(offset),
        statusFilter: filterBar.filter((item) => item.enabled && item.type === tabs[1].value).map((item) => item.value)
      }
    },
    onCompleted: (data) => {
      const { data: investorInterestsData, filters } = data.getInvestorInterests;

      setInvestorInterestsTableData(investorInterestsData);

      const filterBarWithCounts = filterBar.map((filterBarItem) => {
        const matchedFilterItem = filters.find((filterItem: IFilterBarData) => filterItem.value === filterBarItem.value);

        return { ...filterBarItem, count: matchedFilterItem?.count ?? 0 };
      });

      setFilterBar(filterBarWithCounts);
      makeScroll(position);
    },
    onError: (error) => {
      console.error(error);
    }
  });

  const {
    data: otherRecommendedInvestmentsData,
    refetch: otherRecommendedInvestmentsRefetch,
    loading: otherRecommendedInvestmentsLoading
  } = useQuery<{
    getOtherRecommendedInvestments: IInvestorInterestsQuery;
  }>(GET_OTHER_RECOMMENDED_INVESTMENTS_QUERY, {
    fetchPolicy: 'no-cache',
    notifyOnNetworkStatusChange: true,
    skip: activeTab !== tabs[2].value || !assetClassesData,
    variables: {
      data: {
        sortBy: key,
        sortDirection: asc ? SORT_ORDER.ASC : SORT_ORDER.DESC,
        assetClassFilter: primaryFilter !== 'All Asset Classes' ? [getAssetClassId(primaryFilter, assetClassesData?.assetClasses)] : [],
        search: q.toLowerCase().trim(),
        limit: Number(limit),
        offset: Number(offset),
        statusFilter: filterBar.filter((item) => item.enabled && item.type === tabs[2].value).map((item) => item.value)
      }
    },
    onCompleted: (data) => {
      const { data: otherRecommendedData, total, filters } = data.getOtherRecommendedInvestments;

      setOtherRecommendedData(otherRecommendedData);

      const filterBarWithCounts = filterBar.map((filterBarItem) => {
        const matchedFilterItem = filters.find((filterItem: IFilterBarData) => filterItem.value === filterBarItem.value);

        return { ...filterBarItem, count: matchedFilterItem?.count ?? 0 };
      });

      setFilterBar(filterBarWithCounts);
      makeScroll(position);
    },
    onError: (error) => {
      console.error(error);
    }
  });

  const {
    data: inResearchInvestmentsData,
    refetch: inResearchInvestmentsRefetch,
    loading: inResearchInvestmentsLoading
  } = useQuery<{
    getInResearchInvestments: IInvestorInterestsQuery;
  }>(GET_IN_RESEARCH_INVESTMENTS_QUERY, {
    fetchPolicy: 'no-cache',
    notifyOnNetworkStatusChange: true,
    skip: activeTab !== tabs[3].value || !assetClassesData,
    variables: {
      data: {
        sortBy: key,
        sortDirection: asc ? SORT_ORDER.ASC : SORT_ORDER.DESC,
        assetClassFilter: primaryFilter !== 'All Asset Classes' ? [getAssetClassId(primaryFilter, assetClassesData?.assetClasses)] : [],
        search: q.toLowerCase().trim(),
        limit: Number(limit),
        offset: Number(offset),
        statusFilter: filterBar.filter((item) => item.enabled && item.type === tabs[3].value).map((item) => item.value)
      }
    },
    onCompleted: (data) => {
      const { data: inResearchData, filters } = data.getInResearchInvestments;

      setInResearchData(inResearchData);

      const filterBarWithCounts = filterBar.map((filterBarItem) => {
        const matchedFilterItem = filters.find((filterItem: IFilterBarData) => filterItem.value === filterBarItem.value);

        return { ...filterBarItem, count: matchedFilterItem?.count ?? 0 };
      });
      setFilterBar(filterBarWithCounts);
      makeScroll(position);
    },
    onError: (error) => {
      console.error(error);
    }
  });

  const toggleSwitcher = () => {
    saveScrollPostion();
    setSwitcher(!switcher);
  };

  const openPage = (id: string, type: TypeWindow) => {
    setSearchParams(
      (prev) => {
        prev.set('position', window.scrollY.toString());
        return prev;
      },
      { replace: true }
    );
    navigate(`${type}/${id}?backToTitle=Deal Catalog`);
  };

  const getCurrentStatus = () => {
    switch (activeTab) {
      case tabs[0].value:
        return {
          refetch: openOpportunitiesRefetch,
          total: openOpportunitiesData?.OpenOpportunities.total
        };
      case tabs[1].value:
        return {
          refetch: investorInterestsRefetch,
          total: investorInterestsData?.getInvestorInterests.total
        };
      case tabs[2].value:
        return {
          refetch: otherRecommendedInvestmentsRefetch,
          total: otherRecommendedInvestmentsData?.getOtherRecommendedInvestments.total
        };
      case tabs[3].value:
        return {
          refetch: inResearchInvestmentsRefetch,
          total: inResearchInvestmentsData?.getInResearchInvestments.total
        };
      default:
        return {
          refetch: () => null as any,
          total: 0
        };
    }
  };

  const onChangePrimaryFilter = (filter: string) => {
    setSearchParams(
      (prev) => {
        prev.set('primaryFilter', filter);
        prev.delete('limit');
        prev.delete('offset');
        return prev;
      },
      { replace: true }
    );
  };

  const onChangeSearch = (searchValue: string) => {
    setSearchParams(
      (prev) => {
        if (!searchValue) {
          prev.delete('q');
          return prev;
        }
        prev.set('q', searchValue);
        prev.delete('limit');
        prev.delete('offset');
        return prev;
      },
      { replace: true }
    );
  };

  const onChangeSort = (value: ISort) => {
    setSearchParams(
      (prev) => {
        prev.set('key', value.key);
        prev.set('asc', value.asc.toString());
        return prev;
      },
      { replace: true }
    );
  };

  const saveScrollPostion = () => {
    setSearchParams(
      (prev) => {
        prev.set('position', window.scrollY.toString());
        return prev;
      },
      { replace: true }
    );
  };

  const makeScroll = (position: string | number) => {
    if (!position) return;
    setTimeout(() => window.scrollTo({ top: Number(position), behavior: 'smooth' }), 0);
    setSearchParams(
      (prev) => {
        prev.delete('position');
        return prev;
      },
      { replace: true }
    );
  };

  const onChangeFilterBar = (filterBarItems: IFilterBarData[]) => {
    const updatedFilterBar = initialStatusFilter.map((item) => {
      const updatedItem = filterBarItems.find((filterItem) => filterItem.type === item.type && filterItem.value === item.value);

      return updatedItem ? updatedItem : item;
    });

    setFilterBar(updatedFilterBar);
    const filterBarValues = filterBarItems.filter((item) => item.enabled).map((item) => item.value);
    setSearchParams(
      (prev) => {
        if (!filterBarValues.length) {
          prev.delete('filterBar');
          return prev;
        }
        prev.set('filterBar', JSON.stringify(filterBarValues));
        prev.delete('limit');
        prev.delete('offset');
        return prev;
      },
      { replace: true }
    );
  };

  const onChangePaginationData = (limit: number, offset: number) => {
    setSearchParams(
      (prev) => {
        prev.set('limit', limit.toString());
        prev.set('offset', offset.toString());
        return prev;
      },
      { replace: true }
    );
  };

  const onChangeActiveTab = (value: string) => {
    setFilterBar(initialStatusFilter);
    setSearchParams({ tab: value }, { replace: true });
  };

  const onChangeMobileSort = (value: string) => {
    setMobileSort(value);
    const sortItem = mobileSortItems.find((item) => item.title === value);

    if (activeTab === tabs[0].value) {
      openOpportunitiesRefetch({
        order: sortItem?.asc ? SORT_ORDER.ASC : SORT_ORDER.DESC,
        sort: sortItem?.key
      });
      return;
    }
    if (activeTab === tabs[1].value) {
      investorInterestsRefetch({
        data: {
          sortDirection: sortItem?.asc ? SORT_ORDER.ASC : SORT_ORDER.DESC,
          sortBy: sortItem?.key,
          assetClassFilter: primaryFilter !== 'All Asset Classes' ? [getAssetClassId(primaryFilter, assetClassesData?.assetClasses)] : [],
          search: q.toLowerCase().trim(),
          limit: Number(limit),
          offset: Number(offset),
          statusFilter: filterBar.filter((item) => item.enabled && item.type === tabs[1].value).map((item) => item.value)
        }
      });
      return;
    }
    if (activeTab === tabs[2].value) {
      otherRecommendedInvestmentsRefetch({
        data: {
          sortDirection: sortItem?.asc ? SORT_ORDER.ASC : SORT_ORDER.DESC,
          sortBy: sortItem?.key,
          assetClassFilter: primaryFilter !== 'All Asset Classes' ? [getAssetClassId(primaryFilter, assetClassesData?.assetClasses)] : [],
          search: q.toLowerCase().trim(),
          limit: Number(limit),
          offset: Number(offset),
          statusFilter: filterBar.filter((item) => item.enabled && item.type === tabs[2].value).map((item) => item.value)
        }
      });
      return;
    }
    if (activeTab === tabs[3].value) {
      inResearchInvestmentsRefetch({
        data: {
          sortDirection: sortItem?.asc ? SORT_ORDER.ASC : SORT_ORDER.DESC,
          sortBy: sortItem?.key,
          assetClassFilter: primaryFilter !== 'All Asset Classes' ? [getAssetClassId(primaryFilter, assetClassesData?.assetClasses)] : [],
          search: q.toLowerCase().trim(),
          limit: Number(limit),
          offset: Number(offset),
          statusFilter: filterBar.filter((item) => item.enabled && item.type === tabs[3].value).map((item) => item.value)
        }
      });
      return;
    }
  };

  const changeTab = (value: string) => {
    onChangeActiveTab(value);
    setIsTabMenuOpen(false);
  };

  return (
    <>
      <MainWrap>
        <Header
          specialControl={<SpecialControlHeader switcher={switcher} setSwitcher={toggleSwitcher} title="Include Pending Commitments" hide={true} />}
        />
        <PaddingWrap>
          <ImageWrap>
            <CurioImg
              isCurioImgLoaded={isCurioImgLoaded}
              src="/static/fat-mock-images/curioSelect.jpeg"
              draggable="false"
              alt="CURIO Select"
              onLoad={() => setIsCurioImgLoaded(true)}
            />
            {!isCurioImgLoaded && <CurioImgSmall src="/static/fat-mock-images/curioSelectSmall.jpeg" draggable="false" alt="CURIO Select" />}
          </ImageWrap>
          <ManagerPipelineBanner isMobile={isMobile}>CURIO Select Manager Pipeline</ManagerPipelineBanner>
          <InformationBanner isMobile={isMobile}>
            Allocate client capital to any strategy that is currently <BoldText>Accepting Commitments</BoldText>. We are also{' '}
            <BoldText>Seeking Interest</BoldText> in new managers; let us know which are of interest and provide an estimated capital commitment.
          </InformationBanner>
          {isDesktop || isTablet || isLargeDesktop ? (
            <>
              <Tabs tabs={tabs} activeTab={activeTab} setActiveTab={onChangeActiveTab} />
              <TabsLine />
            </>
          ) : (
            <CustomTabsControl isMobile={isMobile}>
              {tabs.length !== 1 && (
                <>
                  {isTabMenuOpen ? (
                    <CloseMenuIcon fill={theme.action.primary} onClick={() => setIsTabMenuOpen(!isTabMenuOpen)} style={{ cursor: 'pointer' }} />
                  ) : (
                    <BurgerMenuIcon fill={theme.action.primary} onClick={() => setIsTabMenuOpen(!isTabMenuOpen)} style={{ cursor: 'pointer' }} />
                  )}
                </>
              )}
              <TabMobileWrap onClick={() => onChangeActiveTab(activeTab)} isActive>
                <TabTitle isActive isMobile={isMobile}>
                  {activeTab}
                </TabTitle>
              </TabMobileWrap>
            </CustomTabsControl>
          )}
        </PaddingWrap>
      </MainWrap>
      <MainWrap>
        <PaddingWrap>
          {isTabMenuOpen && isMobile && (
            <MainTabsMobile>
              {tabs.map((tab, tabId) => (
                <TabsWrapMobile key={tab.value + tabId} onClick={() => changeTab(tab.value)} isActive={activeTab === tab.value}>
                  {tab.amount && <CounterTitle isActive={activeTab === tab.value}>{tab.amount}</CounterTitle>}
                  <TabsDetailsTitleMobile isActive={activeTab === tab.value}>{tab.value}</TabsDetailsTitleMobile>
                </TabsWrapMobile>
              ))}
            </MainTabsMobile>
          )}
          {!isTabMenuOpen && (
            <>
              <CustomFilterPage
                isMobile={isMobile}
                isTablet={isTablet}
                search={{
                  value: q,
                  onChange: onChangeSearch,
                  placeholder: 'Search'
                }}
                primaryFilter={{
                  value: primaryFilter,
                  onChange: onChangePrimaryFilter,
                  selects: ['All Asset Classes', ...listAssetClasses]
                }}
                resultsValue={getCurrentStatus().total ?? 0}
                refetch={getCurrentStatus().refetch}
                {...(activeTab === tabs[0].value && {
                  filterBar: filterBar.filter((item) => item.type === activeTab),
                  onChangeFilterBar: onChangeFilterBar
                })}
                mobileSort={{
                  value: mobileSort,
                  onChange: onChangeMobileSort,
                  selects: mobileSortList
                }}
              />
              {activeTab === tabs[0].value && (
                <OpportunitiesTable
                  openPage={openPage}
                  loading={openOpportunitiesLoading}
                  openOpportunitiesData={openOpportunitiesTableData}
                  refetch={openOpportunitiesRefetch}
                  sort={{ key, asc }}
                  setSort={onChangeSort}
                />
              )}
              {activeTab === tabs[1].value && (
                <SeekingInterestTable
                  openPage={openPage}
                  refetch={investorInterestsRefetch}
                  investorInterestsNetworkStatus={investorInterestsNetworkStatus}
                  investorInterestsData={investorInterestsTableData}
                  sort={{ key, asc }}
                  setSort={onChangeSort}
                />
              )}
              {activeTab === tabs[2].value && (
                <OtherRecommendedTable
                  openPage={openPage}
                  refetch={otherRecommendedInvestmentsRefetch}
                  loading={otherRecommendedInvestmentsLoading}
                  otherRecommendedData={otherRecommendedData}
                  sort={{ key, asc }}
                  setSort={onChangeSort}
                />
              )}
              {activeTab === tabs[3].value && (
                <OtherRecommendedTable
                  openPage={openPage}
                  refetch={inResearchInvestmentsRefetch}
                  loading={inResearchInvestmentsLoading}
                  otherRecommendedData={inResearchData}
                  sort={{ key, asc }}
                  setSort={onChangeSort}
                />
              )}
              <PaginationWrap>
                <TablePagination
                  savePagination={onChangePaginationData}
                  paginationValues={{
                    limit: Number(limit),
                    offset: Number(offset),
                    total: getCurrentStatus().total
                  }}
                  refetch={() => null as any}
                  usePage={activeTab === tabs[0].value}
                />
              </PaginationWrap>
            </>
          )}
        </PaddingWrap>
      </MainWrap>
    </>
  );
};

const PaddingWrap = styled.div`
  padding-left: 16px;
  padding-right: 16px;
  @media (min-width: 600px) {
    padding-left: 50px;
    padding-right: 50px;
  }
`;

const CustomTabsControl = styled(TabsControl)`
  margin-top: 25px;
`;

const PaginationWrap = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
`;

const ImageWrap = styled.div`
  margin: 40px 0 20px 0;
  display: flex;
  justify-content: center;
`;

const CurioImgSmall = styled.img`
  width: 100%;
  height: 100%;
  filter: blur(6px);
  -webkit-filter: blur(6px);
`;

const CurioImg = styled.img<{ isCurioImgLoaded: boolean }>`
  display: ${({ isCurioImgLoaded }) => (isCurioImgLoaded ? 'block' : 'none')};
  width: 100%;
  height: 100%;
`;

const ManagerPipelineBanner = styled.div<{ isMobile: boolean }>`
  padding: ${({ isMobile }) => (isMobile ? '10px' : '20px')};
  background-color: #fbb161;
  color: #fff;
  font-family: Lato;
  font-size: ${({ isMobile }) => (isMobile ? '18px' : '30px')};
  font-weight: 900;
  line-height: 39.2px;
`;

const InformationBanner = styled.div<{ isMobile: boolean }>`
  padding: ${({ isMobile }) => (isMobile ? '10px' : '20px')};
  background-color: #00aeef;
  color: #fff;
  font-family: Lato;
  font-size: ${({ isMobile }) => (isMobile ? '13px' : '16px')};
  font-weight: 400;
  line-height: 24px;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.15) inset;
`;

const BoldText = styled.span`
  font-weight: 900;
`;

const TabsLine = styled.div`
  border-bottom: 1px #c7cbd2 solid;
`;

const CustomFilterPage = styled(FilterPage)<{ isMobile: boolean; isTablet: boolean }>`
  position: sticky;
  top: ${({ isMobile, isTablet }) => (isMobile || isTablet ? '50px' : '0')};
  z-index: 9;
  margin-top: 0;
  margin-left: -1px;
  width: calc(100% + 2px);
  padding: ${({ isMobile }) => (isMobile ? '20px 0 0 0' : '20px 0 10px 0')};
`;
