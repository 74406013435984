import { RejectedBannerIcon } from '@/assets/icons/info-icons/rejectedBanner';
import { Banner } from '@/components/basicComponents/banner';
import RadioButton from '@/components/basicComponents/radioButton';
import { Input, Select } from '@/components/fat-form-field';
import { defaultSelectValue } from '@/components/opportunities-entity/modify/constants';
import { useResponsive } from '@/hooks/use-responsive';
import { AnimatePresence, motion } from 'framer-motion';
import { useTheme } from 'styled-components';
import {
  OptionsWrapper,
  RadioInputWrapper,
  RadioWrapper,
  SectionsWrap,
  TwoColumnEntityWrapper,
  houseHOldSectionOptions,
  taxExemptSectionOptions
} from '..';
import { EntityInput } from '../../fat-investorDetails-page/fat-entityDetails-page/edit-entity/input';
import { Section } from '../../fat-investorDetails-page/fat-entityDetails-page/edit-entity/section';
import { IListInvestors } from '../../types';

interface InvestorDetailsProps {
  householdSelectedOption: string;
  handleUseExistingEntityChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  taxExemptSelectedOption: string;
  handleTaxExemptChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  householdList: IListInvestors[];
  control: any;
  isEditMode: any;
  investorName: string;
}

export const InvestorDetails = ({
  householdSelectedOption,
  handleUseExistingEntityChange,
  taxExemptSelectedOption,
  handleTaxExemptChange,
  householdList,
  control,
  isEditMode,
  investorName
}: InvestorDetailsProps) => {
  const theme = useTheme();
  const { isMobile } = useResponsive();

  const onUseExistingEntityChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    handleUseExistingEntityChange(event);
  };

  return (
    <SectionsWrap>
      <Section title="Investor Details">
        <TwoColumnEntityWrapper isMobile={isMobile}>
          <Input
            title={'Entity Name'}
            nameField={'entityName'}
            rules={{
              required: {
                value: true,
                message: 'Entity Name is required'
              }
            }}
            control={control}
            tooltip="Give this investor a user-friendly name."
            isWarning={true}
          />
          {isEditMode && (
            <EntityInput
              value={investorName}
              setValue={() => null}
              label="Investor Household Name"
              tooltip="All investors consist of a Household and one or more Investor Entities."
              isInputDisabled
            />
          )}
        </TwoColumnEntityWrapper>
        {!isEditMode && (
          <RadioWrapper>
            Investor Household
            <RadioInputWrapper isMobile={isMobile}>
              <OptionsWrapper>
                {houseHOldSectionOptions.map((option, index) => (
                  <RadioButton
                    key={option + index}
                    name="useExistingLegalEntity"
                    radioButtonText={option}
                    selectedOption={householdSelectedOption}
                    handleRadioChange={(event) => onUseExistingEntityChange(event)}
                  />
                ))}
              </OptionsWrapper>
              <AnimatePresence>
                <RadioWrapper>
                  {householdSelectedOption === houseHOldSectionOptions[0] && (
                    <motion.div
                      initial={{
                        opacity: 0
                      }}
                      animate={{
                        opacity: 1
                      }}
                      exit={{
                        opacity: 0
                      }}
                    >
                      <Banner
                        title=""
                        description="When you provide Allocation Targets for this entity capacity calculations and allocation suitability checks are made available."
                        icon={<RejectedBannerIcon fill={theme.header.base} />}
                        bgColor={theme.layer[1]}
                        color={theme.font.base}
                      />
                    </motion.div>
                  )}
                  {householdSelectedOption === houseHOldSectionOptions[1] && (
                    <motion.div
                      initial={{
                        opacity: 0
                      }}
                      animate={{
                        opacity: 1
                      }}
                      exit={{
                        opacity: 0
                      }}
                    >
                      <Select
                        title={'Household'}
                        nameField={'familyId'}
                        control={control}
                        rules={{
                          required: false
                        }}
                        defaultValue={defaultSelectValue}
                        selects={householdList.map((el) => el.name)}
                        isWarning={true}
                        errorMessage="Household is required"
                        withSearch
                        width={isMobile ? '100%' : '500px'}
                      />
                    </motion.div>
                  )}
                </RadioWrapper>
              </AnimatePresence>
            </RadioInputWrapper>
          </RadioWrapper>
        )}

        <RadioWrapper>
          Tax Exempt
          <RadioInputWrapper isMobile={isMobile}>
            <OptionsWrapper>
              {taxExemptSectionOptions.map((option, index) => (
                <RadioButton
                  key={option + index}
                  name="taxExempt"
                  radioButtonText={option}
                  selectedOption={taxExemptSelectedOption}
                  handleRadioChange={(event) => handleTaxExemptChange(event)}
                />
              ))}
            </OptionsWrapper>
            <AnimatePresence>
              <Banner
                title=""
                description="Flag this entity as unsuitable for opportunities that do not provide a UBTI Blocker."
                icon={<RejectedBannerIcon fill={theme.header.base} />}
                bgColor={theme.layer[1]}
                color={theme.font.base}
              />
            </AnimatePresence>
          </RadioInputWrapper>
        </RadioWrapper>
      </Section>
    </SectionsWrap>
  );
};
