import { IFilterBarData } from '../basicComponents/filterBar';
import { Investment } from '../fat-dealCatalog-page/types';

export interface IListInvestmentsForManagerUpdateQuery {
  total: number;
  page: number;
  perPage: number;
  filters: IFilterBarData[];
  data: Investment[];
}

export enum SECURITY_STATUS {
  LEAD = 'Lead',
  DECLINED = 'Declined',
  DUE_DILIGENCE = 'Due Diligence',
  APPROVED = 'Approved',
  REJECTED = 'Rejected',
  OPEN = 'Open',
  ACTIVE = 'Active',
  REALIZED = 'Realized',
  REDEEMED = 'Redeemed'
}
