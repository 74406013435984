export const summaryTablecolumns = [
  { title: 'Asset Class', key: null as any, width: 20, keySort: 'asset class' },
  { title: 'Target%', key: null, width: 20, keySort: 'target %' },
  {
    title: 'Target Allocation',
    key: null,
    width: 20,
    keySort: 'target allocation'
  },
  {
    title: 'Economic Exposure',
    key: null,
    width: 20,
    keySort: 'economic exposure'
  },
  { title: 'Capacity', key: null, width: 20, keySort: 'capacity' },
  { title: 'Pacing Target', key: 'PACING_TARGET', width: 20, keySort: 'pacing target' }
];

export const simplifiedSummaryTableColumns = [
  { title: 'Asset Class', key: null as any, width: 50, keySort: 'asset class' },
  {
    title: 'Economic Exposure',
    key: null,
    width: 50,
    keySort: 'economic exposure'
  }
];

export const commitmentsTablecolumns = [
  { title: 'Commitment', key: 'name', width: 16, keySort: 'commitment' },
  {
    title: 'Asset Class',
    key: 'assetClass',
    width: 14,
    keySort: 'asset class'
  },
  {
    title: 'Committed Capital',
    key: 'committedCapital',
    width: 14,
    keySort: 'committed capital'
  },
  { title: '%Called', key: 'percentCalled', width: 14, keySort: 'called' },
  {
    title: 'Distribution to Date',
    key: 'distributionsToDate',
    width: 14,
    keySort: 'distribution to date'
  },
  {
    title: 'Market Value',
    key: 'marketValue',
    width: 14,
    keySort: 'market value'
  },
  { title: 'Status', key: 'status', width: 14, keySort: 'status' }
];

export const allocationsTablecolumns = [
  { title: 'Strategy', key: 'name', width: 26, keySort: 'strategy' },
  { title: 'Status', key: 'status', width: 12, keySort: 'status' },
  { title: 'Asset Class', key: 'assetClass', width: 12, keySort: 'asset class' },
  { title: 'Investor Docs Due', key: 'investorDocsDue', width: 12, keySort: 'investor docs due' },
  { title: 'Minimum', key: 'minimum', width: 12, keySort: 'minimum' },
  { title: 'Suitability', key: 'suitability', width: 22, keySort: 'suitability' },
  { title: ' ', key: ' ', width: 4 }
];

export const transactionsTableColumns = [
  { title: 'Strategy', key: 'STRATEGY', width: 15, keySort: 'strategy' },
  { title: 'Date', key: 'DATE', width: 14, keySort: 'trade date' },
  { title: 'Transaction Type', key: 'TRANSACTION_TYPE', width: 15, keySort: 'transaction type' },
  { title: 'Amount', key: 'AMOUNT', width: 14, keySort: 'amount' },
  { title: 'Distribution To Date', key: 'DISTRIBUTIONS_TO_DATE', width: 14, keySort: 'distribution to date' },
  { title: 'Remaining Capital', key: 'REMAINING_CAPITAL', width: 14, keySort: 'remaining capital' },
  { title: 'Market Value', key: 'MARKET_VALUE', width: 14, keySort: 'market value' }
];

export const commitmentsTableSort = [
  {
    title: 'Commitment • A -> Z',
    key: 'name',
    asc: true
  },
  {
    title: 'Commitment • Z -> A',
    key: 'name',
    asc: false
  },
  {
    title: 'Asset Class • A -> Z',
    key: 'assetClass',
    asc: true
  },
  {
    title: 'Asset Class • Z -> A',
    key: 'assetClass',
    asc: false
  },
  {
    title: 'Committed Capital • Max -> Min',
    key: 'committedCapital',
    asc: false
  },
  {
    title: 'Committed Capital • Min -> Max',
    key: 'committedCapital',
    asc: true
  },
  {
    title: '%Called • Max -> Min',
    key: 'percentCalled',
    asc: false
  },
  {
    title: '%Called • Min -> Max',
    key: 'percentCalled',
    asc: true
  },
  {
    title: 'Distribution to Date • Max -> Min',
    key: 'distributionsToDate',
    asc: false
  },
  {
    title: 'Distribution to Date • Min -> Max',
    key: 'distributionsToDate',
    asc: true
  },
  {
    title: 'Market Value • Max -> Min',
    key: 'marketValue',
    asc: false
  },
  {
    title: 'Market Value • Min -> Max',
    key: 'marketValue',
    asc: true
  },
  {
    title: 'Status • A -> Z',
    key: 'status',
    asc: true
  },
  {
    title: 'Status • Z -> A',
    key: 'status',
    asc: false
  }
];

export const allocationsTableSort = [
  {
    title: 'Strategy • A -> Z',
    key: 'name',
    asc: true
  },
  {
    title: 'Strategy • Z -> A',
    key: 'name',
    asc: false
  },
  {
    title: 'Status • A -> Z',
    key: 'status',
    asc: true
  },
  {
    title: 'Status • Z -> A',
    key: 'status',
    asc: false
  },
  {
    title: 'Asset Class • A -> Z',
    key: 'assetClass',
    asc: true
  },
  {
    title: 'Asset Class • Z -> A',
    key: 'assetClass',
    asc: false
  },
  {
    title: 'Final Close • Newest -> Oldest',
    key: 'finalClose',
    asc: false
  },
  {
    title: 'Final Close • Oldest -> Newest',
    key: 'finalClose',
    asc: true
  },
  {
    title: 'Minimum • Max -> Min',
    key: 'minimum',
    asc: false
  },
  {
    title: 'Minimum • Min -> Max',
    key: 'minimum',
    asc: true
  },
  {
    title: 'Suitability • Earlier -> Later',
    key: 'suitability',
    asc: false
  },
  {
    title: 'Suitability • Later -> Earlier',
    key: 'suitability',
    asc: true
  }
];

export const transactionsTableSort = [
  {
    title: 'Strategy • A -> Z',
    key: 'STRATEGY',
    asc: true
  },
  {
    title: 'Strategy • Z -> A',
    key: 'STRATEGY',
    asc: false
  },
  {
    title: 'Trade Date • Newest -> Oldest',
    key: 'DATE',
    asc: true
  },
  {
    title: 'Trade Date • Oldest -> Newest',
    key: 'DATE',
    asc: false
  },
  {
    title: 'Transaction Type • A -> Z',
    key: 'TRANSACTION_TYPE',
    asc: true
  },
  {
    title: 'Transaction Type • Z -> A',
    key: 'TRANSACTION_TYPE',
    asc: false
  },
  {
    title: 'Amount • Max -> Min',
    key: 'AMOUNT',
    asc: false
  },
  {
    title: 'Amount • Min -> Max',
    key: 'AMOUNT',
    asc: true
  },
  {
    title: 'Distribution To Date • Max -> Min',
    key: 'DISTRIBUTIONS_TO_DATE',
    asc: false
  },
  {
    title: 'Distribution To Date • Min -> Max',
    key: 'DISTRIBUTIONS_TO_DATE',
    asc: true
  },
  {
    title: 'Remaining Capital • Max -> Min',
    key: 'REMAINING_CAPITAL',
    asc: false
  },
  {
    title: 'Remaining Capital • Min -> Max',
    key: 'REMAINING_CAPITAL',
    asc: true
  },
  {
    title: 'Market Value • Max -> Min',
    key: 'MARKET_VALUE',
    asc: false
  },
  {
    title: 'Market Value • Min -> Max',
    key: 'MARKET_VALUE',
    asc: true
  }
];

export const transactionsFilterBarItems = [
  {
    type: 'filter',
    name: 'Contributions',
    value: 'CONTRIBUTIONS',
    enabled: true,
    count: 0
  },
  {
    type: 'filter',
    name: 'Distributions',
    value: 'DISTRIBUTIONS',
    enabled: true,
    count: 0
  },
  {
    type: 'filter',
    name: 'Recallable Distributions',
    value: 'RECALLABLE_DISTRIBUTIONS',
    enabled: true,
    count: 0
  },
  {
    type: 'filter',
    name: 'Valuations',
    value: 'VALUATIONS',
    enabled: true,
    count: 0
  }
];
