import { Box } from '@/components/muis/box';
import Typography from '@/components/muis/typography';
import { styled } from '@mui/material/styles';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import * as React from 'react';
import { useController } from 'react-hook-form';

import { WrapperHead } from '..';

interface TabsProps {
  title: string;
  items: string[];
  isWarning?: boolean;
  tooltip?: string;
  className?: string;
  nameField: string;
  control: any;
  rules: any;
  setValue: any;
}

export const Tabs: React.FC<TabsProps> = ({
  title,
  items,
  isWarning,
  tooltip,
  className,
  nameField,
  control,
  rules,
  setValue
}) => {
  const {
    field: { value, ...filedProperties },
    fieldState: { invalid },
    formState: { errors }
  } = useController({ name: nameField, control, rules });
  return (
    <Container className={className} valid={invalid.toString()}>
      <WrapperHead title={title} isWarning={isWarning} tooltip={tooltip} />
      <ToggleButtonGroup
        value={value}
        {...filedProperties}
        onChange={(event, value) =>
          setValue(nameField, value, { shouldValidate: true })
        }
        exclusive
        aria-label="text alignment"
      >
        {items.map((item) => (
          <CustomTabsButton selected={item === value} key={item} value={item}>
            {item}
          </CustomTabsButton>
        ))}
      </ToggleButtonGroup>
      {invalid && (
        <Typography sx={{ color: '#C60F0F', pt: 1 }}>
          {String(errors?.[nameField]?.message)}
        </Typography>
      )}
    </Container>
  );
};

const Container = styled(Box)<{ valid: string }>(({ valid }) => ({
  width: 'fit-content',
  border: valid === 'true' ? '3px solid #ED9DAB' : 0,
  padding: valid === 'true' ? '13px 20px' : 0
}));
const CustomTabsButton = styled(ToggleButton)(() => ({
  background: '#D9D9D9',
  borderRadius: 5,
  color: '#323232',
  fontWeight: 400,
  fontFamily: 'Inter',
  fontSize: 18,
  border: 0,
  padding: '3px 20px',
  ['&:hover']: {
    background: '#D9D9D9'
  },
  ['&.Mui-selected']: {
    background: '#0F437F',
    border: '1px solid rgba(82, 153, 137, 0.12)',
    boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.21)',
    color: '#fff',
    borderRadius: 5,
    fontWeight: 600,
    ['&:hover']: {
      background: '#0F437F'
    }
  }
}));
