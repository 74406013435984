import { User } from '../../../types/user';

export type ITier = {
  checklistItems: IChecklistItem[];
  data: ITierData;
  id: string;
  order: number;
  name: string;
  sections: ISection[];
  tasksCount: number;
};

export type IChecklistItem = {
  data: ITierChecklistItemData;
  id: string;
  name: string;
  order: number;
  required: boolean;
};

interface ITierChecklistItemData {
  completed: boolean;
  completedAt: Date;
  completedBy: User;
  assignedTo: User;
  assignedBy: User;
  id: string;
  note: string;
  updatedAt: Date;
}

export type ISection = {
  data: ITierSectionData;
  id: string;
  name: string;
  order: number;
  pdfLayout: string;
  userCanScore: boolean;
  fields: ISectionFields[];
};

interface ITierSectionData {
  id: string;
  assignedAt: Date;
  assignedBy: User;
  assignedTo: User;
  completedAt: Date;
  completedBy: User;
  updatedAt: Date;
  note: string;
  score: number;
  completed: boolean;
}

export type ISectionFields = {
  AI: AIAnswerType;
  data: ITierSectionFieldData;
  id: string;
  name: string;
  order: number;
  type: string;
  required: boolean;
  linkedFieldId: string | null;
  linkedField: any;
  promptGroup: IPromptGroup | null;
  labelerData: any;
};

export interface ILabelerData {
  groundTruth: IGroundTruth;
  rawAnswers: IRawAnswers[];
  humanScore: {
    id: string;
    score: number;
  };
  rubricScore: {
    id: string;
    score: number;
  };
}

interface IGroundTruth {
  id: string;
  answer: string;
  commentary: string | null;
  createdAt: Date;
  docContainsAnswer: boolean;
  dueDiligenceFunnelId: string;
  dueDiligenceFunnelTierSectionFieldId: string;
  filename: string;
  notes: string | null;
  pageNumber: string | null;
}

export interface IRawAnswers {
  id: string;
  answer: string;
  dealAssistantAIRunId: string;
  dueDiligenceFunnelTierSectionFieldId: string;
  embeddingId: string;
  hasAnswer: boolean | null;
  notes: string | null;
  pageNumbers: number[];
  rubricScore: number | null;
  score: number | null;
  isChangedRow?: boolean;
}

interface IPromptGroup {
  id: string;
  prompts: string[];
  summaryPrompt: string;
  contextPrompt: string;
  rubricTemplates: string[];
  useCustomContextPrompt: boolean;
  useCustomSummaryPrompt: boolean;
  createdAt: Date;
  updatedAt: Date;
}

interface ITierSectionFieldData {
  id: string;
  value: string;
  commentary: string;
  linked: boolean;
  attachments: IAttachment[];
}

export type IAttachment = {
  id: string;
  includeInAutomatedContent: boolean;
  asset: {
    url: string;
    assetKey: string;
  };
};

type ITierData = {
  approver: User;
  createdAt: Date;
  id: string;
  note: string;
  status: TierStatusEnum;
  statusUpdatedAt: Date;
  updatedAt: Date;
};

export type NoteDataType = {
  updateAt: Date;
  note: string;
  task: string;
  assignedBy: User | undefined;
  assignedTo: User | undefined;
};

export type AIAnswerType = {
  answers: string[];
  citations: CitationsType[];
};

export type CitationsType = {
  answer: string;
  embeddingId: string;
  filename: string;
  pageNumbers: string[];
  prompt: string;
};

export enum TierStatusEnum {
  NOT_STARTED = 'NOT_STARTED',
  IN_PROGRESS = 'IN_PROGRESS',
  COMPLETED = 'COMPLETED',
  REJECTED = 'REJECTED',
}

export enum RecordStatusEnum {
  NOT_STARTED = 'NOT_STARTED',
  ACTIVE = 'ACTIVE',
  APPROVED = 'APPROVED',
  REJECTED = 'REJECTED',
}
