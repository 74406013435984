import { gql } from '@apollo/client';

export const LIST_ADVISORS_QUERY = gql`
  query listFirms($data: FirmListDto!) {
    listFirms(data: $data) {
      data {
        id
        investmentCount
        investorCount
        name
        totalCommitments
        totalValue
        type
      }
      page
      perPage
      total
    }
  }
`;

export const LIST_INVESTORS_QUERY = gql`
  query listInvestors($data: InvestorListDto!) {
    listInvestors(data: $data) {
      data {
        id
        name
        familyId
        investmentCount
        totalValue
        totalCommitments
        tenant {
          name
        }
        advisor {
          initials
        }
      }
      total
      page
      perPage
    }
  }
`;

export const LIST_ENTITY_QUERY = gql`
  query listEntities($data: LegalEntityListDto!) {
    listEntities(data: $data) {
      data {
        id
        entityName
        investmentCount
        totalEntityValue
        totalCommitments
        ignoreAllocationTargets
        family {
          id
          name
        }
        tenant {
          name
        }
        advisor {
          initials
        }
      }
      total
      page
      perPage
    }
  }
`;

export const ALL_ADVISORY_FIRM_LIST_QUERY = gql`
  query AllAdvisoryFirmList {
    allAdvisoryFirmList {
      id
      name
      type
    }
  }
`;
