import { ApprovedIcon } from '@/assets/icons/info-icons/approved';
import { RejectedBannerIcon } from '@/assets/icons/info-icons/rejectedBanner';
import Label from '@/components/basicComponents/label';
import RadioButton from '@/components/basicComponents/radioButton';
import ModalWrappedContent from '@/components/fat-basicComponents/modal/modalWrappedContent';
import { formatCash } from '@/utils/formatCash';
import { useMutation } from '@apollo/client';
import { ChangeEvent, useEffect, useState } from 'react';
import styled, { useTheme } from 'styled-components';
import { InputWrap } from '../fat-allocator-page/fat-modals/editAmount';
import { createInvestorInterests, deleteInvestorInterestAmount, updateInvestorInterestAmount } from '../queries';
import { IInvestorInterestList, TypeWindow } from '../types';

interface EditToWatchlistProps {
  onClose: () => void;
  isOpen: boolean;
  currentRow: IInvestorInterestList | null;
  refetch: () => void;
  openPage: (id: string, type: TypeWindow) => void;
}

const radioButtonItems = ['Aggregate Commitment for Your Firm', 'Individual Investor Commitments', 'Remove Estimate'];

const EditToWatchlist = ({ onClose, isOpen, currentRow, refetch, openPage }: EditToWatchlistProps) => {
  const theme = useTheme();

  const [selectedOption, setSelectedOption] = useState(radioButtonItems[0]);
  const [taxableInvestorsValue, setTaxableInvestorsValue] = useState('');
  const [exemptInvestorsValue, setExemptInvestorsValue] = useState('');

  const [individualInvestorCommitments, setIndividualInvestorCommitments] = useState({ number: 0, amount: 0 });

  const [createInvestorInterest] = useMutation(createInvestorInterests);

  const [updateInvestorInterest] = useMutation(updateInvestorInterestAmount);

  const [deleteInvestorInterest] = useMutation(deleteInvestorInterestAmount, {
    onCompleted: () => {
      refetch();
    }
  });

  useEffect(() => {
    if (!currentRow) return;
    const isLegalEntity = currentRow.investorInterests.filter((interest) => interest.legal_entity);

    if (!isLegalEntity.length) {
      if (currentRow.investorInterestsExempt.length && currentRow.investorInterestsExempt[0].amount) {
        setExemptInvestorsValue(currentRow.investorInterestsExempt[0].amount.toLocaleString('en-US'));
      }
      if (currentRow.investorInterestsTaxable.length && currentRow.investorInterestsTaxable[0].amount) {
        setTaxableInvestorsValue(currentRow.investorInterestsTaxable[0].amount.toLocaleString('en-US'));
      }
      return;
    }

    if (isLegalEntity.length) {
      setSelectedOption(radioButtonItems[1]);
      setIndividualInvestorCommitments({
        number: currentRow.investorInterests.length,
        amount: currentRow.totalInvestmentAmount
      });
    }
  }, [currentRow]);

  const parseToIntOrDefault = (stringValue: string): number => {
    const parsedInt = parseInt(stringValue);
    return isNaN(parsedInt) ? 0 : parsedInt;
  };

  const deleteAmount = async () => {
    const ids = currentRow.investorInterests.map((item) => item.id);
    deleteInvestorInterest({
      variables: {
        ids
      }
    });
  };

  const saveValue = async () => {
    if (selectedOption === radioButtonItems[0]) {
      const isLegalEntity = currentRow.investorInterests.filter((interest) => interest.legal_entity);
      const taxableInvestorsNumberValue = parseToIntOrDefault(taxableInvestorsValue.replace(/\D/g, ''));
      const exemptInvestorsNumberValue = parseToIntOrDefault(exemptInvestorsValue.replace(/\D/g, ''));
      if (isLegalEntity.length) {
        await deleteAmount();
        await createInvestorInterest({
          variables: {
            data: [
              {
                amount: taxableInvestorsNumberValue,
                investmentId: currentRow.investment.id,
                exempt: false
              },
              {
                amount: exemptInvestorsNumberValue,
                investmentId: currentRow.investment.id,
                exempt: true
              }
            ]
          },
          onCompleted: () => {
            refetch();
            onClose();
          }
        });
        return;
      }

      updateInvestorInterest({
        variables: {
          data: [
            {
              id: currentRow.investorInterestsTaxable[0].id,
              amount: taxableInvestorsNumberValue
            },
            {
              id: currentRow.investorInterestsExempt[0].id,
              amount: exemptInvestorsNumberValue
            }
          ]
        },
        onCompleted: () => {
          refetch();
          onClose();
        }
      });
      return;
    }
    if (selectedOption === radioButtonItems[1]) {
      openPage(currentRow.investment.id, 'seekingInterest');
      onClose();
      return;
    }
    if (selectedOption === radioButtonItems[2]) {
      await deleteAmount();
      onClose();

      return;
    }
  };

  const handleTaxableInvestorsValueChange = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value.replace(/\D/g, '');
    if (!value) {
      setTaxableInvestorsValue('');
      return;
    }
    const formattedValue = parseFloat(value).toLocaleString('en-US');

    setTaxableInvestorsValue(formattedValue);
  };

  const handleExemptInvestorsValueChange = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value.replace(/\D/g, '');
    if (!value) {
      setExemptInvestorsValue('');
      return;
    }
    const formattedValue = parseFloat(value).toLocaleString('en-US');

    setExemptInvestorsValue(formattedValue);
  };

  const handleRadioChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSelectedOption(event.target.value);
  };

  if (!currentRow) return <></>;

  return (
    <ModalWrappedContent
      isOpen={isOpen}
      onClose={onClose}
      width="677px"
      confirmButton={{
        name: selectedOption === radioButtonItems[1] ? 'View Investors' : 'Confirm',
        onClick: saveValue,
        disabled: selectedOption === radioButtonItems[0] && !taxableInvestorsValue && !exemptInvestorsValue
      }}
    >
      <ModalContainer>
        <IconWrap>
          <ApprovedIcon width={60} height={60} fill={theme.context.success} />
        </IconWrap>
        <ModalTitel>Edit watchlist</ModalTitel>
        <ModalDescription>You will receive updates related to this manager.</ModalDescription>
        <ModalBody>
          <OptionsWrapper>
            Enter an estimated commitment:
            <RadioButtonsWrap>
              {radioButtonItems.map((item, index) => (
                <RadioButton
                  key={item + index}
                  name="editFunnel"
                  radioButtonText={item}
                  selectedOption={selectedOption}
                  handleRadioChange={handleRadioChange}
                />
              ))}
            </RadioButtonsWrap>
          </OptionsWrapper>
          <>
            {selectedOption === radioButtonItems[0] && (
              <InputsWrap>
                <div>
                  <Label required={false} fontSize="16px">
                    Taxable Investors:
                  </Label>
                  <InputWrap>
                    <Input type="text" value={taxableInvestorsValue} onChange={handleTaxableInvestorsValueChange} />
                    <Dollar>$</Dollar>
                  </InputWrap>
                </div>
                <div>
                  <Label required={false} fontSize="16px">
                    Exempt Investors:
                  </Label>
                  <InputWrap>
                    <Input type="text" value={exemptInvestorsValue} onChange={handleExemptInvestorsValueChange} />
                    <Dollar>$</Dollar>
                  </InputWrap>
                </div>
              </InputsWrap>
            )}
            {selectedOption === radioButtonItems[1] && (
              <BannerWrap>
                <BannerIconWrap>
                  <RejectedBannerIcon fill={theme.header.base} />
                </BannerIconWrap>
                <BannerText>
                  {individualInvestorCommitments.number} Investors currently selected totaling {formatCash(individualInvestorCommitments.amount)}
                </BannerText>
              </BannerWrap>
            )}
          </>
        </ModalBody>
      </ModalContainer>
    </ModalWrappedContent>
  );
};

export default EditToWatchlist;

const ModalContainer = styled.div`
  font-weight: 400;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const IconWrap = styled.div`
  display: flex;
  justify-content: center;
  justify-content: center;
`;

const ModalTitel = styled.div`
  font-size: 33px;
  text-align: center;
  color: ${({ theme }) => theme.font.strong};
`;

const ModalDescription = styled.div`
  font-size: 19px;
  line-height: 27px;
  text-align: center;
  color: ${({ theme }) => theme.font.base};
`;

const ModalBody = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 35px;
`;

const OptionsWrapper = styled.div`
  width: 85%;
  color: ${({ theme }) => theme.font.base};
  font-size: 16px;
  font-weight: 400;
  line-height: 22.4px;
`;

const RadioButtonsWrap = styled.label`
  display: flex;
  flex-direction: column;
  margin-top: 10px;
`;

const InputsWrap = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
`;

const Input = styled.input`
  width: 225px;
  border: 1px solid ${({ theme }) => theme.border.base};
  background-color: ${({ theme }) => theme.layer[1]};
  color: ${({ theme }) => theme.font.strong};
  border-radius: 4px;
  padding: 10px 20px;
  font-family: Blinker;
  font-size: 16px;
  font-weight: 300;
  line-height: 22.4px;

  &:focus {
    outline: none;
  }

  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  &::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;

const Dollar = styled.span`
  position: absolute;
  top: 10px;
  left: 10px;
  color: ${({ theme }) => theme.font.strong};
`;

const BannerWrap = styled.span`
  display: flex;
  align-items: center;
  gap: 20px;
  background-color: ${({ theme }) => theme.layer[1]};
  width: 60%;
  height: 76px;
  padding: 10px 20px;
`;

const BannerIconWrap = styled.span`
  display: flex;
  align-items: center;
  height: 100%;
  padding-right: 20px;
  border-right: 1px solid ${({ theme }) => theme.border.base};
`;

const BannerText = styled.span`
  font-size: 13px;
  font-weight: 400;
  line-height: 18.2px;
  color: ${({ theme }) => theme.font.base};
`;
