import React, { useEffect, useRef, useState } from 'react';
import styled, { useTheme } from 'styled-components';

import { ArrowDown } from '@/assets/icons/info-icons/arrowDown';

type ShowTextProps = {
  children: React.ReactNode;
};

const ShowText = ({ children }: ShowTextProps) => {
  const theme = useTheme();

  const [isShowMore, setIsShowMore] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);
  const containerRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (containerRef.current) {
      const containerHeight = containerRef.current.clientHeight;
      setIsExpanded(Boolean(containerHeight > 88));
    }
  }, [children, isShowMore]);

  return (
    <LongTextWrapper>
      <TodoRowCell isShowMore={isShowMore} ref={containerRef}>
        {children}
      </TodoRowCell>
      {isExpanded && (
        <ShowButton onClick={() => setIsShowMore(!isShowMore)}>
          Show {isShowMore ? 'less' : 'more'}
          {isShowMore ? (
            <svg width="8" height="6" viewBox="0 0 8 6" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M0.940002 5.47L4 2.418L7.06 5.47L8 4.53L4 0.530001L-3.70319e-08 4.53L0.940002 5.47Z" fill={theme.font.action} />
            </svg>
          ) : (
            <ArrowDown fill={theme.font.action} />
          )}
        </ShowButton>
      )}
    </LongTextWrapper>
  );
};

const TodoRowCell = styled.div<{ isShowMore: boolean }>`
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: ${({ theme }) => theme.font.base};
  max-width: 680px;
  width: 100%;
  max-height: ${({ isShowMore }) => (isShowMore ? 'max-content' : '92px')};
  height: max-content;
  overflow: ${({ isShowMore }) => (isShowMore ? 'visible' : 'hidden')};
  white-space: pre-line;
`;

const ShowButton = styled.div`
  display: flex;
  align-items: center;
  gap: 3px;
  font-weight: 600;
  font-size: 13px;
  line-height: 20px;
  color: ${({ theme }) => theme.font.action};
  cursor: pointer;
`;

const LongTextWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export default ShowText;
