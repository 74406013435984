import { useEffect, useState } from 'react';
import styled from 'styled-components';

import { Helmet as Head } from 'react-helmet';
import { useLocation } from 'react-router-dom';
import { FirebaseLogin } from '../../components/authentication/fat-login';
import LoginHero from '../../components/authentication/fat-login-hero';
import { useResponsive } from '../../hooks/use-responsive';

const Login = () => {
  const { isMobile, isTablet, isPortrait } = useResponsive();
  const location = useLocation();

  const [isNextReady, setIsNextReady] = useState(false);
  const [loginPageHeight, setLoginPageHeight] = useState('');

  useEffect(() => {
    setIsNextReady(true);
    setLoginPageHeight(window.innerHeight.toString() + 'px');
  }, [isPortrait]);

  const [showNotification, setShowNotification] = useState(false);

  useEffect(() => {
    if (location.state?.isLastPageProfile) {
      setShowNotification(true);
    }
    const timerId = setTimeout(() => {
      setShowNotification(false);
    }, 10000);
    return () => clearTimeout(timerId);
  }, []);

  return (
    <>
      <Head>
        <title>Login</title>
      </Head>
      {isNextReady ? (
        <Container isMobile={isMobile} isTablet={isTablet} isPortrait={isPortrait} loginPageHeight={loginPageHeight}>
          {isMobile || (isPortrait && isTablet) ? (
            <HeroBlockMobile>
              <LoginHero />
            </HeroBlockMobile>
          ) : (
            <HeroBlock>
              <LoginHero />
            </HeroBlock>
          )}
          {isMobile || (isPortrait && isTablet) ? (
            <LogInBlockMobile>
              <FirebaseLogin />
              {showNotification && <LogOutNotification>You have been signed out</LogOutNotification>}
            </LogInBlockMobile>
          ) : (
            <LogInBlock>
              <FirebaseLogin />
              {showNotification && <LogOutNotification>You have been signed out</LogOutNotification>}
            </LogInBlock>
          )}
          {isMobile || (isPortrait && isTablet) ? (
            <Annotation color={'#36474F'}>{`@${new Date().getFullYear()} WECtec Enterprises, LLC`}</Annotation>
          ) : (
            <></>
          )}
        </Container>
      ) : (
        <></>
      )}
    </>
  );
};

export default Login;

const Container = styled.div<{ isMobile: boolean; isTablet: boolean; isPortrait: boolean; loginPageHeight: string }>`
  height: ${({ isMobile, isTablet, isPortrait, loginPageHeight }) => (isMobile || (isPortrait && isTablet) ? loginPageHeight : '100vh')};
  width: 100%;
  display: flex;
  flex-direction: ${({ isMobile, isTablet, isPortrait }) => (isMobile || (isPortrait && isTablet) ? 'column' : 'row')};
  justify-content: center;
`;

const LogOutNotification = styled.div<{ color?: string }>`
  width: 300px;
  transform: translateX(-13%);
  background: #76e7cd;
  color: ${({ color }) => color};
  padding: 14px 16px;
  opacity: 0.9;
  border-radius: 6px;
  font-family: Inter, serif;
  font-weight: 500;
  position: absolute;
  left: calc(50% - 110px);
  bottom: 80px;
  text-align: center;
`;

const HeroBlock = styled.div`
  background-color: ${({ theme }) => theme.layer[2]};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 50%;
  position: relative;
`;

const HeroBlockMobile = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 36%;
  background-color: ${({ theme }) => theme.layer[2]};
`;

const LogInBlock = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100%;
  width: 50%;
  overflow: hidden;
  position: relative;
  background-color: ${({ theme }) => theme.layer.base};
`;

const LogInBlockMobile = styled(LogInBlock)`
  width: 100%;
`;

const Annotation = styled('span')`
  text-align: center;
  color: #36474f;
  font-size: 14px;
  font-family: Inter, serif;
  padding-bottom: 10px;
`;
