import { GroupedIcon } from '@/assets/icons/info-icons/groupedIcon';
import { PlusIcon } from '@/assets/icons/info-icons/plusIcon';
import { useState } from 'react';
import styled, { useTheme } from 'styled-components';
import { IAssetClass } from '../types';

interface AssetClassRowProps {
  row: IAssetClass;
  targetSubAssetClasses: boolean;
}

export const AssetClassRow = ({ row, targetSubAssetClasses }: AssetClassRowProps) => {
  const theme = useTheme();

  const [showSubAssetClass, setShowSubAssetClass] = useState(false);

  return (
    <>
      <SimpleTableRow>
        <RowItem>
          {targetSubAssetClasses && row.subAssetClasses?.length ? (
            <PlusIconWrapper onClick={() => setShowSubAssetClass(!showSubAssetClass)}>
              <PlusIcon fill={theme.font.base} />
            </PlusIconWrapper>
          ) : (
            <></>
          )}
          {row.name}
        </RowItem>
        <RowItem isBold={Boolean(targetSubAssetClasses && row.subAssetClasses?.length)}>{row.defaultAllocationPercentage?.toFixed(2)}%</RowItem>
      </SimpleTableRow>
      {showSubAssetClass && (
        <>
          {row.subAssetClasses?.map((subAssetClass) => (
            <SimpleTableRow key={subAssetClass.id}>
              <RowItem>
                <GroupIconWrapper>
                  <GroupedIcon width={10} height={10} />
                </GroupIconWrapper>
                {subAssetClass.name}
              </RowItem>
              <RowItem>{subAssetClass.defaultAllocationPercentage?.toFixed(2)}%</RowItem>
            </SimpleTableRow>
          ))}
        </>
      )}
    </>
  );
};

const RowItem = styled.div<{ isBold?: boolean }>`
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 6px;
  font-family: Blinker, serif;
  font-size: 16px;
  font-weight: ${({ isBold }) => (isBold ? 600 : 400)};
  line-height: 22px;
  letter-spacing: 0;
  color: ${({ theme }) => theme.font.base};
`;

const PlusIconWrapper = styled.div`
  padding: 5px;
  cursor: pointer;
`;

const GroupIconWrapper = styled.div`
  padding: 5px;
  position: relative;
  top: -5px;
  left: 5px;
`;

const SimpleTableRow = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  background-color: ${({ theme }) => theme.layer[1]};
  margin-bottom: 3px;
  justify-content: space-between;
  border-radius: 4px;
  padding: 5px 24px;
  position: relative;
`;
