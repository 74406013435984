import { Button as MuiButton } from '@mui/material';
import { styled } from 'styled-components';
import React from 'react';

interface ButtonProps {
  children: React.ReactNode;
  onClick?: () => void;
  type?: 'button' | 'reset' | 'submit';
  disabled?: boolean;
  className?: string;
}

export const Button = ({
  children,
  onClick,
  type = 'button',
  disabled,
  className
}: ButtonProps) => {
  return (
    <CustomButton
      onClick={onClick}
      disabled={disabled}
      type={type}
      className={className}
    >
      {children}
    </CustomButton>
  );
};

const CustomButton = styled(MuiButton)`
  padding: 6px 20px;
  border-radius: 5;
  display: flex;
  justify-content: center;
  font-size: 19;
  font-style: normal;
  font-weight: 600,
  align-items: center;
  '&.MuiButton-root': {
    color: ${(props) => props.theme.secondary.contrastText},
    background: ${(props) => props.theme.actions.default},
  },
  '&.Mui-disabled': {
    background: ${(props) => props.theme.actions.defaultDisabled}},
    color: ${(props) => props.theme.font.disabled}
  }
`;
