import { EditIcon } from '@/assets/icons/edit';
import { RejectedBannerIcon } from '@/assets/icons/info-icons/rejectedBanner';
import { Banner } from '@/components/basicComponents/banner';
import Label from '@/components/basicComponents/label';
import RadioButton from '@/components/basicComponents/radioButton';
import BasicTooltip from '@/components/basicComponents/tooltip';
import { useResponsive } from '@/hooks/use-responsive';
import { AnimatePresence, motion } from 'framer-motion';
import { ChangeEvent, Dispatch, SetStateAction, useEffect, useState } from 'react';
import { UseFormGetValues, UseFormSetValue } from 'react-hook-form';
import styled, { useTheme } from 'styled-components';
import { OptionsWrapper, RadioInputWrapper, RadioWrapper, SectionsWrap } from '..';
import { EntityInput, Info, Input, LabelWrap } from '../../fat-investorDetails-page/fat-entityDetails-page/edit-entity/input';
import { Section } from '../../fat-investorDetails-page/fat-entityDetails-page/edit-entity/section';
import AssetClassTable from '../assetClassTable/index';
import { CreateInvestorDto, IAssetClass } from '../types';

const ignoreAllocationTargetsOptions = ['Disregard allocation targets', 'Set allocation targets for this entity'];

interface SetAllocationTargetsProps {
  setFormValue: UseFormSetValue<CreateInvestorDto>;
  getValues: UseFormGetValues<CreateInvestorDto>;
  setIsAllocationTargetsValid: Dispatch<SetStateAction<boolean>>;
  openAssetClassTargetsPage: () => void;
  assetClasses: IAssetClass[];
  targetSubAssetClasses: boolean;
}

export const SetAllocationTargets = ({
  setFormValue,
  getValues,
  setIsAllocationTargetsValid,
  openAssetClassTargetsPage,
  assetClasses,
  targetSubAssetClasses
}: SetAllocationTargetsProps) => {
  const { isMobile, isTablet } = useResponsive();
  const theme = useTheme();

  const [ignoreAllocationTargets, setIgnoreAllocationTargets] = useState<string>(ignoreAllocationTargetsOptions[0]);
  const [dataFields, setDataFields] = useState({
    totalEntityValue: '',
    privatePercentage: '',
    overCommitRatio: ''
  });

  useEffect(() => {
    const ignoreAllocationTargets = getValues('ignoreAllocationTargets');
    const totalEntityValue = getValues('totalEntityValue');
    const privatePercentage = getValues('privatePercentage');
    const overCommitRatio = getValues('overCommitRatio');

    const formatTotalEntityValue = (value: number) => (value ? value.toLocaleString('en-US') : '');
    const formatPrivatePercentage = (value: number) => (value ? value.toString() : '');
    const formatOverCommitRatio = (ratio: number) => {
      return ratio !== null ? ratio.toString() : '';
    };

    setIgnoreAllocationTargets(ignoreAllocationTargets ? ignoreAllocationTargetsOptions[0] : ignoreAllocationTargetsOptions[1]);
    setDataFields({
      totalEntityValue: formatTotalEntityValue(totalEntityValue),
      privatePercentage: formatPrivatePercentage(privatePercentage),
      overCommitRatio: formatOverCommitRatio(overCommitRatio)
    });
  }, [getValues('ignoreAllocationTargets')]);

  useEffect(() => {
    if (ignoreAllocationTargets === ignoreAllocationTargetsOptions[0]) {
      setIsAllocationTargetsValid(true);
      return;
    }
    if (dataFields.totalEntityValue && dataFields.privatePercentage && dataFields.overCommitRatio) {
      setIsAllocationTargetsValid(true);
      return;
    }
    setIsAllocationTargetsValid(false);
  }, [dataFields, ignoreAllocationTargets]);

  const setValue = (key: string, value: any) => {
    switch (key) {
      case 'overCommitRatio':
        const sanitizedValue = String(value).replace(/\D/g, '');
        const limitedValue = sanitizedValue.slice(0, 2);

        setFormValue(key as any, parseFloat(limitedValue));
        setDataFields((prev) => ({ ...prev, [key]: limitedValue }));
        break;
      case 'privatePercentage':
        if (value >= 0 && value <= 100) {
          setFormValue(key as any, parseFloat(value));
          setDataFields((prev) => ({ ...prev, [key]: value }));
        }
        break;
      case 'totalEntityValue':
        const cleanValue = value.replace(/\D/g, '');

        if (!cleanValue) {
          setFormValue(key as any, parseFloat(cleanValue));
          setDataFields((prev) => ({ ...prev, [key]: cleanValue }));
          return;
        }
        const formattedValue = parseFloat(cleanValue).toLocaleString('en-US');
        setFormValue(key as any, parseFloat(cleanValue));
        setDataFields((prev) => ({ ...prev, [key]: formattedValue }));
        break;
      default:
        break;
    }
  };

  const handleChangeIgnoreAllocationTargetsOption = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setIgnoreAllocationTargets(value);
    setFormValue('ignoreAllocationTargets', value === ignoreAllocationTargetsOptions[0]);
  };

  return (
    <SectionsWrap>
      <Section title="Allocation Targets">
        <RadioWrapper>
          Set Allocation Targets for this entity.
          <RadioInputWrapper isMobile={isMobile}>
            <OptionsWrapper>
              {ignoreAllocationTargetsOptions.map((option, index) => (
                <RadioButton
                  key={option + index}
                  name="ignoreAllocationTargets"
                  radioButtonText={option}
                  selectedOption={ignoreAllocationTargets}
                  handleRadioChange={handleChangeIgnoreAllocationTargetsOption}
                />
              ))}
            </OptionsWrapper>
            <Banner
              title=""
              description="When you provide Allocation Targets for this entity capacity calculations and allocation suitability checks are made available."
              icon={<RejectedBannerIcon fill={theme.header.base} />}
              bgColor={theme.layer[1]}
              color={theme.font.base}
            />
          </RadioInputWrapper>
        </RadioWrapper>
        <AnimatePresence>
          {ignoreAllocationTargets === ignoreAllocationTargetsOptions[1] && (
            <motion.div
              initial={{
                opacity: 0
              }}
              animate={{
                opacity: 1
              }}
              exit={{
                opacity: 0
              }}
            >
              <AllocationTargets isMobile={isMobile}>
                <EntityInput
                  value={dataFields?.totalEntityValue ?? ''}
                  setValue={(value) => setValue('totalEntityValue', value)}
                  type="text"
                  label="Total Entity Value"
                  required
                  tooltipWidth={400}
                  tooltip="The total investable assets for this entity.  This value is used to calculate target allocation amounts."
                  width={isMobile ? '100%' : '33%'}
                  withDollarSign
                />
                <EntityInput
                  value={dataFields?.privatePercentage ?? ''}
                  setValue={(value) => setValue('privatePercentage', value as any)}
                  type="number"
                  label="Private Capital %"
                  required
                  tooltip="The percentage of the Total Entity Value that should be allocated to private investments."
                  width={isMobile ? '100%' : '33%'}
                />
                <RatioWrap isMobile={isMobile}>
                  <LabelWrap>
                    <Label required>OC Ratio</Label>
                    <BasicTooltip tooltipContent="Optionally set an over commitment ratio to over allocate slightly." tooltipWidth={400}>
                      <Info src={'/static/icons/info-circle.svg'} alt="info" />
                    </BasicTooltip>
                  </LabelWrap>
                  <RatioInputWrap>
                    <RatioValue>X</RatioValue>
                    <RatioValue>1.</RatioValue>
                    <Input
                      type="number"
                      value={dataFields?.overCommitRatio ?? ''}
                      onChange={(event) => setValue('overCommitRatio', event.target.value as any)}
                      width="45px"
                      min={0}
                      max={99}
                      onWheel={(e) => (e.target as HTMLInputElement).blur()}
                    />
                  </RatioInputWrap>
                </RatioWrap>
              </AllocationTargets>
              <TargetsWrap isMobile={isMobile}>
                Set Allocation Targets by Asset Class
                <AssetClassTableWrapper>
                  <EditIconWrap onClick={openAssetClassTargetsPage}>
                    <EditIcon fill={theme.font.action} />
                    Edit
                  </EditIconWrap>
                  <AssetClassTable assetClasses={assetClasses} targetSubAssetClasses={targetSubAssetClasses} />
                </AssetClassTableWrapper>
              </TargetsWrap>
            </motion.div>
          )}
        </AnimatePresence>
      </Section>
    </SectionsWrap>
  );
};

const AllocationTargets = styled.div<{ isMobile: boolean }>`
  width: 100%;
  display: flex;
  align-items: ${({ isMobile }) => (isMobile ? 'flex-start' : 'center')};
  flex-direction: ${({ isMobile }) => (isMobile ? 'column' : 'row')};
  gap: ${({ isMobile }) => (isMobile ? '30px' : '15px')};
`;

const TargetsWrap = styled.div<{ isMobile: boolean }>`
  color: ${({ theme }) => theme.font.base};
  margin-top: 45px;
  width: ${({ isMobile }) => (isMobile ? '100%' : '50%')};
  display: flex;
  flex-direction: column;
  gap: 15px;
`;

const EditIconWrap = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 4px;
  cursor: pointer;
  padding: 10px 20px 20px;
  color: ${({ theme }) => theme.font.action};
`;

const AssetClassTableWrapper = styled.div`
  border: 1px solid ${({ theme }) => theme.border.base};
  padding: 10px 20px 20px;
`;

const RatioWrap = styled.div<{ isMobile: boolean }>`
  width: ${({ isMobile }) => (isMobile ? '100%' : '33%')};
`;

const RatioInputWrap = styled.div`
  display: flex;
  align-items: center;
`;

const RatioValue = styled.div`
  padding: 10px 12px;
  color: ${({ theme }) => theme.font.strong};
`;
