import { useMutation, useQuery } from '@apollo/client';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import styled from 'styled-components';

import CreateManager from '@/components/allocations-page/modals/createManager';
import CreateStrategy from '@/components/allocations-page/modals/createStrategy';
import CreateTag from '@/components/allocations-page/modals/createTag';
import { Investment } from '@/components/allocations-page/types';
import { FormFooter } from '@/components/fat-basicComponents/formFooter';
import { defaultListData } from '@/components/fat-dealPipeline-page/fat-modify/constatnts';
import { IListData } from '@/components/fat-dealPipeline-page/fat-modify/types';
import Header from '@/components/fat-header';
import { GoBackButton } from '@/components/fat-header/goBackButton';
import { PageTitle } from '@/components/fat-header/pageTitle';
import { LoaderOnWholeScreen } from '@/components/loaders/loader-on-whole-screen';
import { SORT_ORDER } from '@/components/table/types';
import { useResponsive } from '@/hooks/use-responsive';
import { useGoBack } from '@/hooks/useGoBack';
import { ContainerPage, MainWrap } from '@/styles/common';
import { User } from '@/types/user';
import { createInvestmentMutation, defaultAssetClassValue, defaultSelectValue } from '../../opportunities-entity/modify/constants';
import {
  DUE_DILIGENCE_FUNNEL_LIST_QUERY,
  DUE_DILIGENCE_RECORD_QUERY_MIN,
  LIST_USERS_QUERY,
  UPDATE_INVESTMENT,
  createDueDiligenceRecordMutation,
  getCurrentManagers,
  getCurrentSubAssetClasses,
  getCurrentTags,
  updateDueDiligenceRecordMutation
} from '../queries';
import { IFunnelData } from '../types';
import { DetailsSection } from './sections/details';
import { getDefaultFormValues } from './utils';

export type IDiligenceForm = {
  name: string;
  assetClass: string;
  closeDate: string;
  owner: string;
  manager: string;
  funnel: string;
  contact: string;
  referral: string;
  targetIRR: string | number;
  targetYield: string | number;
  vintage: string;
};

const ModifyDiligence = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { isMobile, isTablet } = useResponsive();
  const goBack = useGoBack();

  const [searchParams, setSearchParams] = useSearchParams();

  const record = searchParams.get('record') || '';
  const funnelId = searchParams.get('funnelId') || '';
  const owner = searchParams.get('owner') || '';
  const status = searchParams.get('status') || '';
  const backToTitle = searchParams.get('backToTitle') || '';

  const { data: recordData, loading: recordLoading } = useQuery(DUE_DILIGENCE_RECORD_QUERY_MIN, {
    fetchPolicy: 'network-only',
    variables: { id: record },
    skip: !record
  });

  const {
    handleSubmit,
    control,
    setError,
    formState: { errors, isValid, isSubmitted },
    getValues,
    setValue,
    reset
  } = useForm<IDiligenceForm>();
  const [userList, setUserList] = useState<string[]>([defaultSelectValue]);
  const [managerList, setManagerList] = useState<string[]>([]);
  const [tags, setTags] = useState<IListData>(defaultListData);
  const [strategies, setStrategies] = useState<IListData>(defaultListData);
  const [funnelList, setFunnelList] = useState<string[]>([defaultSelectValue]);
  const [modalWindow, setModalWindow] = useState({
    isOpen: false,
    type: 'create-manager'
  });
  const [descriptionValue, setDescriptionValue] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);

  useQuery(getCurrentManagers, {
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      const sortedManagerList = [...data.getCurrentManagers.values].filter((manager: string) => manager !== '');
      setManagerList(sortedManagerList);
    }
  });

  const { data: tagsListData } = useQuery(getCurrentTags, {
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      const sortedTagsList = [...data.getCurrentTags.values].filter((tag: string) => tag !== '');
      setTags({ ...tags, list: sortedTagsList });
    }
  });

  const { data: strategiesListData } = useQuery(getCurrentSubAssetClasses, {
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      const sortedStrategiesList = [...data.getCurrentSubAssetClasses.values].filter((strategy: string) => strategy !== '');
      setStrategies({ ...strategies, list: sortedStrategiesList });
    }
  });

  useEffect(() => {
    const record = recordData?.getDueDiligenceRecord;
    if (recordData) {
      setValue('funnel', record.funnel.name ?? defaultSelectValue);
      setFunnelList([record.funnel.name]);
      setValue('targetIRR', record.investment.targetIRR ?? '');
      setValue('targetYield', record.investment.targetYield ?? '');
      setValue('vintage', record.investment.vintageYear ?? '');
      setValue('contact', record.investment.contact ?? '');
      setValue('referral', record.investment.referral ?? '');
      setDescriptionValue(record.investment.description ?? '');
      setTags({ ...tags, chosen: record.investment.tags ?? [] });
      setStrategies({
        ...strategies,
        chosen: record.investment.subAssetClasses ?? []
      });
      setManagerList(record.manager ? [...managerList, record.manager] : managerList);
    }
    reset(getDefaultFormValues(record?.investment as Investment, record));
  }, [recordData]);

  useEffect(() => {
    const sortedManager = [...managerList].filter((manager: string) => manager !== '').sort((a: string, b: string) => a.localeCompare(b));
    setManagerList(sortedManager);
  }, [getValues('manager')]);

  useEffect(() => {
    setTags((prevTags) => {
      return {
        ...prevTags,
        list: prevTags.list.filter((tag) => !tags.chosen.includes(tag))
      };
    });
  }, [recordData, tagsListData, tags.chosen]);

  useEffect(() => {
    setStrategies((prevStrategies) => {
      return {
        ...prevStrategies,
        list: prevStrategies.list.filter((strategy) => !strategies.chosen.includes(strategy))
      };
    });
  }, [recordData, strategiesListData, strategies.chosen]);

  const { data: usersData, loading: userLoading } = useQuery(LIST_USERS_QUERY, {
    notifyOnNetworkStatusChange: true,
    variables: {
      filters: {
        roles: ['INVESTMENT_LEAD', 'INVESTMENT_MEMBER']
      }
    },
    onCompleted: (data) => {
      setUserList([owner ? owner : defaultSelectValue, ...data.listUsers.map((user: User) => `${user.firstName} ${user.lastName}`)]);
    }
  });

  const { data: funnelListData, loading: funnelListLoading } = useQuery(DUE_DILIGENCE_FUNNEL_LIST_QUERY, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
    variables: {
      data: {
        statusFilter: 'PUBLISHED',
        sortDirection: SORT_ORDER.ASC,
        sortBy: 'ORDER'
      }
    },
    onCompleted: (data) => {
      if (!Boolean(record)) {
        setFunnelList([defaultSelectValue, ...data.listDueDiligenceFunnels.map((funnel: IFunnelData) => funnel.name)]);
      }
    }
  });

  useEffect(() => {
    if (funnelListData && funnelId) {
      setValue('funnel', funnelListData.listDueDiligenceFunnels.find((funnel: IFunnelData) => funnel.id === funnelId).name);
    }
  }, [funnelListData]);

  const handleClose = () => {
    goBack({ fallBack: '/dueDiligence' });
  };

  const [createDueDiligenceRecord] = useMutation(createDueDiligenceRecordMutation);
  const [updateDueDiligenceRecord] = useMutation(updateDueDiligenceRecordMutation);

  const [createInvestment] = useMutation(createInvestmentMutation);
  const [updateInvestment] = useMutation(UPDATE_INVESTMENT);

  const onSubmit = async (data: IDiligenceForm) => {
    setIsSubmitting(true);

    const owner = usersData.listUsers.find((item: User) => `${item.firstName} ${item.lastName}` === data.owner);
    const funnel = funnelListData.listDueDiligenceFunnels.find((item: IFunnelData) => item.name === getValues('funnel'));
    const investmentStrategies = [...strategies.chosen].filter((el) => el.length > 0 && el !== 'None').map((el) => el.trim());
    const recordTags = [...tags.chosen].filter((el) => el.length > 0 && el !== 'None').map((el) => el.trim());

    if (!getValues('name')?.length || getValues('funnel') === 'Select' || data.owner === 'Select') return;

    if (record) {
      try {
        const response = await updateInvestment({
          variables: {
            data: {
              id: recordData?.getDueDiligenceRecord.investment.id,
              name: getValues('name'),
              managerId: getValues('manager') === defaultSelectValue ? '' : getValues('manager'),
              securityStatus: recordData?.getDueDiligenceRecord.investment.securityStatus,
              assetClass: getValues('assetClass') === defaultAssetClassValue ? null : getValues('assetClass'),
              finalClose: getValues('closeDate') ? getValues('closeDate') : null,
              subAssetClasses: investmentStrategies,
              targetIRR: Boolean(data.targetIRR) ? (typeof data.targetIRR === 'string' ? +data.targetIRR.replace(',', '.') : +data.targetIRR) : null,
              targetYield: Boolean(data.targetYield)
                ? typeof data.targetYield === 'string'
                  ? +data.targetYield.replace(',', '.')
                  : +data.targetYield
                : null,
              vintageYear: Boolean(data.vintage) ? +data.vintage : null,
              tags: recordTags,
              contact: getValues('contact'),
              referral: getValues('referral'),
              description: descriptionValue
            }
          }
        });

        await updateDueDiligenceRecord({
          variables: {
            data: {
              id: record,
              manager: getValues('manager') === defaultSelectValue ? null : getValues('manager'),
              investmentId: response.data.updateInvestment.id,
              ownerEmail: owner.email
            }
          },
          onCompleted: () => {
            handleClose();
          }
        });
      } catch (error) {
        console.error(error, 'error');
      } finally {
        setIsSubmitting(false);
      }

      return;
    }

    try {
      const response = await createInvestment({
        variables: {
          data: {
            name: getValues('name'),
            managerId: getValues('manager') === defaultSelectValue ? '' : getValues('manager'),
            securityStatus: status,
            investmentOwnerEmail: owner.email,
            assetClass: getValues('assetClass') === defaultAssetClassValue ? null : getValues('assetClass'),
            finalClose: getValues('closeDate') ? getValues('closeDate') : null,
            subAssetClasses: investmentStrategies,
            targetIRR: Boolean(data.targetIRR) ? (typeof data.targetIRR === 'string' ? +data.targetIRR.replace(',', '.') : +data.targetIRR) : null,
            targetYield: Boolean(data.targetYield)
              ? typeof data.targetYield === 'string'
                ? +data.targetYield.replace(',', '.')
                : +data.targetYield
              : null,
            vintageYear: Boolean(data.vintage) ? +data.vintage : null,
            tags: recordTags,
            contact: getValues('contact'),
            referral: getValues('referral'),
            description: descriptionValue
          }
        }
      });

      await createDueDiligenceRecord({
        variables: {
          data: {
            funnelId: funnel.id,
            manager: getValues('manager') === defaultSelectValue ? null : getValues('manager'),
            status: status === 'LEAD' ? 'NOT_STARTED' : 'ACTIVE',
            investmentId: response.data.createInvestment.id,
            ownerEmail: owner.email
          }
        },
        onCompleted: () => {
          handleClose();
        }
      });
    } catch (error) {
      console.error(error, 'error');
    } finally {
      setIsSubmitting(false);
    }
  };

  const openModalWindow = (type: string) => {
    setModalWindow({ ...modalWindow, isOpen: true, type });
  };

  if (recordLoading || userLoading || funnelListLoading) {
    return <LoaderOnWholeScreen size={60} />;
  }

  return (
    <>
      {modalWindow.isOpen && modalWindow.type === 'create-manager' && (
        <CreateManager
          onClose={() => setModalWindow({ type: 'create-manager', isOpen: false })}
          isOpen={modalWindow.isOpen}
          managerList={managerList}
          setManagerList={setManagerList}
          getNewManager={(newManager) => setValue('manager', newManager)}
        />
      )}
      {modalWindow.isOpen && modalWindow.type === 'create-strategy' && (
        <CreateStrategy
          onClose={() => setModalWindow({ type: 'create-strategy', isOpen: false })}
          isOpen={modalWindow.isOpen}
          setStrategies={setStrategies}
        />
      )}
      {modalWindow.isOpen && modalWindow.type === 'create-tag' && (
        <CreateTag onClose={() => setModalWindow({ type: 'create-tag', isOpen: false })} isOpen={modalWindow.isOpen} setTags={setTags} />
      )}
      <MainWrap>
        <Header modalControl={<GoBackButton handleClose={handleClose} backToTitle={backToTitle} />} />
        <PageTitle title={record ? 'Edit Deal' : 'Add New Deal'} />
        <Form id="diligenceForm" onSubmit={handleSubmit(onSubmit)}>
          <CustomContainerPage>
            <SectionsWrap>
              <DetailsSection
                control={control}
                errors={errors}
                setError={setError}
                managerList={managerList}
                openModalWindow={openModalWindow}
                strategies={strategies}
                setStrategies={setStrategies}
                tags={tags}
                setTags={setTags}
                descriptionValue={descriptionValue}
                setDescriptionValue={setDescriptionValue}
                userList={userList}
                funnelList={funnelList}
              />
            </SectionsWrap>
            <FormFooter onCancel={handleClose} disableSaveButton={false} />
          </CustomContainerPage>
        </Form>
      </MainWrap>
    </>
  );
};

export default ModifyDiligence;

const Form = styled.form`
  width: 100%;
`;

const CustomContainerPage = styled(ContainerPage)`
  padding-top: 40px;
`;

const SectionsWrap = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

export const ControlWrapper = styled.div<{ isMobile: boolean }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: ${({ isMobile }) => (isMobile ? 'column' : 'row')};
  gap: ${({ isMobile }) => (isMobile ? '10px' : '0')};
  margin: 45.5px 0;
`;

export const ButtonWrapper = styled.div`
  display: flex;
  gap: 10px;
`;
