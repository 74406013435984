import { AIAnswerType } from '@/components/dueDiligence-page/dueDiligenceRecord/types';
import { User } from '@/types/user';

export interface IListDocumentRecordsQuery {
  total: number;
  limit: number;
  offset: number;
  records: IDocumentRecordEntity[];
}

export interface IDocumentRecordEntity {
  id: string;
  name: string;
  manager: string;
  status: keyof typeof DOCUMENT_RECORD_STATUS_ENUM;
  statusUpdatedAt: string;
  note: string;
  createdAt: string;
  updatedAt: string;
  deletedAt: string;
  owner: User;
}

export interface IDocumentRecordOwnerIdToUser {
  firstName: string;
  lastName: string;
  initials: string;
  email: string;
}

export interface IGetDocumentRecordQuery {
  id: string;
  name: string;
  status: keyof typeof DOCUMENT_RECORD_STATUS_ENUM;
  documentType: string;
  statusUpdatedAt: string;
  note: string;
  createdAt: Date;
  updatedAt: Date;
  deletedAt: Date;
  assignee: User;
  publisher: User;
  owner: User;
  fields: IDocumentRecordFieldEntity[];
}

export interface IDocumentRecordFieldEntity {
  id: string;
  value: string;
  commentary: string;
  createdAt: Date;
  updatedAt: Date;
  deletedAt: Date;
  recordId: string;
  fieldId: string;
  labelerData: string;
  documentField: IDocumentFieldEntity;
  AI: AIAnswerType;
}

export interface IDocumentFieldEntity {
  id: string;
  name: string;
  type: string;
  order: number;
}

export enum DOCUMENT_RECORD_STATUS_ENUM {
  DRAFT = 'Draft',
  PUBLISHED = 'Published'
}
