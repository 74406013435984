import Label from '@/components/basicComponents/label';
import ModalWrappedContent from '@/components/fat-basicComponents/modal/modalWrappedContent';
import ErrorNotification from '@/components/recommendations-page/error-notification';
import { PROPOSAL_TYPE } from '@/types/investments';
import { ChangeEvent, useState } from 'react';
import styled from 'styled-components';

interface EditAmountProps {
  onClose: () => void;
  isOpen: boolean;
  row: any | null;
  updateAmountInState: (id: string, value: number) => void;
}

interface ErrorMessage {
  title: string;
  message: string;
}

const EditAmount = ({ onClose, isOpen, row, updateAmountInState }: EditAmountProps) => {
  const [value, setValue] = useState(row?.committedCapital.toLocaleString('en-US') ?? '');
  const [errorText, setErrorText] = useState<ErrorMessage | null>(null);
  const validateAmount = (amount: number, allocation: any) => {
    let errorMessage: ErrorMessage | null = null;
    if (value[0] === '0') {
      errorMessage = {
        title: 'Cannot be less than 0',
        message: 'Any increase investment value must be greater than 0'
      };
    }
    if (allocation.proposalType === PROPOSAL_TYPE.INITIAL) {
      if (amount < allocation.investment.minimum) {
        errorMessage = {
          title: 'Cannot be less than fund minimum',
          message: 'The initial investment amount must be equal or greater than the investment minimum.'
        };
      }
    }
    if (allocation.proposalType === PROPOSAL_TYPE.INCREASE) {
      if (amount <= 0) {
        errorMessage = {
          title: 'Cannot be less than 0',
          message: 'Any increase investment value must be greater than 0'
        };
      }
    }

    setErrorText(errorMessage);
    return errorMessage;
  };

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value.replace(/\D/g, '');
    if (!value) {
      setValue('');
      return;
    }
    const formattedValue = parseFloat(value).toLocaleString('en-US');

    setValue(formattedValue);
  };

  const saveValue = () => {
    const numberAmount = Number(value.replace(/\D/g, ''));
    const errorMessage = validateAmount(numberAmount, row);
    if (errorMessage) return;
    updateAmountInState(row.legalEntity.id, numberAmount);
    onClose();
    return;
  };

  if (!row) return <></>;

  return (
    <ModalWrappedContent
      isOpen={isOpen}
      onClose={onClose}
      title="Edit Investment Amount"
      confirmButton={{
        name: 'Save',
        onClick: saveValue,
        disabled: !value
      }}
    >
      {errorText && <ErrorNotification title={errorText?.title ?? ''} message={errorText?.message ?? ''} />}
      <Label required>{row.proposalType === 'Increase' ? 'Follow-on Investment Amount' : 'Initial Investment Amount'}</Label>
      <InputWrap>
        <Input isBackground={!!value} width="100%" type="text" value={value} onChange={handleInputChange} autoFocus />
        <Dollar isBackground={!!value}>$</Dollar>
      </InputWrap>
      <Description>{row.proposalType === 'Increase' ? '' : `Initial Investment minimum: $${row.investment.minimum}`}</Description>
    </ModalWrappedContent>
  );
};

export default EditAmount;

const Input = styled.input<{ width: string; isBackground: boolean }>`
  width: ${({ width }) => width};
  border-radius: 4px;
  border: ${({ isBackground, theme }) => (isBackground ? `1px solid ${theme.context.success}` : `1px solid  ${theme.border.base}`)};
  background-color: ${({ isBackground, theme }) => (isBackground ? '#efffea' : theme.layer[1])};
  color: ${({ isBackground, theme }) => (isBackground ? '' : theme.font.strong)};
  padding: 10px 20px;
  font-size: 16px;
  font-family: Blinker;
  font-weight: 400;

  &:focus {
    outline: none;
  }
`;

export const InputWrap = styled.div`
  position: relative;
`;

export const Dollar = styled.span<{ isBackground: boolean }>`
  position: absolute;
  top: 9px;
  left: 10px;
  color: ${({ isBackground, theme }) => (isBackground ? '' : theme.font.strong)};
`;

const Description = styled.span`
  padding-top: 10px;
  font-weight: 300;
  font-size: 13px;
  line-height: 18px;
  color: ${({ theme }) => theme.font.weak};
`;
