import { InputBase } from '@mui/material';
import Typography from '@/components/muis/typography';
import { styled } from 'styled-components';
import React from 'react';
import { useController } from 'react-hook-form';

import { WrapperHead } from '..';

interface FormFieldProps {
  title: string;
  nameField: string;
  placeHolder?: string;
  isWarning?: boolean;
  tooltip?: string;
  className?: string;
  control: any;
  rules: any;
  disabled?: boolean;
  onBlur?: any;
}

export const Input: React.FC<FormFieldProps> = ({
  title,
  nameField,
  placeHolder = '',
  className,
  isWarning,
  tooltip,
  control,
  rules,
  disabled = false,
  onBlur = () => {}
}) => {
  const {
    field,
    fieldState: { invalid },
    formState: { errors }
  } = useController({ name: nameField, control, rules, defaultValue: '' });
  return (
    <div style={{ width: '100%' }} className={className}>
      <WrapperHead title={title} isWarning={isWarning} tooltip={tooltip} />
      <CustomTextField
        valid={invalid.toString()}
        id="outlined-basic"
        placeholder={placeHolder}
        {...field}
        disabled={disabled}
        onBlur={onBlur}
      />
      {invalid && (
        <Typography sx={{ color: '#C60F0F' }}>
          {String(errors?.[nameField]?.message)}
        </Typography>
      )}
    </div>
  );
};

const CustomTextField = styled(InputBase)<{ valid: string }>`
  width: 100%;
  padding: 5px 20px;
  border-radius: 8px;
  background: ${(props) =>
    props.disabled
      ? '#DEDEDE'
      : props.valid === 'true'
        ? '#FFDFE5'
        : props.theme.primary.dark};
  border: ${(props) =>
    props.disabled
      ? '1px solid #C7CBD2'
      : props.valid === 'true'
        ? '3px solid #ED9DAB'
        : `2px solid ${props.theme.secondary.light}`};
`;
