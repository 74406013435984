import { InfoIcon } from '@/assets/icons/info-icons/info';
import { getUTCDate } from '@/components/allocations-page/utils';
import { Banner } from '@/components/basicComponents/banner';
import ModalWrappedContent from '@/components/fat-basicComponents/modal/modalWrappedContent';
import { Input, Select, ToggleCircle } from '@/components/fat-form-field';
import { DollarInput } from '@/components/fat-form-field/dollarInput';
import { PercentageInput } from '@/components/fat-form-field/percentageInput';
import { Loader } from '@/components/loaders/loader';
import { useMutation } from '@apollo/client';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';
import { autoPlanMutation } from '../../queries';

interface AutoPlanProps {
  onClose: () => void;
  isOpen: boolean;
  entityName: string;
}

interface ICreateAutoPlan {
  name: string;
  planStart: string;
  yearsToAchieve: string;
  biteSize: string;
  growthRate: string;
  includePendingCommitments: string;
  automaticallyGenerate: string;
}

const getDefaultPlanStart = (): string => {
  const today = new Date();
  const month = today.getMonth();
  const year = today.getFullYear();

  const quarterStartMonth = Math.floor(month / 3) * 3;
  const quarterEndMonth = quarterStartMonth + 2;

  const quarterStartDate = new Date(year, quarterStartMonth, 1);
  const quarterEndDate = new Date(year, quarterEndMonth + 1, 0);

  const quarterMidDate = new Date(quarterStartDate.getTime() + (quarterEndDate.getTime() - quarterStartDate.getTime()) / 2);

  if (today <= quarterMidDate) {
    return 'Today';
  }
  return 'Next Quarter';
};

const defaultFormValues: ICreateAutoPlan = {
  name: '',
  planStart: getDefaultPlanStart(),
  yearsToAchieve: '5',
  biteSize: '',
  growthRate: '',
  includePendingCommitments: 'Yes',
  automaticallyGenerate: 'Yes'
};

const AutoPlan = ({ onClose, isOpen, entityName }: AutoPlanProps) => {
  const params = useParams();
  const navigate = useNavigate();

  const {
    handleSubmit,
    getValues,
    control,
    reset,
    formState: { isValid }
  } = useForm<ICreateAutoPlan>({ defaultValues: defaultFormValues });

  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    reset({
      ...defaultFormValues,
      name: `Plan for ${entityName} ${getUTCDate(new Date()).toUpperCase()}`
    });
  }, [entityName]);

  const [createAutoPlan, { loading: createAutoPlanLoading, error: createAutoPlanError }] = useMutation(autoPlanMutation, {
    onError: (error) => {
      console.error(error);
    }
  });

  const onSave = () => {
    if (!isValid) return;

    const getStartDateValue = (planStart: string) => {
      switch (planStart) {
        case 'Today':
          return 'TODAY';
        case 'Next Quarter':
          return 'NEXT_QUARTER';
        default:
          break;
      }
    };

    const { name, biteSize, growthRate, planStart, automaticallyGenerate, yearsToAchieve } = getValues();
    setIsSubmitting(true);
    createAutoPlan({
      variables: {
        input: {
          legalEntityId: params.entityId,
          name: name.trim(),
          startDate: getStartDateValue(planStart),
          biteSize: Number(biteSize.replace(/\D/g, '')),
          growthRate: parseFloat(growthRate),
          yearsToAchieveAllocationTarget: parseInt(yearsToAchieve),
          generateAllocations: automaticallyGenerate === 'Yes'
        }
      },
      onCompleted: (data) => {
        navigate(`/investors/${params.investorId}/${params.entityId}/editCommitmentPlan?backToTitle=Investor Entity`, {
          state: { autoPlanData: JSON.stringify(data.autoPlan) }
        });
        setIsSubmitting(false);
      },
      onError: (error) => {
        setIsSubmitting(false);
        console.error(error);
      }
    });
  };

  const validatePercentage = (value: string) => {
    const numericValue = parseFloat(value.replace('%', ''));
    if (isNaN(numericValue)) {
      return 'Invalid number';
    }
    if (numericValue < 0) {
      return 'Value must be at least 0';
    }
    if (numericValue > 100) {
      return 'Value must be at most 100';
    }
    return true;
  };

  return (
    <CustomModalWrappedContent
      isOpen={isOpen}
      onClose={onClose}
      title={createAutoPlanLoading ? 'Creating Auto Plan' : 'Auto Plan'}
      showRequiredFields={!createAutoPlanLoading}
      showCloseIcon={!createAutoPlanLoading}
      showCancelButton={!createAutoPlanLoading}
      isUseOutsideClick={!createAutoPlanLoading}
      confirmButton={{
        name: 'Confirm',
        onClick: handleSubmit(onSave),
        disabled: isSubmitting
      }}
    >
      {createAutoPlanLoading ? (
        <LoaderWrapper>
          <ModalText>Please allow a few moment for this process to complete.</ModalText>
          <Loader />
        </LoaderWrapper>
      ) : (
        <ModalBody>
          {createAutoPlanError && (
            <Banner icon={<InfoIcon width={26} height={26} />} title="Error" description={createAutoPlanError.message} bgColor="#D63B4B" />
          )}
          <SubTitle>Automatically generate a commitment plan that achieves the allocation targets.</SubTitle>
          <Input
            title={'Name'}
            nameField={'name'}
            tooltipWidth={250}
            tooltip="You can create alternative plans to present to your client or for what-if scenarios."
            rules={{
              required: {
                value: true,
                message: 'Name is required'
              }
            }}
            control={control}
            isWarning={true}
          />
          <Select
            title={'Plan Start'}
            nameField={'planStart'}
            tooltipWidth={300}
            tooltip="By default, if the current calendar quarter is more than half over the commitment plan will start next quarter."
            control={control}
            rules={{
              required: true
            }}
            selects={['Today', 'Next Quarter']}
            isWarning={true}
            errorMessage="Plan Start is required"
          />
          <Select
            title={'Years to achieve allocation target'}
            nameField={'yearsToAchieve'}
            tooltipWidth={300}
            tooltip=""
            control={control}
            rules={{
              required: true
            }}
            selects={Array.from({ length: 10 }, (_, i) => (i + 1).toString())}
            isWarning={true}
            errorMessage="Years to achieve allocation target is required"
          />
          <DollarInput
            title="Bite Size"
            nameField="biteSize"
            control={control}
            isWarning
            rules={{
              required: {
                value: true,
                message: 'Bite Size is required'
              }
            }}
          />
          <PercentageInput
            title={'Growth Rate ex. Drawdowns'}
            nameField={'growthRate'}
            tooltipWidth={300}
            tooltip="The pacing model uses this number to project the growth in the non-drawdown portion of the portfolio."
            rules={{
              required: {
                value: true,
                message: 'Growth Rate ex. Drawdowns is required'
              },
              validate: validatePercentage
            }}
            control={control}
            isWarning={true}
          />
          {/* <ToggleCircle
          nameField="includePendingCommitments"
          control={control}
          label="Include Pending Commitments"
          tooltip="By default the pacing model includes any pending commitments as part of the investor’s starting portfolio."
          tooltipWidth={300}
        /> */}
          <ToggleCircle
            nameField="automaticallyGenerate"
            control={control}
            label="Automatically Generate Commitment Amounts"
            tooltip="By default a full commitment plan will be created as a starting point for your edits.  Otherwise, only existing commitments will be included in the pacing model forecasts and you will need to manually enter all future commitment amounts"
            tooltipWidth={300}
          />
        </ModalBody>
      )}
    </CustomModalWrappedContent>
  );
};

export default AutoPlan;

const CustomModalWrappedContent = styled(ModalWrappedContent)`
  padding: 85px 0 45px;
`;

const ModalBody = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
  height: 100%;
  max-height: 500px;
  overflow-y: auto;
  overflow-x: hidden;
  padding: 0 50px;
`;

const SubTitle = styled.p`
  text-align: center;
  color: ${({ theme }) => theme.font.base};
  font-size: 19px;
  font-weight: 400;
  line-height: 26.6px;
`;

const LoaderWrapper = styled.div`
  text-align: center;
`;

const ModalText = styled.div`
  display: inline-block;
  text-align: center;
  font-size: 19px;
  font-weight: 400;
  color: ${({ theme }) => theme.font.base};
`;
