import { ApprovedIcon } from '@/assets/icons/info-icons/approved';
import { ArrowDown } from '@/assets/icons/info-icons/arrowDown';
import { OverdueIcon } from '@/assets/icons/info-icons/overdue';
import { AddButtonIcon } from '@/assets/static/icons/add-button-icon';
import { ITab } from '@/components/basicComponents/fat-tabs';
import { Divider, FilterItems, PrimaryFilterItem, SearchInput, SearchWrap } from '@/components/basicComponents/select';
import { IManagerInvestment, IManagerInvestments } from '@/components/fat-activeInvestments-page/types';
import Button from '@/components/fat-basicComponents/button';
import { Investment } from '@/components/fat-dealCatalog-page/types';
import Header from '@/components/fat-header';
import { AdditionalOptionWrap, GoBackButton, GoBackButtonWrap } from '@/components/fat-header/goBackButton';
import { PageTitle } from '@/components/fat-header/pageTitle';
import { LoaderOnWholeScreen } from '@/components/loaders/loader-on-whole-screen';
import { initialValuesFromTable } from '@/components/table/constants';
import { IItems, ISort, SORT_ORDER } from '@/components/table/types';
import { useResponsive } from '@/hooks/use-responsive';
import { useClickOutside } from '@/hooks/useClickOutside';
import { useGoBack } from '@/hooks/useGoBack';
import { MainWrap, PaddingWrap } from '@/styles/common';
import { dateFormatter } from '@/utils/dateFormatter';
import guid from '@/utils/guid';
import { NetworkStatus, useQuery } from '@apollo/client';
import { format } from 'date-fns';
import { ChangeEvent, useRef, useState } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import styled, { useTheme } from 'styled-components';
import { isMoreThan90DaysInPast } from '../utils';
import { Details } from './details';
import AddUpdate from './modals/addUpdate';
import ChangeOwner from './modals/changeOwner';
import DeleteUpdate from './modals/deleteUpdate';
import { GET_DOCUMENT_RECORD_QUERY, INVESTMENT_DETAILS_QUERY, LIST_DOCUMENT_RECORDS_QUERY, MANAGER_INVESTMENTS_MIN_QUERY } from './queries';
import { AllUpdatesSection } from './sections/allUpdates';
import { LatestUpdateSection } from './sections/latestUpdate';
import { DOCUMENT_RECORD_STATUS_ENUM, IDocumentRecordEntity, IGetDocumentRecordQuery, IListDocumentRecordsQuery } from './types';

export const ManagerUpdateDetails = () => {
  const { isMobile, isTablet } = useResponsive();
  const navigate = useNavigate();
  const goBack = useGoBack();
  const theme = useTheme();
  const params = useParams();

  const [searchParams, setSearchParams] = useSearchParams();

  const backToTitle = searchParams.get('backToTitle') || '';
  const activeTab = searchParams.get('tab') || 'Updates';
  const key = searchParams.get('key') || 'NAME';
  const asc = searchParams.get('asc') === null || searchParams.get('asc') === 'true';
  const limit = searchParams.get('limit') || initialValuesFromTable.initialAmountRowsPerPage[0];
  const offset = searchParams.get('offset') || 0;
  const primaryFilter = searchParams.get('primaryFilter') || 'All';

  const [tabs, setTabs] = useState<ITab[]>([
    { value: 'Investors', amount: null, disabled: true },
    { value: 'Updates', amount: null }
  ]);
  const [isTabMenuOpen, setIsTabMenuOpen] = useState(false);
  const [initialStrategiesList, setInitialStrategiesList] = useState<IManagerInvestment[]>([]);
  const [strategiesList, setStrategiesList] = useState<IManagerInvestment[]>([]);
  const [isOpen, setIsOpen] = useState(false);
  const [showZeroState, setShowZeroState] = useState(true);
  const [updatesTableData, setUpdatesTableData] = useState<IDocumentRecordEntity[]>([]);
  const [isFirstLoading, setIsFirstLoading] = useState(true);
  const [managerId, setManagerId] = useState<string | null>(null);
  const [latestUpdateId, setLatestUpdateId] = useState<string | null>(null);
  const [search, setSearch] = useState('');
  const [currentRow, setCurrentRow] = useState<IDocumentRecordEntity | null>(null);
  const [modalWindow, setModalWindow] = useState({
    isOpen: false,
    type: 'add-update'
  });

  const ref = useRef<HTMLDivElement | null>(null);
  useClickOutside(ref, () => setIsOpen(false));

  useQuery<{ ManagerInvestments: IManagerInvestments }>(MANAGER_INVESTMENTS_MIN_QUERY, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'cache-and-network',
    skip: !managerId,
    variables: {
      managerId: managerId,
      sort: 'name',
      order: SORT_ORDER.ASC,
      rowsPerPage: 1000,
      page: 1
    },
    onCompleted: (data) => {
      const { investments: listInvestments } = data.ManagerInvestments;
      setStrategiesList(listInvestments);
      setInitialStrategiesList(listInvestments);
    },
    onError: (error) => {
      console.error(error);
    }
  });

  const { data: investmentData, loading: loadingInvestment } = useQuery<{ Investment: Investment }>(INVESTMENT_DETAILS_QUERY, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'cache-and-network',
    variables: { id: params?.strategyId },
    skip: !params?.strategyId,
    onCompleted: (data) => {
      setManagerId(data.Investment?.managerId ?? null);
    },
    onError: (error) => {
      console.error(error);
    }
  });

  const { refetch: latestUpdateRefetch, networkStatus: latestUpdateNetworkStatus } = useQuery<{ listDocumentRecords: IListDocumentRecordsQuery }>(
    LIST_DOCUMENT_RECORDS_QUERY,
    {
      notifyOnNetworkStatusChange: true,
      fetchPolicy: 'cache-and-network',
      skip: !params?.strategyId,
      variables: {
        data: {
          investmentId: params?.strategyId,
          sortBy: 'STATUS_UPDATED_DATE',
          sortDirection: SORT_ORDER.DESC,
          statusFilter: 'PUBLISHED',
          limit: 1
        }
      },
      onCompleted: (data) => {
        setLatestUpdateId(data.listDocumentRecords.records[0]?.id ?? null);
      },
      onError: (error) => {
        console.error(error);
      }
    }
  );

  const {
    data: documentRecordData,
    refetch: documentRecordRefetch,
    loading: documentRecordLoading,
    networkStatus: documentRecordNetworkStatus
  } = useQuery<{ getDocumentRecord: IGetDocumentRecordQuery }>(GET_DOCUMENT_RECORD_QUERY, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'cache-and-network',
    skip: !latestUpdateId,
    variables: {
      id: latestUpdateId
    },
    onError: (error) => {
      console.error(error);
    }
  });

  const {
    data: listDocumentRecordsData,
    refetch: listDocumentRecordsRefetch,
    loading: listDocumentRecordsLoading,
    networkStatus: listDocumentRecordsNetworkStatus
  } = useQuery<{ listDocumentRecords: IListDocumentRecordsQuery }>(LIST_DOCUMENT_RECORDS_QUERY, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'cache-and-network',
    skip: !params?.strategyId,
    variables: {
      data: {
        investmentId: params?.strategyId,
        sortBy: key,
        sortDirection: asc ? SORT_ORDER.ASC : SORT_ORDER.DESC,
        statusFilter:
          primaryFilter === 'All'
            ? undefined
            : Object.keys(DOCUMENT_RECORD_STATUS_ENUM).find(
                (key) => DOCUMENT_RECORD_STATUS_ENUM[key as keyof typeof DOCUMENT_RECORD_STATUS_ENUM] === primaryFilter
              ),
        limit: Number(limit),
        offset: Number(offset)
      }
    },
    onCompleted: (data) => {
      setUpdatesTableData(data.listDocumentRecords.records);
      setShowZeroState(!data.listDocumentRecords.records.length);
      setIsFirstLoading(false);
    },
    onError: (error) => {
      console.error(error);
      setIsFirstLoading(false);
    }
  });

  const onChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (initialStrategiesList) {
      const value = event.target.value;
      setSearch(value);
      const newList = [...initialStrategiesList];
      const filteredList = newList.filter((item) => item.name.toLowerCase().indexOf(event.target.value.toLowerCase()) !== -1);
      if (value === '' && filteredList.length === 0) {
        setStrategiesList(initialStrategiesList);
      } else {
        setStrategiesList(filteredList);
      }
    }
  };

  const handleSelectCLick = (event: any) => {
    if (event.target.id !== 'input') {
      setIsOpen(!isOpen);
    }
  };

  const onItemClick = (id: string) => {
    navigate(`/managerUpdates/${id}?backToTitle=${backToTitle}`, { replace: true });
  };

  const openModalWindow = (type: string, row?: IDocumentRecordEntity) => {
    setModalWindow({ ...modalWindow, isOpen: true, type });
    if (row) {
      setCurrentRow(row);
    }
  };

  const closeModalWindow = () => {
    setModalWindow({ ...modalWindow, isOpen: false });
    setCurrentRow(null);
  };

  const onChangeActiveTab = (value: string) => {
    setSearchParams(
      (prev) => {
        prev.set('tab', value);
        return prev;
      },
      { replace: true }
    );
  };

  const onChangeSort = (value: ISort) => {
    setSearchParams(
      (prev) => {
        prev.set('key', value.key);
        prev.set('asc', value.asc.toString());
        return prev;
      },
      { replace: true }
    );
  };

  const onChangePaginationData = (limit: number, offset: number) => {
    setSearchParams(
      (prev) => {
        prev.set('limit', limit.toString());
        prev.set('offset', offset.toString());
        return prev;
      },
      { replace: true }
    );
  };

  const onChangePrimaryFilter = (filter: string) => {
    setSearchParams(
      (prev) => {
        prev.set('primaryFilter', filter);
        prev.delete('limit');
        prev.delete('offset');
        return prev;
      },
      { replace: true }
    );
  };

  const openEditManagerUpdatePage = (updateId: string) => {
    navigate(`/managerUpdates/${params?.strategyId}/edit/${updateId}?backToTitle=Manager Update Details`);
  };

  const openModifyInvestmentPage = (id?: string) => {
    navigate(`/dealPipeline/modify?backToTitle=Manager Update Details&investment=${id}`, {
      state: {
        investment: id
      }
    });
  };

  const dropdownActions = (row: IDocumentRecordEntity): IItems[] => {
    return [
      {
        name: 'Open',
        onClick: () => openEditManagerUpdatePage(row.id)
      },
      {
        name: 'Download',
        onClick: () => {}
      },
      {
        name: 'Change Owner',
        onClick: () => openModalWindow('change-owner', row)
      },
      {
        name: 'Delete',
        onClick: () => openModalWindow('delete-update', row)
      }
    ];
  };

  if (
    isFirstLoading ||
    loadingInvestment ||
    latestUpdateNetworkStatus === NetworkStatus.loading ||
    latestUpdateNetworkStatus === NetworkStatus.setVariables ||
    documentRecordNetworkStatus === NetworkStatus.loading ||
    documentRecordNetworkStatus === NetworkStatus.setVariables
  ) {
    return <LoaderOnWholeScreen />;
  }

  return (
    <>
      {modalWindow.isOpen && modalWindow.type === 'add-update' && (
        <AddUpdate
          onClose={closeModalWindow}
          isOpen={modalWindow.isOpen}
          manager={investmentData?.Investment.managerId ?? null}
          openEditManagerUpdatePage={openEditManagerUpdatePage}
        />
      )}
      {modalWindow.isOpen && modalWindow.type === 'delete-update' && (
        <DeleteUpdate
          isOpen={modalWindow.isOpen}
          onClose={closeModalWindow}
          currentRow={currentRow}
          latestUpdateId={latestUpdateId}
          latestUpdateRefetch={latestUpdateRefetch}
          listDocumentRecordsRefetch={listDocumentRecordsRefetch}
        />
      )}
      {modalWindow.isOpen && modalWindow.type === 'change-owner' && (
        <ChangeOwner
          isOpen={modalWindow.isOpen}
          onClose={closeModalWindow}
          currentRow={currentRow}
          listDocumentRecordsRefetch={listDocumentRecordsRefetch}
        />
      )}
      <MainWrap>
        <Header
          modalControl={
            <GoBackButtonWrap>
              <GoBackButton
                handleClose={() => goBack({ fallBack: '/investors' })}
                backToTitle={backToTitle}
                withAdditionalOption={!!initialStrategiesList.length}
              />
              {initialStrategiesList.length ? (
                <AdditionalOptionWrap ref={ref} onClick={(event) => handleSelectCLick(event)}>
                  <ArrowDown fill={theme.font.action} />
                  <SelectWrap isMobile={isMobile} openZIndex={10} onClick={(event) => handleSelectCLick(event)} width={'200px'} isOpen={isOpen}>
                    <SearchWrap id="input">
                      <SearchInput id="input" placeholder={'Type Here To Filter Results'} value={search} onChange={onChange} />
                      <Divider />
                    </SearchWrap>
                    <>
                      <SelectItem key={guid()} onClick={() => {}}>
                        {investmentData?.Investment?.name ?? ''}
                      </SelectItem>
                    </>
                    {strategiesList?.map((el) => (
                      <SelectItem isActive={el.id === params?.strategyId} key={guid()} onClick={() => onItemClick(el.id)}>
                        {'- ' + el.name}
                      </SelectItem>
                    ))}
                  </SelectWrap>
                </AdditionalOptionWrap>
              ) : (
                <></>
              )}
            </GoBackButtonWrap>
          }
        />
        <PageTitle title={investmentData?.Investment?.name ?? ''} onEditClick={() => openModifyInvestmentPage(investmentData?.Investment.id ?? '')} />
        <Details
          data={investmentData?.Investment || null}
          tabs={tabs}
          activeTab={activeTab}
          setActiveTab={onChangeActiveTab}
          isTabMenuOpen={isTabMenuOpen}
          setIsTabMenuOpen={setIsTabMenuOpen}
        />
      </MainWrap>
      <MainWrap>
        <PaddingWrap>
          {showZeroState ? (
            <NoUpdates>
              <div>No manager updates have been logged for this investment.</div>
              <Button size="md" onClick={() => openModalWindow('add-update')} icon={<AddButtonIcon />}>
                Add New Update
              </Button>
            </NoUpdates>
          ) : (
            <>
              <StatusWrapper isMobile={isMobile}>
                {documentRecordData?.getDocumentRecord && (
                  <Status isMobile={isMobile}>
                    <div>
                      {isMoreThan90DaysInPast(documentRecordData.getDocumentRecord.statusUpdatedAt) ? (
                        <OverdueIcon style={{ position: 'relative', top: '2px' }} />
                      ) : (
                        <ApprovedIcon width={27} height={27} style={{ position: 'relative', top: '3px' }} />
                      )}
                    </div>
                    <SeparateStick />
                    <StatusText
                      isMoreThan90DaysInPast={isMoreThan90DaysInPast(documentRecordData.getDocumentRecord.statusUpdatedAt)}
                    >{`Latest Update Published By ${documentRecordData.getDocumentRecord?.publisher?.firstName ?? ''} ${
                      documentRecordData.getDocumentRecord?.publisher?.lastName ?? ''
                    } on ${dateFormatter(
                      format(new Date(documentRecordData.getDocumentRecord.statusUpdatedAt), 'yyyy-MM-dd'),
                      'yyyy-MM-dd'
                    )}`}</StatusText>
                  </Status>
                )}
                <AddNewUpdateButton size="md" onClick={() => openModalWindow('add-update')} icon={<AddButtonIcon />}>
                  Add New Update
                </AddNewUpdateButton>
              </StatusWrapper>
              <SectionsWrapper>
                <LatestUpdateSection
                  documentRecordData={documentRecordData?.getDocumentRecord ?? null}
                  openEditManagerUpdatePage={openEditManagerUpdatePage}
                />
                <AllUpdatesSection
                  updatesTableData={updatesTableData}
                  sort={{ key, asc }}
                  setSort={onChangeSort}
                  loading={
                    listDocumentRecordsNetworkStatus === NetworkStatus.loading || listDocumentRecordsNetworkStatus === NetworkStatus.setVariables
                  }
                  paginationValues={{ limit: Number(limit), offset: Number(offset), total: listDocumentRecordsData?.listDocumentRecords?.total ?? 0 }}
                  onChangePaginationData={onChangePaginationData}
                  listDocumentRecordsRefetch={listDocumentRecordsRefetch}
                  primaryFilter={primaryFilter}
                  onChangePrimaryFilter={onChangePrimaryFilter}
                  dropdownActions={dropdownActions}
                />
              </SectionsWrapper>
            </>
          )}
        </PaddingWrap>
      </MainWrap>
    </>
  );
};

const SelectWrap = styled(FilterItems)<{ isMobile: boolean; isOpen: boolean; width: string; openZIndex: number }>`
  width: 245px;
  top: 70px;
  left: ${({ isMobile }) => (isMobile ? '100px' : '130px')};
  overflow-x: hidden;
`;

const SelectItem = styled(PrimaryFilterItem)`
  padding: 11px 9.5px;
  font-size: 16px;
  font-weight: 600;
  color: ${({ theme }) => theme.action.primary};
`;

export const StatusWrapper = styled.div<{ isMobile: boolean }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: ${({ isMobile }) => (isMobile ? 'column' : 'row')};
  padding: 0 20px;
  margin: 30px 0;
`;

const Status = styled.div<{ isMobile: boolean }>`
  display: flex;
  align-items: center;
  justify-content: ${({ isMobile }) => (isMobile ? 'center' : 'space-between')};
  width: ${({ isMobile }) => (isMobile ? '100%' : 'auto')};
  gap: ${({ isMobile }) => (isMobile ? '5px' : '20px')};
`;

export const SeparateStick = styled.div`
  width: 1px;
  height: 33px;
  background-color: ${({ theme }) => theme.border.base};
`;

export const StatusText = styled.span<{ bold?: boolean; isMoreThan90DaysInPast?: boolean }>`
  font-weight: ${({ bold }) => (bold ? '700' : '600')};
  font-size: 16px;
  line-height: 22px;
  color: ${({ isMoreThan90DaysInPast, theme }) => (isMoreThan90DaysInPast ? theme.context.error : theme.font.base)};
`;

export const SectionsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const AddNewUpdateButton = styled(Button)`
  margin-left: auto;
`;

const NoUpdates = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-weight: 400;
  font-size: 16px;
  line-height: 22.4px;
  color: ${({ theme }) => theme.font.base};
  gap: 40px;
  margin-top: 40px;
`;
