import { ChangeEvent } from 'react';
import styled from 'styled-components';

interface RadioButtonProps {
  name: string;
  radioButtonText: string;
  selectedOption: string;
  handleRadioChange: (event: ChangeEvent<HTMLInputElement>) => void;
  icon?: JSX.Element;
  disabled?: boolean;
  showRadioButton?: boolean;
}

const RadioButton = ({
  name,
  radioButtonText,
  selectedOption,
  handleRadioChange,
  icon,
  disabled = false,
  showRadioButton = true
}: RadioButtonProps) => {
  return (
    <RadioButtonContainer disabled={disabled}>
      <Button
        name={name}
        value={radioButtonText}
        checked={showRadioButton ? selectedOption === radioButtonText : false}
        onChange={handleRadioChange}
      />
      {icon && <IconWrap>{icon}</IconWrap>}
      <RadioText>{radioButtonText}</RadioText>
    </RadioButtonContainer>
  );
};

const RadioButtonContainer = styled.label<{ disabled: boolean }>`
  display: flex;
  align-items: center;
  cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
  color: ${({ disabled, theme }) => (disabled ? theme.font.disabled : theme.font.base)};
  padding: 8px;
`;

const Button = styled.input.attrs({ type: 'radio' })`
  appearance: none;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 1px solid ${({ theme }) => theme.border.base};
  margin-right: 6px;
  outline: none;
  position: relative;

  &:after {
    content: '';
    display: block;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: ${({ theme }) => theme.font.base};
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    opacity: 0;
  }

  &:checked {
    border-color: ${({ theme }) => theme.border.base};

    &:after {
      opacity: 1;
    }
  }

  &:focus {
    box-shadow: 0 0 2px 2px rgba(55, 63, 78, 0.3);
  }
`;

const RadioText = styled.span`
  line-height: 22.4px;
`;

const IconWrap = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 5px;
`;

export default RadioButton;
