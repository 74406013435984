import { ApprovedIcon } from '@/assets/icons/info-icons/approved';
import { RejectedBannerIcon } from '@/assets/icons/info-icons/rejectedBanner';
import Label from '@/components/basicComponents/label';
import RadioButton from '@/components/basicComponents/radioButton';
import ModalWrappedContent from '@/components/fat-basicComponents/modal/modalWrappedContent';
import { useMutation } from '@apollo/client';
import { ChangeEvent, useState } from 'react';
import styled, { useTheme } from 'styled-components';
import { InputWrap } from '../fat-allocator-page/fat-modals/editAmount';
import { createInvestorInterests } from '../queries';
import { IInvestorInterestList, TypeWindow } from '../types';

interface AddToWatchlistProps {
  onClose: () => void;
  isOpen: boolean;
  currentRow: IInvestorInterestList | null;
  refetch: () => void;
  openPage: (id: string, type: TypeWindow) => void;
}

const radioButtonItems = ['Aggregate Commitment for Your Firm', 'Individual Investor Commitments'];

const AddToWatchlist = ({ onClose, isOpen, currentRow, refetch, openPage }: AddToWatchlistProps) => {
  const theme = useTheme();

  const [selectedOption, setSelectedOption] = useState(radioButtonItems[0]);
  const [taxableInvestorsValue, setTaxableInvestorsValue] = useState('');
  const [exemptInvestorsValue, setExemptInvestorsValue] = useState('');

  const [createInvestorInterest] = useMutation(createInvestorInterests, {
    onCompleted: () => {
      refetch();
    }
  });

  const parseToIntOrDefault = (stringValue: string): number => {
    const parsedInt = parseInt(stringValue);
    return isNaN(parsedInt) ? 0 : parsedInt;
  };

  const saveValue = () => {
    if (selectedOption === radioButtonItems[0]) {
      const taxableInvestorsNumberValue = parseToIntOrDefault(taxableInvestorsValue.replace(/\D/g, ''));
      const exemptInvestorsNumberValue = parseToIntOrDefault(exemptInvestorsValue.replace(/\D/g, ''));

      createInvestorInterest({
        variables: {
          data: [
            {
              investmentId: currentRow.investment.id,
              amount: taxableInvestorsNumberValue,
              exempt: false
            },
            {
              investmentId: currentRow.investment.id,
              amount: exemptInvestorsNumberValue,
              exempt: true
            }
          ]
        },
        onCompleted: () => {
          refetch();
          onClose();
        }
      });
      return;
    }
    if (selectedOption === radioButtonItems[1]) {
      openPage(currentRow.investment.id, 'seekingInterest');
      onClose();
    }
  };

  const handleTaxableInvestorsValueChange = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value.replace(/\D/g, '');
    if (!value) {
      setTaxableInvestorsValue('');
      return;
    }
    const formattedValue = parseFloat(value).toLocaleString('en-US');

    setTaxableInvestorsValue(formattedValue);
  };

  const handleExemptInvestorsValueChange = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value.replace(/\D/g, '');
    if (!value) {
      setExemptInvestorsValue('');
      return;
    }
    const formattedValue = parseFloat(value).toLocaleString('en-US');

    setExemptInvestorsValue(formattedValue);
  };

  const handleRadioChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSelectedOption(event.target.value);
  };

  if (!currentRow) return <></>;

  return (
    <ModalWrappedContent
      isOpen={isOpen}
      onClose={onClose}
      width="677px"
      confirmButton={{
        name: selectedOption === radioButtonItems[0] ? 'Confirm' : 'View Investors',
        onClick: saveValue,
        disabled: selectedOption === radioButtonItems[0] && !taxableInvestorsValue && !exemptInvestorsValue
      }}
    >
      <ModalContainer>
        <IconWrap>
          <ApprovedIcon width={60} height={60} fill={theme.context.success} />
        </IconWrap>
        <ModalTitel>Add to watchlist</ModalTitel>
        <ModalDescription>You will receive updates related to this manager.</ModalDescription>
        <ModalBody>
          <OptionsWrapper>
            Enter an estimated commitment:
            <RadioButtonsWrap>
              {radioButtonItems.map((item, index) => (
                <RadioButton
                  key={item + index}
                  name="editFunnel"
                  radioButtonText={item}
                  selectedOption={selectedOption}
                  handleRadioChange={handleRadioChange}
                />
              ))}
            </RadioButtonsWrap>
          </OptionsWrapper>
          <>
            {selectedOption === radioButtonItems[0] ? (
              <InputsWrap>
                <div>
                  <Label required={false} fontSize="16px">
                    Taxable Investors:
                  </Label>
                  <InputWrap>
                    <Input type="text" value={taxableInvestorsValue} onChange={handleTaxableInvestorsValueChange} />
                    <Dollar>$</Dollar>
                  </InputWrap>
                </div>
                <div>
                  <Label required={false} fontSize="16px">
                    Exempt Investors:
                  </Label>
                  <InputWrap>
                    <Input type="text" value={exemptInvestorsValue} onChange={handleExemptInvestorsValueChange} />
                    <Dollar>$</Dollar>
                  </InputWrap>
                </div>
              </InputsWrap>
            ) : (
              <BannerWrap>
                <BannerIconWrap>
                  <RejectedBannerIcon fill={theme.header.base} />
                </BannerIconWrap>
                <BannerText>Enter individual investor commitments on the next screen.</BannerText>
              </BannerWrap>
            )}
          </>
        </ModalBody>
      </ModalContainer>
    </ModalWrappedContent>
  );
};

export default AddToWatchlist;

const ModalContainer = styled.div`
  font-weight: 400;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const IconWrap = styled.div`
  display: flex;
  justify-content: center;
  justify-content: center;
`;

const ModalTitel = styled.div`
  font-size: 33px;
  text-align: center;
  color: ${({ theme }) => theme.font.strong};
`;

const ModalDescription = styled.div`
  font-size: 19px;
  line-height: 27px;
  text-align: center;
  color: ${({ theme }) => theme.font.base};
`;

const ModalBody = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 35px;
`;

const OptionsWrapper = styled.div`
  width: 85%;
  color: ${({ theme }) => theme.font.base};
  font-size: 16px;
  font-weight: 400;
  line-height: 22.4px;
`;

const RadioButtonsWrap = styled.label`
  display: flex;
  flex-direction: column;
  margin-top: 10px;
`;

const InputsWrap = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
`;

const Input = styled.input`
  width: 225px;
  border: 1px solid ${({ theme }) => theme.border.base};
  background-color: ${({ theme }) => theme.layer[1]};
  color: ${({ theme }) => theme.font.strong};
  border-radius: 4px;
  padding: 10px 20px;
  font-family: Blinker;
  font-size: 16px;
  font-weight: 300;
  line-height: 22.4px;

  &:focus {
    outline: none;
  }

  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  &::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;

const Dollar = styled.span`
  position: absolute;
  top: 10px;
  left: 10px;
  color: ${({ theme }) => theme.font.strong};
`;

const BannerWrap = styled.span`
  display: flex;
  align-items: center;
  gap: 20px;
  background-color: ${({ theme }) => theme.layer[1]};
  width: 60%;
  height: 76px;
  padding: 10px 20px;
`;

const BannerIconWrap = styled.span`
  display: flex;
  align-items: center;
  height: 100%;
  padding-right: 20px;
  border-right: 1px solid ${({ theme }) => theme.border.base};
`;

const BannerText = styled.span`
  font-size: 13px;
  font-weight: 400;
  line-height: 18.2px;
  color: ${({ theme }) => theme.font.base};
`;
