import { gql } from '@apollo/client';

export const LIST_DUE_DILIGENCE_RECORD = gql`
  query listDueDiligenceRecords($data: DueDiligenceRecordsListDto!) {
    listDueDiligenceRecords(data: $data) {
      total
      limit
      offset
      records {
        id
        investment {
          id
          name
          assetClass
          managerId
          subAssetClasses
          securityStatus
          finalClose
          targetIRR
          targetYield
          tags
        }
        owner {
          id
          firstName
          lastName
          email
        }
        manager
        activeTier {
          id
          name
          order
          tasksCount
          funnel {
            id
            funnelStaticId
            name
            latestFunnel {
              name
              tiers {
                id
                name
                order
              }
            }
          }
        }
        activeTierOpenTasksCount
        approver {
          id
          firstName
          lastName
        }
        updatedAt
        createdAt
        activeTierOpenTasksCount
        status
        dealAssistantAIRuns {
          startedAt
          completedAt
        }
      }
    }
  }
`;

export const DELETE_DUE_DILIGENCE_RECORD_MUTATION = gql`
  mutation deleteDueDiligenceRecord($id: String!) {
    deleteDueDiligenceRecord(id: $id)
  }
`;

export const LIST_DUE_DILIGENCE_FUNNELS_DISTINCT_STATIC_ID_QUERY = gql`
  query listDueDiligenceFunnelsDistinctStaticId {
    listDueDiligenceFunnelsDistinctStaticId {
      id
      funnelStaticId
      name
      createdAt
      status
      records {
        id
        investment {
          name
          securityStatus
        }
      }
    }
  }
`;
