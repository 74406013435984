import { gql } from '@apollo/client';

export const createLegalEntityMutation = gql`
  mutation createLegalEntity($data: LegalEntityCreateDto!) {
    createLegalEntity(data: $data) {
      id
      family {
        id
      }
    }
  }
`;

export const updateLegalEntityMutation = gql`
  mutation updateLegalEntity($data: LegalEntityUpdateDto!) {
    updateLegalEntity(data: $data) {
      id
    }
  }
`;

export const GET_ASSET_CLASSES = gql`
  query AssetClasses($tenantId: String) {
    assetClasses(tenantId: $tenantId) {
      id
      name
      defaultAllocationPercentage
      illiquid
      subAssetClasses {
        id
        name
        defaultAllocationPercentage
        illiquid
      }
    }
  }
`;

export const GET_LEGAL_ENTITY = gql`
  query LegalEntity($id: String!) {
    legalEntity(id: $id) {
      entityName
      ignoreAllocationTargets
      ignoreBiteSize
      sectorsToAvoid
      taxExempt
      onHold
      defaultBiteSize1
      defaultBiteSize2
      overCommitRatio
      privatePercentage
      totalEntityValue
      advisorReportingAccountNumber
      advisorCRMId
      administratorCode
      tenant {
        id
      }
      allocationTargets {
        id
        percentage
        assetClass {
          id
          name
          subAssetClass {
            id
            name
          }
        }
      }
    }
  }
`;
