import { DECISION_STATUS, SUITABILITY_STATUS } from '@/components/allocations-page/constants';
import { Mode, ModeType } from '@/components/allocations-page/types';
import { CounterTitle, MainTabsMobile, TabsDetailsTitleMobile, TabsWrapMobile } from '@/components/basicComponents/fat-tabs';
import { IFilterBarData } from '@/components/basicComponents/filterBar';
import Header from '@/components/fat-header';
import { GoBackButton } from '@/components/fat-header/goBackButton';
import { PageTitle } from '@/components/fat-header/pageTitle';
import EntityPopover from '@/components/fat-pendingInvestments-page/fat-table/popovers/entity';
import { LoaderOnWholeScreen } from '@/components/loaders/loader-on-whole-screen';
import MobileModal from '@/components/mobileModal';
import { ADVISORS_QUERY } from '@/components/opportunities-entity/constants';
import { createAllocationMutation } from '@/components/opportunities-entity/pendingAllocation/constants';
import SuitabilityDetails from '@/components/opportunities-entity/pendingAllocation/suitabilityDetails';
import FilterPage from '@/components/table/fat-filterPage';
import { AdditionalFilter, AllocationsFiltersStructure, FiltersItems } from '@/components/table/filterPage/types';
import { createNewFilter } from '@/components/table/filterPage/utils';
import { IAdvisor, ISort, SORT_ORDER } from '@/components/table/types';
import { useResponsive } from '@/hooks/use-responsive';
import { useGoBack } from '@/hooks/useGoBack';
import { MainWrap } from '@/styles/common';
import { SELECTORS } from '@/types/enums';
import guid from '@/utils/guid';
import { useMutation, useQuery } from '@apollo/client';
import { useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import styled from 'styled-components';
import { Details } from '../fat-opportunityDetails-page/details';
import { OfferingMaterials } from '../fat-opportunityDetails-page/offeringMaterials';
import { Investment } from '../types';
import { initialFilterBarValues } from './constatnts';
import EditAmount from './fat-modals/editAmount';
import SubmitTo from './fat-modals/submitTo';
import Table from './fat-table/table';
import FooterModal from './footer';
import { POTENTIAL_ALLOCATION_QUERY } from './queries';
import { IAllocationRow, IPotentialAllocationQuery } from './types';

export const AllocatorPage = () => {
  const navigate = useNavigate();
  const goBack = useGoBack();
  const params = useParams();
  const [searchParams, setSearchParams] = useSearchParams();

  const backToTitle = searchParams.get('backToTitle') || '';
  const activeTab = searchParams.get('tab') || 'Begin Allocation';
  const q = searchParams.get('q') || '';
  const filterBarParams = searchParams.get('filterBar');
  const key = searchParams.get('key') || 'entity';
  const asc = searchParams.get('asc') === null || searchParams.get('asc') === 'true';
  const position = searchParams.get('position') || 0;

  const { isMobile, isTablet } = useResponsive();

  const [tabs, setTabs] = useState<{ value: string; amount: null | number }[]>([
    { value: 'Offering Materials', amount: null },
    { value: 'Begin Allocation', amount: null }
  ]);
  const [filterBar, setFilterBar] = useState<IFilterBarData[]>(initialFilterBarValues);
  const [additionalFilters, setAdditionalFilters] = useState<AdditionalFilter[]>([createNewFilter('Select A Filter Option')]);
  const [filtersItems, setFiltersItems] = useState<FiltersItems>({
    advisors: [SELECTORS.ALL_ADVISORS],
    suitability: [SELECTORS.ALL_SUITABILITY, ...Object.values(SUITABILITY_STATUS)],
    decision: [SELECTORS.ANY_DECISION, ...Object.values(DECISION_STATUS)]
  });
  const [isTabMenuOpen, setIsTabMenuOpen] = useState(false);
  const [mode, setMode] = useState<ModeType>(Mode.VIEW);
  const [modalWindow, setModalWindow] = useState({
    isOpen: false,
    type: 'submit-to'
  });
  const [detailsData, setDetailsData] = useState<Investment | null>(null);
  const [potentialAllocationData, setPotentialAllocationData] = useState<IAllocationRow[]>([]);
  const [currentRow, setCurrentRow] = useState<IAllocationRow | null>(null);
  const [isFirstLoading, setIsFirstLoading] = useState(true);
  const [isSuitabilityOpen, setIsSuitabilityOpen] = useState(false);
  const [suitabilityData, setSuitabilityData] = useState<IAllocationRow>();
  const [isEntityOpen, setIsEntityOpen] = useState(false);
  const [entityData, setEntityData] = useState<IAllocationRow>();
  const [changedAllocations, setChangedAllocations] = useState<IAllocationRow[]>([]);

  const [createAllocations] = useMutation(createAllocationMutation);

  useEffect(() => {
    if (!filterBarParams) return;

    const matchedFilterBarValues = initialFilterBarValues.map((item) => {
      const matchedFilterItem = JSON.parse(filterBarParams).find((filterItem: string) => filterItem === item.value);

      return {
        ...item,
        enabled: Boolean(matchedFilterItem)
      };
    });
    setFilterBar(matchedFilterBarValues);
  }, []);

  const unitedFilters = additionalFilters.reduce<AllocationsFiltersStructure>(
    (acc, { selected, operator, typeFilter }) => {
      return acc[typeFilter as keyof AllocationsFiltersStructure]
        ? {
            ...acc,
            [typeFilter]: [
              ...acc[typeFilter as keyof AllocationsFiltersStructure],
              {
                selected,
                operator
              }
            ]
          }
        : acc;
    },
    {
      advisors: [],
      suitability: [],
      decision: []
    }
  );

  useQuery<{ Advisors: IAdvisor[] }>(ADVISORS_QUERY, {
    onCompleted: (data) =>
      setFiltersItems({
        ...filtersItems,
        advisors: [SELECTORS.ALL_ADVISORS, ...data.Advisors.map(({ initials }) => initials)]
      })
  });

  const {
    data,
    loading: potentialAllocationLoading,
    refetch: potentialAllocationRefetch
  } = useQuery<IPotentialAllocationQuery>(POTENTIAL_ALLOCATION_QUERY, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'no-cache',
    skip: !params?.id,
    variables: {
      investmentId: params?.id,
      search: q.toLowerCase().trim(),
      sort: key,
      order: asc ? SORT_ORDER.ASC : SORT_ORDER.DESC,
      allocationStatusFilter: filterBar.every((el: { enabled: boolean }) => !el.enabled)
        ? []
        : {
            initialAllocation: filterBar.find((filter) => filter.value === 'initialAllocation')?.enabled,
            alreadyCommitted: filterBar.find((filter) => filter.value === 'alreadyCommitted')?.enabled,
            internalReviewRejections: filterBar.find((filter) => filter.value === 'internalReviewRejections')?.enabled,
            terminated: filterBar.find((filter) => filter.value === 'terminated')?.enabled
          },
      additionalAdvisorFilters: unitedFilters.advisors,
      additionalDecisionFilters: unitedFilters.decision,
      additionalSuitabilityFilters: unitedFilters.suitability
    },
    onCompleted: (data) => {
      const { investment, total, allocations, filters } = data.PotentialAllocations;

      setDetailsData(investment);
      setFilterBar(filters);

      setTabs([
        ...(investment.offeringMaterialsAttachments.length || investment.offeringMaterialsDescription
          ? [{ value: 'Offering Materials', amount: null }]
          : []),
        { value: 'Begin Allocation', amount: total }
      ]);

      const updatedAllocationData = allocations.map((allocation: IAllocationRow) => {
        const changedAllocation = changedAllocations.find((changedAllocation) => changedAllocation.legalEntity.id === allocation.legalEntity.id);
        if (changedAllocation) {
          return changedAllocation;
        }
        return {
          ...allocation,
          checked: false,
          decisionReason: allocation.decision ? 'Yes' : 'No'
        };
      });
      setPotentialAllocationData(updatedAllocationData);
      setIsFirstLoading(false);
      makeScroll(position);
    },
    onError: () => {
      setIsFirstLoading(false);
    }
  });

  useEffect(() => {
    if (potentialAllocationData.length) {
      setIsFirstLoading(false);
    }
  }, [potentialAllocationData]);

  const selectRow = (row: IAllocationRow) => {
    setPotentialAllocationData((prevList) => {
      const updatedList = prevList.map((item) => (item.legalEntity.id === row.legalEntity.id ? { ...item, checked: !item.checked } : item));
      const isAllocationChanged = changedAllocations.some((changedAllocationRow) => changedAllocationRow.legalEntity?.id === row.legalEntity?.id);

      const updatedChosenRows = isAllocationChanged
        ? changedAllocations.map((item) => (item.legalEntity.id === row.legalEntity.id ? { ...item, checked: !item.checked } : item))
        : [...changedAllocations, { ...row, checked: !row.checked }];

      setChangedAllocations(updatedChosenRows);

      return updatedList;
    });
  };

  const selectAllRows = (isChecked: boolean) => {
    setPotentialAllocationData((prevList) => {
      const updatedList = prevList.map((item) => ({ ...item, checked: isChecked }));
      const updatedChangedRows = prevList.map((item) => {
        const currentChangedAllocation = changedAllocations.find(
          (changedAllocationRow) => changedAllocationRow.legalEntity?.id === item.legalEntity?.id
        );

        if (currentChangedAllocation) {
          return {
            ...currentChangedAllocation,
            checked: isChecked
          };
        }

        return { ...item, checked: isChecked };
      });
      setChangedAllocations(updatedChangedRows);

      return updatedList;
    });
  };

  const updateAmountInState = (id: string, value: number) => {
    setPotentialAllocationData((prevChosenRows) => {
      return prevChosenRows.map((row) =>
        row.legalEntity?.id === id
          ? {
              ...row,
              committedCapital: value
            }
          : row
      );
    });

    const currentRow = potentialAllocationData.find((row) => row.legalEntity?.id === id);
    const isAllocationChanged = changedAllocations.some((row) => row.legalEntity?.id === currentRow.legalEntity?.id);

    setChangedAllocations(
      isAllocationChanged
        ? changedAllocations.map((row) => (row.legalEntity?.id === currentRow.legalEntity?.id ? { ...currentRow, committedCapital: value } : row))
        : [...changedAllocations, { ...currentRow, committedCapital: value }]
    );
  };

  const updateDecisionReason = (id: string, value: string) => {
    const currentRow = potentialAllocationData.find((row) => row.legalEntity?.id === id);

    if (currentRow && currentRow.decisionReason === value) return;
    const updatedCurrentRow = {
      ...currentRow,
      decisionReason: value,
      decision: (value as string).split(' - ')[0] === 'Yes',
      reason: (value as string).split(' - ')[1]
    };

    const isAllocationChanged = changedAllocations.some((row) => row.legalEntity?.id === updatedCurrentRow.legalEntity?.id);

    setPotentialAllocationData(potentialAllocationData.map((row) => (row.legalEntity?.id === id ? updatedCurrentRow : row)));
    setChangedAllocations(
      isAllocationChanged
        ? changedAllocations.map((row) => (row.legalEntity?.id === updatedCurrentRow.legalEntity?.id ? updatedCurrentRow : row))
        : [...changedAllocations, updatedCurrentRow]
    );
  };

  const openModalWindow = (type: string, row?: IAllocationRow) => {
    setModalWindow({ type, isOpen: true });
    if (row) {
      setCurrentRow(row);
    }
  };

  const closeModalWindow = () => {
    setModalWindow({ ...modalWindow, isOpen: false });
    setCurrentRow(null);
  };

  const openMobileModal = (data: IAllocationRow, modalType: string) => {
    if (modalType === 'suitability') {
      setIsSuitabilityOpen(true);
      setSuitabilityData(data);
    }
    if (modalType === 'entity') {
      setIsEntityOpen(true);
      setEntityData(data);
    }
  };

  const createDataToUpdate = () => {
    const chosenRows = changedAllocations.filter((row) => row.checked);
    return chosenRows.map((row) => ({
      ...(row.id && { id: row.id }),
      investmentId: row.investment.id,
      legalEntityId: row.legalEntity?.id,
      committedCapital: row.committedCapital,
      proposalType: row.proposalType,
      decision: row.decision,
      reason: row.reason,
      suitability: row.suitability,
      allocationStatus: row.decision ? 'Outstanding' : 'Internal Review Rejected',
      ...(row.selectedGroupLegalEntity && {
        selectedGroupLegalEntityId: row.selectedGroupLegalEntity.id
      })
    }));
  };

  const updateAllocationRequest = () => {
    const data = createDataToUpdate();
    if (!data.length) return;
    createAllocations({
      variables: {
        data
      },
      onCompleted: () => {
        setPotentialAllocationData([]);
        setChangedAllocations([]);
        potentialAllocationRefetch();
      }
    });
  };

  const getSummary = () => {
    const initialSummary = {
      count: 0,
      yesCount: 0,
      totalAmount: 0
    };
    let footerData = undefined;

    if (data) {
      footerData = {
        count: potentialAllocationData.filter((allocation) => allocation.editable).length,
        yesCount: potentialAllocationData.filter((allocation) => allocation.decision).length,
        totalAmount: potentialAllocationData
          .filter((allocation) => allocation.decision)
          .reduce((sum: number, allocation) => sum + allocation.committedCapital, 0)
      };
    }

    return footerData || initialSummary;
  };

  const changeTab = (value: string) => {
    onChangeActiveTab(value);
    setIsTabMenuOpen(false);
  };

  const openEntityDetails = (investorId: string, entityId: string) => {
    saveScrollPostion();
    navigate(`/investors/${investorId}/${entityId}?backToTitle=Allocator`);
  };

  const openInvestorDetails = (investorId: string) => {
    saveScrollPostion();
    navigate(`/investors/${investorId}?backToTitle=Allocator`);
  };

  const onChangeSearch = (searchValue: string) => {
    setSearchParams(
      (prev) => {
        if (!searchValue) {
          prev.delete('q');
          return prev;
        }
        prev.set('q', searchValue);
        return prev;
      },
      { replace: true }
    );
  };

  const onChangeFilterBar = (filterBarItems: IFilterBarData[]) => {
    saveScrollPostion();
    setFilterBar(filterBarItems);
    const filterBarValues = filterBarItems.filter((item) => item.enabled).map((item) => item.value);
    setSearchParams(
      (prev) => {
        if (!filterBarValues.length) {
          prev.delete('filterBar');
          return prev;
        }
        prev.set('filterBar', JSON.stringify(filterBarValues));
        return prev;
      },
      { replace: true }
    );
  };

  const onChangeActiveTab = (value: string) => {
    setSearchParams(
      (prev) => {
        prev.set('tab', value);
        return prev;
      },
      { replace: true }
    );
  };

  const onChangeSort = (value: ISort) => {
    setSearchParams(
      (prev) => {
        prev.set('key', value.key);
        prev.set('asc', value.asc.toString());
        return prev;
      },
      { replace: true }
    );
  };

  const saveScrollPostion = () => {
    setSearchParams(
      (prev) => {
        prev.set('position', window.scrollY.toString());
        return prev;
      },
      { replace: true }
    );
  };

  const makeScroll = (position: string | number) => {
    if (!position) return;
    setTimeout(() => window.scrollTo({ top: Number(position), behavior: 'smooth' }), 0);
    setSearchParams(
      (prev) => {
        prev.delete('position');
        return prev;
      },
      { replace: true }
    );
  };

  const getSelectedRows = useMemo(() => {
    return changedAllocations.filter((row) => row.checked);
  }, [changedAllocations]);

  if (isFirstLoading) {
    return <LoaderOnWholeScreen size={60} />;
  }

  return (
    <>
      {isSuitabilityOpen && suitabilityData && (
        <MobileModal
          title={suitabilityData.suitability}
          onClose={() => {
            setIsSuitabilityOpen(false);
          }}
          onClick={() => null}
        >
          <SuitabilityDetails data={suitabilityData.suitabilityCriteria} key={guid()} />
        </MobileModal>
      )}
      {isEntityOpen && entityData && (
        <MobileModal
          title={entityData.legalEntity?.entityName}
          onClose={() => {
            setIsEntityOpen(false);
          }}
          onClick={() => openEntityDetails(entityData.legalEntity?.family.id, entityData.legalEntity?.id)}
        >
          <EntityPopover
            tooltipContent={`${entityData.investment.assetClass} corresponds to\n${entityData.investment.name}.`}
            id={entityData.id}
            allocation={entityData}
            type={entityData.investment.type.toString()}
            alwaysShow={entityData.legalEntity.alwaysShow}
          />
        </MobileModal>
      )}
      {modalWindow.type === 'submit-to' && (
        <SubmitTo
          isOpen={modalWindow.isOpen}
          onClose={closeModalWindow}
          changedAllocations={changedAllocations}
          updateAllocationRequest={updateAllocationRequest}
        />
      )}
      {modalWindow.type === 'edit-amount' && (
        <EditAmount isOpen={modalWindow.isOpen} onClose={closeModalWindow} row={currentRow} updateAmountInState={updateAmountInState} />
      )}
      <MainWrap>
        <Header modalControl={<GoBackButton handleClose={() => goBack({ fallBack: '/dealCatalog' })} backToTitle={backToTitle} />} />
        <PageTitle title={detailsData?.name ?? ''} />
        <Details
          tabs={tabs}
          activeTab={activeTab}
          setActiveTab={onChangeActiveTab}
          isTabMenuOpen={isTabMenuOpen}
          setIsTabMenuOpen={setIsTabMenuOpen}
          detailsData={detailsData}
        />
      </MainWrap>
      <MainWrap>
        <PaddingWrap>
          {isTabMenuOpen && isMobile && (
            <MainTabsMobile>
              {tabs.map((tab, tabId) => (
                <TabsWrapMobile key={tab.value + tabId} onClick={() => changeTab(tab.value)} isActive={activeTab === tab.value}>
                  {tab.amount && <CounterTitle isActive={activeTab === tab.value}>{tab.amount}</CounterTitle>}
                  <TabsDetailsTitleMobile isActive={activeTab === tab.value}>{tab.value}</TabsDetailsTitleMobile>
                </TabsWrapMobile>
              ))}
            </MainTabsMobile>
          )}
          {!isTabMenuOpen && (
            <>
              {activeTab === 'Offering Materials' && (
                <OfferingMaterials
                  attachments={detailsData?.offeringMaterialsAttachments ?? []}
                  description={detailsData?.offeringMaterialsDescription ?? ''}
                />
              )}
              {activeTab === 'Begin Allocation' && (
                <>
                  <CustomFilterPage
                    isMobile={isMobile}
                    isTablet={isTablet}
                    search={{
                      value: q,
                      onChange: onChangeSearch,
                      placeholder: 'Search for an investor'
                    }}
                    isResult
                    // additionalFilters={{
                    //   filtersItems,
                    //   filters: additionalFilters,
                    //   applyFilters: setAdditionalFilters,
                    //   amountResults: data?.PotentialAllocations.total ?? 0,
                    //   showChosenFilters: false,
                    //   onRefetch: potentialAllocationRefetch,
                    // }}
                    resultsValue={data?.PotentialAllocations.total ?? 0}
                    refetch={potentialAllocationRefetch}
                    filterBar={filterBar}
                    onChangeFilterBar={onChangeFilterBar}
                  />
                  <Table
                    sort={{ key, asc }}
                    setSort={onChangeSort}
                    potentialAllocationData={potentialAllocationData}
                    refetch={() => null}
                    loading={potentialAllocationLoading}
                    mode={mode}
                    selectAllRows={selectAllRows}
                    selectRow={selectRow}
                    openModalWindow={openModalWindow}
                    updateDecisionReason={updateDecisionReason}
                    openMobileModal={openMobileModal}
                    openInvestorDetails={openInvestorDetails}
                    openEntityDetails={openEntityDetails}
                  />
                </>
              )}
            </>
          )}
        </PaddingWrap>
      </MainWrap>
      {!isMobile && activeTab === 'Begin Allocation' && (
        <FooterWrap>
          <FooterModal mode={mode} setMode={setMode} openModalWindow={openModalWindow} summary={getSummary()} selectedRows={getSelectedRows} />
        </FooterWrap>
      )}
    </>
  );
};

const PaddingWrap = styled.div`
  margin: 20px 0;
  padding: 0 16px;
  @media (min-width: 600px) {
    padding: 0 60px;
  }
`;

const FooterWrap = styled.div`
  margin-top: 150px;
`;

const CustomFilterPage = styled(FilterPage)<{ isMobile: boolean; isTablet: boolean }>`
  position: sticky;
  top: ${({ isMobile, isTablet }) => (isMobile || isTablet ? '50px' : '0')};
  z-index: 9;
  margin-top: 0;
  margin-left: -1px;
  width: calc(100% + 2px);
  padding: ${({ isMobile }) => (isMobile ? '20px 0 0 0' : '20px 0 10px 0')};
`;
