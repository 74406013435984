import FormattingTooltip from '@/components/basicComponents/tooltip/dollarsTooltip';
import NoResults from '@/components/table/noResults';
import { TableRowCell, TableRowWrapper, TextCell } from '@/components/table/styles';
import guid from '@/utils/guid';
import styled from 'styled-components';
import { CommitmentForecastValueDto } from '../../../types';

interface TableBodyProps {
  tableData: CommitmentForecastValueDto[];
  columnWidth: number;
}

const TableBody = ({ tableData, columnWidth }: TableBodyProps) => {
  if (!tableData?.length) {
    return <NoResults />;
  }

  return (
    <TableBodyWrapper>
      {tableData.map((row) => (
        <TableRowWrapper key={guid()}>
          <TableRowCell width={15}>
            <TextCell>{row.name}</TextCell>
          </TableRowCell>
          {row.data.map((item) => (
            <TableRowCell key={item.date} width={columnWidth}>
              <TextCell>
                <FormattingTooltip zIndex={1000}>{item.value}</FormattingTooltip>
              </TextCell>
            </TableRowCell>
          ))}
        </TableRowWrapper>
      ))}
    </TableBodyWrapper>
  );
};

const TableBodyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

export default TableBody;
