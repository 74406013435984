import { Box, InputBase } from '@mui/material';
import { styled } from '@mui/material/styles';
import React, { Dispatch, SetStateAction, useState } from 'react';

import { ISelect } from '../../../types/opportunity/modify-opportunity';
import { ButtonProps } from '../../buttons';

interface AddSelectsProps {
  selects: ISelect[];
  setSearchValue: Dispatch<SetStateAction<string>>;
  searchValue: string;
  setIsAdding: Dispatch<SetStateAction<boolean>>;
  updateSelects: (value: ISelect[]) => void;
}

export const AddSelect: React.FC<AddSelectsProps> = ({ selects, setSearchValue, setIsAdding, searchValue, updateSelects }) => {
  const [value, setValue] = useState(searchValue);
  const backToList = () => {
    setIsAdding(false);
    setSearchValue('');
  };
  const createStrategy = () => {
    if (!value) return;
    updateSelects([{ text: value, checked: false }, ...selects]);
    backToList();
  };
  return (
    <Box sx={{ padding: 3 }}>
      <CustomInput placeholder={'Search managers'} value={value} onChange={event => setValue(event.target.value)} />
      <Box sx={{ display: 'flex', justifyContent: 'center' }}>
        <CustomButtonProps label={'Create Strategy'} variant={'contained'} type="submit" onClick={createStrategy} />
      </Box>
      <CancelButton onClick={backToList}>Cancel</CancelButton>
    </Box>
  );
};

const CustomInput = styled(InputBase)(() => ({
  width: '100%',
  padding: '8px 13px',
  background: '#ffffff',
  boxShadow: '0px 8px 16px rgba(0, 0, 0, 0.04), 0px 2px 8px rgba(0, 0, 0, 0.03), 0px 1.12694px 2.75474px rgba(0, 0, 0, 0.01)',
  borderRadius: 8,
}));
const CustomButtonProps = styled(ButtonProps)(() => ({
  margin: '60px 0 20px',
}));
const CancelButton = styled('span')(() => ({
  padding: '5px 20px',
  color: '#2A2E3F',
  textDecoration: 'underline',
  textAlign: 'center',
  cursor: 'pointer',
}));
