import Label from '@/components/basicComponents/label';
import ModalWrappedContent from '@/components/fat-basicComponents/modal/modalWrappedContent';
import ErrorNotification from '@/components/recommendations-page/error-notification';
import { PROPOSAL_TYPE } from '@/types/investments';
import { ChangeEvent, useState } from 'react';
import styled from 'styled-components';
import { IRowData } from '../types';

interface EditAmountProps {
  onClose: () => void;
  isOpen: boolean;
  updateAllocationAmount: (variables: any) => void;
  allocation: IRowData | null;
}

interface ErrorMessage {
  title: string;
  message: string;
}

const EditAmount = ({ onClose, isOpen, updateAllocationAmount, allocation }: EditAmountProps) => {
  if (!allocation) return <></>;

  const [value, setValue] = useState(allocation.committedCapital.toString());
  const [errorText, setErrorText] = useState<ErrorMessage | null>(null);

  const validateAmount = (amount: number, allocation: IRowData) => {
    let errorMessage: ErrorMessage | null = null;

    if (allocation.proposalType === PROPOSAL_TYPE.INITIAL) {
      if (amount < allocation.investment.minimum) {
        errorMessage = {
          title: 'Cannot be less than fund minimum',
          message: 'The initial investment amount must be equal or greater than the investment minimum.'
        };
      }
    } else if (allocation.proposalType === PROPOSAL_TYPE.INCREASE) {
      if (amount <= 0) {
        errorMessage = {
          title: 'Cannot be less than 0',
          message: 'Any increase investment value must be greater than 0'
        };
      }
    }
    setErrorText(errorMessage);
    return errorMessage;
  };

  const saveValue = () => {
    const errorMessage = validateAmount(Number(value), allocation);
    if (errorMessage) return;
    updateAllocationAmount({
      variables: {
        data: {
          id: allocation.id,
          amount: Number(value)
        }
      }
    });
    onClose();
  };

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    if (/^\d*$/.test(value)) {
      setValue(value);
    }
  };

  return (
    <ModalWrappedContent
      isOpen={isOpen}
      onClose={onClose}
      title={'Edit Investment Amount'}
      confirmButton={{
        name: 'Save',
        onClick: saveValue,
        disabled: !value
      }}
    >
      {errorText && <ErrorNotification title={errorText?.title ?? ''} message={errorText?.message ?? ''} />}
      <Label required>{allocation.proposalType === 'Increase' ? 'Follow-on Investment Amount' : 'Initial Investment Amount'}</Label>
      <Input isBackground={!!value} width="100%" type="text" value={value} onChange={handleInputChange} autoFocus />
      <Description>{allocation.proposalType === 'Increase' ? '' : `Initial Investment minimum: $${allocation.investment.minimum}`}</Description>
    </ModalWrappedContent>
  );
};

export default EditAmount;

const Input = styled.input<{ width: string; isBackground: boolean }>`
  width: ${({ width }) => width};
  border-radius: 4px;
  border: ${({ isBackground, theme }) => (isBackground ? `1px solid ${theme.context.success}` : `1px solid  ${theme.border.base}`)};
  background-color: ${({ isBackground, theme }) => (isBackground ? '#efffea' : theme.layer[1])};
  color: ${({ isBackground, theme }) => (isBackground ? '' : theme.font.strong)};

  padding: 10px;
  font-size: 16px;
  font-family: Blinker;
  font-weight: 400;

  &:focus {
    outline: none;
  }
`;

const Description = styled.span`
  padding-top: 10px;
  font-weight: 300;
  font-size: 13px;
  line-height: 18px;
  color: #7f7f7f;
`;
