import FormattingTooltip from '@/components/basicComponents/tooltip/dollarsTooltip';
import { AnchorPopover } from '@/components/table/anchor-drop-down/fat-index';
import DropdownActions from '@/components/table/fat-dropdownActions';
import NoResults from '@/components/table/noResults';
import RedirectTitleCell from '@/components/table/redirectTitleCell';
import { TableRowCell, TableRowWrapper, TextCell } from '@/components/table/styles';
import { TableColumns } from '@/components/table/types';
import guid from '@/utils/guid';
import styled from 'styled-components';
import { InfoList } from '../infoList';
import { ILegalEntity } from '../types';

interface TableBodyProps {
  tableRows: ILegalEntity[];
  openEntityDetails: (id: string) => void;
  openEditEntityPage: (id: string) => void;
  showSpecificColumn: boolean;
  tableColumns: TableColumns[];
}

const TableBody = ({ tableRows, openEntityDetails, openEditEntityPage, showSpecificColumn, tableColumns }: TableBodyProps) => {
  if (!tableRows?.length) {
    return <NoResults />;
  }

  const WIDTH = 100 / tableColumns.length;

  return (
    <>
      {tableRows.map((row) => (
        <CustomTableRowWrapper key={guid()}>
          <TableRowCell width={WIDTH}>
            <RedirectTitleCell id="actionCurioText" onClick={() => openEntityDetails(row.id)}>
              {row.entityName}
            </RedirectTitleCell>
          </TableRowCell>
          {showSpecificColumn && (
            <TableRowCell width={WIDTH}>
              <TextCell>
                {row.ignoreAllocationTargets ? <>--</> : <FormattingTooltip zIndex={1000}>{+row.totalEntityValue}</FormattingTooltip>}
              </TextCell>
            </TableRowCell>
          )}
          {showSpecificColumn && (
            <TableRowCell width={WIDTH}>
              <TextCell>{row.ignoreAllocationTargets ? <>--</> : <>{row.privatePercentage.toFixed(2)}%</>}</TextCell>
            </TableRowCell>
          )}
          {showSpecificColumn && (
            <TableRowCell width={WIDTH}>
              <TextCell>{row.ignoreAllocationTargets ? <>--</> : row.overCommitRatio.toFixed(2)}</TextCell>
            </TableRowCell>
          )}
          <TableRowCell width={WIDTH}>
            <TextCell>
              <FormattingTooltip zIndex={1000}>{+row.economicExposure}</FormattingTooltip>
            </TextCell>
          </TableRowCell>
          {showSpecificColumn && (
            <TableRowCell width={WIDTH}>
              <TextCell>
                {row.ignoreAllocationTargets ? (
                  <>--</>
                ) : (
                  <AnchorPopover
                    title={row.entityName}
                    showLeft
                    hyperTitle={() => openEntityDetails(row.id)}
                    rowBody={
                      <RedirectTitleCell id="actionCurioText">
                        <FormattingTooltip zIndex={11}>{+row.capacity}</FormattingTooltip>
                      </RedirectTitleCell>
                    }
                  >
                    <InfoList onRedirect={() => openEntityDetails(row.id)} title={row.entityName} data={row} />
                  </AnchorPopover>
                )}
              </TextCell>
            </TableRowCell>
          )}
          <DropdownActionsWrapper>
            <DropdownActions
              items={[
                {
                  name: 'View',
                  onClick: () => openEntityDetails(row.id)
                },
                {
                  name: 'Edit',
                  onClick: () => openEditEntityPage(row.id)
                }
              ]}
            />
          </DropdownActionsWrapper>
        </CustomTableRowWrapper>
      ))}
    </>
  );
};

export default TableBody;

const CustomTableRowWrapper = styled(TableRowWrapper)`
  position: relative;
`;

const DropdownActionsWrapper = styled.div`
  position: absolute;
  right: 20px;
`;
